import {
  always,
  ifElse
} from 'ramda'
import { isTitle } from '../content'

export const customDimensions = {
  CategoryName: 'dimension1',
  ShowTitle: 'dimension2',
  UserId: 'dimension3',
  ProfileId: 'dimension4',
  SessionId: 'dimension5',
  ParentalControl: 'dimension6',
  ProfileName: 'dimension7',
  Manufacturer: 'dimension8',
  Model: 'dimension9',
  SeasonNumber: 'dimension10',
  EpisodeNumber: 'dimension11',
  Promo: 'dimension12',
  Timestamp: 'dimension13',
  TimestampPlayStart: 'dimension14',
  TimestampPlayStop: 'dimension15',
  GAClientID: 'dimension16',
  CarouselNumber: 'dimension17',
  AdditionalGenre: 'dimension18',
  CarouselNumberTBD: 'dimension19',
  SeriesTitleHouseId: 'dimension20',
  SeasonHouseId: 'dimension21',
  EpisodeHouseId: 'dimension22',
  Email: 'dimension23',
  ComponentName: 'dimension24'
}

export const getCategoryNameFromContentItem = ifElse(isTitle, always('Movies'), always('TV'))

export const COMPONENT_NAME_SERIES_DETAIL = 'SeriesDetail'
export const COMPONENT_NAME_SERIES_DETAIL_KIDS = 'SeriesDetailKids'
export const COMPONENT_NAME_TITLE_DETAIL = 'TitleDetail'
export const COMPONENT_NAME_TITLE_DETAIL_KIDS = 'TitleDetailKids'
export const COMPONENT_NAME_MY_LIST = 'MyList'
export const COMPONENT_NAME_MY_LIST_KIDS = 'MyListKids'
export const COMPONENT_NAME_MY_RENTALS = 'MyRentals'
export const COMPONENT_NAME_MY_RENTALS_KIDS = 'MyRentalsKids'
export const COMPONENT_NAME_GRID = 'Grid'
export const COMPONENT_NAME_GRID_KIDS = 'GridKids'
export const COMPONENT_NAME_CAROUSEL = 'Carousel'
export const COMPONENT_NAME_CAROUSEL_KIDS = 'CarouselKids'
