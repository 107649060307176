import React from 'react'
import PropTypes from 'prop-types'

import Arrow from '../navigation-arrow'
import switchIcon from '../../../../images/switch.svg'
import styles from './navigation-side.css'
import { E2E_SELECTORS } from '../../../e2e-selectors'

const NavAvatarSection = ({
  userName,
  avatarUri,
  isKid,
  isExpanded,
  onToggleAccountNav,
  onSwitchProfileClick
}) => {
  return (
    <div className={styles.navAvatarWrapper}>
      <div
        className={styles.accountNavAvatar}
        onClick={onToggleAccountNav}
        data-lbx-e2e={E2E_SELECTORS.ACCOUNT_NAME_SIDE_NAVIGATION}
      >
        <img className={styles.avatar} src={avatarUri} alt="Avatar" />
        <span className={styles.userName} data-lbx-e2e={`targetName: ${userName}`}>{userName}</span>
        {
          !isKid && <Arrow isExpanded={isExpanded} />
        }
      </div>
      <div
        className={styles.switchProfile}
        data-lbx-e2e={E2E_SELECTORS.SWITCH}
        onClick={onSwitchProfileClick}
      >
        <img src={switchIcon} className={styles.switchIcon} />
        SWITCH
      </div>
    </div>
  )
}

NavAvatarSection.defaultProps = {
  isExpanded: false,
  onToggleAccountNav: null
}

NavAvatarSection.propTypes = {
  userName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]).isRequired,
  avatarUri: PropTypes.string.isRequired,
  isKid: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool,
  onToggleAccountNav: PropTypes.func,
  onSwitchProfileClick: PropTypes.func.isRequired
}

export default NavAvatarSection
