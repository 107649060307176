import React, { Suspense } from 'react'

import { LoadingComponent } from '../../../loading'

const SubscriptionAndPayment = React.lazy(() => import('./subscription-and-payment'))

const AsyncSubscriptionAndPayment = props => (
  <Suspense fallback={<LoadingComponent />}>
    <SubscriptionAndPayment {...props} />
  </Suspense>
)

export default AsyncSubscriptionAndPayment
