import React, { createContext, useReducer } from 'react'
import { initialState, reducer } from './continuity-reducer'

export const ContinuityContext = createContext(initialState)

const ContinuityProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <ContinuityContext.Provider value={[state, dispatch]}>
      {children}
    </ContinuityContext.Provider>
  )
}

export default ContinuityProvider
