import {
  find,
  propEq
} from 'ramda'

import { MW_ERRORS, MW_ERROR_MESSAGES } from '../../constants'

import {
  updateAuthTokenAndDefaultTheme,
  displayErrorDialog
} from '../../actions'
import { segmentTrackDeviceLimit } from '../../segment/segment-track'

export function getSessionExpiredError(errors = []) {
  return find(propEq('code', MW_ERRORS.SESSION_EXPIRED))(errors)
}

/**
 * Handles expired session tokens through the network interface afterware
 * @param {Array} errors - List of GraphQL JSON error objects
 * @param {Object} store - Redux store
 */
export function handleExpiredSession(errors, store) {
  const sessionExpired = getSessionExpiredError(errors)

  if (!sessionExpired) {
    return false
  }

  store.dispatch(displayErrorDialog({
    message: sessionExpired.message || MW_ERROR_MESSAGES.INTERNAL_SERVER_ERROR_MESSAGE
  }))

  store.dispatch(updateAuthTokenAndDefaultTheme(null))

  // Add segment data analytics for reaching device limit
  if (sessionExpired.code === 'SESSION_EXPIRED') {
    const { code, message } = sessionExpired
    segmentTrackDeviceLimit({ code, message })
  }

  return 1
}
