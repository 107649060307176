import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles(theme => ({
  carouseTitle: {
    margin: [25, 0, 15, 50],
    fontSize: 24,
    display: 'flex',
    alignItems: 'center'
  },

  icon: {
    marginRight: 5
  },

  kidsIcon: {
    '& path': {
      fill: [theme.black]
    }
  }
}))
