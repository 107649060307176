import {
  T,
  any,
  cond,
  curryN,
  evolve,
  identity,
  lensPath,
  map,
  over,
  pathEq,
  pipe,
  reject,
  view,
  path,
  tryCatch,
  always,
  uniq,
  allPass
} from 'ramda'
import qs from 'query-string'

import { STATIC_SCREENS, SCREEN_SIZE_BREAKPOINTS } from '../constants'

const myListComponentsLens = lensPath(['screen', 'components'])

export const hasMyListComponentsP = pipe(
  view(myListComponentsLens),
  any(pathEq(['subType'], STATIC_SCREENS.MY_LIST.subType))
)

export const dropMyListTile = curryN(
  2,
  (contentItemId, screen) => over(
    myListComponentsLens,
    map(
      cond([
        [
          pathEq(['subType'], STATIC_SCREENS.MY_LIST.subType),
          evolve({
            tiles: reject(pathEq(['contentItem', 'id'], contentItemId))
          })
        ],
        [T, identity]
      ])
    )
  )(screen)
)

// Extracts the ids needed for the playbackInfoMany query
export const getPlabackInfoIds = (data) => {
  if (!data.screen) return []
  return uniq(data.screen.components.reduce((ids, {
    __typename, subType, tiles, slides
  }) => {
    // For carousel tiles, we only care about MY_RENTALS and MY_CONTINUE_WATCHING
    if (__typename === 'Carousel' && ['MY_RENTALS', 'MY_CONTINUE_WATCHING'].includes(subType)) {
      const tileIds = tiles.map(tile => tile.contentItem.id)
      return ids.concat(tileIds)
    }

    if (__typename === 'Hotspot') {
      const tileIds = slides.reduce((memo, slide) => {
        if (slide.tile) {
          memo.push(slide.tile.contentItem.id)
        }
        return memo
      }, [])
      return ids.concat(tileIds)
    }

    // We don't need to inspect any other component, so just return the memo
    return ids
  }, []))
}

export const mapPlaybackInfoResponse = (playbackInfoMany) => {
  if (!playbackInfoMany) return null
  return playbackInfoMany.reduce((dictionary, info) => {
    dictionary[info.contentItemId] = info
    return dictionary
  }, {})
}

export const getScreenId = cond([
  [path(['screenId']), path(['screenId'])],
  [
    path(['match', 'params', 'screenId']),
    path(['match', 'params', 'screenId'])
  ],
  [
    allPass([
      pathEq(['location', 'pathname'], '/'),
      pathEq(['isAuthenticated'], false)
    ]),
    always('/neon-home-guest')
  ],
  [pathEq(['location', 'pathname'], '/'), always('home')],
  [T, path(['location', 'pathname'])]
])

export const getOverrides = pipe(
  path(['location', 'search']),
  qs.parse,
  path(['overrides']),
  tryCatch(
    JSON.parse,
    always(undefined)
  )
)

export const isSmallerThan768 = ({ screenWidth }) => {
  return screenWidth < SCREEN_SIZE_BREAKPOINTS.MIN_768
}

export const isSmallerThan1024 =
  ({ screenWidth }) => {
    return screenWidth < SCREEN_SIZE_BREAKPOINTS.MIN_1024
  }

export const isLargerThan1024 =
  ({ screenWidth }) => {
    return screenWidth >= SCREEN_SIZE_BREAKPOINTS.MIN_1024
  }

export const isSmallerThan480 =
  ({ screenWidth }) => {
    return screenWidth < SCREEN_SIZE_BREAKPOINTS.MIN_480
  }

export const isSmallerThan667 =
  ({ screenWidth }) => {
    return screenWidth < SCREEN_SIZE_BREAKPOINTS.MIN_667
  }
