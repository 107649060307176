import ActionTypes from '../actions/types'

const voucherValidateError = (state = '', action) => {
  switch (action.type) {
    case ActionTypes.SET_VOUCHER_VALIDATE_ERROR:
      return action.voucherValidateError
    default:
      return state
  }
}

export default voucherValidateError
