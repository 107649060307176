import { withRouter } from 'react-router-dom'
import {
  compose,
  withHandlers,
  withState
} from 'recompose'

import CarouselTile from '../../../components/carousel/tile'
import withIsAuthenticated from '../../../hoc/is-authenticated'

const enhance = compose(
  withRouter,
  withIsAuthenticated,
  withState('isMouseHover', 'setIsMouseHover', false),
  withState('isVisible', 'setIsVisible', false),
  withHandlers({
    onMouseEnter: ({ setIsMouseHover }) => () => {
      setIsMouseHover(true)
    },
    onMouseLeave: ({ setIsMouseHover }) => () => {
      setIsMouseHover(false)
    },
    onClick: ({ history }) => (url) => {
      history.push(url)
    },
    onVisibilityChange: ({
      isVisible,
      setIsVisible
    }) => () => {
      if (isVisible) {
        return
      }
      setIsVisible(true)
    }
  })
)

export default enhance(CarouselTile)
