import { getGraphQLURI } from './get-api-url'

const initCastSender = ({ appConfig, updateCastSenderStatus, deviceInfo }) => {
  const interval = setInterval(() => {
    if (!window.castPlayer) {
      return
    }

    clearInterval(interval)

    window.castPlayer.updateCastSenderStatus = updateCastSenderStatus
    window.castPlayer.graphql_endpoint = getGraphQLURI()
    const castConfig = appConfig.values.find(a => a.key === 'GOOGLE_CAST_RECEIVER_ID')
    window.castPlayer.receiverId = castConfig && castConfig.value
    window.castPlayer.app_token = appConfig.session
      ? appConfig.session.token
      : null
    window.castPlayer.deviceInfo = deviceInfo
  }, 200)
}

export default initCastSender
