import React from 'react'
import PropTypes from 'prop-types'
import './html.css'

class HTMLSurvey extends React.Component {
  componentDidMount() {
    const { copy } = this.props
    const scriptCopy = this.extractScript(copy)
    this.scriptNode = document.createElement('script')
    this.scriptNode.type = 'text/javascript'
    this.scriptNode.textContent = scriptCopy
    document.body.appendChild(this.scriptNode)
  }

  componentWillUnmount() {
    document.body.removeChild(this.scriptNode)
    const surveyNode = document.getElementsByClassName('smcx-widget')[0]
    surveyNode && document.body.removeChild(surveyNode)
  }

  // eslint-disable-next-line
  extractScript = copy => {
    return /<script>(.+)<\/script>/gi.exec(copy)[1]
  }

  render() {
    return null
  }
}

HTMLSurvey.propTypes = {
  copy: PropTypes.string.isRequired
}

export default HTMLSurvey
