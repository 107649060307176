import { graphql } from 'react-apollo'
import {
  compose,
  withProps,
  branch,
  renderNothing
} from 'recompose'
import { pathOr, sort } from 'ramda'

import Devices from '../../../../components/settings/my-account/devices-and-downloads'
import DEVICES_QUERY from '../../../../../graphql/queries/devices.gql'

export const getRecentlyActiveDevices = (devices = []) => {
  if (devices === null) return []
  return sort((a, b) => {
    return new Date(b.lastSeen).getTime() - new Date(a.lastSeen).getTime()
  }, devices).slice(0, 5)
}

const getDevices = (deviceQuery) => {
  return compose(getRecentlyActiveDevices, pathOr([], ['devices']))(deviceQuery)
}

const enhance = compose(
  graphql(DEVICES_QUERY, {
    name: 'deviceQuery'
  }),
  withProps(({ deviceQuery }) => {
    return {
      loading: pathOr(false, ['loading'], deviceQuery),
      error: pathOr(false, ['error'], deviceQuery),
      devices: getDevices(deviceQuery)
    }
  }),
  branch(
    ({ loading, error }) => loading || error,
    renderNothing
  )
)

export default enhance(Devices)
