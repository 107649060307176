import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import cvod18 from '../../../images/cvod/cvod-18.svg'
import cvod16 from '../../../images/cvod/cvod-16.svg'
import cvod13 from '../../../images/cvod/cvod-13.svg'
import cvodG from '../../../images/cvod/cvod-g.svg'
import cvodM from '../../../images/cvod/cvod-m.svg'
import cvodPG from '../../../images/cvod/cvod-pg.svg'
import styles from './rating.css'

const smallSize = '20px'
const mediumSize = '30px'
const largeSize = '55px'
const xlargeSize = '75px'

const getIcon = value => {
  switch (value) {
    case 'R18':
      return cvod18
    case 'R16':
      return cvod16
    case 'R13':
      return cvod13
    case 'G':
      return cvodG
    case 'M':
      return cvodM
    case 'PG':
      return cvodPG
    default:
      return null
  }
}

export const Rating = ({
  size = 'small',
  rating,
  showReason,
  className,
  isInPlayer
}) => {
  const icon = getIcon(rating.rating)

  let width = ''
  let height = ''

  if (size === 'small') {
    width = smallSize
    height = smallSize
  } else if (size === 'medium') {
    width = mediumSize
    height = mediumSize
  } else if (size === 'large') {
    width = largeSize
    height = largeSize
  } else if (size === 'xlarge') {
    width = xlargeSize
    height = xlargeSize
  }

  return (
    <div className={classNames(className, styles.iconEffect)}>
      <img
        src={icon}
        width={width}
        height={height}
        className={classNames({
          [styles.iconInPlayer]: isInPlayer
        })}
      />
      {showReason && rating.reason && <p>{rating.reason}</p>}
    </div>
  )
}

Rating.propTypes = {
  rating: PropTypes.shape({
    rating: PropTypes.string.isRequired,
    reason: PropTypes.string
  }).isRequired,
  showReason: PropTypes.bool.isRequired,
  size: PropTypes.string.isRequired, // small, medium and large
  className: PropTypes.string,
  isInPlayer: PropTypes.bool
}
