import {
  compose, branch, renderComponent, shouldUpdate
} from 'recompose'
import { connect } from 'react-redux'
import { isAfter, addMinutes, parseISO } from 'date-fns'
import { pathOr } from 'ramda'
import { MY_ACCOUNT } from '../constants'

import {
  getAccountIdFromState
} from '../selectors/session'

import EnterAccountPasswordModal from '../components/modals/enter-account-password'

const mapStateToProps = (state) => {
  const accountId = getAccountIdFromState(state)
  return {
    passwordTimestamp: pathOr(null, ['accounts', accountId, 'passwordTimestamp'], state)
  }
}

const withEnterAccountPassword = compose(
  connect(mapStateToProps),
  // Prevent updates to this HOC aside from when actually entering the password.
  // Whenever this needs to be engaged again, it's remounted, so updating isn't needed.
  shouldUpdate((props, nextProps) => props.passwordTimestamp !== nextProps.passwordTimestamp),
  branch(
    ({ passwordTimestamp }) => {
      // if passwordTimestamp === null, ask for password
      if (!passwordTimestamp) return true
      const expiryTime = addMinutes(parseISO(passwordTimestamp), MY_ACCOUNT.PASSWORD_VALIDATION_INTERVAL)
      const isExpired = isAfter(new Date(), expiryTime)
      return isExpired
    },
    renderComponent(EnterAccountPasswordModal)
  )
)

export default withEnterAccountPassword
