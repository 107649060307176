import {
  READY_TO_RESET,
  SAFE_APOLLO_RESET,
  SAFE_APOLLO_RESET_DONE
} from './actions/types'
import ActionTypes from '../actions/types'
import { getAccountIdFromState, getSelectedProfileFromState } from '../selectors/session'

export class AuthMiddleware {
  constructor() {
    this.apolloClient = null
    this.postReadyAction = null
    this.reduxMiddleware = this.reduxMiddleware.bind(this)
  }

  reduxMiddleware(store) {
    return next => (action) => {
      switch (action.type) {
        case SAFE_APOLLO_RESET:
          if (action.postReadyAction) {
            this.postReadyAction = action.postReadyAction
          }
          return next(action)
        case READY_TO_RESET:
          return this.handleReset(store, next, action)

        // Actions from rest of app
        case ActionTypes.USER_LOGIN:
          return this.handleLogin(store, next, action)
        case ActionTypes.USER_LOGOUT:
          return this.handleLogout(store, next, action)
        case ActionTypes.UPDATE_AUTH_TOKEN:
        case ActionTypes.UPDATE_AUTH_TOKEN_AND_THEME:
          return this.handleUpdateAuthToken(store, next, action)
        default:
          return next(action)
      }
    }
  }

  handleReset({ dispatch }, next) {
    const result = this.postReadyAction
      ? next(this.postReadyAction)
      : null

    this.postReadyAction = null

    this.apolloClient.resetStore().then(() => {
      dispatch({ type: SAFE_APOLLO_RESET_DONE })
    })

    return result
  }

  handleLogin({ dispatch, getState }, next, action) {
    if (!this.apolloClient) return next(action)

    dispatch({ type: SAFE_APOLLO_RESET, postReadyAction: action })

    return null
  }

  handleLogout({ dispatch, getState }, next, action) {
    if (!this.apolloClient) return next(action)

    dispatch({ type: SAFE_APOLLO_RESET, postReadyAction: action })

    return null
  }

  handleUpdateAuthToken({ dispatch, getState }, next, action) {
    if (!this.apolloClient) return next(action)

    const oldState = getState()
    const prevAccountId = getAccountIdFromState(oldState)
    const prevSelectedProfile = getSelectedProfileFromState(oldState)

    const result = next(action)

    const newState = getState()
    const newAccountId = getAccountIdFromState(newState)
    const newSelectedProfile = getSelectedProfileFromState(newState)

    if (newAccountId !== prevAccountId || newSelectedProfile !== prevSelectedProfile) {
      dispatch({ type: SAFE_APOLLO_RESET, postReadyAction: action })
    }

    return result
  }
}

const authMiddleware = new AuthMiddleware()

export default authMiddleware
