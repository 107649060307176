import { withRouter } from 'react-router-dom'
import {
  compose, branch, renderComponent, withProps
} from 'recompose'

import HotspotDesktop from '../../components/hotspot/hotspot-desktop'
import HotspotMobile from '../../components/hotspot/hotspot-mobile'
import HotspotGuest from '../../components/hotspot/hotspot-guest'
import HotspotHalloween from '../../components/hotspot/hotspot-halloween'

import { isLargerThan1024 } from '../../lib/screen'
import { getLocation, contentAdapter } from './util'
import withIsAuthenticated from '../../hoc/is-authenticated'
import content from '../../components/hotspot/hotspot-guest/content'
import { withScreenWidthWatching } from '../../hoc/with-screen-width-watching'
import halloweenContent from '../../components/hotspot/hotspot-halloween/content'
import withConfigCacheOnly from '../../hoc/with-config'

export default compose(
  withScreenWidthWatching,
  withConfigCacheOnly,
  withIsAuthenticated,
  branch(
    () => {
      return location.href.toLowerCase().indexOf('halloween') !== -1
    },
    compose(
      withProps(() => {
        return {
          pageContent: halloweenContent
        }
      }),
      renderComponent(HotspotHalloween)
    ),
    branch(
      ({ isAuthenticated }) => isAuthenticated,
      branch(
        isLargerThan1024,
        renderComponent(HotspotDesktop),
        renderComponent(HotspotMobile)
      ),
      compose(
        withRouter,
        withProps(({ location, hotspot, appConfig }) => {
          return {
            pageContent:
              content[getLocation(location.pathname || 'home')] ||
              contentAdapter(hotspot),
            appConfig
          }
        }),
        renderComponent(HotspotGuest)
      )
    )
  )
)()
