const storeKey = 'lbxw:hotspotVideoCounter'

const useHotspotVideoCounter = () => {
  const current = { ...JSON.parse(localStorage.getItem(storeKey) || '{}') }

  const getByVideo = video => {
    return { ...JSON.parse(localStorage.getItem(storeKey) || '{}') }[video] || 0
  }

  const incrementByVideo = video => {
    current[video] = getByVideo(video) + 1
    localStorage.setItem(storeKey, JSON.stringify(current))
  }

  return {
    getByVideo,
    incrementByVideo
  }
}

export default useHotspotVideoCounter
