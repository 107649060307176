import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import { NavLink } from 'react-router-dom'
import ClickOutside from 'react-click-outside'
import classNames from 'classnames'

import NavigationItem from '../../../../containers/navigation/navigation-item'
import NavigationBurgerIcon from '../../../../containers/navigation/navigation-burger-icon'
import NAV_ITEM_FRAGMENT from '../../../../../graphql/fragments/nav-item.gql'

import NavAvatarSection from '../nav-avatar-section'
import MyListIcon from './my-list-icon'
import MyRentalsIcon from './my-rentals-icon'

import styles from '../navigation-side.css'

import { E2E_SELECTORS } from '../../../../e2e-selectors'

const KidsSideNavigation = ({
  display,
  isAuthenticated,
  name,
  avatarUri,
  mainSectionLinks,
  handleProfileChange,
  propertiesCount,
  onClickOutside,
  expandOnArrow,
  onSideNavLinkClick
}) => {
  return (
    <ClickOutside onClickOutside={onClickOutside}>
      <div
        className={
          classNames(styles.sideNavWrapper, { [styles.hidden]: !display })
        }
        data-lbx-e2e="nav nav-side"
      >
        <div className={styles.sideNav}>
          <div
            className={classNames({
              [styles.header]: true,
              [styles.navLogged]: isAuthenticated
            })}
          >
            <div className={styles.logoWrapper}>
              <NavigationBurgerIcon
                display={display}
              />
            </div>
          </div>
          {
            isAuthenticated && (
              <NavAvatarSection
                userName={name}
                avatarUri={avatarUri}
                onSwitchProfileClick={handleProfileChange}
                isKid
              />
            )
          }
          <div className={styles.accountLinks}>
            <NavLink
              exact
              to="/my-list"
              className={styles.accountLink}
              onClick={onSideNavLinkClick}
            >
              <MyListIcon />
              <span
                className={styles.accountLinkText}
                data-lbx-e2e={E2E_SELECTORS.MY_LIST_KIDS_NAVSIDE}
              >
                My List
              </span>
            </NavLink>
            <NavLink
              exact
              to="/my-rentals"
              className={styles.accountLink}
              onClick={onSideNavLinkClick}
            >
              <MyRentalsIcon />
              <span
                className={styles.accountLinkText}
                data-lbx-e2e={E2E_SELECTORS.MY_RENTALS_KIDS_NAVSIDE}
              >
                My Rentals
              </span>
            </NavLink>
          </div>
          <div className={styles.mainSection}>
            {
              mainSectionLinks.map((navItem, idx) => {
                return (
                  <NavigationItem
                    inSideNav
                    key={idx} // eslint-disable-line react/no-array-index-key
                    navItem={navItem}
                    className={styles.navLink}
                    activeClassName={styles.navLinkActive}
                    wrapperClassName={styles.kidsNavLinkWrapper}
                    propertiesCount={propertiesCount}
                    expandOnArrow={expandOnArrow}
                  />
                )
              })
            }
          </div>
          <div
            className={styles.exitKids}
            onClick={handleProfileChange}
            data-lbx-e2e={E2E_SELECTORS.EXIT_KIDS_NAVSIDE}
          >
            Exit Kids
          </div>
        </div>
      </div>
    </ClickOutside>
  )
}

KidsSideNavigation.propTypes = {
  display: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  avatarUri: PropTypes.string.isRequired,
  mainSectionLinks: PropTypes.arrayOf(propType(NAV_ITEM_FRAGMENT)).isRequired,
  handleProfileChange: PropTypes.func.isRequired,
  propertiesCount: PropTypes.number.isRequired,
  onClickOutside: PropTypes.func.isRequired,
  onSideNavLinkClick: PropTypes.func.isRequired,
  expandOnArrow: PropTypes.bool
}

KidsSideNavigation.defaultProps = {
  expandOnArrow: true
}

export default KidsSideNavigation
