import React from 'react'
import PropTypes from 'prop-types'

import styles from './hotspt-guest.css'
import Slide from '../slide'
import Fader from '../fader'
import { appConfigPropType } from '../../player/utils/prop-types'

function HotspotGuest({
  screenWidth,
  pageContent,
  appConfig
}) {
  const rentalInfo = pageContent.slides.map(slide => slide.isRental)

  return (
    <>
      <Fader
        interval={5000}
        rentalInfo={rentalInfo}
      >
        {
          pageContent.slides.map((slide, index) => (
            <Slide
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              slide={slide}
              screenWidth={screenWidth}
              appConfig={appConfig}
            />
          ))
        }
      </Fader>
      {
        pageContent.slogan && (
          <div className={styles.sloganWrapper}>
            {pageContent.slogan}
          </div>
        )
      }
    </>
  )
}

HotspotGuest.propTypes = {
  screenWidth: PropTypes.number.isRequired,
  pageContent: PropTypes.shape({
    slides: PropTypes.arrayOf(PropTypes.shape({
      image: PropTypes.string.isRequired,
      copyright: PropTypes.string,
      line1: PropTypes.string,
      line2: PropTypes.string,
      line3: PropTypes.string,
      cta: PropTypes.shape({
        text: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
      })
    })).isRequired,
    slogan: PropTypes.string
  }).isRequired,
  appConfig: appConfigPropType.isRequired
}

export default HotspotGuest
