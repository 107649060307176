import { analytics } from './segment-setup'
import { getCurrentProfile } from './getCurrentProfile'

/**
 * Provides functions to enable segment data collection
 * operations on the segment workspace "Sky-As-An-App".
 */

export const segmentPage = ({ path, url }) => {
  // title & search might be inaccurate due to the delaying, path and url provide enough info for web at this stage
  analytics.page({
    path, url, search: null, title: null, referrer: null, userProfile: getCurrentProfile()
  })
}
