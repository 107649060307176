import {
  compose,
  setDisplayName,
  branch,
  renderNothing,
  withHandlers,
  withProps
} from 'recompose'
import { connect } from 'react-redux'

import withConfig from '../../hoc/with-config'
import { findConfigValue } from '../../lib/config'
import { MW_ERRORS, MW_ERROR_MESSAGES } from '../../constants'

import ErrorDialog from '../../components/error-dialog'

import { dismissErrorDialog } from '../../actions'

export const mapStateToProps = (state) => {
  const { error } = state

  // AM: The error from the server does not come with a code, just message. So we map on message here :(
  const errorMessagesToMap = [
    MW_ERROR_MESSAGES.STREAMING_LIMIT_SVOD,
    MW_ERROR_MESSAGES.STREAMING_LIMIT_SVOD_UPGRADE
  ]

  if (errorMessagesToMap.includes(error.message)) {
    return {
      errorCode: MW_ERRORS.STREAMING_LIMIT_NEON,
      errorMessage: MW_ERROR_MESSAGES.STREAMING_LIMIT_NEON
    }
  }

  return {
    errorMessage: error.message,
    errorCode: error.code
  }
}

const enhance = compose(
  setDisplayName('ErrorDialogContainer'),
  withConfig,
  connect(mapStateToProps, dispatch => ({
    dismiss: () => {
      dispatch(dismissErrorDialog())
    }
  })),
  branch(({ errorMessage }) => !errorMessage, renderNothing),
  withProps(({ appConfig }) => {
    return {
      errorHeader: findConfigValue('MODAL_TITLE_WARNING')(appConfig)
    }
  }),
  withHandlers({
    onConfirmClick: ({ dismiss }) => () => {
      dismiss()
    }
  })
)

export default enhance(ErrorDialog)
