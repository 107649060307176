import { useState, useEffect } from 'react'
import throttle from 'lodash.throttle'

/**
 * Hook to return screen changed width by giving it certain throttle time
 */
export const useScreenWidth = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  const updateWidth = throttle(() => {
    setScreenWidth(window.innerWidth)
  }, 100)

  useEffect(() => {
    window.addEventListener('resize', updateWidth)
    return () => window.removeEventListener('resize', updateWidth)
  }, [])
  return screenWidth
}
