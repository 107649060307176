import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './card.css'

export function CardWrapper({ className, children }) {
  return (
    <div className={classNames(styles.cardWrapper, className)}>
      {children}
    </div>
  )
}
CardWrapper.propTypes = {
  className: PropTypes.string
}

CardWrapper.defaultProps = {
  className: ''
}

export function CardContent({ className, children }) {
  return (
    <div className={classNames(styles.cardContent, className)}>
      {children}
    </div>
  )
}
CardContent.propTypes = {
  className: PropTypes.string
}

CardContent.defaultProps = {
  className: ''
}

export function CardInnerHeading({ className, header, subHeader }) {
  return (
    <div className={classNames(styles.innerHeading, className)}>
      <h2 className={styles.header}>
        {header}
        {subHeader && <p className={styles.subHeader}>{subHeader}</p>}
      </h2>
    </div>
  )
}
CardInnerHeading.propTypes = {
  header: PropTypes.string,
  subHeader: PropTypes.string,
  className: PropTypes.string
}

CardInnerHeading.defaultProps = {
  header: '',
  subHeader: '',
  className: ''
}

const Card = React.memo(({ header, subHeader, children }) => {
  return (
    <CardWrapper>
      <CardContent>
        {header && <CardInnerHeading header={header} subHeader={subHeader} />}
        {children}
      </CardContent>
    </CardWrapper>
  )
})

Card.propTypes = {
  header: PropTypes.string,
  subHeader: PropTypes.string
}

Card.defaultProps = {
  header: '',
  subHeader: ''
}

export default Card
