import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import SubscriptionPriceSection from '../subscription-price-section/subscription-price-section'
import { usePlanChangeFlag } from '../../../../hooks/usePlanChange'
import { useFeatureFlags } from '../../../../hooks/useFeatureFlags'
import {
  basicPlanDetailsWithDiscount,
  standardPlanDetailsWithDiscount,
  annualPlanDetailsWithDiscount
} from '../../../shared/subscription-constants'
import SubscriptionDiscountSection from '../subscription-discount-section/subscription-discount-section'
import { useStyles } from './subscription-prices-view.styles'

const SubscriptionPricesView = props => {
  const { activeSubscription, updateActiveSubscription, currentSubscription } = props
  const getPlanDetails = usePlanChangeFlag()
  const styles = useStyles()
  const flags = useFeatureFlags(['discount_basic', 'discount_standard', 'discount_annual'])
  const showBasicDiscount = flags.discount_basic
  const showStandardDiscount = flags.discount_standard
  const showAnnualDiscount = flags.discount_annual

  return (
    <div>
      <div className={styles.priceSectionFrame}>
        <div className={styles.priceSectionLeftFrame}>
          <div className={styles.outlineButtonPink}>With ADS</div>
          <SubscriptionPriceSection
            title={getPlanDetails?.basic.title}
            label={getPlanDetails?.basic.label}
            price={getPlanDetails?.basic.price}
            description={getPlanDetails?.basic.description}
            unit={getPlanDetails?.basic.unit}
            activeSubscription={activeSubscription}
            updateActiveSubscription={updateActiveSubscription}
            currentSubscription={currentSubscription}
            showBasicDiscount={showBasicDiscount}
            showStandardDiscount={showStandardDiscount}
            showAnnualDiscount={showAnnualDiscount}
          />
        </div>

        <div className={styles.priceSectionRightFrame}>
          <div className={styles.outlineButtonPink}>Uninterrupted Viewing</div>
          <div className={styles.priceSectionFrame}>
            <SubscriptionPriceSection
              title={getPlanDetails?.standard.title}
              label={getPlanDetails?.standard.label}
              price={getPlanDetails?.standard.price}
              description={getPlanDetails?.standard.description}
              unit={getPlanDetails?.standard.unit}
              activeSubscription={activeSubscription}
              updateActiveSubscription={updateActiveSubscription}
              currentSubscription={currentSubscription}
              showBasicDiscount={showBasicDiscount}
              showStandardDiscount={showStandardDiscount}
              showAnnualDiscount={showAnnualDiscount}
            />

            <SubscriptionPriceSection
              title={getPlanDetails?.annual.title}
              label={getPlanDetails?.annual.label}
              price={getPlanDetails?.annual.price}
              description={getPlanDetails?.annual.description}
              unit={getPlanDetails?.annual.unit}
              activeSubscription={activeSubscription}
              updateActiveSubscription={updateActiveSubscription}
              currentSubscription={currentSubscription}
              showBasicDiscount={showBasicDiscount}
              showStandardDiscount={showStandardDiscount}
              showAnnualDiscount={showAnnualDiscount}
            />
          </div>
        </div>
      </div>

      {/* The section below is just for mobile and tablet view */}
      <div className={styles.discountSectionForMobileView}>
        <div className={styles.divider} />

        <div className={styles.discountSectionForDescription}>
          <div className={styles.discountDescription} onClick={() => updateActiveSubscription(getPlanDetails?.basic.title)}>
            { showBasicDiscount && !currentSubscription
              ? <SubscriptionDiscountSection priceDetail={basicPlanDetailsWithDiscount} />
              : (
                <div className={classNames(styles.normalDescription,
                  currentSubscription === getPlanDetails?.basic.title && styles.priceDescriptionDisabled)}
                >
                  {getPlanDetails?.basic.description}
                </div>
              )}
          </div>

          <div className={styles.discountDescription} onClick={() => updateActiveSubscription(getPlanDetails?.standard.title)}>
            { showStandardDiscount && !currentSubscription
              ? <SubscriptionDiscountSection priceDetail={standardPlanDetailsWithDiscount} />
              : (
                <div className={classNames(styles.normalDescription,
                  currentSubscription === getPlanDetails?.standard.title && styles.priceDescriptionDisabled)}
                >
                  {getPlanDetails?.standard.description}
                </div>
              )}
          </div>

          <div className={styles.discountDescription} onClick={() => updateActiveSubscription(getPlanDetails?.annual.title)}>
            { showAnnualDiscount && !currentSubscription
              ? <SubscriptionDiscountSection priceDetail={annualPlanDetailsWithDiscount} />
              : (
                <div className={classNames(styles.normalDescription,
                  currentSubscription === getPlanDetails?.annual.title && styles.priceDescriptionDisabled)}
                >
                  {getPlanDetails?.annual.description}
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  )
}

SubscriptionPricesView.propTypes = {
  activeSubscription: PropTypes.string.isRequired,
  updateActiveSubscription: PropTypes.func.isRequired,
  currentSubscription: PropTypes.string
}

SubscriptionPricesView.defaultProps = {
  activeSubscription: '',
  updateActiveSubscription: null,
  currentSubscription: ''
}

export default SubscriptionPricesView
