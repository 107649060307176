import { compose, withProps } from 'recompose'

// Higher order component that adds the 'usableOnClick' property, which
// If the user holds 'meta' while clicking:
// - Does not execute the defaultAction
// Else:
// - preventDefault, stopPropagation, execute defaultAction
export const withUsableOnClick = compose(
  withProps(() => ({

    /**
     * @param  {Function} defaultAction - The function to execute if the event is a normal click
     */
    usableOnClick: (defaultAction) => {
      return (event) => {
        if (event.metaKey) {
          return
        }

        event.stopPropagation()
        event.preventDefault()
        defaultAction()
      }
    }
  }))
)
