function calcRatio(x, y) {
  return y / x
}

const recipes = {
  tileEpisode: {
    width: 344,
    ratio: calcRatio(16, 9)
  },
  tileSeries: {
    width: 240,
    ratio: calcRatio(4, 3)
  },
  tileTitle: {
    width: 240,
    ratio: calcRatio(2, 3)
  },
  castAndCrew: {
    width: 177,
    ratio: calcRatio(2, 3)
  },
  purchaseConfirmation: {
    width: 450,
    ratio: calcRatio(2, 3)
  },
  subscriptionPaymentBanner: {
    width: 255,
    ratio: calcRatio(1.72297297, 1)
  },
  promoTile: {
    width: 150,
    ratio: calcRatio(2, 2)
  },
  series: {
    width: 260,
    ratio: calcRatio(16, 9)
  },
  characterTile: {
    width: 2000,
    ratio: calcRatio(0.88, 1)
  },
  mediaBar: {
    width: 900,
    ratio: calcRatio(16, 9)
  },
  hotspotDesktop: {
    width: 1440,
    ratio: calcRatio(16, 9)
  },
  hotspotMobile: {
    height: 500,
    ratio: calcRatio(16, 9)
  },
  videoFeature: {
    width: 900,
    ratio: calcRatio(16, 9)
  },
  trailer: {
    width: 300,
    ratio: calcRatio(16, 9)
  }
}

const contentTypeRecipes = {
  TITLE: recipes.tileTitle,
  SERIES: recipes.tileSeries,
  EXTRA: recipes.tileSeries,
  EPISODE: recipes.tileEpisode,
  PROMO: recipes.promoTile,
  CHARACTER: recipes.characterTile,
  LANDSCAPE: recipes.tileSeries,
  PORTRAIT: recipes.tileTitle
}

export default recipes

export function getTileRecipe(tileType) {
  return contentTypeRecipes[tileType] || recipes.tileSeries
}
