/** @module */

import { REHYDRATE } from 'redux-persist/constants'
import { path, pipe } from 'ramda'
import jwtDecode from 'jwt-decode'

import { createReducer } from '../lib/reducer'
import ActionTypes from '../actions/types'
import { initializeDataLayer } from '../lib/analytics/events'

// Reuseable context for UPDATE_AUTH_TOKEN action types
function updateAuthTokenContext(state, action) {
  const sessionToken = action.token
  let decodedJwt = {}
  try {
    decodedJwt = jwtDecode(sessionToken)
  } catch (error) {
    console.error('Invalid JWT:', error)
    localStorage.removeItem('token')
    return {
      ...state,
      token: null,
      decodedJwt: null
    }
  }
  // Update token in local storage
  // when switch profile
  if (localStorage.getItem('token')) {
    localStorage.setItem('token', sessionToken)
  }

  return {
    ...state,
    token: sessionToken,
    decodedJwt
  }
}

const authReducer = createReducer({}, {
  /**
   * Stores the encoded and decoded JWT in the sessions store.
   * @public
   * @name UserLoginHandler
   * @param {Object} state              - The previous state of the store
   * @param {string} [state.token]      - The still-encoded JWT
   * @param {Object} [state.decodedJwt] - The decoded data from the JWT
   * @param {Object} action             - The action
   * @param {string} action.type        - *MUST* be set to `ActionTypes.USER_LOGIN`
   * @param {Account} action.login      - This is the result of the `login` GraphQL query.
   * @param {boolean} action.rememberMe - Persist the user session between browser sessions
   */
  [ActionTypes.USER_LOGIN]: (state, { login, rememberMe }) => {
    const sessionToken = path(['session', 'token'], login)

    try {
      const decodedJwt = pipe(
        path(['session', 'token']),
        jwtDecode
      )(login)

      if (rememberMe) {
        localStorage.setItem('token', sessionToken)
      }

      return {
        ...state,
        token: sessionToken,
        decodedJwt
      }
    } catch (err) {
      console.error('Invalid JWT', err)
      return {
        ...state,
        token: null,
        decodedJwt: null
      }
    }
  },
  [ActionTypes.USER_LOGOUT]: (state) => {
    return {
      ...state,
      token: null,
      decodedJwt: null
    }
  },
  [ActionTypes.UPDATE_AUTH_TOKEN_AND_THEME]: updateAuthTokenContext,
  [ActionTypes.UPDATE_AUTH_TOKEN]: updateAuthTokenContext,
  [REHYDRATE]: (state) => {
    // Get token from localstorage if exists and add it to state
    const sessionToken = localStorage.getItem('token')
    if (!sessionToken) {
      initializeDataLayer()

      return {
        ...state,
        token: null,
        decodedJwt: null
      }
    }

    let decodedJwt = {}
    try {
      decodedJwt = jwtDecode(sessionToken)
    } catch (error) {
      console.error('Invalid JWT:', error)
      return {
        ...state,
        token: null,
        decodedJwt: null
      }
    }

    initializeDataLayer(decodedJwt.sub)

    return {
      ...state,
      token: sessionToken,
      decodedJwt
    }
  }
})

/**
 * The session reducer
 */
export default authReducer
