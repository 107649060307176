import React from 'react'
import PropTypes from 'prop-types'
import styles from './html-countdown.css'

// HoC to allow us to refresh a component on a ms interval
const onInterval = (refresh) => (WrappedComponent) => {
  return class WithInterval extends React.Component {
    constructor(props) {
      super(props)
      this.state = { ticks: 0 }
      this.interval = setInterval(this.tick.bind(this), refresh)
    }

    componentWillUnmount() {
      clearInterval(this.interval)
    }

    tick() {
      this.setState(prevState => ({ ticks: prevState.ticks + 1 }))
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }
}

const getDate = (date) => {
  return date ? new Date(date).getTime() : new Date().getTime()
}

const Time = (props) => {
  if (!props?.copy) {
    return null
  }

  const countdownData = JSON.parse(props?.copy)
  const countDownEndDate = getDate(countdownData.endDate)
  const nowDate = new Date().getTime()
  const distance = countDownEndDate - nowDate

  if (distance < 0) {
    return null
  }

  const days = Math.floor(distance / (1000 * 60 * 60 * 24))
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((distance % (1000 * 60)) / 1000)

  return (
    <div className={styles.root}>
      <div className={styles.countbox}>
        <p>
          {days}
          <span>days</span>
        </p>
        <p>
          {hours}
          <span>hours</span>
        </p>
        <p>
          {minutes}
          <span>minutes</span>
        </p>
        <p>
          {seconds}
          <span>seconds</span>
        </p>
      </div>
    </div>
  )
}

const Timer = onInterval(1000)(Time)

Time.propTypes = {
  copy: PropTypes.string
}

export default Timer
