import React from 'react'
import { propType } from 'graphql-anywhere'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { sanitizeDom } from '../../utils'
import styles from './markdown.css'

import MARKDOWN_FRAGMENT from '../../../graphql/fragments/markdown.gql'
import withIsWhiteTheme from '../../hoc/with-is-white-theme'

function Markdown({
  markdown,
  isWhiteTheme,
  isMarketing
}) {
  return (
    <div
      className={classnames(
        styles.wrapper,
        { [styles.whiteTheme]: isWhiteTheme || isMarketing }
      )}
    >
      <div
        className={styles.markdownWrapper}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: sanitizeDom(markdown.copy) }}
      />
    </div>
  )
}

Markdown.propTypes = {
  markdown: propType(MARKDOWN_FRAGMENT).isRequired,
  isWhiteTheme: PropTypes.bool.isRequired,
  isMarketing: PropTypes.bool.isRequired
}

export default withIsWhiteTheme(Markdown)
