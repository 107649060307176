import {
  findIndex,
  propEq,
  insert
} from 'ramda'

const createKidsLink = (isAuthenticated) => {
  const path = isAuthenticated
    ? `#`
    : '/kids'
  return {
    name: 'Kids',
    target: '40161d6d-8d34-4800-893b-ca702dec842e',
    type: 'SCREEN_ID',
    path,
    isRental: false,
    isDiscoverNav: false,
    children: [],
    isAuthenticated,
    isKids: true,
    __typename: 'NavItem'
  }
}

export const addKidsLinkWhenLoggedOut = (isAuthenticated, mainSectionLinks) => {
  const rentalLinkIndex = findIndex(propEq('isRental', true), mainSectionLinks)
  const discoverLinkIndex = findIndex(propEq('isDiscoverNav', true), mainSectionLinks)

  const insertionIndex = rentalLinkIndex >= 0 ? rentalLinkIndex : discoverLinkIndex

  const mainSectionLinksWithKids = insert(
    insertionIndex,
    createKidsLink(isAuthenticated),
    mainSectionLinks
  )

  return isAuthenticated ? mainSectionLinks : mainSectionLinksWithKids
}

export const addKidsLinkToAdultProfile = (isAuthenticated, isKid, mainSectionLinks) => {
  const rentalLinkIndex = findIndex(propEq('isRental', true), mainSectionLinks)
  const discoverLinkIndex = findIndex(propEq('isDiscoverNav', true), mainSectionLinks)

  const insertionIndex = rentalLinkIndex >= 0 ? rentalLinkIndex : discoverLinkIndex

  const mainSectionLinksWithKids = insert(
    insertionIndex,
    createKidsLink(isAuthenticated),
    mainSectionLinks
  )

  return isKid ? mainSectionLinks : mainSectionLinksWithKids
}
