import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { useTitleSearch } from '../../hooks/use-title-search'
import { useSeriesSearch } from '../../hooks/use-series-search'
import { useRentalSearch } from '../../hooks/use-rental-search'
import NoResults from './no-results'
import Searching from './searching'
import Fade from '../../../../components/transitions/fade'
import { SEARCH } from '../../../../constants'
import { useStyles } from './paged-searc-result.styles'
import { SearchCarouselWithType } from '../search-carousel/search-carousel-with-type'
import { useMyList } from '../../hooks/use-my-list'
import { segmentTrackSearchResult, segmentTrackSearchNoResult } from '../../../../segment/segment-track'

/**
 * Component to display all three carousels for search result
 */
export const PagedSearchResult = () => {
  const {
    seriesTiles,
    isLoadingSeries,
    fetchMoreSeries,
    seriesCount
  } = useSeriesSearch()

  const {
    titleTiles,
    isLoadingTitles,
    fetchMoreTitles,
    titleCount
  } = useTitleSearch()

  const {
    rentalsTiles,
    isLoadingRentals,
    fetchMoreRentals,
    rentalsCount
  } = useRentalSearch()

  useEffect(() => {
    document.body.classList.add('noScroll')
    return () => {
      document.body.classList.remove('noScroll')
    }
  }, [])

  const isLoading = isLoadingSeries || isLoadingTitles || isLoadingRentals

  const totalItems =
    seriesTiles.length + titleTiles.length + rentalsTiles.length

  const location = useLocation()
  const { search: searchQuery } = qs.parse(location.search)

  const mapState = useSelector(state => state)
  const { theme } = mapState

  const styles = useStyles()

  const { allItems, addToMyList, removeFromMyList } = useMyList()

  const isKids = theme === 'LIGHTBOX_KIDS'
  const classes = classNames({
    [styles.searchQuery]: !isKids
  })

  useEffect(() => {
    // Add segment data analytics for searching
    const totalCount = seriesCount + titleCount + rentalsCount
    if (!isLoading) {
      if (totalCount === 0) {
        segmentTrackSearchNoResult({
          searchItem: searchQuery
        })
      } else if (totalCount > 0) {
        segmentTrackSearchResult({
          searchItem: searchQuery,
          totalResults: totalCount
        })
      }
    }
  }, [seriesCount, titleCount, rentalsCount])

  return (
    <Fade
      in={Boolean(searchQuery.length >= SEARCH.MIN_CHAR)}
      mountOnEnter
      unmountOnExit
    >
      <div className={styles.wrapper}>
        <div className={styles.searchTitle}>
          Results for
          {' '}
          <span className={classes}>
            &apos;
            {searchQuery}
            &apos;
          </span>
        </div>
        <Fade in={Boolean(isLoading)} mountOnEnter unmountOnExit>
          <Searching theme={theme} />
        </Fade>
        <Fade
          in={Boolean(
            !isLoadingSeries &&
              !isLoadingTitles &&
              !isLoadingRentals &&
              totalItems === 0
          )}
          mountOnEnter
          unmountOnExit
        >
          <NoResults query={searchQuery} />
        </Fade>
        <Fade
          in={Boolean(!isLoading && searchQuery)}
          mountOnEnter
          unmountOnExit
        >
          <SearchCarouselWithType
            tiles={seriesTiles}
            carouselName="Series"
            tilesCount={seriesCount}
            onClickFetchMore={fetchMoreSeries}
            type="SERIES"
            theme={theme}
            myListItems={allItems}
            addToMyList={addToMyList}
            removeFromMyList={removeFromMyList}
          />
          <SearchCarouselWithType
            tiles={titleTiles}
            carouselName="Movies"
            tilesCount={titleCount}
            onClickFetchMore={fetchMoreTitles}
            type="TITLE"
            theme={theme}
            myListItems={allItems}
            addToMyList={addToMyList}
            removeFromMyList={removeFromMyList}
          />
          <SearchCarouselWithType
            tiles={rentalsTiles}
            carouselName="Rentals"
            tilesCount={rentalsCount}
            onClickFetchMore={fetchMoreRentals}
            type="RENTAL"
            theme={theme}
            myListItems={allItems}
            addToMyList={addToMyList}
            removeFromMyList={removeFromMyList}
          />
        </Fade>
      </div>
    </Fade>
  )
}
