import React from 'react'

export const TitleIcon = props => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.0597 9.29432L22.9589 7.73355C23.2601 7.6818 23.5286 7.51221 23.7055 7.26199C23.8823 7.01177 23.9531 6.70133 23.9023 6.39877L23.0854 0.987517C23.0374 0.683205 22.8727 0.409823 22.6266 0.225884C22.3787 0.043186 22.0686 -0.0332505 21.7646 0.013357L0.971922 3.29357C0.669874 3.34249 0.399459 3.50965 0.219934 3.7584C0.0404094 4.00716 -0.033582 4.31723 0.0141743 4.62066L0.830175 10.0165L0.877105 22.8393C0.877611 23.147 0.999567 23.4419 1.21625 23.6595C1.43293 23.877 1.72667 23.9995 2.03311 24H22.844C23.1504 23.9995 23.4442 23.877 23.6609 23.6595C23.8775 23.4419 23.9995 23.147 24 22.8393V9.29432H13.0597ZM17.5161 3.03681L20.9639 2.49251L21.4352 5.59194L20.8385 5.6881L17.5161 3.03681ZM12.1651 3.88211L14.4129 3.52726L17.7354 6.17663L15.4875 6.53148L12.1651 3.88211ZM21.9792 11.6167V21.0237C21.9774 21.199 21.9064 21.3664 21.7818 21.4892C21.6571 21.612 21.4891 21.6801 21.3145 21.6786H3.83559C3.66547 21.6801 3.50169 21.6138 3.38015 21.4943C3.2586 21.3748 3.18921 21.2118 3.18719 21.041V11.6167H21.9792ZM6.80173 4.72933L9.06201 4.37255L12.3892 7.02096L10.1318 7.37774L6.80173 4.72933ZM7.02584 7.86914L2.95542 8.51153L2.47942 5.41211L3.69671 5.21978L7.02584 7.86914Z"
        fill="white"
      />
    </svg>
  )
}
