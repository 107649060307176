import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

import AsyncMyAccountLayout from './index'
import { checkIsAuthenticated } from '../../lib/auth'
import { getModalLocation } from '../../lib/modal'

const MyAccountLayoutRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  const checkIfAuthenticated = (props) => {
    if (props.maintenanceModeIsEnabled) {
      return null
    }
    return isAuthenticated
      ? (
        <AsyncMyAccountLayout>
          <Component {...props} />
        </AsyncMyAccountLayout>
      )
      : (
        <Redirect
          to={getModalLocation({
            pathname: '/my-account/details'
          }, 'login')}
        />
      )
  }
  return (
    <Route
      {...rest}
      render={checkIfAuthenticated}
    />
  )
}

MyAccountLayoutRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  maintenanceModeIsEnabled: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: checkIsAuthenticated(state),
    maintenanceModeIsEnabled: state.maintenance.mode
  }
}

export default connect(mapStateToProps)(MyAccountLayoutRoute)
