import { mergeDeepRight } from 'ramda'
import { useLocation } from 'react-router-dom'
/**
 * Hook to get watch location for play button and watch button
 */
export function useGetWatchLocation(watchUrl, skipIntro, isKid, watchable) {
  const location = useLocation()

  if (isKid && !watchable) {
    // Do not show add subscription modal for user on kids profile without subscription
    watchUrl = location.pathname
  }

  if (typeof watchUrl === 'string') {
    return {
      pathname: watchUrl,
      state: { skipIntro }
    }
  }

  return mergeDeepRight(watchUrl, { state: { skipIntro } })
}
