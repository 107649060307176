import { analytics } from './segment-setup'

/**
 * Provides functions to enable segment data collection
 * operations on the segment workspace "Sky-As-An-App".
 */

export const segmentIdentify = (account, currentProfile, checkPrevUserId = false) => {
  const previousIdentifyId = analytics.instance?.user()?.id()
  /* Trigger identify in two scenarios:
  ** checkPrevUserId is false: sign in, sign up, switch profile
  ** checkPrevUserId is true: check if current userId is not equal to previous userId while user nav
  */
  if (!checkPrevUserId || account?.cpCustomerID !== previousIdentifyId) {
    analytics.identify(account?.cpCustomerID, {
      email: account?.email,
      firstName: account?.name,
      lastName: account?.surname,
      profile: {
        id: currentProfile?.id,
        name: currentProfile?.name
      }
    })
  }
}
