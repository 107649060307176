import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useSelectPlan } from '../../hooks/use-select-plan'
import { useStyles } from './plan-selection-medium-view.styles'
import RentMovieSection from '../rent-movie-view/rent-movie-section'
import SubscriptionPricesView from '../subscription-prices-view/subscription-prices-view'
import SubscriptionContentsView from '../subscription-contents-view/subscription-contents-view'
import SubscriptionCardContent from '../subscription-card-content/subscription-card-content'
import { planDetailsForAvod } from '../../../shared/subscription-constants'

const PlanSelectionMediumView = props => {
  const styles = useStyles()
  const { showRentMovies, selectToNext } = props
  const { activeSubscription, updateActiveSubscription } = useSelectPlan()

  return (
    <div className={classNames(styles.mediumScreenContainer, !showRentMovies && styles.mediumScreenContainerWidth)}>
      <div className={styles.tableRowTitle}>
        <div className={styles.tableRowTitleText}>{planDetailsForAvod.componentTitle}</div>
        <SubscriptionCardContent />
      </div>

      <div className={styles.tableRowContainer}>
        <SubscriptionPricesView activeSubscription={activeSubscription} updateActiveSubscription={updateActiveSubscription} />
      </div>

      <SubscriptionContentsView
        activeSubscription={activeSubscription}
        updateActiveSubscription={updateActiveSubscription}
        selectToNext={selectToNext}
        showRentMovies={showRentMovies}
        addSubscriptionFromSignup
      />

      { showRentMovies && <RentMovieSection selectToNext={selectToNext} /> }
    </div>
  )
}

PlanSelectionMediumView.propTypes = {
  showRentMovies: PropTypes.bool.isRequired,
  selectToNext: PropTypes.func
}

PlanSelectionMediumView.defaultProps = {
  showRentMovies: true
}

export default React.memo(PlanSelectionMediumView)
