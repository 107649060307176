import { merge, flip } from 'ramda'

/*
 * I stole this from https://char0n.github.io/ramda-adjunct/2.1.0/mergeRight.js.html
 * rather than bring in their whole library.
 *
 * It's simply merge, but the object on the _left_ takes precedence.
 */

const mergeRight = flip(merge)

export default mergeRight
