
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"HotspotSlideFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"HotspotSlide"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"image"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"header"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"subHeader"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"description"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"primaryCta"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"addMylistCta"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"removeMylistCta"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"copyright"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"badge"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"tags"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"headerImageUri"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"contentItem"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"brandOrDistributorLogo"},"arguments":[],"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"ContentItemLightFields"},"directives":[]}]}}]}}],"loc":{"start":0,"end":294}};
    doc.loc.source = {"body":"#import \"./content-item-light.gql\"\n\nfragment HotspotSlideFields on HotspotSlide {\n  image\n  header\n  subHeader\n  description\n  primaryCta\n  addMylistCta\n  removeMylistCta\n  copyright\n  badge\n  tags\n  headerImageUri\n  contentItem {\n    brandOrDistributorLogo\n    ...ContentItemLightFields\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./content-item-light.gql").definitions));


      module.exports = doc;
    
