import React from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'
import classNames from 'classnames'

import removeIcon from '../../../images/add-or-remove-from-list.svg'
import addRemoveCircleIcon from '../../../images/add-or-remove-from-list-circle.svg'
import styles from './add-or-remove-from-list.css'
import { E2E_SELECTORS } from '../../e2e-selectors'
import { THEME_OPTIONS } from '../../constants'

function AddOrRemoveFromListButton({
  contentItem,
  removeFromList,
  addToList,
  ctaAdd,
  ctaRemove,
  direction,
  contentIsOnList,
  showCta,
  className,
  iconWrapperClassName,
  isRental,
  theme
}) {
  const isKids = theme === THEME_OPTIONS.light

  return (
    <div // eslint-disable-line jsx-a11y/no-static-element-interactions
      className={classNames(
        styles.addOrRemoveFromListButton,
        className, {
          [styles.row]: direction === 'row',
          [styles.isRental]: isRental
        }
      )}
      onClick={(event) => {
        event.preventDefault()
        if (contentIsOnList) {
          return removeFromList(contentItem)
        }

        return addToList(contentItem)
      }}
      data-lbx-e2e={E2E_SELECTORS.ADD_REMOVE_FROM_MY_LIST_BUTTON}
    >
      <ReactSVG
        src={isKids ? removeIcon : addRemoveCircleIcon}
        beforeInjection={(svg) => {
          svg.setAttribute('class', classNames(styles.addOrRemoveFromListIcon, {
            [styles.contentIsOnList]: contentIsOnList,
            [styles.contentIsNotOnList]: !contentIsOnList
          }))
        }}
        className={classNames(
          styles.addOrRemoveFromListIconWrapper,
          iconWrapperClassName
        )}
      />
      {showCta && (
        <span className={classNames(styles.cta, styles.addOrRemoveButtonLabel)}>
          {contentIsOnList ? ctaRemove : ctaAdd}
        </span>
      )}
    </div>
  )
}

AddOrRemoveFromListButton.propTypes = {
  contentItem: PropTypes.oneOfType([PropTypes.object]).isRequired,
  removeFromList: PropTypes.func.isRequired,
  addToList: PropTypes.func.isRequired,
  ctaAdd: PropTypes.string,
  ctaRemove: PropTypes.string,
  direction: PropTypes.string,
  contentIsOnList: PropTypes.bool.isRequired,
  showCta: PropTypes.bool,
  className: PropTypes.string,
  iconWrapperClassName: PropTypes.string,
  isRental: PropTypes.bool,
  theme: PropTypes.string
}

AddOrRemoveFromListButton.defaultProps = {
  ctaAdd: 'My List',
  ctaRemove: 'Remove',
  direction: 'column',
  showCta: true,
  className: '',
  iconWrapperClassName: '',
  isRental: false,
  theme: THEME_OPTIONS.dark
}

export default AddOrRemoveFromListButton
