import {
  compose,
  setDisplayName,
  withProps
} from 'recompose'
import { propOr } from 'ramda'

import CalloutComponent from '../../components/callout'

import { TARGET_TYPES, UUID_SLUG } from '../../constants'

const enhance = compose(
  setDisplayName('CalloutContainer'),
  withProps(({ callout }) => {
    const cta = propOr({}, 'cta', callout)
    const type = propOr('', 'type', cta)

    const onCtaClickLocation = type === TARGET_TYPES.SCREEN_ID
      ? `/${UUID_SLUG}/${cta.target}`
      : cta.target

    return {
      onCtaClickLocation
    }
  })
)

export default enhance(CalloutComponent)
