import React, { Suspense, useContext } from 'react'
import { IdleQueue } from '../../lib/idlize/IdleQueue.mjs'
import { UseNotFoundContext } from '../../components/not-found/hooks/use-not-found-context'

const idleQueue = new IdleQueue({
  defaultMinTaskTime: 5
})

const NavigationFooter = React.lazy(() => import('./navigation-footer'))

const NavigationFooterLoader = props => (
  <Suspense fallback={null}>
    <NavigationFooter {...props} />
  </Suspense>
)

const AsyncNavigationFooter = props => {
  const [shouldRender, setShouldRender] = React.useState(false)
  const [{ isNotFoundRendered }] = useContext(UseNotFoundContext)

  React.useEffect(() => {
    idleQueue.pushTask(() => {
      if (!shouldRender) {
        setShouldRender(true)
      }
    })
    return () => {
      idleQueue.clearPendingTasks()
    }
  }, [])

  if (!shouldRender) {
    return null
  }
  if (isNotFoundRendered) {
    return null
  }
  return <NavigationFooterLoader {...props} />
}

export default AsyncNavigationFooter
