import React from 'react'

import Section from '../../../shared/section'

import MyRentals from '../../../../../containers/settings/my-account/my-activity/rentals'
import { TESTERS } from '../../../../../lib/tester-classnames'

function Rentals() {
  return (
    <Section title="Rentals" testerClassName={TESTERS.ACCOUNT.RENTALS}>
      <MyRentals />
    </Section>
  )
}

export default Rentals
