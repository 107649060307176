import React from 'react'

import PlayButton from '../../containers/play-button/details-play-button'

import styles from './details-play-button.css'

function DetailsPlayButton({ ...props }) {
  return (
    <div className={styles.playButtonPositioning}>
      <div className={styles.playButtonContainer}>
        <PlayButton
          {...props}
        />
      </div>
    </div>
  )
}

export default DetailsPlayButton
