import {
  compose
} from 'recompose'
import { withRouter } from 'react-router-dom'

import withIsAuthenticated from '../../hoc/is-authenticated'
import MainLayout from './main-layout'

const enhance = compose(
  withIsAuthenticated,
  withRouter
)

export default enhance(MainLayout)
