import { createUseStyles } from 'react-jss'
import { commonStyles } from '../../../shared/common.styles'

export const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),
  tableRowContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    [theme.media.smallMobile]: {
      flexDirection: 'column',
      alignItems: 'center',
      padding: 20
    },
    [theme.media.mobile]: {
      flexDirection: 'column',
      alignItems: 'center'
    },
    [theme.media.tablet]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '20px'
    },
    [theme.media.desktop]: {
      maxWidth: 1120,
      justifyContent: 'space-between',
      gap: '50px'
    },
    width: '100%',
    border: '2px solid #434343',
    boxSizing: 'border-box',
    borderRadius: 4,
    padding: 40
  },

  button: {
    zIndex: theme.zIndexCardBadge,
    position: 'relative',
    padding: 10,
    fontSize: 20,
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: [0, 0, 18, 0]
  },

  rentMovieTextFrame: {
    [theme.media.tablet]: {
      textAlign: 'center',
      padding: [0, 100]
    },
    [theme.media.mobile]: {
      textAlign: 'center'
    },
    [theme.media.smallMobile]: {
      textAlign: 'center'
    },
    [theme.media.desktop]: {
      flex: 3
    }
  },

  rentMovieButtonFrame: {
    [theme.media.desktop]: {
      flex: 2
    },
    [theme.media.tablet]: {
      width: '65%'
    },
    [theme.media.mobile]: {
      width: '65%'
    },
    [theme.media.smallMobile]: {
      width: '90%'
    },
    textAlign: 'center'
  },

  rentMovieText: {
    color: theme.neonPink,
    fontSize: 28,
    paddingBottom: 12,
    [theme.media.smallMobile]: {
      fontSize: 20,
      paddingBottom: 10
    }
  },

  rentMovieTextTitle: {
    fontSize: 20,
    paddingBottom: 12,
    [theme.media.smallMobile]: {
      fontSize: 16,
      paddingBottom: 8
    }
  },
  rentMovieTextSubTitle: {
    color: '#a0a0a0',
    fontSize: 16,
    paddingBottom: 12,
    [theme.media.smallMobile]: {
      fontSize: 12,
      paddingBottom: 8
    }
  },

  rentMovieLinkText: {
    color: theme.neonPink,
    textDecoration: 'underline'
  }
}))
