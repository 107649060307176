import { path } from 'ramda'

export function getTokenFromState(state) {
  return state.session && state.session.token
}

export function getJwtFromAccount(account) {
  return path(['session', 'token'])(account)
}

export function checkIsAuthenticated(state) {
  return Boolean(state.session && state.session.decodedJwt && state.session.decodedJwt.accountId)
}

export function getMyList(state) {
  return state.user.myList || []
}
