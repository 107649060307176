import { connect } from 'react-redux'
import { compose } from 'recompose'

import { checkIsAuthenticated } from '../lib/auth'

// Higher order component to manage loading screen on mutations
export default compose(
  connect(
    state => ({
      isAuthenticated: checkIsAuthenticated(state) // used to skip account query
    })
  )
)
