import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { propType } from 'graphql-anywhere'

import RemoveFromListOverlay from '../shared/remove-from-list-overlay'
import MyListButton from '../carousel/my-list-button'

import styles from './grid-overlay-series.css'

import TILE_FRAGMENT from '../../../graphql/fragments/tile.gql'

function GridOverlaySeries({
  tile,
  hasRemoveIcon,
  setRemoved,
  onRemoveClick,
  isOnMyList,
  isAuthenticated,
  addToMyList,
  removeFromMyList
}) {
  const contentItem = tile.contentItem

  let myListButton = null
  if (hasRemoveIcon) {
    myListButton = (
      <RemoveFromListOverlay
        contentItem={contentItem}
        setRemoved={setRemoved}
        onRemoveClick={onRemoveClick}
      />
    )
  } else if (isAuthenticated) {
    myListButton = (
      <MyListButton
        contentItem={contentItem}
        className={styles.addMyListButton}
        isOnMyList={isOnMyList}
        addAction={addToMyList}
        removeAction={removeFromMyList}
      />
    )
  }

  return (
    <div className={classNames(styles.wrapper, {
      [styles.wrapperNeon]: true
    })}
    >
      {myListButton}
    </div>
  )
}

GridOverlaySeries.propTypes = {
  tile: propType(TILE_FRAGMENT).isRequired,
  hasRemoveIcon: PropTypes.bool.isRequired,
  setRemoved: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  isOnMyList: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  addToMyList: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]).isRequired,
  removeFromMyList: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]).isRequired
}

GridOverlaySeries.defaultProps = {
  isOnMyList: false,
  isAuthenticated: false
}

export default GridOverlaySeries
