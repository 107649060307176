import React from 'react'
import { propType } from 'graphql-anywhere'

import styles from './episode.css'

import TILE_FRAGMENT from '../../../../../graphql/fragments/tile.gql'

function OverlayEpisode({ tile }) {
  const contentItem = tile.contentItem
  const title = contentItem.title
  const seasons = contentItem && contentItem.seasons &&
    contentItem.seasons.length > 1
    ? `seasons 1 - ${contentItem.seasons.length}`
    : 'season 1'
  const genres = (contentItem.genres || []).join(', ')

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.year}>{seasons}</div>
      <div className={styles.genres}>{genres}</div>
      <div className={styles.buttonWrapper}>
        <div className={styles.button}>
          More info
        </div>
      </div>
    </div>
  )
}

OverlayEpisode.propTypes = {
  tile: propType(TILE_FRAGMENT).isRequired
}

export default OverlayEpisode
