import React from 'react'
import PropTypes from 'prop-types'

import Form from '../forms/layout-form'
import Row from '../forms/layout-row'
import Col from '../forms/layout-column'
import PinInput from '../forms/pin-input'
import SubmitButton from '../forms/button'
import { FORM_VALUES } from '../../constants'

function PinForm({
  // Formik props
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  handleReset,
  errors,
  error,
  setError,
  touched,
  isSubmitting,
  // Custom props
  onCancel
}) {
  return (
    <Form isSubmitting={isSubmitting} handleSubmit={handleSubmit}>
      <div>
        <Row>
          <Col>
            <PinInput
              name="pin"
              value={values.pin}
              onChange={(event) => {
                if (error) {
                  setError('')
                }
                handleChange(event)
              }}
              onBlur={handleBlur}
              warning={(values.pin || touched.pin) && (errors.pin || error)}
              minLength="0"
              maxLength={`${FORM_VALUES.pin.length}`}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <SubmitButton disabled={isSubmitting} text="OK" />
          </Col>
          <Col>
            <SubmitButton
              disabled={isSubmitting}
              text="Cancel"
              onClick={() => { handleReset(); onCancel(false) }}
              secondary
            />
          </Col>
        </Row>
      </div>
    </Form>
  )
}

PinForm.propTypes = {
  values: PropTypes.oneOfType([PropTypes.object]).isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired
}

PinForm.defaultProps = {
  error: ''
}

export default PinForm
