import { combineReducers } from 'redux'

// Reducers
import accounts from './accounts'
import app from './app'
import auth from '../auth/reducer'
import castSender from './cast-sender'
import error from './error'
import loading from './loading'
import maintenance from './maintenance'
import navigation from './navigation'
import pin from './pin'
import player from './player'
import serviceMessages from './service-messages'
import session from './session'
import theme from './theme'
import user from './user'
import planUpgradePopup from './plan-upgrade-popup'
import manageSubsOptions from './manage-subs-options'
import currentSubscriptionName from './current-subscription-name'
import voucherValidateError from './voucher-validate-error'
import voucherValidateType from './voucher-validate-type'
import signupFormValue from './signup-form-value'

const reducers = combineReducers({
  accounts,
  app,
  auth,
  castSender,
  error,
  loading,
  maintenance,
  navigation,
  pin,
  player,
  serviceMessages,
  session,
  theme,
  user,
  planUpgradePopup,
  manageSubsOptions,
  currentSubscriptionName,
  voucherValidateError,
  voucherValidateType,
  signupFormValue
})

export default reducers
