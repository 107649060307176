import * as DOMPurify from 'dompurify'
import { isRelativeUrl, isValidUrl } from './url-helper'

export const initDomPurify = appConfig => {
  // Add a hook to enforce URI scheme allow-list
  DOMPurify.addHook('afterSanitizeAttributes', node => {
    // check all href attributes for validity
    if (node.hasAttribute('href')) {
      const href = node.getAttribute('href')

      if (isRelativeUrl(href)) {
        // keep the url if it is relative path
        return
      }

      if (!isValidUrl(href)) {
        node.removeAttribute('href')
        return
      }

      const url = new URL(href)

      // allowed URI schemes
      const allowDomains = appConfig?.whitelistedDomainsOnMarketingScreen || [] // ['neontv.co.nz', 'helpneon.freshdesk.com']

      const isDomainValid = allowDomains.some(
        domain => url.hostname.indexOf(domain) !== -1
      )

      if (!isDomainValid) {
        node.removeAttribute('href')
      }
    }
  })
}

export const sanitizeDom = dirtyContent => {
  const cleanDom = DOMPurify.sanitize(dirtyContent)
  return cleanDom
}
