import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { propType } from 'graphql-anywhere'
import {
  always,
  cond,
  path,
  pathOr,
  T
} from 'ramda'

import { compose } from 'recompose'

import { getWatchUrl } from '../../lib/utils'
import Imgix from '../imgix'
import { MEDIA_BAR_LAYOUTS } from '../../constants'
import { setTrailerLaunchedFromComponent } from '../../actions/index'

import MEDIA_BAR_FRAGMENT from '../../../graphql/fragments/media-bar.gql'

import styles from './media-bar.css'

import playCirle from '../../../images/play-circle.svg'
import withIsWhiteTheme from '../../hoc/with-is-white-theme'

const isVideoMediaBar = pathOr(false, ['extra'])

const getImageUri = cond([
  [path(['extra', 'tile', 'image']), path(['extra', 'tile', 'image'])],
  [path(['image', 'uri']), path(['image', 'uri'])],
  [T, always('')]
])

function MediaBar({
  mediaBar,
  setTrailerLaunched,
  isWhiteTheme,
  isMarketing
}) {
  const isImageSitsLeft = mediaBar.layout === MEDIA_BAR_LAYOUTS.LEFT

  return (
    <div
      className={classnames(
        styles.wrapper,
        { [styles.whiteTheme]: isWhiteTheme || isMarketing }
      )}
    >
      <div
        className={classnames(
          styles.imagePanel,
          isImageSitsLeft ? styles.left : styles.right
        )}
      >
        <div className={styles.imageWrapper}>
          <Imgix
            className={styles.image}
            recipe="mediaBar"
            src={getImageUri(mediaBar)}
          />
          {
            isVideoMediaBar(mediaBar) && (
              <Link
                to={getWatchUrl(mediaBar.extra)}
                onClick={setTrailerLaunched}
              >
                <img
                  className={styles.playButton}
                  src={playCirle}
                />
              </Link>
            )
          }
        </div>
      </div>
      <div
        className={classnames(
          styles.textPanel,
          !isImageSitsLeft ? styles.left : styles.right
        )}
      >
        <span className={styles.tagline}>
          {mediaBar.tagline}
        </span>
        <h3 className={styles.header}>
          {mediaBar.header}
        </h3>
        <p className={styles.description}>
          {mediaBar.description}
        </p>
      </div>
    </div>
  )
}

MediaBar.propTypes = {
  mediaBar: propType(MEDIA_BAR_FRAGMENT).isRequired,
  setTrailerLaunched: PropTypes.func.isRequired,
  isWhiteTheme: PropTypes.bool.isRequired,
  isMarketing: PropTypes.bool.isRequired
}

const mapDispatchToProps = dispatch => ({
  setTrailerLaunched: () => {
    dispatch(setTrailerLaunchedFromComponent(true))
  }
})

const enhance = compose(
  withIsWhiteTheme,
  connect(null, mapDispatchToProps)
)

export default enhance(MediaBar)
