// NOTE: This component was made to adapt and work with pre-existing flow.
// As stated in the parent component, it would be ideal to refactor the container->comp
// props to avoid unnecessary re-renders
// (i.e. Try and avoid passing objects and arrays down directly)

import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import {
  defaultTo,
  map,
  trim
} from 'ramda'

import { getHotspotCTAUrl } from '../../../lib/utils'
import AddOrRemoveFromListButton from '../../../containers/add-or-remove-from-list-button'

import HOTSPOT_SLIDE_FRAGMENT from '../../../../graphql/fragments/hotspot-slide.gql'
import CONTENT_ITEM_LIGHT_FRAGMENT from '../../../../graphql/fragments/content-item-light.gql'

import styles from './user-content-cta-box.css'
import {
  COMPONENT_NAME_TITLE_DETAIL,
  COMPONENT_NAME_TITLE_DETAIL_KIDS
} from '../../../lib/analytics/custom-dimensions'
import CTABoxHeaderImage from '../hotspot-header-image/hotspot-header-image'

const UserContentCTABox = ({
  slide,
  title,
  brandWords,
  onCtaClick,
  isKid,
  usableOnClick,
  myList,
  LinkNode,
  AddOrRemoveFromListButtonNode,
  isRental
}) => {
  const {
    badge,
    subHeader,
    description,
    contentItem,
    addMylistCta,
    removeMylistCta,
    primaryCta,
    headerImageUri
  } = slide
  const { tags } = slide
  const url = getHotspotCTAUrl(contentItem)
  const displayMyListCta = addMylistCta && removeMylistCta
  const applyCTABoxColor = (...classes) => classNames(...classes, {
    [styles.isRental]: isRental
  })

  const badgeContent = trim(badge) ? (
    <span>{badge}</span>
  ) : null

  return (
    <div className={styles.wrapper}>
      <div>
        <div className={styles.sideGradient} />
        <div className={styles.bottomGradient} />

        <div className={applyCTABoxColor(styles.ctaBox)}>
          <CTABoxHeaderImage headerImageUri={headerImageUri} />
          <div className={styles.headers}>
            <div className={applyCTABoxColor(styles.badge)}>{badgeContent}</div>
            <div className={styles.title}>{brandWords}</div>
            <div className={styles.title}>{title}</div>
          </div>
          <div className={styles.subHeader}>{subHeader}</div>
          <div className={styles.description}>
            {description && <p>{description}</p>}
            <p>{map(tag => <span key={tag}>{tag}</span>)(defaultTo([], tags))}</p>
          </div>
          <div className={styles.buttonsWrapper}>
            {
              primaryCta && (
                <LinkNode
                  className={applyCTABoxColor(styles.cta)}
                  onClick={usableOnClick(() => onCtaClick(url, contentItem))}
                  to={url}
                  data-e2e="user-hotspot-cta-button"
                >
                  {primaryCta}
                </LinkNode>
              )
            }
            {
              displayMyListCta && (
                <AddOrRemoveFromListButtonNode
                  direction="row"
                  contentItem={contentItem}
                  myList={myList}
                  ctaAdd={addMylistCta}
                  ctaRemove={removeMylistCta}
                  renderedInComponent={
                    isKid ? COMPONENT_NAME_TITLE_DETAIL_KIDS : COMPONENT_NAME_TITLE_DETAIL
                  }
                />
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

UserContentCTABox.propTypes = {
  slide: propType(HOTSPOT_SLIDE_FRAGMENT).isRequired,
  title: PropTypes.string,
  brandWords: PropTypes.string,
  onCtaClick: PropTypes.func.isRequired,
  usableOnClick: PropTypes.func.isRequired,
  myList: PropTypes.arrayOf(propType(CONTENT_ITEM_LIGHT_FRAGMENT)).isRequired,
  LinkNode: PropTypes.oneOfType([PropTypes.object]),
  AddOrRemoveFromListButtonNode: PropTypes.func,
  isKid: PropTypes.bool.isRequired,
  isRental: PropTypes.bool.isRequired
}

UserContentCTABox.defaultProps = {
  title: '',
  brandWords: '',
  LinkNode: Link,
  AddOrRemoveFromListButtonNode: AddOrRemoveFromListButton
}

export default UserContentCTABox
