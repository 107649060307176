import React from 'react'
import PropTypes from 'prop-types'
import {
  propEq,
  any,
  pathOr
} from 'ramda'
import ReactSVG from 'react-svg'
import classNames from 'classnames'

import styles from './loading-overlay.css'

import { LOADING_OVERLAY } from '../../../constants'
import { getLocationBaseWithHref } from '../../../lib/location'

import neonLoadingImg from '../../../../images/neon/loading-white.svg'
import neonCheckImg from '../../../../images/neon/check.svg'

function allCompleted(loaders) {
  const unfinished = loaders.find(loader => loader.loading)
  return !unfinished
}

const isError = propEq('error', true)
const checkIfErrorExist = any(isError)

export const loadingOnPlayer = (message) => {
  const loadingMsg = pathOr('', ['operations', 'playerLoading', 'loadingMsg'], LOADING_OVERLAY)
  const successMsg = pathOr('', ['operations', 'playerLoading', 'successMsg'], LOADING_OVERLAY)
  return message === loadingMsg || message === successMsg
}

function renderLoader(loader) {
  if (loader.loading) {
    return (
      <div className={styles.loadingMsg}>{loader.message}</div>
    )
  }

  if (loader.error) {
    return (
      <div className={styles.errorMsg}>{loader.message}</div>
    )
  }

  const isPlayer = loadingOnPlayer(loader.message) || getLocationBaseWithHref().includes('watch')

  return (
    <div
      data-e2e="success-msg-loader"
      className={classNames({
        [styles.successMsg]: !isPlayer,
        [styles.hideLoadingMsg]: isPlayer
      })}
    >
      {loader.message}
    </div>
  )
}

function LoadingOverlay({ loaders, theme }) {
  if (!loaders.length) {
    return null
  }

  const isPlayer = loadingOnPlayer(loaders[0].message)

  const successImg = checkIfErrorExist(loaders)
    ? null
    : (
      <ReactSVG
        src={neonCheckImg}
        beforeInjection={(svg) => {
          svg.setAttribute('class', classNames({
            [styles.completed]: !isPlayer,
            [styles.hideLoadingMsg]: isPlayer,
            [styles.kidsGreen]: false
          }))
        }}
      />
    )

  return (
    <div
      className={`loading-overlay ${styles.wrapper}`}
      data-lbx-e2e="loading-overlay"
    >
      <div>
        {
          allCompleted(loaders)
            ? successImg
            : (
              <ReactSVG
                src={neonLoadingImg}
                beforeInjection={(svg) => {
                  svg.setAttribute('class', classNames(
                    styles.loading, {
                      [styles.kidsGreen]: false
                    }
                  ))
                }}
              />
            )
        }
      </div>
      {
        loaders.map(loader => (
          <div key={loader.key}>
            {renderLoader(loader)}
          </div>
        ))
      }
    </div>
  )
}

LoadingOverlay.propTypes = {
  loaders: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  theme: PropTypes.string.isRequired
}

export default LoadingOverlay
