import React from 'react'
import PropTypes from 'prop-types'
import className from 'classnames'

import styles from './layout.css'

function Form({
  isSubmitting, handleSubmit, children, formClassName
}) {
  return (
    <form
      className={className({
        [styles.form]: true,
        [styles.isSubmitting]: isSubmitting,
        [formClassName]: formClassName
      })}
      onSubmit={handleSubmit}
    >
      {children}
    </form>
  )
}

Form.propTypes = {
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool.isRequired,
  formClassName: PropTypes.string
}

Form.defaultProps = {
  handleSubmit: () => { console.info('Form: no handleSubmit defined') },
  formClassName: ''
}

export default Form
