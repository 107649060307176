import { createUseStyles } from 'react-jss'
import { commonStyles } from '../../../modules/shared/common.styles'

export const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),

  emailInput: {
    color: '#000',
    backgroundColor: '#fff',
    width: 320,
    height: 52,
    borderRadius: '4px 0px 0px 4px',
    [theme.media.mobile]: {
      width: '100%',
      height: 42,
      borderRadius: '4px 4px 4px 4px'
    },
    [theme.media.smallMobile]: {
      width: '100%',
      height: 32,
      borderRadius: '4px 4px 4px 4px'
    }
  },

  emailBoxFrame: {
    display: 'flex',
    width: '100%',
    marginTop: 10,
    [theme.media.mobile]: {
      flexDirection: 'column',
      maxWidth: 556,
      marginTop: 10
    },
    [theme.media.smallMobile]: {
      flexDirection: 'column',
      width: 310,
      marginTop: 10
    }
  },

  button: {
    fontWeight: 500,
    textTransform: 'uppercase',
    [theme.media.mobile]: {
      marginTop: 10,
      width: 256
    }
  },

  buttonWrapper: {
    width: '100%',
    [theme.media.mobile]: {
      padding: '15px 0 20px 0',
      alignSelf: 'center'
    },
    [theme.media.smallMobile]: {
      padding: '15px 0 30px 0',
      alignSelf: 'center',
      width: 310
    }
  },

  errorMessage: {
    color: '#e73339',
    [theme.media.mobile]: {
      marginTop: '1px',
      fontSize: '14px'
    },
    [theme.media.smallMobile]: {
      marginTop: '0px',
      fontSize: '12px'
    }
  },

  exclamation: {
    marginTop: '5px'
  },

  checkEmailButton: {
    fontWeight: 500,
    fontSize: 18,
    height: 52,
    textTransform: 'uppercase',
    width: '100%',
    margin: 0,
    borderRadius: '0px 4px 4px 0px',
    padding: [1, 40],
    lineHeight: 0,
    [theme.media.tablet]: {
      padding: [1, 25]
    },
    [theme.media.mobile]: {
      height: 42,
      padding: [1, 10],
      fontSize: 16,
      borderRadius: '4px 4px 4px 4px'
    },
    [theme.media.smallMobile]: {
      height: 32,
      padding: [1, 10],
      fontSize: 12,
      borderRadius: '4px 4px 4px 4px'
    }
  }
}))
