import React from 'react'

import RentalsSection from './rentals'

function MyActivity() {
  return (
    <div>
      <RentalsSection />
    </div>
  )
}

export default MyActivity
