import React from 'react'

import neonLoadingImg from '../../../../images/neon/loading.svg'

import { loadingModalWrapper } from './loading-modal.css'

export function LoadingModal() {
  return (
    <div className={loadingModalWrapper}>
      <img src={neonLoadingImg} />
    </div>
  )
}
