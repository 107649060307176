import { prop } from 'ramda'
import { defaultDataIdFromObject } from 'apollo-cache-inmemory'

const dataIdFromObject = (object) => {
  switch (object.__typename) {
    case 'Screen':
      return object.id
    case 'NavSection':
      return `NavSection:${object.name}`
    case 'Account':
      return 'Account'
    case 'SubscriptionInformation':
      return 'SubscriptionInformation'
    case 'SubscriptionVoucherView':
      return 'SubscriptionVoucherView'
    case 'CurrentSubscription':
      return `CurrentSubscription:${prop('sku', object)}`
    case 'PlaybackDetails':
      return `PlaybackDetails:${object.contentItemId}`
    case 'Rating':
      // Prevent this from getting an ID at all, thus not allowing a globalish lookup
      return null
    case 'MyRentalHistory':
      return 'MyRentalHistory'
    default:
      return defaultDataIdFromObject(object)
  }
}

export default dataIdFromObject
