export const PLAYBACK_EVENT_CAPTIONS_TOGGLED = 'CAPTIONS_CHANGED'
export const PLAYBACK_EVENT_CONTINUE = 'CONTINUE'
export const PLAYBACK_EVENT_PAUSE = 'PAUSE'
export const PLAYBACK_EVENT_RESUME = 'RESUME'
export const PLAYBACK_EVENT_START = 'START'
export const PLAYBACK_EVENT_STOP = 'STOP'
export const PLAYBACK_EVENT_PREROLL_START = 'PREROLL_START'

export const PLAYBACK_INFO_NOT_STARTED = 'NOT_STARTED'
export const PLAYBACK_INFO_IN_PROGRESS = 'IN_PROGRESS'
export const PLAYBACK_INFO_FINISHED = 'FINISHED'
