import { withRouter } from 'react-router-dom'
import {
  branch, compose, renderNothing, withProps
} from 'recompose'
import { graphql } from 'react-apollo'
import NavigationHeader from '../../layouts/shared-navigation/navigation-header'
import withIsAuthenticated from '../../hoc/is-authenticated'

import NAV_HEADER_QUERY from '../../../graphql/queries/navigation-header.gql'

const enhance = compose(
  withRouter,
  withIsAuthenticated,
  graphql(NAV_HEADER_QUERY, {
    name: 'navHeaderQuery',
    options: {
      fetchPolicy: 'cache-only'
    }
  }),
  branch(
    props => props.navHeaderQuery && props.navHeaderQuery.navHeader == null,
    renderNothing
  ),
  withProps(({ navHeaderQuery }) => {
    return {
      sections: navHeaderQuery.navHeader
    }
  })
)

const NavigationHeaderContainer = enhance(NavigationHeader)
NavigationHeaderContainer.displayName = 'NavigationHeaderContainer'

export default NavigationHeaderContainer
