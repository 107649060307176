import { Formik } from 'formik'
import { compose } from 'recompose'
import { path, equals } from 'ramda'

import Yup from '../../lib/yup'
import { getGQLErrorMsg } from '../../lib/apollo'

import PinForm from '../../components/pin/pin-form'
import withApolloQueryAndLoading from '../../hoc/with-apollo-query-and-loading'
import { FORM_VALUES, FORM_MESSAGES } from '../../constants'

import VALIDATE_PIN_QUERY from '../../../graphql/queries/validate-pin.gql'

const enhance = compose(
  withApolloQueryAndLoading,
  Formik({
    validateOnChange: true,
    // Define the form's validation schema with Yup.
    validationSchema: Yup.object().shape({
      pin: Yup.string()
        .min(FORM_VALUES.pin.length, FORM_MESSAGES.pin.min)
        .max(FORM_VALUES.pin.length, FORM_MESSAGES.pin.max)
        .matches(/^[0-9]*$/, { message: FORM_MESSAGES.pin.valid, excludeEmptyString: false })
        .required(FORM_MESSAGES.required)
    }),

    // Map the field values to props if necessary. Used as empty strings for empty forms
    mapPropsToValues: () => {
      return ({
        pin: ''
      })
    },

    // Formik lets you colocate your submission handler with your form.
    // In addition to the payload (the result of mapValuesToPayload), you have
    // access to all props and some stateful helpers.
    handleSubmit: (values, { props, setError, setSubmitting }) => {
      // handleSubmit: (payload, { props, setError, setSubmitting }) => {
      // e.preventDefault(), setSubmitting, setError(undefined) are
      // called before handleSubmit is. So you don't have to do repeat this.
      // handleSubmit will only be executed if form values pass Yup validation.
      setError('')
      const isPinInvalid = response => equals(false)(path(['data', 'validatePin'])(response))
      props.apolloQuery('validatePin', {
        query: VALIDATE_PIN_QUERY,
        variables: {
          pin: values.pin
        },
        fetchPolicy: 'network-only'
      }, isPinInvalid)
        .then((response) => {
          // store auth token and go to home page logged in
          setSubmitting(false)
          if (response.data.validatePin) {
            setTimeout(() => props.onValidPin(values.pin), 500)
          } else {
            setError('Incorrect PIN, please try again.')
          }
        })
        .catch((error) => {
          setSubmitting(false)
          // display error
          if (isPinInvalid(error)) {
            setError('Incorrect PIN, please try again')
            return
          }
          setError(getGQLErrorMsg(error))
        })
    }
  })
)

export default enhance(PinForm)
