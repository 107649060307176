import React from 'react'
import PropTypes from 'prop-types'

import ConfirmationModal from '../shared/confirmation-modal'

function ErrorDialog({
  errorCode, errorHeader, errorMessage, onConfirmClick
}) {
  return (
    <ConfirmationModal
      header={errorHeader}
      message={errorMessage}
      confirmText="OK"
      onConfirmClick={() => onConfirmClick(errorCode)}
    />
  )
}

ErrorDialog.propTypes = {
  errorCode: PropTypes.string,
  errorHeader: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  onConfirmClick: PropTypes.func.isRequired
}

ErrorDialog.defaultProps = {
  errorCode: null
}

export default ErrorDialog
