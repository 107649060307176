import { compose } from 'recompose'

import TileOverlay from '../../../../components/carousel/tile/overlay/index'
import withTheme from '../../../../hoc/with-theme'
import withIsAuthenticated from '../../../../hoc/is-authenticated'

const enhance = compose(
  withTheme,
  withIsAuthenticated
)

export default enhance(TileOverlay)
