import { planType } from '../../modules/shared/subscription-constants'

const dataLayerPush = payload => {
  window.dataLayer.push(payload)
}

const variationText = (plan) => {
  if (plan === planType.TVOD) {
    return 'signup without subscription'
  }
  const convertPlan = plan && plan.toLowerCase()
  return `signup with ${convertPlan} subscription`
}

const initializeDataLayer = userId => {
  window.dataLayer = [{
    content: {
      environment: 'production' // We differentiate envs on GTM IDs
    },
    user: {
      userId,
      userStatus: userId ? 'signed in' : 'guest'
    }
  }]
}

const ctaClick = label => {
  dataLayerPush({
    event: 'custom.navigation.cta',
    group: 'cta',
    label
  })
}

const playbackStart = (title, videoType, { season, episode } = {}) => {
  dataLayerPush({
    event: 'custom.video.interactions',
    group: title,
    label: 'play',
    data: {
      videoType,
      season,
      episode
    }
  })
}

const stepView = {
  event: 'custom.form.step.view',
  group: 'signup'
}

const signup = {
  start: () => {
    dataLayerPush({
      event: 'custom.form.start',
      group: 'signup'
    })
  },

  planSelectionSeen: (sequence = 2) => {
    dataLayerPush({
      ...stepView,
      step: {
        sequence,
        label: 'choose a plan',
        variation: ''
      }
    })
  },

  createAccountSeen: (sequence = 1) => {
    dataLayerPush({
      ...stepView,
      step: {
        sequence,
        label: 'details',
        variation: ''
      }
    })
  },

  subscriptionPaymentSeen: (plan = 'monthly') => {
    dataLayerPush({
      ...stepView,
      step: {
        sequence: 3,
        label: 'payment details',
        variation: variationText(plan)
      }
    })
  },

  addCreditCardSeen: () => {
    dataLayerPush({
      ...stepView,
      step: {
        sequence: 3,
        label: 'payment details',
        variation: 'signup without subscription'
      }
    })
  },

  subscriptionConfirmationSeen: (plan = 'monthly') => {
    dataLayerPush({
      ...stepView,
      step: {
        sequence: 4,
        label: 'signup complete',
        variation: variationText(plan)
      }
    })
  },

  rentalOnlyConfirmationSeen: () => {
    dataLayerPush({
      ...stepView,
      step: {
        sequence: 4,
        label: 'signup complete',
        variation: 'signup without subscription'
      }
    })
  },

  fieldComplete: (fieldName, data, sequence = 1) => {
    dataLayerPush({
      event: 'custom.form.field.complete',
      group: 'signup',
      label: fieldName,
      step: {
        sequence,
        label: 'details',
        variation: ''
      },
      data
    })
  },

  validationError: (errorMessage, sequence = 1) => {
    dataLayerPush({
      event: 'custom.form.error.view',
      group: 'signup',
      label: errorMessage,
      step: {
        sequence,
        label: 'details',
        variation: ''
      }
    })
  },

  completeWithSubscription: (userId, orderId, isCreditCard, plan = 'monthly') => {
    dataLayerPush({
      event: 'custom.form.complete',
      group: 'signup',
      data: {
        userId,
        orderId,
        planName: plan,
        creditCard: isCreditCard ? 'yes' : 'no',
        loggedinStatus: true,
        variation: variationText(plan)
      }
    })
  },

  completeWithoutSubscription: (userId) => {
    dataLayerPush({
      event: 'custom.form.complete',
      group: 'signup',
      data: {
        userId,
        creditCard: 'no',
        loggedinStatus: true,
        variation: 'signup without subscription'
      }
    })
  }
}

export {
  initializeDataLayer,
  ctaClick,
  playbackStart,
  signup
}
