import UAParser from 'ua-parser-js'

const map = {
  name: [
    [/Mac/, 'macOS'],
    [/Ubuntu/, 'Linux'],
    [/Debian/, 'Linux'],
    [/Gentoo/, 'Linux'],
    [/Chromium/, 'Chrome_OS']
  ],

  version: {
    Windows: [
      [/10/g, '10.0'],
      [/8/g, '6.2'],
      [/8.1/g, '6.3'],
      [/7/g, '6.1'],
      [/Vista/g, '6.0'],
      [/XP/g, '5.1']
    ]
  }
}

const validOsNames = [
  'Windows',
  'macOS',
  'Linux',
  'Android',
  'iOS',
  'Chrome_OS'
]

const userAgentParser = new UAParser(navigator.userAgent)

/**
 * Gets the operating system family and version
 *
 * returns operatingSystem { name, version }
 */
export const getOperatingSystem = () => {
  let { name, version } = userAgentParser.getOS()

  name = map.name.reduce((current, [regex, replacement]) => {
    return regex.test(current) ? replacement : current
  }, name)

  if (map.version[name]) {
    version = map.version[name].reduce((current, [regex, replacement]) => {
      return regex.test(current) ? replacement : current
    }, version)
  }

  if (!validOsNames.includes(name)) {
    name = 'Unknown'
    version = '0'
  }

  if (!version) {
    version = '0'
  }

  return { name, version }
}

export const getDevice = () => {
  const { model } = userAgentParser.getDevice()
  return { model }
}

export const isMobile = userAgentParser.getDevice().type === 'mobile'
export const iOS = userAgentParser.getOS().name === 'iOS'
export const Android = userAgentParser.getOS().name === 'Android'
export const iPad = userAgentParser.getDevice().model === 'iPad'
export const hasNativeApp = iOS || Android || iPad
