import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'

import MyListRibbon from '../../my-list-ribbon'
import styles from './series-kids.css'

import TILE_FRAGMENT from '../../../../../graphql/fragments/tile.gql'
import { COMPONENT_NAME_CAROUSEL_KIDS } from '../../../../lib/analytics/custom-dimensions'
import Clamped from '../../../shared/clamped'

function OverlaySeriesKids({
  tile,
  isOnMyList,
  isAuthenticated,
  addToMyList,
  removeFromMyList
}) {
  const contentItem = tile.contentItem
  const title = contentItem.title

  return (
    <div className={styles.wrapper}>
      {isAuthenticated && (
        <MyListRibbon
          contentItem={contentItem}
          className={styles.ribbon}
          isOnMyList={isOnMyList}
          addAction={addToMyList(COMPONENT_NAME_CAROUSEL_KIDS)}
          removeAction={removeFromMyList(COMPONENT_NAME_CAROUSEL_KIDS)}
        />
      )}
      <div className={styles.title}>
        <Clamped>{title}</Clamped>
      </div>
      <div className={styles.seasons}>
        <Clamped lines={1}>{tile.subHeader}</Clamped>
      </div>
      <div className={styles.buttonWrapper}>
        <div className={styles.button}>
          More Info
        </div>
      </div>
    </div>
  )
}

OverlaySeriesKids.propTypes = {
  tile: propType(TILE_FRAGMENT).isRequired,
  isOnMyList: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  addToMyList: PropTypes.func.isRequired,
  removeFromMyList: PropTypes.func.isRequired
}

OverlaySeriesKids.defaultProps = {
  isOnMyList: false,
  isAuthenticated: false
}

export default OverlaySeriesKids
