
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"DeviceFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Device"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uuid"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"platform"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"os"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"model"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"lastSeen"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"firstSeen"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"numberOfDownloads"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":117}};
    doc.loc.source = {"body":"fragment DeviceFields on Device {\n  uuid\n  platform\n  name\n  os\n  model\n  lastSeen\n  firstSeen\n  numberOfDownloads\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
