import {
  connect
} from 'react-redux'
import { path, prop } from 'ramda'
import { compose, withHandlers } from 'recompose'
import { withApollo } from 'react-apollo'

import {
  selectDataSaverOption
} from '../actions'
import getDeviceInfo from '../lib/device-info'
import withDeviceFingerprint from './with-device-fingerprint'

import CONTENT_ITEM_QUERY from '../../graphql/queries/content-item.gql'
import CONFIG_QUERY from '../../graphql/queries/config.gql'
import ACCOUNT_QUERY from '../../graphql/queries/account.gql'
import { getAccountIdFromState } from '../selectors/session'
// Higher order component to manage loading screen on mutations

const withInitializeCastSender = compose(
  withApollo,
  withDeviceFingerprint,
  // connecting to Redux Store:
  // https://github.com/reactjs/react-redux/blob/master/docs/api.md#connectmapstatetoprops-mapdispatchtoprops-mergeprops-options
  connect(
    // mapStateToProps
    (state) => {
      const getOptionSelected = path(['user', 'account', 'dataSaver'])
      const getCastSender = prop('castSender')
      return {
        selectedDataSaverOption: getOptionSelected(state),
        castSender: getCastSender(state),
        accountId: getAccountIdFromState(state)
      }
    },
    // mapDispatchToProps
    (dispatch) => { // define handlers that use dispatch to dispatch actions, pass dispatch
      return {
        dispatchSelectDataSaver: (dataSaverOption, accountId) => {
          dispatch(selectDataSaverOption(dataSaverOption, accountId))
        }
      }
    },
    // mergeProps
    (
      { accountId, ...stateProps },
      { dispatchSelectDataSaver, ...dispatchProps },
      ownProps
    ) => ({
      ...ownProps,
      ...stateProps,
      ...dispatchProps,
      selectDataSaver: (dataSaverOption) => {
        dispatchSelectDataSaver(dataSaverOption, accountId)
      }
    })
  ),
  withHandlers({
    maybeInitializeCastSender: ({
      history,
      client,
      selectDataSaver,
      selectedDataSaverOption,
      castSender,
      deviceFingerprint
    }) => (url, contentItem, isTrailer) => {
      if (castSender.isConnected) {
        const deviceInformation = {
          ...getDeviceInfo(),
          uuid: deviceFingerprint,
          platform: 'Web'
        }
        window.castPlayer.loadMedia({
          apolloClient: client,
          selectDataSaverFunction: selectDataSaver,
          selectedDataSaverOption,
          contentItemQuery: {
            query: CONTENT_ITEM_QUERY,
            variables: {
              id: contentItem.id
            }
          },
          isVideoExtra: isTrailer,
          deviceUUID: deviceInformation.uuid,
          configQuery: {
            query: CONFIG_QUERY,
            variables: {
              input: {
                deviceInfo: deviceInformation
              }
            }
          },
          accountQuery: {
            query: ACCOUNT_QUERY
          },
          contentItemId: contentItem.id,
          app_token: localStorage.getItem('token')
        })
      } else if (history) {
        history.push(url)
      }
    },
    castPreviewPageSender: ({ castSender }) => (contentItem) => {
      if (castSender.isConnected) {
        window.castPlayer.loadPreviewPage(contentItem)
      }
    }
  })
)

export default withInitializeCastSender
