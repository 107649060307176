import React from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'

import neonLoadingImg from '../../../images/neon/loading.svg'

import styles from './index.css'

export function LoadingComponent({
  error,
  timedOut,
  pastDelay
}) {
  if (error) {
    return (
      <div>Error loading component!</div>
    )
  }

  if (timedOut) {
    return (
      <div className={styles.loading}>
        <ReactSVG src={neonLoadingImg} />
      </div>
    )
  }

  if (pastDelay) {
    return (
      <div className={styles.loading}>
        <ReactSVG src={neonLoadingImg} />
      </div>
    )
  }

  return null
}

LoadingComponent.propTypes = {
  error: PropTypes.bool,
  timedOut: PropTypes.bool,
  pastDelay: PropTypes.bool
}

LoadingComponent.defaultProps = {
  error: false,
  timedOut: false,
  pastDelay: false
}
