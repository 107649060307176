import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'

import styles from '../hotspot.css'
import Fader from '../fader'
import { HotspotSlide } from '../../../containers/hotspot/hotspot-slide'

import HOTSPOT_COMPONENT_FRAGMENT from '../../../../graphql/fragments/hotspot-component.gql'

function HotspotDesktop({
  hotspot,
  hotspotPosition,
  playbackInfoMany,
  theme
}) {
  const slides = hotspot.slides
  if (!slides || !slides.length) {
    return null
  }

  const rentalInfo = slides.map(slide => slide.contentItem.isRental)

  return (
    <div className={`hotspot-slider ${styles.hotspotComponentWrapper} ${theme}`}>
      <Fader interval={hotspot.uiConfig.interval} rentalInfo={rentalInfo}>
        {
          slides.map((slide, index) => (
            <HotspotSlide
              slide={slide}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              isDesktop
              hotspotPosition={hotspotPosition}
              playbackInfoMany={playbackInfoMany}
            />
          ))
        }
      </Fader>
    </div>
  )
}

HotspotDesktop.propTypes = {
  hotspot: propType(HOTSPOT_COMPONENT_FRAGMENT).isRequired,
  theme: PropTypes.string.isRequired,
  hotspotPosition: PropTypes.number.isRequired,
  playbackInfoMany: PropTypes.oneOfType([PropTypes.object])
}

HotspotDesktop.defaultProps = {
  playbackInfoMany: null
}

export default HotspotDesktop
