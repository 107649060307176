import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import classnames from 'classnames'

import Imgix from '../../imgix'
import DetailsPlayButton from '../../../containers/play-button/details-play-button'

import CONTENT_ITEM_FRAGMENT from '../../../../graphql/fragments/content-item.gql'
import CONTENT_ITEM_LIGHT_FRAGMENT from '../../../../graphql/fragments/content-item-light.gql'

import styles from './content-video-display.css'

const ContentVideoDisplay = ({
  className,
  imageWrapperClass,
  imageClass,
  iconWrapperClass,
  imageUri,
  contentItem,
  playbackInfoMany,
  hideTime
}) => {
  const playbackInfo = playbackInfoMany && playbackInfoMany[contentItem.id]
  // playbackInfoMany will be undefined if it wasn't passed in as a prop, but may be null
  // even if it was passed in while the playbackInfoMany query is in flight, in which case we
  // should hold off rendering the button until the query completes
  const showPlayButton = (playbackInfoMany === undefined) || Boolean(playbackInfoMany)
  return (
    <div
      className={classnames(
        styles.contentVideoDisplayWrapper,
        className
      )}
    >
      <div
        className={classnames(
          styles.contentVideoDisplayImage,
          imageWrapperClass
        )}
      >

        <Imgix
          className={imageClass}
          src={imageUri}
          recipe="videoFeature"
        />

        <div className={styles.playButtonWrapper}>
          <div className={styles.playButton}>
            {
              showPlayButton && (
                <DetailsPlayButton
                  playIconWrapperClassName={iconWrapperClass}
                  contentItem={contentItem}
                  playbackInfo={playbackInfo}
                  hideTime={hideTime}
                />
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

ContentVideoDisplay.propTypes = {
  imageUri: PropTypes.string.isRequired,
  contentItem: PropTypes.oneOfType([
    propType(CONTENT_ITEM_FRAGMENT),
    propType(CONTENT_ITEM_LIGHT_FRAGMENT)
  ]).isRequired,
  playbackInfoMany: PropTypes.oneOfType([PropTypes.object]),
  imageClass: PropTypes.string,
  imageWrapperClass: PropTypes.string,
  className: PropTypes.string,
  iconWrapperClass: PropTypes.string,
  hideTime: PropTypes.bool
}

ContentVideoDisplay.defaultProps = {
  playbackInfoMany: undefined,
  imageClass: '',
  imageWrapperClass: '',
  className: '',
  iconWrapperClass: '',
  hideTime: false
}

export default ContentVideoDisplay
