import { graphql } from 'react-apollo'
import { connect } from 'react-redux'
import {
  compose,
  lifecycle,
  setDisplayName,
  branch,
  renderComponent
} from 'recompose'

import { findConfigValue } from '../../lib/config'
import withConfigCacheOnly from '../../hoc/with-config'

import MaintenanceMode from '../../components/maintenance-mode'
import MAINTENANCE_MODE_QUERY from '../../../graphql/queries/maintenance-mode.gql'

import { MAINTENANCE_MODE } from '../../constants'

import { disableMaintenanceMode } from '../../actions'

const mapStateToProps = (state) => {
  return {
    currentMode: state.maintenance.mode,
    message: state.maintenance.message,
    playerIsInView: state.app.playerIsInView
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    turnOffMaintenanceMode() {
      dispatch(disableMaintenanceMode())
    }
  }
}

const enhance = compose(
  setDisplayName('MaintenanceModeContainer'),
  connect(mapStateToProps, mapDispatchToProps),
  withConfigCacheOnly,
  graphql(MAINTENANCE_MODE_QUERY, {
    name: 'maintenanceModeQuery',
    options: () => {
      return {
        variables: {
          platform: 'Web'
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
      }
    },
    props: ({
      ownProps: {
        appConfig, currentMode, message, turnOffMaintenanceMode, playerIsInView
      },
      maintenanceModeQuery: {
        loading, maintenanceMode, startPolling, stopPolling
      }
    }) => {
      const pollingInterval = findConfigValue(MAINTENANCE_MODE.POLL_INTERVAL, appConfig)
      const background = findConfigValue(MAINTENANCE_MODE.BACKGROUND, appConfig)

      return {
        maintenanceModeResponse: maintenanceMode,
        maintenanceModeQueryLoading: loading,
        currentMode,
        message,
        background,
        turnOffMaintenanceMode,
        playerIsInView,
        startPolling,
        stopPolling,
        // pollingInterval = seconds and apollo interprets ms
        // e.g. pollingInterval = 300 * 1000 = 5 minutes
        pollInterval: pollingInterval * 1000
      }
    }
  }),
  lifecycle({
    UNSAFE_componentWillReceiveProps({
      turnOffMaintenanceMode,
      maintenanceModeResponse,
      maintenanceModeQueryLoading,
      currentMode,
      playerIsInView,
      history
    }) {
      // Start polling Maintenance Mode query
      if (currentMode && (this.props.currentMode !== currentMode)) {
        this.props.startPolling(this.props.pollInterval)
        if (!playerIsInView) {
          history.replace('/')
        }
      }

      // Stop polling Maintenance Mode query
      if (
        this.props.currentMode &&
        (this.props.maintenanceModeQueryLoading && !maintenanceModeQueryLoading) &&
        (maintenanceModeResponse === false)
      ) {
        turnOffMaintenanceMode()
        this.props.stopPolling()
      }
    }
  }),
  branch(
    ({ currentMode, playerIsInView }) => {
      return (
        currentMode && !playerIsInView
      )
    },
    renderComponent(MaintenanceMode)
  )
)

export default enhance
