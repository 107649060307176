import { DeviceUUID } from 'device-uuid'

function getTabID() {
  let id = sessionStorage.getItem('tabID')
  if (!id) {
    id = Date.now()
    sessionStorage.setItem('tabID', id)
  }
  return id
}

export default function getDeviceInfo() {
  const duuid = new DeviceUUID()
  const info = duuid.parse()
  return {
    uuid: duuid.get(),
    platform: info.platform,
    name: info.source,
    os: info.os,
    model: info.browser,
    version: info.version,
    tab: getTabID()
  }
}
