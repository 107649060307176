import React, { lazy, Suspense } from 'react'

const AppDownload = lazy(() => import('./app-download'))

const AppDownloadLazy = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <AppDownload />
  </Suspense>
)

export default AppDownloadLazy
