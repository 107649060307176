import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import ClickOutside from 'react-click-outside'
import classNames from 'classnames'

import { navSectionPropType } from '../../common-proptypes'
import { navigationBarGa } from '../../../../lib/analytics/ga'
import NavigationItem from '../../../../containers/navigation/navigation-item'
import NavigationBurgerIcon from '../../../../containers/navigation/navigation-burger-icon'
import NavigationSettings from '../../../../containers/navigation/navigation-settings'

import styles from '../navigation-side.css'
import { customDimensions } from '../../../../lib/analytics/custom-dimensions'
import NavigationAccountSide from '../../../../containers/navigation/navigation-account-side'
import { addKidsLinkWhenLoggedOut } from '../../../../lib/navigation-links'

const AdultsSideNavigation = ({
  display,
  isAuthenticated,
  mainSectionLinks,
  propertiesCount,
  profileId,
  onClickOutside,
  name,
  avatarUri,
  handleProfileChange,
  expandOnArrow
}) => {
  // TODO: We should do something about this.
  const searchItem = {
    name: 'Search',
    __typename: 'NavItem',
    path: {},
    target: 0,
    type: 'SCREEN_ID',
    children: [],
    isRental: false,
    isDiscoverNav: false
  }

  const sideSectionLinks = addKidsLinkWhenLoggedOut(
    isAuthenticated,
    mainSectionLinks
  )

  return (
    <ClickOutside onClickOutside={onClickOutside}>
      <div
        className={classNames(styles.sideNavWrapper, {
          [styles.hidden]: !display
        })}
      >
        <div className={styles.sideNav} data-lbx-e2e="nav nav-side">
          <div
            className={classNames({
              [styles.header]: true,
              [styles.navLogged]: isAuthenticated
            })}
          >
            <div className={styles.logoWrapper}>
              <NavigationBurgerIcon display={display} />
              <NavLink
                exact
                to="/"
                onClick={() => {
                  navigationBarGa({
                    action: 'Logo',
                    [customDimensions.ProfileId]: profileId
                  })
                }}
                className={classNames(styles.navLink, styles.navLinkLogo)}
              />
            </div>
          </div>
          {isAuthenticated && (
            <NavigationAccountSide
              userName={name}
              avatarUri={avatarUri}
              onSwitchProfileClick={handleProfileChange}
            />
          )}
          <div className={styles.mainSection}>
            {sideSectionLinks.map((navItem, idx) => {
              if (navItem.isDiscoverNav) {
                return null
              }

              return (
                <NavigationItem
                  inSideNav
                  key={idx} // eslint-disable-line react/no-array-index-key
                  navItem={navItem}
                  className={styles.navLink}
                  activeClassName={styles.navLinkActive}
                  propertiesCount={propertiesCount}
                  expandOnArrow={expandOnArrow}
                />
              )
            })}
            {/* TODO: Make search it's own isolated nav item */}
            <NavigationItem
              inSideNav
              key="search"
              navItem={searchItem}
              className={styles.navLink}
            />
          </div>
          <NavigationSettings inSideNav />
        </div>
      </div>
    </ClickOutside>
  )
}

AdultsSideNavigation.propTypes = {
  display: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  mainSectionLinks: navSectionPropType.isRequired,
  propertiesCount: PropTypes.number.isRequired,
  profileId: PropTypes.string,
  onClickOutside: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  avatarUri: PropTypes.string.isRequired,
  handleProfileChange: PropTypes.func.isRequired,
  expandOnArrow: PropTypes.bool
}

AdultsSideNavigation.defaultProps = {
  profileId: null,
  expandOnArrow: true
}

export default AdultsSideNavigation
