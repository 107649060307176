import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { compose, withHandlers } from 'recompose'

import Button from '../forms/button'
import {
  BROWSE_NEON, BROWSE_MOVIES, BROWSE_TV, THEME_OPTIONS
} from '../../constants'

import styles from './grid-empty-my-list.css'

const getButtons = (onButtonClick, theme) => {
  return theme === THEME_OPTIONS.dark
    ? (
      <>
        <Button
          text={BROWSE_TV.label}
          onClick={() => onButtonClick(BROWSE_TV.url)}
        />
        <Button
          text={BROWSE_MOVIES.label}
          onClick={() => onButtonClick(BROWSE_MOVIES.url)}
        />
      </>
    )
    : (
      <Button
        text={BROWSE_NEON.label}
        onClick={() => onButtonClick(BROWSE_NEON.url)}
      />
    )
}

function GridEmptyMyList({
  theme,
  onButtonClick
}) {
  return (
    <div className={styles.gridEmpty}>
      <p>There&apos;s nothing here yet!</p>
      <p>
        {
          theme !== THEME_OPTIONS.light
            ? 'Add some shows or movies to your list.'
            : 'When you add a TV show or Movie to your list, it will appear here.'
        }
      </p>
      <div className={styles.buttonsWrapper}>
        {getButtons(onButtonClick, theme)}
      </div>
    </div>
  )
}

GridEmptyMyList.propTypes = {
  theme: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func
}

GridEmptyMyList.defaultProps = {
  onButtonClick: null
}

const enhance = compose(
  withHandlers({
    onButtonClick: ({ history }) => (url) => {
      history.push(url)
    }
  })
)

export default withRouter(enhance(GridEmptyMyList))
