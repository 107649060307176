import React from 'react'
import { pathOr, path } from 'ramda'
import classNames from 'classnames'
import { propType } from 'graphql-anywhere'
import PropTypes from 'prop-types'

import { useRentalPageDetector } from '../../../../hooks/use-rental-page-detector'
import TILE_FRAGMENT from '../../../../../graphql/fragments/tile.gql'

import styles from './default-overlay.css'

export const DefaultOverlay = ({ tile, tileType }) => {
  const isOnARentalPage = useRentalPageDetector()

  const contentType = path(['contentItem', '__typename'], tile)
  const isRentalCategorySelector = contentType === 'Screen' && isOnARentalPage
  const isRentalContent = pathOr(false, ['contentItem', 'isRental'], tile)

  const isRental = isRentalContent || isRentalCategorySelector

  const isGenreCarousel = tileType === 'GENRE_CAROUSEL'

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.wrapperNeonRental]: isRental && !isGenreCarousel
      })}
    />
  )
}

DefaultOverlay.propTypes = {
  tile: propType(TILE_FRAGMENT),
  tileType: PropTypes.string
}

DefaultOverlay.defaultProps = {
  tileType: null
}
