import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useStyles } from './subscription-price-section.styles'
import {
  basicPlanDetailsWithDiscount,
  standardPlanDetailsWithDiscount,
  annualPlanDetailsWithDiscount,
  planType
} from '../../../shared/subscription-constants'
import SubscriptionDiscountSection from '../subscription-discount-section/subscription-discount-section'

const SubscriptionPriceSection = props => {
  const styles = useStyles()
  const {
    title,
    label,
    price,
    description,
    activeSubscription,
    updateActiveSubscription,
    currentSubscription,
    unit,
    showBasicDiscount,
    showStandardDiscount,
    showAnnualDiscount
  } = props

  const priceSection = () => {
    let priceDetail = {
      price,
      description
    }

    if (showBasicDiscount && title?.toUpperCase() === planType.BASIC && !currentSubscription) {
      priceDetail = basicPlanDetailsWithDiscount
    }

    if (showStandardDiscount && title?.toUpperCase() === planType.STANDARD && !currentSubscription) {
      priceDetail = standardPlanDetailsWithDiscount
    }

    if (showAnnualDiscount && title?.toUpperCase() === planType.ANNUAL && !currentSubscription) {
      priceDetail = annualPlanDetailsWithDiscount
    }

    const [priceBeforeDot, priceAfterDot] = priceDetail.price.split('.')

    return (
      <div className={classNames(styles.priceContent, currentSubscription === title && styles.priceContentDisabled, currentSubscription && styles.priceContentWithSubscription)}>
        {/* Use the "Current Plan" as a placeholder here if currentSubscription exist */}
        {
          currentSubscription &&
          (
            <div className={styles.planTitle}>{currentSubscription === title && 'Current Plan'}</div>
          )
        }

        <div className={classNames(styles.priceAmount, activeSubscription === title && styles.activeSubscription, currentSubscription === title && styles.priceAmountDisabled)}>
          <span className={styles.currency}>$</span>
          <span className={styles.priceAmountBeforeDot}>{priceBeforeDot}</span>
          <div className={styles.priceAmountRightFrame}>
            <span className={styles.priceAmountAfterDot}>{priceAfterDot}</span>
            <span className={styles.priceAmountUnit}>{unit}</span>
          </div>
        </div>

        {/* No description if plan has discount */}
        {
          priceDetail.description &&
          (
            <span
              className={classNames(styles.priceContentDescription, styles.displayForWebView)}
            >
              {priceDetail.description}
            </span>
          )
        }

        {/* The originalPrice and SubscriptionDiscountSection are just for plan with discount */}
        {
          priceDetail.originalPrice &&
          (
            <span className={styles.originalPrice}>
              &nbsp;$
              {priceDetail.originalPrice}
              {unit}
              &nbsp;
            </span>
          )
        }

        <div className={styles.displayForWebView}>
          <SubscriptionDiscountSection priceDetail={priceDetail} />
        </div>
      </div>
    )
  }

  return (
    <div className={styles.tableRowColumn} onClick={() => updateActiveSubscription(title)}>
      <div
        className={classNames(styles.outlineButton, styles.button, activeSubscription === title && styles.hoverButton)}
        data-e2e={`plan-title-${title}`}
      >
        {label}
      </div>

      {priceSection()}
    </div>
  )
}

SubscriptionPriceSection.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  currentSubscription: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  activeSubscription: PropTypes.string.isRequired,
  updateActiveSubscription: PropTypes.func.isRequired,
  showBasicDiscount: PropTypes.bool.isRequired,
  showStandardDiscount: PropTypes.bool.isRequired,
  showAnnualDiscount: PropTypes.bool.isRequired
}

SubscriptionPriceSection.defaultProps = {
  title: '',
  price: '',
  currentSubscription: '',
  description: '',
  unit: '',
  label: '',
  activeSubscription: '',
  updateActiveSubscription: null,
  showBasicDiscount: false,
  showStandardDiscount: false,
  showAnnualDiscount: false
}

export default SubscriptionPriceSection
