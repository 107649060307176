import React from 'react'
import PropTypes from 'prop-types'

import Input from './input'
import styles from './pin-input.css'

function PinInput({
  label,
  readonly,
  readonlyTextOverwrite,
  ...props
}) {
  const noLabelClass = !label ? styles.noLabel : ''
  return (
    <div className={styles.showPasswordWrapper}>
      <Input
        {...props}
        inputClass={styles.pinInput}
        label={label}
        type="password"
        readonly={readonly}
        readonlyTextOverwrite={readonlyTextOverwrite}
        checkClass={styles.checkPassword}
        exclamationClass={`${styles.exclamationPassword} ${noLabelClass}`}
        inputmode="numeric"
      />
    </div>
  )
}

PinInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  readonly: PropTypes.bool,
  readonlyTextOverwrite: PropTypes.string
}

PinInput.defaultProps = {
  label: '',
  value: '',
  readonly: false,
  readonlyTextOverwrite: ''
}

export default PinInput
