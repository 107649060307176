import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import ReactSVG from 'react-svg'

import CONTENT_ITEM_LIGHT_FRAGMENT from '../../../graphql/fragments/content-item-light.gql'

import MyListRibbonAdd from '../../../images/my-list-ribbon-add.svg'
import MyListRibbonRemove from '../../../images/my-list-ribbon-remove.svg'
import { dataLayerAddToMyList } from '../../lib/analytics/datalayer'

const MyListRibbon = ({
  contentItem, isOnMyList, addAction, removeAction, className
}) => {
  const image = isOnMyList ? MyListRibbonRemove : MyListRibbonAdd
  const onClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    return isOnMyList
      ? removeAction(contentItem)
      : (addAction(contentItem), dataLayerAddToMyList(contentItem))
  }

  return (
    <div
      data-lbx-e2e={`my-list-ribbon-${contentItem.path}`}
      className={className}
      onClick={onClick}
    >
      <ReactSVG src={image} />
    </div>
  )
}

MyListRibbon.propTypes = {
  contentItem: propType(CONTENT_ITEM_LIGHT_FRAGMENT).isRequired,
  addAction: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]).isRequired,
  removeAction: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]).isRequired,
  isOnMyList: PropTypes.bool.isRequired,
  className: PropTypes.string
}

MyListRibbon.defaultProps = {
  className: ''
}

export default MyListRibbon
