import {
  branch,
  compose,
  withProps,
  renderNothing
} from 'recompose'
import { graphql } from 'react-apollo'
import PropTypes from 'prop-types'
import {
  defaultTo,
  path
} from 'ramda'

import ACCOUNT_QUERY from '../../graphql/queries/account.gql'

export const withCurrentProfile = compose(
  graphql(ACCOUNT_QUERY, {
    name: 'accountQuery',
    skip: ownProps => !ownProps.isAuthenticated
  }),
  branch(
    ({ accountQuery }) => (
      (accountQuery && (accountQuery.loading || accountQuery.error))
    ),
    renderNothing
  ),
  withProps((props) => {
    const accountQuery = props.accountQuery
    const selectedProfile = path(['account', 'selectedProfile'], defaultTo({}, accountQuery))
    if (!selectedProfile) {
      return {}
    }

    const currentProfile = accountQuery.account.profiles.find(
      profile => profile.id === selectedProfile
    )

    return {
      currentProfile: {
        id: selectedProfile,
        name: currentProfile.name
      }
    }
  })
)

export const currentProfilePropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string
})
