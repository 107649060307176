import {
  compose,
  withProps,
  withHandlers,
  setDisplayName,
  onlyUpdateForPropTypes,
  renderNothing,
  branch
} from 'recompose'
import { withRouter } from 'react-router-dom'
import {
  pipe,
  pathOr,
  head,
  mergeDeepRight,
  prop,
  propEq
} from 'ramda'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import { getWatchUrl, getDetailsUrl, checkQueryStatus } from '../../lib/utils'
import { checkIsAuthenticated } from '../../lib/auth'
import { getModalLocation } from '../../lib/modal'
import { isTitle, isSeries } from '../../lib/content'
import { setTrailerLaunchedFromComponent } from '../../actions/index'

import { SUBSCRIPTION_STATUS, MODALS } from '../../constants'

import VideoFeature from '../../components/video-feature'

import ACCOUNT_QUERY from '../../../graphql/queries/account.gql'
import PLAYBACK_INFO_QUERY from '../../../graphql/queries/playback-info.gql'
import withIsWhiteTheme from '../../hoc/with-is-white-theme'

const getImageUri = pathOr('', ['extra', 'tile', 'image'])

const getVideoExtraContentItem = pipe(
  prop('contentItems'),
  head
)

const getVideoFeatureWatchUrl = pipe(
  getVideoExtraContentItem,
  getWatchUrl
)

const getVideoFeatureDetailsUrl = pipe(
  getVideoExtraContentItem,
  getDetailsUrl
)

const isSeriesTypeContentItem = pipe(
  getVideoExtraContentItem,
  isSeries
)

const isTitleTypeContentItem = pipe(
  getVideoExtraContentItem,
  isTitle
)

const withPlaybackInfoQuery = compose(
  graphql(PLAYBACK_INFO_QUERY, {
    name: 'playbackInfoQuery',
    options: ({ component }) => {
      const contentItem = getVideoExtraContentItem(component.extra)
      return {
        variables: {
          contentItemId: contentItem.id
        },
        fetchPolicy: 'network-only'
      }
    },
    props: ({ ownProps, playbackInfoQuery }) => {
      return {
        playbackInfoQuery,
        watchable: pathOr(false, ['playbackInfo', 'watchable'], playbackInfoQuery),
        ...ownProps
      }
    }
  }),
  branch(
    ({ playbackInfoQuery }) => checkQueryStatus(playbackInfoQuery),
    renderNothing
  )
)

const mapStateToProps = (state) => {
  return {
    isAuthenticated: checkIsAuthenticated(state)
  }
}

const mapDispatchToProps = dispatch => ({
  setTrailerLaunched: () => {
    dispatch(setTrailerLaunchedFromComponent(true))
  }
})

const enhance = compose(
  setDisplayName('VideoFeatureContainer'),
  connect(mapStateToProps, mapDispatchToProps),
  graphql(ACCOUNT_QUERY, {
    name: 'accountQuery',
    options: {
      fetchPolicy: 'cache-only'
    },
    props: ({ ownProps, accountQuery }) => {
      return {
        subscription: pathOr('', ['account', 'subscription'], accountQuery),
        ...ownProps
      }
    },
    skip: ownProps => !ownProps.isAuthenticated
  }),
  branch(
    ({ isAuthenticated }) => isAuthenticated,
    withPlaybackInfoQuery
  ),
  withProps(({ component }) => {
    return {
      imageUri: getImageUri(component),
      copy: component.copy || '',
      isSeriesContentType: isSeriesTypeContentItem(component.extra),
      isTitleContentType: isTitleTypeContentItem(component.extra),
      showWatch: !pathOr(false, ['extra', 'contentItems', '0', 'isComingSoon'], component)
    }
  }),
  withRouter,
  withHandlers({
    onTrailerClick: ({ history, component, setTrailerLaunched }) => () => {
      const url = getWatchUrl(component.extra)
      if (url) {
        setTrailerLaunched()
        history.push(url)
      }
    },
    onWatchCTAClick: ({
      history,
      location,
      component,
      subscription,
      isAuthenticated,
      isSeriesContentType,
      isTitleContentType,
      watchable
    }) => () => {
      /**
       * Ticket: https://bcconsulting.atlassian.net/browse/LBXW-1385
       * For Movie
       * - Launch player if user is entitled
       * - Signup rental if the logged in user doesn't have entitlement (no subscription)
       * - Signup subscription if user is a guest
       *
       * For Series
       * - Launch player if user is entitled
       * - Signup selection if the logged in user doesn't have entitlement (no subscription)
       * - Signup subscription if user is a guest
       *
       */

      let locationObject
      let historyState

      if (!isAuthenticated) {
        if (isSeriesContentType) {
          locationObject = getModalLocation(location, MODALS.qsParams.signupSubscription)
        } else {
          locationObject = getModalLocation(location, MODALS.qsParams.loginRental)
          historyState = { detailsUrl: getVideoFeatureDetailsUrl(component.extra) }
        }
        history.push(mergeDeepRight(locationObject, { state: historyState }))
        return
      }

      const subscriptionStatusActive = propEq('status', SUBSCRIPTION_STATUS.ACTIVE, subscription)

      if (isSeriesContentType) {
        if (subscriptionStatusActive && watchable) {
          locationObject = getVideoFeatureWatchUrl(component.extra)
        } else {
          locationObject = getModalLocation(location, MODALS.qsParams.subscriptionSelection)
        }
      } else if (isTitleContentType) {
        if (watchable) {
          locationObject = getVideoFeatureWatchUrl(component.extra)
        } else {
          locationObject = getModalLocation(location, MODALS.qsParams.loginRental)
          historyState = { detailsUrl: getVideoFeatureDetailsUrl(component.extra) }
        }
      }

      if (locationObject) {
        history.push(mergeDeepRight(locationObject, { state: historyState }))
      }
    },
    onDetailsCTAClick: ({ history, component }) => () => {
      const url = getVideoFeatureDetailsUrl(component.extra)
      if (url) history.push(url)
    }
  }),
  withIsWhiteTheme,
  onlyUpdateForPropTypes
)

export default enhance(VideoFeature)
