import { find, propEq } from 'ramda'
import { MW_ERRORS } from '../../constants'

import {
  displayErrorDialog
} from '../../actions'

export function getGeoBlockingError(errors = []) {
  return find(propEq('code', MW_ERRORS.RESTRICTED_GEOLOCATION))(errors)
}

/**
 * Handles geoBlocking errors through the network interface afterware
 * @param {Array} errors - List of GraphQL JSON error objects
 * @param {Object} store - Redux store
 */
export function handleGeoBlocking(errors, store) {
  const geoBlockingError = getGeoBlockingError(errors)

  if (!geoBlockingError) {
    return false
  }

  return store.dispatch(displayErrorDialog(geoBlockingError))
}
