import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import SlipNSlide from '../../../../../lib/slip-n-slide'
import SearchTile from '../search-tile/search-tile'
import { checkIsAuthenticated } from '../../../../lib/auth'
import {
  slipnslideIsMobile,
  slipnslideCalcTileShowCount,
  slipnslideGetMargin,
  isContentItemOnMyList
} from '../../../../lib/utils'
import { useScreenWidth } from '../../../../hooks/use-screen-width'
import { THEME_OPTIONS } from '../../../../constants'
import { useStyles } from './search-carousel.styles'

/**
 * Search Carousel component
 */
export const SearchCarousel = React.memo(
  ({
    tiles, onClickFetchMore, myListItems, addToMyList, removeFromMyList
  }) => {
    const mapState = useSelector(state => state)
    const { theme, session } = mapState
    const isAuthenticated = session && checkIsAuthenticated(mapState)

    // Calculating items space and how many items to display
    const screenWidth = useScreenWidth()
    const slidesToShow = slipnslideCalcTileShowCount(screenWidth, 2)
    const margin = slipnslideGetMargin(screenWidth)

    const styles = useStyles()

    return (
      <SlipNSlide
        arrowClass={theme === THEME_OPTIONS.light ? '' : styles.blackArrow}
        margin={margin}
        swipeMode={slipnslideIsMobile}
        duration={slidesToShow * 180}
        slidesToShow={slidesToShow}
        onClickRight={onClickFetchMore}
      >
        {tiles &&
          tiles.map((tile, index) => {
            return (
              <div key={tile.contentItem.id}>
                <SearchTile
                  tile={tile}
                  index={index}
                  carouselName="Search"
                  isAuthenticated={isAuthenticated}
                  isOnMyList={isContentItemOnMyList(tile.contentItem.id, myListItems)}
                  addToMyList={addToMyList}
                  removeFromMyList={removeFromMyList}
                  theme={theme}
                />
              </div>
            )
          })}
      </SlipNSlide>
    )
  }
)

SearchCarousel.propTypes = {
  tiles: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  onClickFetchMore: PropTypes.func,
  myListItems: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  addToMyList: PropTypes.func,
  removeFromMyList: PropTypes.func
}
