import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import { T, F } from 'ramda'

import { STATIC_SCREENS } from '../../constants'
import GridEmptyMyList from './grid-empty-my-list'
import GridTile from '../../containers/grid/grid-tile'
import styles from './grid.css'

import TILE_FRAGMENT from '../../../graphql/fragments/tile.gql'

function renderTiles(
  tiles,
  contentType,
  subType,
  theme,
  hasRemoveIcon,
  gridPosition,
  listTitle,
  isContentItemOnMyList,
  addToMyList,
  removeFromMyList
) {
  return (tiles || []).map((tile, index) => (
    <GridTile
      // eslint-disable-next-line react/no-array-index-key
      key={`${tile.contentItem.id}-${index}`}
      tile={tile}
      theme={theme}
      contentType={contentType}
      subType={subType}
      hasRemoveIcon={hasRemoveIcon}
      listTitle={listTitle}
      index={index}
      gridPosition={gridPosition}
      isOnMyList={isContentItemOnMyList(tile.contentItem.id)}
      addToMyList={addToMyList}
      removeFromMyList={removeFromMyList}
    />
  ))
}

function GridBody({
  tiles,
  contentType,
  subType,
  theme,
  hasRemoveIcon,
  isEmptyGrid,
  gridPosition,
  listTitle,
  isContentItemOnMyList,
  addToMyList,
  removeFromMyList
}) {
  if (!isEmptyGrid) {
    return (
      <div className={styles.gridContentWrapper}>
        {renderTiles(
          tiles,
          contentType,
          subType,
          theme,
          hasRemoveIcon,
          gridPosition,
          listTitle,
          isContentItemOnMyList,
          addToMyList,
          removeFromMyList
        )}
      </div>
    )
  }

  if (subType === STATIC_SCREENS.MY_LIST.subType) {
    return (
      <GridEmptyMyList
        theme={theme}
      />
    )
  }

  return null
}

GridBody.propTypes = {
  tiles: PropTypes.arrayOf(propType(TILE_FRAGMENT)).isRequired,
  theme: PropTypes.string.isRequired,
  contentType: PropTypes.string,
  subType: PropTypes.string,
  hasRemoveIcon: PropTypes.bool.isRequired,
  isEmptyGrid: PropTypes.bool,
  gridPosition: PropTypes.number.isRequired,
  listTitle: PropTypes.string.isRequired,
  isContentItemOnMyList: PropTypes.func,
  addToMyList: PropTypes.func,
  removeFromMyList: PropTypes.func
}

GridBody.defaultProps = {
  contentType: null,
  subType: null,
  tabbing: true,
  isEmptyGrid: false,
  isContentItemOnMyList: F,
  addToMyList: T,
  removeFromMyList: T
}

export { GridBody }
