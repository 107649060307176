import React from 'react'
import PropTypes from 'prop-types'

const CheckIcon = props => {
  const { color } = props
  return (
    <svg
      width="26"
      height="19"
      viewBox="0 0 26 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={color}
        opacity="0.998"
        d="M8.49996 15.2547L3.29496 10.0497C2.70996 9.46474 1.76496 9.46474 1.17996 10.0497C0.594961 10.6347 0.594961 11.5797 1.17996 12.1647L7.44996 18.4347C8.03496 19.0197 8.97996 19.0197 9.56496 18.4347L25.435 2.56474C26.02 1.97974 26.02 1.03474 25.435 0.449736C24.85 -0.135264 23.905 -0.135264 23.32 0.449736L8.49996 15.2547Z"
      />
    </svg>
  )
}

export default CheckIcon

CheckIcon.propTypes = {
  color: PropTypes.string.isRequired
}

CheckIcon.defaultProps = {
  color: 'white'
}
