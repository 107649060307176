import { mergeRight } from 'ramda'

const color = {
  lightboxYellow: '#ffd500',
  neonGreen: '#cafd34',
  neonBlack: '#303030',
  black: '#000000',
  white: '#ffffff',
  rental: '#ff2ea1',
  transparent: 'transparent'
}

const colorKids = mergeRight(color, {
  lightboxYellow: '#9edf00',
  neonGreen: '#789b13',
  rental: '#789b13',
  black: color.white,
  white: color.black
})

export const getColor = isKid => {
  if (isKid) {
    return colorKids
  }

  return color
}

export default color
