import ActionTypes from '../actions/types'

const initialNavigationState = {
  searchIsClicked: false,
  sideNav: false,
  accountNav: false,
  trailerModalVisibility: false
}

const accountNav = (state, action) => {
  switch (action.type) {
    case ActionTypes.ACCOUNT_NAVIGATION_TOGGLE_VISIBILITY:
      return !state
    case ActionTypes.SET_ACCOUNT_NAVIGATION_VISIBILITY:
      return action.visibility
    default:
      return state
  }
}

const sideNav = (state, action) => {
  switch (action.type) {
    case ActionTypes.NAVIGATION_TOGGLE_VISIBILITY:
      return !state
    case ActionTypes.SET_NAVIGATION_VISIBILITY:
      return action.visibility
    default:
      return state
  }
}

const navigation = (state = initialNavigationState, action) => {
  switch (action.type) {
    case ActionTypes.SET_SEARCH_IS_CLICKED:
      return {
        ...state,
        searchIsClicked: action.isClicked
      }
    case ActionTypes.SET_TRAILER_LAUNCHED_FROM_COMPONENT:
      return {
        ...state,
        trailerModalVisibility: action.trailerModalVisibility
      }
    case ActionTypes.CLEANUP_PLAYER:
      return {
        ...state,
        trailerModalVisibility: false
      }
    case ActionTypes.NAVIGATION_TOGGLE_VISIBILITY:
    case ActionTypes.SET_NAVIGATION_VISIBILITY:
    case ActionTypes.ACCOUNT_NAVIGATION_TOGGLE_VISIBILITY:
    case ActionTypes.SET_ACCOUNT_NAVIGATION_VISIBILITY:
    default:
      return {
        ...state,
        navComponentWidth: action.navComponentWidth || state.navComponentWidth,
        sideNav: sideNav(state.sideNav, action),
        accountNav: accountNav(state.accountNav, action)
      }
  }
}

export default navigation
