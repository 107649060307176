import { SAFE_APOLLO_RESET, SAFE_APOLLO_RESET_DONE } from './actions/types'

const initialState = {
  resetting: false
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAFE_APOLLO_RESET:
      return {
        ...state,
        resetting: true
      }
    case SAFE_APOLLO_RESET_DONE:
      return {
        ...state,
        resetting: false
      }
    default:
      return state
  }
}

export default authReducer
