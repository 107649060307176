import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { getModalLocation } from '../../lib/modal'
import NavLinkWithQuery from '../../components/shared/nav-link-with-query'

import styles from './login-nav-button.css'

function LoginNavButton({
  isAuthenticated,
  location,
  searchIsClicked,
  isHomePage
}) {
  return !isAuthenticated ? (
    <NavLinkWithQuery
      exact
      to={getModalLocation(location, 'login')}
      analyticsLabel="login"
      className={classNames(styles.navLink, styles.borderLink, searchIsClicked && styles.navLinkWhileSearching, isHomePage && styles.signupButton)}
      data-e2e="nav-login"
    >
      log in
    </NavLinkWithQuery>
  ) : null
}

LoginNavButton.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  searchIsClicked: PropTypes.bool.isRequired,
  isHomePage: PropTypes.bool.isRequired
}

export default LoginNavButton
