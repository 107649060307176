import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose, branch, renderNothing } from 'recompose'
import qs from 'query-string'
import { pathEq } from 'ramda'

import PinCheck from '../../components/pin/check-pin'
import { MODALS } from '../../constants'
import { validatePin, dismissPin } from '../../actions'
import { getOnValidPin, getPinVisibility } from '../../lib/pin'

const mapStateToProps = state => {
  return {
    onValidPin: getOnValidPin(state),
    visibility: getPinVisibility(state)
  }
}

const fromAddProfile = location => {
  const parsedSearch = qs.parse(location && location.search)
  return pathEq(['modal'], MODALS.qsParams.profilesAdd, parsedSearch)
}

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    dispatch => ({
      validate: () => {
        dispatch(validatePin())
      },
      dismiss: () => {
        dispatch(dismissPin())
        // If the check pin form is triggered when the user clicks on add profile button
        // and then he cancels it, he should see the select profile modal again
        if (fromAddProfile(location)) {
          history.back()
        }
      }
    }),
    (
      { onValidPin, visibility, ...others },
      { validate, dismiss },
      { onCancel, ...ownProps }
    ) => {
      return {
        ...ownProps,
        visibility,
        onValidPin: pin => {
          if (onValidPin) {
            onValidPin(pin)
          }
          validate()
        },
        onCancel: () => {
          if (onCancel) {
            onCancel()
          }
          dismiss()
        }
      }
    }
  ),
  branch(({ visibility }) => !visibility, renderNothing)
)

export default enhance(PinCheck)
