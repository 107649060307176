import React from 'react'
import PropTypes from 'prop-types'

import styles from './hotspot-header-image.css'

const CTABoxHeaderImage = ({ headerImageUri }) => {
  return (
    <>
      { headerImageUri && (
        <div className={styles.headerImageFrame}>
          <img src={headerImageUri} alt="Hotspot Header" className={styles.headerImage} />
        </div>
      )}
    </>
  )
}

CTABoxHeaderImage.propTypes = {
  headerImageUri: PropTypes.string
}

CTABoxHeaderImage.defaultProps = {
  headerImageUri: null
}

export default CTABoxHeaderImage
