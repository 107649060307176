import { createUseStyles } from 'react-jss'
import { commonStyles } from '../../../shared/common.styles'

export const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),
  discountEndDate: {
    fontSize: 16,
    fontWeight: 325,
    lineHeight: '20.8px',
    textAlign: 'center',
    [theme.media.smallMobile]: {
      fontSize: 14
    }
  },

  discountPeriod: {
    textAlign: 'center',
    fontSize: 14,
    color: '#9f9f9f',
    [theme.media.smallMobile]: {
      fontSize: 12
    }
  },

  discountTermsAndConditions: {
    fontSize: 14,
    fontWeight: 325,
    lineHeight: '20.8px',
    textDecoration: 'underline',
    textUnderlineOffset: '4px',
    color: '#9f9f9f',
    paddingBottom: 30,
    [theme.media.smallMobile]: {
      fontSize: 12
    }
  },

  discountLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 153,
    height: 28,
    padding: '6px 10px 6px 10px',
    borderRadius: '8px 0px 8px 0px',
    backgroundColor: theme.neonGreen,
    margin: '10px 0',
    [theme.media.smallMobile]: {
      width: 100,
      height: 22,
      padding: '3px 4px 3px 4px'
    }
  },

  discountLabelText: {
    color: 'black',
    fontSize: 16,
    fontFamily: 'GothamRounded',
    textAlign: 'center',
    [theme.media.smallMobile]: {
      fontSize: 11,
      fontWeight: 500
    }
  }
}))
