import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import { compose, withStateHandlers, mapProps } from 'recompose'
import ReactSVG from 'react-svg'

import AsyncReactSelect from '../../containers/async-react-select'
import arrowDown from '../../../images/select-arrow-down.svg'
import arrowUp from '../../../images/select-arrow-up.svg'

import { history } from '../../store'
import { useRentalPageDetector } from '../../hooks/use-rental-page-detector'

import CATEGORY_COMPONENT_ITEM_FRAGMENT from '../../../graphql/fragments/category.gql'

import styles from './category-navigation-selector.css'
import {
  customReactSelectStyles,
  customReactSelectTheme
} from '../forms/select-custom-syles'

const SELECT_VALUE_KEY = 'name'

function Arrow({ isOpen }) {
  // TODO: Move this
  return (
    <ReactSVG
      src={isOpen ? arrowUp : arrowDown}
      className={styles.selectorArrow}
    />
  )
}

Arrow.propTypes = {
  isOpen: PropTypes.bool.isRequired
}

function CategoryNavSelector({
  options,
  onSelectionChange,
  selectedOption,
  prefetchScreen
}) {
  const isOnARentalPage = useRentalPageDetector()

  return (
    <div className={styles.wrapper}>
      <AsyncReactSelect
        styles={customReactSelectStyles({
          isKid: false,
          isRentalThemed: isOnARentalPage
        })}
        theme={customReactSelectTheme({
          isKid: false,
          isRentalThemed: isOnARentalPage
        })}
        getOptionLabel={option => option[SELECT_VALUE_KEY]}
        getOptionValue={option => option.id}
        defaultValue={selectedOption}
        labelKey={SELECT_VALUE_KEY}
        formatOptionLabel={option => (
          <div key={option.id} className={styles.option}>
            {option[SELECT_VALUE_KEY]}
          </div>
        )}
        options={options}
        onChange={onSelectionChange}
        arrowRenderer={Arrow}
        isSearchable={false}
        isClearable={false}
        deleteRemoves={false}
        backspaceRemoves={false}
      />
    </div>
  )
}

CategoryNavSelector.propTypes = {
  options: PropTypes.arrayOf(propType(CATEGORY_COMPONENT_ITEM_FRAGMENT))
    .isRequired,
  selectedOption: propType(CATEGORY_COMPONENT_ITEM_FRAGMENT).isRequired,
  prefetchScreen: PropTypes.func.isRequired,
  onSelectionChange: PropTypes.func.isRequired
}

function initialSelectorState({ selectedOption }) {
  return {
    selectedOption
  }
}

const enhanced = compose(
  mapProps(({ categoryItems, selectedCategoryIdx, prefetchScreen }) => {
    const selectedOption = categoryItems[selectedCategoryIdx]
    return {
      options: categoryItems,
      selectedOption,
      prefetchScreen
    }
  }),
  withStateHandlers(initialSelectorState, {
    onSelectionChange: () => selectedOption => {
      // For now, we are manually handling selected menu items,
      // because for some reason Links and anchor tags do not work
      // with react-select
      const pathname = selectedOption.path || '/404'
      history.push(pathname)
      return {
        selectedOption
      }
    }
  })
)(CategoryNavSelector)

export default enhanced
