import { withRouter } from 'react-router-dom'
import { compose, withHandlers } from 'recompose'

import { getModalLocation } from '../lib/modal'
import { omitProps } from '.'

const withShowModalClick = compose(
  withRouter,
  withHandlers({
    showModal: ({ history, location }) => (modalType, params) => {
      params
        ? history.push(getModalLocation(location, modalType, params))
        : history.push(getModalLocation(location, modalType))
    }
  }),
  omitProps(['history', 'location', 'match'])
)

export default withShowModalClick
