import React from 'react'
import PropTypes from 'prop-types'
import { Transition } from 'react-transition-group'

import { pure } from 'recompose'

import styles from './transitions.css'

const duration = 500

const transitionStyles = {
  entered: { opacity: 1 }
}

const Fade = ({ in: inProp, children, ...props }) => (
  <Transition
    {...props}
    in={inProp}
    timeout={duration}
  >
    {(state) => {
      return (
        <div
          className={styles.opacityTransition}
          style={{
            ...transitionStyles[state]
          }}
        >
          {children}
        </div>
      )
    }}
  </Transition>
)

Fade.propTypes = {
  in: PropTypes.bool.isRequired
}

export default pure(Fade)
