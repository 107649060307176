import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { propType } from 'graphql-anywhere'

import { dataLayerProductClick } from '../../../lib/analytics/datalayer'
import RATINGFIELDS_FRAGMENT from '../../../../graphql/fragments/rating.gql'
import { Rating } from '../../rating'
import styles from './synopsis.css'

function Synopsis({ synopsis, isRental, rating }) {
  const applyRentalBranding = (...classList) => classNames(...classList, {
    [styles.isRental]: isRental
  })

  return (
    <div className={styles.wrapper}>
      <div className={applyRentalBranding(styles.tag)}>
        <span>{synopsis.tag}</span>
      </div>
      { rating && (
        <div className={styles.ratingLabelSmallScreen}>
          <Rating size="medium" rating={rating} className={styles.iconInHotspotVideo} />
        </div>
      )}

      <div className={styles.title}>{synopsis.title}</div>
      <div className={styles.title2}>{synopsis.title2}</div>
      <div className={styles.description}>{synopsis.description}</div>
      <div className={applyRentalBranding(styles.cta)}>
        <Link
          to={synopsis.cta.url}
          onClick={() => {
            dataLayerProductClick(
              {
                title: `${synopsis.title} HotspotVideo`,
                series: {
                  ldId: 0
                }
              },
              0,
              'HotspotVideo'
            )
          }}
        >
          {synopsis.cta.text}
        </Link>
      </div>

      { rating && (
        <div className={styles.ratingLabel}>
          <Rating size="large" rating={rating} className={styles.iconInHotspotVideo} />
        </div>
      )}
    </div>
  )
}

export const SynopsisType = PropTypes.shape({
  tag: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  title2: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  cta: PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired
})

Synopsis.propTypes = {
  synopsis: SynopsisType.isRequired,
  isRental: PropTypes.bool.isRequired,
  rating: propType(RATINGFIELDS_FRAGMENT)
}

export default Synopsis
