import React from 'react'
import { withRouter } from 'react-router-dom'
import { setDisplayName } from 'recompose'

import NavigationAccount from '../../layouts/shared-navigation/navigation-account'
import KidsNavigation from '../../layouts/shared-navigation/navigation-account-kids'
import { getCurrentProfile } from '../../lib/account'
import { useProfilesEntitlement } from '../../hooks/useProfilesEntitlement'
import NavigationAccountHoC from './navigation-account'

export default setDisplayName('NavigationAccountMenu')(withRouter(NavigationAccountHoC(({ accountQuery, ...props }) => {
  const selectedProfile = accountQuery &&
  accountQuery.account &&
  accountQuery.account.selectedProfile

  const currentProfile = getCurrentProfile(accountQuery)

  const profiles = accountQuery &&
  accountQuery.account &&
  accountQuery.account.profiles &&
  accountQuery.account.profiles.filter(profile => profile.id !== selectedProfile)
  const { isProfileAllowBasicUpsell, profileLimit } = useProfilesEntitlement(accountQuery)

  return (
    currentProfile && currentProfile.isKid
      ? <KidsNavigation {...props} />
      : (
        <NavigationAccount
          {...props}
          profiles={profiles}
          selectedProfile={selectedProfile}
          isProfileAllowBasicUpsell={isProfileAllowBasicUpsell}
          profileLimit={profileLimit}
          email={accountQuery?.account?.email}
          cpId={accountQuery?.account?.cpCustomerID}
        />
      )
  )
})))
