/**
 * A reducer to process continue item(s) been removed
 * and filter & update continue tiles for carousel UI
 * @returns state
 */

/**
 * Actions
 */
export const GET_ALL_TILES = 'GET_ALL_TILES'
export const REMOVE_ITEM = 'REMOVE_ITEM'
export const UNDO_REMOVE_ITEM = 'UNDO_REMOVE_ITEM'
export const REFRESH_UI = 'REFRESH_UI'

/**
 * Initial states for continuity hook
 */
export const initialState = {
  tiles: [],
  // a list of contentItems that will be removed
  removedItemsList: [],
  // user selected contentItem to remove/undo remove
  selectedItem: {
    contentItem: {},
    isInUndoState: false,
    timerId: null
  }
}

export const reducer = (state, action) => {
  switch (action.type) {
    case GET_ALL_TILES:
      return {
        ...state,
        tiles: action.tiles
      }
    case REMOVE_ITEM:
      return {
        ...state,
        selectedItem: action.selectedItem,
        tiles: updateTiles(state.tiles, action.selectedItem),
        removedItemsList: updateRemovedItems(state.removedItemsList, action.selectedItem)
      }
    case UNDO_REMOVE_ITEM: // remove selected item from remove list
      return {
        ...state,
        selectedItem: action.selectedItem,
        tiles: updateTiles(state.tiles, action.selectedItem),
        removedItemsList: state.removedItemsList.filter(item => item.contentItem.id !== action.selectedItem.contentItem.id)
      }
    case REFRESH_UI:
      return {
        ...state,
        selectedItem: action.selectedItem,
        tiles: state.tiles.filter(tile => tile.contentItem.id !== action.selectedItem.id),
        removedItemsList: state.removedItemsList.filter(item => item.contentItem.id !== action.selectedItem.id)
      }
    default:
      return state
  }
}

/**
 * Update removed items list for refreshing UI
 * @param {[]} items - total content items will be removed
 * @param {object} contentItem - selected item to remove
 */
const updateRemovedItems = (items, removedItem) => {
  if (items && items.indexOf(removedItem) > -1) {
    return items
  }
  items.push(removedItem)
  return items
}

/**
 * Update selected item in tiles
 * @param {[]} tiles - tiles for rendering continue watching
 * @param {object} selectedItem - user selected item
 */
const updateTiles = (tiles, selectedItem) => {
  const updateTileState = tiles.map(tile => {
    if (tile.contentItem.id === selectedItem.contentItem.id) {
      tile.isInUndoState = selectedItem.isInUndoState
    }
    return tile
  })
  return updateTileState
}
