import {
  compose, branch, renderNothing
} from 'recompose'
import { connect } from 'react-redux'
// import { STATIC_SCREENS } from './constants'
import App from './index'
// import { LoadingModal } from './components/loading/modal'

const mapStateToProps = (state) => {
  return {
    appIsLoading: state.app.isLoading
  }
}

const Gatekeeper = compose(
  connect(mapStateToProps),
  branch(
    ({ appIsLoading }) => (
      appIsLoading
    ),
    renderNothing
  )
  // it's causing infinite loading when EV test iframe return URL in the browser,
  // (e.g. https://www.test.npe.neontv.co.nz/credit-card-form?sessionToken=x&sku=LBPREMTV&amount=19.99&key=x&status=success).
  // So comment below change unless this is necessary:
  // branch(
  //   () => {
  //     return (
  //       location.pathname === STATIC_SCREENS.EVERGENT_IFRAME_RETURN_URL.creditCardForm
  //     )
  //   },
  //   renderComponent(LoadingModal)
  // )
)

export default Gatekeeper(App)
