import PropTypes from 'prop-types'
import {
  setDisplayName,
  withHandlers,
  withStateHandlers,
  wrapDisplayName,
  compose
} from 'recompose'
import { Component, createElement } from 'react'
import { throttle } from './utils'
import { STILL, LEFT_SLIDE, RIGHT_SLIDE } from './constants'

const LOAD_DELAY = 100

export const withOffsetState = compose(
  withStateHandlers(
    { offset: 0, sliderStatus: STILL },
    {
      setOffset: () => offset => {
        return { offset }
      },
      setSliderStatus: () => direction => {
        return { sliderStatus: direction }
      }
    }
  )
)

export default compose(
  withOffsetState,
  withHandlers({
    slideLeft: ({ setSliderStatus, sliderStatus }) => () => {
      if (sliderStatus === STILL) {
        // 300ms delay for loading images to avoid lag, ideal should be call after image loaded
        setTimeout(() => setSliderStatus(LEFT_SLIDE), LOAD_DELAY)
      }
    },
    slideRight: ({ setSliderStatus, sliderStatus }) => () => {
      if (sliderStatus === STILL) {
        // 300ms delay for loading images to avoid lag, ideal should be call after image loaded
        setTimeout(() => setSliderStatus(RIGHT_SLIDE), LOAD_DELAY)
      }
    },
    resetSlide: ({
      setSliderStatus,
      setOffset,
      sliderStatus,
      offset
    }) => () => {
      setOffset(sliderStatus === LEFT_SLIDE ? offset - 1 : offset + 1)
      setSliderStatus(STILL)
    }
  })
)

export function withThrottle(handlerName, interval) {
  return Target => {
    class ThrottleHandler extends Component {
      constructor(props, context) {
        super(props, context)
        const throttled = throttle(
          props[handlerName],
          interval || props.duration
        )

        this[handlerName] = (e, ...rest) => {
          if (e && typeof e.persist === 'function') {
            e.persist()
          }

          return throttled(e, ...rest)
        }
      }

      render() {
        return createElement(Target, {
          ...this.props,
          [handlerName]: this[handlerName]
        })
      }
    }

    ThrottleHandler.propTypes = {
      duration: PropTypes.number
    }

    ThrottleHandler.defaultProps = {
      duration: 0
    }

    if (process.env.NODE_ENV !== 'production') {
      return setDisplayName(wrapDisplayName(Target, 'withThrottle'))(
        ThrottleHandler
      )
    }

    return ThrottleHandler
  }
}
