import { createUseStyles } from 'react-jss'
import { commonStyles } from '../../../shared/common.styles'

export const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),
  paymentMethodCardContent: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    [theme.media.desktop]: {
      padding: [20, 30, 0, 30]
    },
    [theme.media.tablet]: {
      alignItems: 'center'
    },
    [theme.media.mobile]: {
      alignItems: 'center'
    },
    [theme.media.smallMobile]: {
      alignItems: 'center'
    }
  },

  paymentMethodCardContentRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 15,
    fontSize: 20,
    [theme.media.smallMobile]: {
      fontSize: 14
    }
  },

  paymentMethodCardCheckIcon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 26,
    height: 28,
    width: 28,
    [theme.media.tablet]: {
      display: 'none'
    },
    [theme.media.mobile]: {
      display: 'none'
    },
    [theme.media.smallMobile]: {
      display: 'none'
    }
  },

  highlightText: {
    color: theme.neonGreen
  }
}))
