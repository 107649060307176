import { useLocation } from 'react-router-dom'
import { RENTAL_THEMED_PAGES } from '../constants'

/**
 * Hook to detect whether we are on a rental themed page.
 *
 * @returns {Boolean} isRentalPage - A value indicating whether we are on a rental themed page
 */
export const useRentalPageDetector = () => {
  const { pathname } = useLocation()
  return RENTAL_THEMED_PAGES.some(route => pathname.indexOf(route) === 0)
}
