import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './badge.css'

function TileBadge({
  title, hide, gridBadge, isRental, isKids
}) {
  return (
    <div
      className={classnames(styles.badgeWrapper, {
        [styles.hide]: hide,
        [styles.gridBadge]: gridBadge
      })}
    >
      <div
        className={classnames(styles.badge, {
          [styles.isRental]: isRental,
          [styles.roundedBadge]: !isKids
        })}
      >
        {title}
      </div>
    </div>
  )
}

TileBadge.propTypes = {
  title: PropTypes.string.isRequired,
  hide: PropTypes.bool,
  gridBadge: PropTypes.bool,
  isRental: PropTypes.bool,
  isKids: PropTypes.bool
}

TileBadge.defaultProps = {
  hide: false,
  gridBadge: false
}

export default TileBadge
