import {
  THEME_OPTIONS
} from '../../constants'

import { getFirstTrailer } from '../../lib/utils'
import {
  PLAY_BUTTON_COMING_SOON_TEXT,
  PLAY_BUTTON_TRAILER_TEXT,
  PLAY_BUTTON_KIDS_PLAY_ICON,
  PLAY_BUTTON_PLAY_ICON
} from './constants'

// Get trailer icon
const getTrailerICon = theme => (
  (theme === THEME_OPTIONS.light)
    ? PLAY_BUTTON_KIDS_PLAY_ICON
    : PLAY_BUTTON_PLAY_ICON
)

export const getTrailerProps = ({ contentItem, theme }) => {
  const trailer = getFirstTrailer(contentItem)

  // If no trailer is defined
  // set no click handler and add coming soon cta text
  if (!trailer) {
    return {
      icon: getTrailerICon(theme),
      ctaPrefix: PLAY_BUTTON_COMING_SOON_TEXT,
      price: '',
      progressPercentage: 0,
      displayCta: true,
      displayPrice: false,
      displayTime: false,
      clickable: false
    }
  }

  // If a trailer is defined
  // Go to player view and watch trailer
  const trailerId = trailer && trailer.id
  return {
    icon: getTrailerICon(theme),
    ctaPrefix: PLAY_BUTTON_TRAILER_TEXT,
    price: '',
    progressPercentage: 0,
    displayCta: true,
    displayPrice: false,
    displayTime: false,
    onClick: () => {},
    watchUrl: `/trailer/${trailerId}/${contentItem.id}`
  }
}
