// import { getEndCuepoint } from './get-end-cuepoint'

export function shouldSendStopEvent(state) {
  if (state.duration === 0) return false
  if (state.isPlayingAd) return false

  // using up_next of cue_points if up_next exists
  const metaData = state.getMetaData()
  const cuePoints = metaData.cuePoints
  const upNext = cuePoints.find(cp => cp.name === 'up_next')
  if (upNext) {
    const roundedUpNextTime = Math.floor(upNext.time)
    return state.currentTime >= roundedUpNextTime
  }

  // using PLAYER_VIDEO_DONE_THRESHOLD of config if up_next of cue_points not exists
  const endTime = state.values.PLAYER_VIDEO_DONE_THRESHOLD * state.duration

  return state.currentTime >= endTime
}
