import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import classNames from 'classnames'
import { length } from 'ramda'
import { Link } from 'react-router-dom'

import Arrow from './navigation-arrow'
import styles from './navigation-item.css'

import NAV_ITEM_FRAGMENT from '../../../graphql/fragments/nav-item.gql'

export default function NavigationItemButton({
  navItem,
  onToggleMenu,
  activeClassName,
  location,
  isExpanded,
  pathname,
  className,
  onClick,
  inDropdown,
  setIsExpanded,
  expandOnHover,
  expandOnArrow
}) {
  if (navItem.children && length(navItem.children)) {
    if (expandOnArrow) {
      return (
        <div>
          <span // eslint-disable-line jsx-a11y/no-static-element-interactions
            className={classNames(className, activeClassName, styles.navItemToggle)}
            onClick={() => (!expandOnHover ? onToggleMenu() : undefined)}
            onMouseEnter={() => (expandOnHover ? setIsExpanded(true) : undefined)}
            data-e2e={`${navItem.name}-in-Hamburger-menu`}
          >
            <a onClick={onClick}>{navItem.name}</a>
            {
              !inDropdown && <Arrow isExpanded={isExpanded} />
            }
          </span>
        </div>
      )
    }
    return (
      <a // eslint-disable-line jsx-a11y/no-static-element-interactions
        className={classNames(className, activeClassName, styles.navItemToggle)}
        onClick={(e) => (!expandOnHover ? onToggleMenu() : onClick(e))}
        onMouseEnter={() => (expandOnHover ? setIsExpanded(true) : undefined)}
        data-e2e={`nav-item-${navItem.name}`}
      >
        <span>{navItem.name}</span>
        {
          !inDropdown && <Arrow isExpanded={isExpanded} />
        }
      </a>
    )
  }

  return (
    <Link
      className={classNames(className, activeClassName, styles.navItemLink)}
      onClick={onClick}
      to={pathname}
      data-e2e={`nav-item-${navItem.name}`}
    >
      {navItem.name}
    </Link>
  )
}

NavigationItemButton.propTypes = {
  navItem: propType(NAV_ITEM_FRAGMENT).isRequired,
  className: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onToggleMenu: PropTypes.func.isRequired,
  activeClassName: PropTypes.string,
  inDropdown: PropTypes.bool,
  setIsExpanded: PropTypes.func,
  expandOnHover: PropTypes.bool,
  expandOnArrow: PropTypes.bool
}

NavigationItemButton.defaultProps = {
  navItem: {},
  activeClassName: '',
  setIsClicked: () => {},
  inDropdown: false,
  setIsExpanded: () => {},
  expandOnHover: false,
  expandOnArrow: false
}
