import { path } from 'ramda'

import {
  MODALS,
  PLAYBACK_INFO,
  RENTAL_STATUS
} from '../../constants'
import { isRentable, getLowestPrice } from '../../lib/content-item'
import { getModalLocation } from '../../lib/modal'
import { getWatchUrl, getDetailsUrl } from '../../lib/utils'
import {
  calcProgressPercentage,
  findPlaybackInfoById,
  getCTA,
  getIcon,
  getDurationMinutesRemaining,
  getNumberMinutesWatched,
  getPlaybackInfo,
  getTimeString
} from './utils'
import {
  episodeHouseId,
  navigationGa,
  playerGaPlayButtonEvent,
  seasonHouseId,
  seriesTitleHouseId
} from '../../lib/analytics/ga'
import { customDimensions } from '../../lib/analytics/custom-dimensions'
import {
  getAdditionalGenreFromContentItem,
  dataLayerPlay
} from '../../lib/analytics/datalayer'
import { PLAY_BUTTON_WATCH_NOW_TEXT, PLAY_BUTTON_RESUME_TEXT } from './constants'
import { modalContent } from '../../modules/shared/subscription-constants'

const titleWatchUrlOrModal = ({
  castSender,
  contentItem,
  isAuthenticated,
  location,
  rentable,
  watchable,
  isRental
}) => {
  if (watchable && !castSender.isConnected) {
    return getWatchUrl(contentItem)
  }

  if (!isAuthenticated) {
    getModalLocation(
      location,
      MODALS.qsParams.login
    )
  }

  if (!isRental && !castSender.isConnected) {
    if (!isAuthenticated) {
      return getModalLocation(
        location,
        MODALS.qsParams.login
      )
    }

    return getModalLocation(
      location,
      MODALS.qsParams.addSubscription,
      { content: modalContent.selection }
    )
  }

  if (rentable || !isAuthenticated) {
    return getModalLocation(
      location,
      MODALS.qsParams.loginRental,
      undefined,
      { detailsUrl: getDetailsUrl(contentItem) }
    )
  }

  return '#'
}

export const getTitlePlaybackInfo = ({
  playbackInfoArray,
  contentItem
}) => {
  // Get appropriate playback
  // Find playback info for ContentItem
  const playbackInfo = getPlaybackInfo(
    findPlaybackInfoById(contentItem.id)(playbackInfoArray)
  )

  return {
    playbackInfo
  }
}

const getTitlePriceString = (rentable, lowestPrice) => (
  (rentable && lowestPrice) ? ` $${lowestPrice}` : ''
)

export const getTitleProps = ({
  isAuthenticated,
  maybeInitializeCastSender,
  displayedInLocation,
  contentItem,
  watchable,
  rentalStatus,
  theme,
  castSender,
  playbackInfo,
  location,
  currentProfile,
  isRental,
  fromContinuityTile
}) => {
  const rentable = isRentable(rentalStatus)
  // get products lower price
  const lowestPrice = getLowestPrice(contentItem)

  // Get number of minutes remaining
  const numberMinutesRemaining = getDurationMinutesRemaining(playbackInfo)
  // Get number of minutes watched
  const numberMinutesWatched = getNumberMinutesWatched(playbackInfo)

  // Get playback status
  const status = path(['status'], playbackInfo)
  const inProgress = status === PLAYBACK_INFO.status.resume

  // Get play button icon
  const icon = getIcon(status, theme, !fromContinuityTile)

  // Calculate progress indicator
  // Hide progress if the movie is rentable (i.e. expired rental)
  const progressPercentage = isRental && rentable
    ? 0
    : calcProgressPercentage(contentItem.duration, numberMinutesWatched, status)

  // const castSenderDisconnected = watchable && !castSender.isConnected;
  const watchUrl = titleWatchUrlOrModal({
    castSender,
    contentItem,
    isAuthenticated,
    location,
    rentable,
    watchable,
    isRental
  })

  // Get play button main call to action text
  let ctaPrefix = PLAY_BUTTON_WATCH_NOW_TEXT
  if (isRental) {
    ctaPrefix = getCTA(status, isAuthenticated, rentalStatus, watchable)
  } else if (status === PLAYBACK_INFO.status.resume) {
    ctaPrefix = PLAY_BUTTON_RESUME_TEXT
  }
  const time = getTimeString(inProgress, numberMinutesRemaining)
  const price = getTitlePriceString(!isAuthenticated || rentable, lowestPrice)

  // do not show cta and time when it is from continue watching
  const displayCta = !fromContinuityTile
  // if we can rent the title (i.e. expired rental), don't show the time
  const displayTime = fromContinuityTile ? false : (!rentable || Boolean(inProgress))

  // only display price if not watchable
  const displayPrice = (!isAuthenticated || !watchable) && Boolean(lowestPrice) && isRental
  const onClick = (event, navigationGaFunction = navigationGa) => {
    // Only fire play button event if the item is rented
    if (!isRental || rentalStatus === RENTAL_STATUS.RENTED) {
      playerGaPlayButtonEvent(
        contentItem,
        displayedInLocation,
        numberMinutesWatched,
        currentProfile
      )
      dataLayerPlay(contentItem)
    } else {
      navigationGaFunction({
        action: 'Rent',
        label: contentItem.title,
        [customDimensions.ShowTitle]: contentItem.title,
        [customDimensions.ProfileId]: currentProfile && currentProfile.id,
        [customDimensions.ProfileName]: currentProfile && currentProfile.name,
        [customDimensions.SeasonNumber]: contentItem.seasonNumber,
        [customDimensions.EpisodeNumber]: contentItem.episodeNumber,
        [customDimensions.AdditionalGenre]: getAdditionalGenreFromContentItem(contentItem),
        [customDimensions.SeriesTitleHouseId]: seriesTitleHouseId(contentItem),
        [customDimensions.SeasonHouseId]: seasonHouseId(contentItem),
        [customDimensions.EpisodeHouseId]: episodeHouseId(contentItem)
      })
    }

    // Let Link handle the click and location change
    // if cast sender is disconnected
    if (!castSender.isConnected) {
      return
    }

    if (watchable) {
      // PreventLink from handling the click
      event.preventDefault()

      maybeInitializeCastSender(getWatchUrl(contentItem), contentItem)
    }
  }

  return {
    icon,
    progressPercentage,
    watchUrl,
    ctaPrefix,
    time,
    price,
    displayCta,
    displayTime,
    displayPrice,
    onClick
  }
}
