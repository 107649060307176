import React, {
  useEffect,
  useRef,
  useState,
  useCallback
} from 'react'
import { useMutation } from 'react-apollo'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { propType } from 'graphql-anywhere'

import RATINGFIELDS_FRAGMENT from '../../../../graphql/fragments/rating.gql'
import ControlVhsStream from './control-vhs-stream'
import Synopsis, { SynopsisType } from './synopsis'
import Image from './image'
import { loadScript } from '../../../lib/script-loader'
import useHotspotVideoCounter from '../../../hooks/useHotspotVideoCounter'
import INCREMENT_VIDEO_HOTSPOT_PLAYBACK_COUNT from '../../../../graphql/mutations/increment-video-hotspot-playback-count.gql'

import styles from './hotspot-video.css'
import useStyles from './hotspot-video-stream.styles'
import rentalBadge from '../../../../images/neon/rental-badge.png'
import { VHS_PLAYER } from '../../../constants'

const PLAYER_ID = process.env.BC_PLAYER_ID
const ACCOUNT_ID = process.env.BC_ACCOUNT_ID
const scriptUrl = `https://players.brightcove.net/${ACCOUNT_ID}/${PLAYER_ID}_default/index.min.js`

const counter = useHotspotVideoCounter()

const HotspotVideoStream = ({ content, fallback }) => {
  const videoElem = useRef(null)
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const [player, setPlayer] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [shouldHideImage, setShouldHideImage] = useState(false)
  const classes = useStyles()

  const {
    video,
    maxShown,
    isRental,
    image,
    synopsis
  } = content
  const selectedVideo = synopsis.title
  const shownCount = counter.getByVideo(selectedVideo)
  let onLoadedTimer

  // Load Script
  useEffect(() => {
    if (!scriptLoaded) {
      loadScript(scriptUrl, () => {
        setScriptLoaded(true)
      })
    }
  }, [])

  // Load player
  useEffect(() => {
    if (!scriptLoaded || !videoElem.current) {
      return
    }

    setPlayer(window.bc(videoElem.current))
  }, [scriptLoaded, videoElem.current])

  // Load media and set event handlers
  useEffect(() => {
    if (!player || !video) {
      return
    }

    const videoProcessed = player.catalog.transformVideoResponse(video.stream)
    player.catalog.load(videoProcessed)
    player.autoplay(false)

    player.on('play', onPlay)
    player.on('pause', onPause)
    player.on('loadeddata', onLoaded)
    player.on('ended', onEnded)
  }, [player, video])

  // Update counter
  const [incrementVideoHotspotPlaybackCount] = useMutation(
    INCREMENT_VIDEO_HOTSPOT_PLAYBACK_COUNT,
    {
      variables: {
        videoPath: selectedVideo
      }
    }
  )

  // Event Handlers
  const onPlay = useCallback(() => {
    setIsPlaying(true)
    setShouldHideImage(true)
  }, [setIsPlaying, setShouldHideImage])

  const onPause = useCallback(() => {
    setIsPlaying(false)
  }, [setIsPlaying])

  const onEnded = useCallback(() => {
    setIsPlaying(false)
    setShouldHideImage(false)
    counter.incrementByVideo(selectedVideo)
    incrementVideoHotspotPlaybackCount()
  }, [
    setIsPlaying,
    setShouldHideImage,
    counter,
    selectedVideo,
    incrementVideoHotspotPlaybackCount
  ])

  const onLoaded = useCallback(() => {
    let { delaySeconds } = content

    if (!delaySeconds) {
      delaySeconds = 0
    }

    onLoadedTimer = setTimeout(() => {
      if (!player) return
      player.muted(true)
      player.play()
    }, delaySeconds * 1000)
  }, [content, player])

  // Remove timeout when unmount
  useEffect(() => {
    return () => {
      if (onLoadedTimer) {
        clearTimeout(onLoadedTimer)
      }

      if (player) {
        player.off('play', onPlay)
        player.off('pause', onPause)
        player.off('loadeddata', onLoaded)
        player.off('ended', onEnded)
        player.dispose()
      }
    }
  }, [player])

  return shownCount > maxShown ? (
    fallback
  ) : (
    <div className={styles.wrapper}>
      {isRental && (
        <img
          className={styles.rentalBadge}
          src={rentalBadge}
          alt="Rental Content"
        />
      )}

      <div className={styles.videoWrapper}>
        <ControlVhsStream player={player} isPlaying={isPlaying} />

        <Image isHidden={shouldHideImage} image={image} />

        <div
          className={classnames(classes.video, {
            [classes.hidden]: !shouldHideImage
          })}
        >
          <div className={classes.videoRatio}>
            <div className={classes.videoContainer}>
              <video
                id={VHS_PLAYER.id}
                className={classnames('video-js', classes.videoPlayer)}
                ref={videoElem}
              />
            </div>
          </div>
        </div>

        <div className={styles.videoLeftGradient} />
        <div className={styles.videoBottomGradient} />
      </div>
      <Synopsis synopsis={synopsis} isRental={isRental} rating={content.rating} />
    </div>
  )
}

const HotspotVideoContentType = PropTypes.shape({
  image: PropTypes.string.isRequired,
  video: PropTypes.shape({
    high: PropTypes.string,
    low: PropTypes.string,
    stream: PropTypes.shape({
      sources: PropTypes.oneOfType([PropTypes.array])
    })
  }).isRequired,
  synopsis: SynopsisType.isRequired,
  maxShown: PropTypes.number.isRequired,
  delaySeconds: PropTypes.number,
  isRental: PropTypes.bool.isRequired,
  rating: propType(RATINGFIELDS_FRAGMENT).isRequired
})

HotspotVideoStream.propTypes = {
  content: HotspotVideoContentType.isRequired,
  fallback: PropTypes.element.isRequired
}

export default HotspotVideoStream
