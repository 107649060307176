import ActionTypes from '../actions/types'

// { player: { volume } } = state;
const volume = (state = 1, action) => {
  switch (action.type) {
    case ActionTypes.SET_VOLUME:
      return action.volume
    default:
      return state
  }
}

// { player: { volume } } = state;
const muted = (state = false, action) => {
  switch (action.type) {
    case ActionTypes.SET_MUTED:
      return action.muted
    default:
      return state
  }
}

// { player } = state;
const player = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.SET_VOLUME:
    case ActionTypes.SET_MUTED:
      return {
        ...state,
        volume: volume(state.volume, action),
        muted: muted(state.muted, action)
      }
    default:
      return state
  }
}

export default player
