import DOM from 'react-dom'

/**
 * see https://reactjs.org/docs/portals.html
 * children must be single element
 */
const Portal = ({ children }) => {
  return DOM.createPortal(children, document.body)
}

export default Portal
