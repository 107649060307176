exports.E2E_SELECTORS = {
  ACCOUNT_DETAILS: 'Account Details',
  ACCOUNT_PASSWORD: 'account-password-input',
  ACCOUNT_NAME_SIDE_NAVIGATION: 'account-name-side-navigation',
  ADD_REMOVE_FROM_MY_LIST_BUTTON: 'add-or-remove-from-my-list',
  ADULT_AVATAR_MENU: 'adult-avatar-menu',
  ADULT_AVATAR_MENU_MY_LIST: 'adult-avatar-menu-my-list',
  ADULT_AVATAR_MENU_MY_RENTALS: 'adult-avatar-menu-my-rentals',
  ALL_MOVIES_SUBMENU: '/browse-movies/all-movies',
  ALL_MOVIES_SUBMENU_IN_HAMBURGER_MENU: 'All Movies',
  ALL_TV_SUBMENU: '/tv-series/all',
  ALL_TV_SUBMENU_IN_HAMBURGER_MENU: 'All TV',
  AVAILABLE_SEASONS: 'Available Seasons',

  BACK_TO_TOP: 'Back To Top',

  CARD_NUMBER: '[id=cardNumber]',
  CONFIRM_EMAIL: '[id=emailConfirmation]',
  CONFIRM_CANCEL_PLAN: 'confirm_cancel',
  CONTINUE_WITHOUT_PAYMENT: 'signup-continue-without-payment',
  CONTINUE_WITHOUT_TV_PLAN: 'signup-continue-without-tv-plan',
  CROSS_BUTTON: '[data-lbx-e2e*="nav-side"] [data-lbx-e2e="hamburger-icon"]',

  DEVICES_AND_DOWNLOADS: 'Devices and downloads',
  DISCOVER: 'Discover',
  DISCOVER_IN_HAMBURGER_MENU: 'Discover-in-Hamburger-menu',
  DISCOVER_MENU_TAB: 'DISCOVER Menu Tab',
  DOWNGRADE_TO_STANDARD: 'Downgrade to Standard: Standard',
  DURATION_OF_MOVIE: 'duration',

  ENTER_ACCOUNT_PASSWORD: 'enter-account-password',
  EPISODE_LIST: 'episode-list',
  EPISODE_NUMBER: '[data-lbx-e2e*="episodeNo"]',
  EVERGENT_IFRAME: 'e2e-iframe',
  EXIT_KIDS_NAVSIDE: 'exit-kids-navside',
  EXIT_KIDS_ACCOUNT: 'exit-kids-account',
  EXPIRY_MONTH: '[id=cc_exp_mo]',
  EXPIRY_YEAR: '[id=cc_exp_yr]',

  FEED_BACK: 'feed-back',
  FIRST_NAME: '[id=firstName]',
  FOOTER: 'footer',

  GO_TO_MY_RENTALS_BUTTON: 'go-to-my-rentals',
  GUEST_LBX_LOGO: 'logo guest',
  GENRE: 'Genre',

  HAMBURGER_ICON: 'hamburger-icon',
  HAMBURGER_ICON_IN_NAV:
    "[data-lbx-e2e='nav nav-header'] [data-lbx-e2e='hamburger-icon']",
  HELP_IN_ACCOUNT_MENU: 'help-in-account-menu',
  HELP_IN_SETTINGS_MENU: 'help-in-settings-menu',
  HOT_SPOT_PLAY_BUTTON: 'hot-spot-play-button',

  KEEP_STANDARD: 'Keep Standard: Standard',
  KIDS: 'KIDS',
  KIDS_LOGO: 'Kids-logo',

  LAST_NAME: '[id=lastName]',
  LBX_LOGO: '[data-lbx-e2e*="logo"]',
  LOGGED_IN_CAROUSEL: 'logged-in-carousel',
  LOGGED_IN_LBX_LOGO: 'logo logged-in',
  LOGIN_EMAIL: '[id=email]',
  LOGIN_ERROR_TOP: 'login-error-top',
  LOGIN_MODAL_HEADER: 'login-modal-header',
  LOGIN_PASSWORD: '[id=password]',
  LOGIN_SUBMIT_BUTTON: 'login-submit-button',
  LOGOUT_IN_SETTINGS: 'logout-in-settings',
  LOGOUT_IN_ACCOUNT_MENU: 'logout-in-account-menu',

  MODAL_CLOSE_BUTTON: 'modal-close-button',
  MANAGE_PROFILE_IN_ACCOUNT_MENU: 'manage-profiles-in-account-menu',
  MANAGE_PROFILE_IN_SETTINGS: 'manage-profiles-in-settings',
  MORE_INFO: 'More Info',
  MOST_POPULAR_SHOWS: 'Most Popular Shows',
  MOST_POPULAR_SHOW: 'most-popular-shows',
  MOVIE_TITLE: 'movie-title',
  MOVIE_TILE_COMMON_IDENTIFIER: '/movie',
  MOVIES_IN_DISCOVER: 'Movies in Discover',
  MOVIES_IN_HAMBURGER_MENU: 'Movies-in-Hamburger-menu',
  MY_ACCOUNT_IN_ACCOUNT_MENU: 'my-account-in-account-menu',
  MY_ACCOUNT_IN_SETTINGS_MENU: 'my-account-in-settings-menu',
  MY_ACTIVITY: 'My Activity',
  MY_LIST_KIDS_ACCOUNT: 'my-list-kids-account',
  MY_LIST_KIDS_NAVSIDE: 'my-list-kids-navside',
  MY_LIST_MOVIES_TAB: 'my-list-movies-tab',
  MY_PLAN: 'My plan',
  MY_LIST_SIDE_NAVIGATION: 'my-list-side-navigation',
  MY_RENTALS_KIDS_ACCOUNT: 'my-rentals-kids-account',
  MY_RENTALS_KIDS_NAVSIDE: 'my-rentals-kids-navside',

  NAME_ON_CARD: '[id=nameoncard]',
  NAV: 'nav',
  NAVSEARCH: '[id=navSearch]',
  NEW_THIS_MONTH: 'new-this-month',

  OK_BUTTON: 'OK-button',
  OUR_MOST_POPULAR_SHOWS: '[id=our-most-popular-shows]',

  PASSWORD_RENTAL_IFRAME: '[name=ac_password]',
  PLAN_DESCRIPTION: 'plan_description',
  PRICE_CHANGE_ALERT: 'price_change_alert',
  PLAN_VOUCHERS_PAYMENTS: 'Plan, Vouchers & Payment',
  PLAY_PAUSE_TOGGLE_BUTTON: 'play-pause-toggle-button',

  RATING: 'Rating',
  RECENTLY_ADDED: 'Recently Added',
  RECENTLY_ADDED_MOVIES: '[id=recently-added-movies]',
  RECENTLY_ADDED_TV: '[id=recently-added-tv]',

  RENT_MOVIE: 'rent-movie',
  RENT_NOW_BUTTON: '[id=usr_payment_btn]',
  RENTAL_IFRAME: 'rental-iframe',
  RESET_PASSWORD_LINK: 'reset-password-link',
  RESET_SENT_LOGIN_BUTTON: 'reset-sent-login-button',
  RESET_SUBMIT_BUTTON: 'reset-submit-button',

  SEARCH: 'Search',
  SEASON_SELECTOR: 'Season Selector',
  SECURITY_CODE: '[id=securecode]',
  SERIES_TILE_COMMON_IDENTIFIER: '/series',
  SHOW_TITLE: 'show-title',
  SIGNUP_COMPLETE_ORDER: 'signup-complete-order',
  SIGNUP_MODAL_HEADER: 'signup-modal-header',
  SIGNUP_SUBMIT_BUTTON: 'signup-submit-button',
  SIGNUP_TV_PLAN_VOUCHER_CODE: 'tv-plan-voucher-code',
  SPARK_CUSTOMER: 'Spark customer',
  START_NOTIFICATION: 'Your Standard Plan will begin on',
  SUBMIT_SUBSCRIPTION_BUTTON: '[id=submit_btn]',
  SECURITY_CODE_ERROR:
    'Please re-enter your cvv if you wish to complete payment with this card.',
  SWITCH: 'switch',
  SYNOPSIS: 'Synopsis',

  TOP_NAV_HOME: "[data-lbx-e2e='nav nav-header'] [data-lbx-e2e='Home']",
  TOP_NAV_TV: 'TV',
  TOP_NAV_MOVIES: 'Movies',
  TOP_NAV_KIDS: 'Kids',
  TRY_ONE_MONTH_FREE_PREMIUM: 'Try one month free: Premium',
  TRY_ONE_MONTH_FREE_STANDARD: 'Try one month free: Standard',
  TV_PLAN_AND_PAYMENT: 'Plan, Vouchers & Payment',
  TV_IN_DISCOVER: 'TV in Discover',
  TV_IN_HAMBURGER_MENU: 'TV-in-Hamburger-menu',

  VIDEO_BODY: 'video-body',
  VIDEO_BUFFERED_PROGRESS: 'video-buffered-progress',
  VIDEO_MIDROLL_MARKER: 'video-midroll-marker',

  UPGRADE_TO_PREMIUM: 'Upgrade to Premium: Premium',

  WATCH_NOW: 'Watch now',

  YEAR_RELEASED: 'year',
  YOU_HAVE_NO_PLAN: 'you_have_no_plan'
}
