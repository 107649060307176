import ActionTypes from '../actions/types'
import { THEME_OPTIONS, DEFAULT_THEME } from '../constants'

const theme = (state = DEFAULT_THEME, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_AUTH_TOKEN_AND_THEME:
    case ActionTypes.THEME_SET:
    case ActionTypes.SET_PLAYER_IS_IN_VIEW:

      if (action.theme === THEME_OPTIONS.light) {
        document.body.classList.add(THEME_OPTIONS.light)
        document.body.classList.remove(THEME_OPTIONS.dark)
      } else if (action.theme === THEME_OPTIONS.dark || action.theme == null) {
        document.body.classList.add(THEME_OPTIONS.dark)
        document.body.classList.remove(THEME_OPTIONS.light)
      }
      return action.theme || DEFAULT_THEME
    default:
      return state
  }
}

export default theme
