import React, { Suspense } from 'react'
import FullScreenLoadingComponent from '../loading/fullscreen-loader'

const Title = React.lazy(() => import('./title'))

const AsyncTitle = props => (
  <Suspense fallback={<FullScreenLoadingComponent />}>
    <Title {...props} />
  </Suspense>
)

export default AsyncTitle
