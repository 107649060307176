import { compose, mapProps } from 'recompose'
import { omit } from 'ramda'

/**
 * From the Recompose docs https://github.com/acdlite/recompose/blob/master/docs/API.md#mapprops
 * A HoC that can be used to filter out any unwanted props.
 * Useful when you're manually cutting stuff out with mapProps.
 * Simply pass an array of strings.
 */
const omitProps = compose(mapProps, omit)

export default omitProps
