import {
  compose,
  withHandlers,
  withProps,
  onlyUpdateForPropTypes
} from 'recompose'
import { connect } from 'react-redux'
import { path, pathEq } from 'ramda'
import { Formik } from 'formik'
import { withRouter } from 'react-router-dom'

import {
  getAccountIdFromState
} from '../../../selectors/session'
import { setAccountPasswordTimestamp } from '../../../actions'
import Yup from '../../../lib/yup'

import PasswordInputForm from '../../../components/modals/enter-account-password/password-input-form'

import withApolloQueryAndLoading from '../../../hoc/with-apollo-query-and-loading'
import VALIDATE_PASSWORD_QUERY from '../../../../graphql/queries/validate-password.gql'
import { MODALS } from '../../../constants'

const isPasswordInvalid = pathEq(['data', 'validatePassword'], false)

const withValidatePasswordQuery = compose(
  withApolloQueryAndLoading,
  withHandlers({
    makeValidatePasswordQuery: ({ apolloQuery }) => (password) => {
      return apolloQuery('validatePasswordQuery', {
        query: VALIDATE_PASSWORD_QUERY,
        variables: { password },
        fetchPolicy: 'network-only'
      }, isPasswordInvalid)
    }
  })
)

const mapStateToProps = (state) => {
  return {
    accountId: getAccountIdFromState(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleAccountPasswordTimestamp(timestamp, accoundId) {
      dispatch(setAccountPasswordTimestamp(timestamp, accoundId))
    }
  }
}

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withValidatePasswordQuery,
  Formik({
    displayName: 'PasswordInputForm',
    mapValuesToValues: ({ password }) => ({ password }),
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      password: Yup.string().required()
    }),
    handleSubmit: ({ password }, {
      setSubmitting,
      setStatus,
      props: { makeValidatePasswordQuery, handleAccountPasswordTimestamp, accountId }
    }) => {
      makeValidatePasswordQuery(password)
        .then(({ data: { validatePassword: passwordValidated } }) => {
          setSubmitting(false)
          setStatus({ passwordValidated })

          if (passwordValidated) {
            // Two second buffer, so that the user
            // can see their password successfully validated
            sleep(2000).then(() => {
              const timestamp = new Date()
              handleAccountPasswordTimestamp(timestamp, accountId)
            })
          }
        })
        .catch(() => {
          setSubmitting(false)
          setStatus({ passwordValidated: false })
        })
    }
  }),
  withHandlers({
    handleCancel: ({ history, resetForm }) => () => {
      resetForm()
      // It is to resolve the issue of Edge browser setting the user's password as a query parameter in the URL
      // Clearing form fields before redirect can do it
      // resetForm of Formik uses reducer to dispatch action which occurs in next loop iteration
      // so put history.push in behind, otherwise router will change before resetting form
      setTimeout(() => history.push('/'), 0)
    },
    handleIForgotMyPassword: ({ history }) => () => {
      history.push(`/?modal=${MODALS.qsParams.passwordForgotten}`)
    }
  }),
  withProps(({ values, status }) => {
    return {
      value: path(['password'], values),
      passwordValidated: path(['passwordValidated'], status)
    }
  }),
  onlyUpdateForPropTypes
)

export default enhance(PasswordInputForm)
