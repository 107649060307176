import React, { Suspense } from 'react'
import FullScreenLoadingComponent from '../loading/fullscreen-loader'

const Series = React.lazy(() => import('./series'))
const AsyncSeries = props => (
  <Suspense fallback={<FullScreenLoadingComponent />}>
    <Series {...props} />
  </Suspense>
)

export default AsyncSeries
