import {
  basicPlanDetailsForAvod,
  standardPlanDetailsForAvod,
  annuallyPlanDetailsForAvod
} from '../shared/subscription-constants'

export const screens = {
  payment: 'payment',
  confirmation: 'confirmation',
  error: 'error'
}

export const errors = {
  modalParemetersInvalid: {
    errorCode: '001',
    message: `Couldn't display modal`
  },
  mutationUpgrade: {
    errorCode: '002',
    message: `Couldn't perform upgrade`
  },
  query: {
    errorCode: '003',
    message: `Couldn't retrieve data`
  },
  upgradeNotAllowed: {
    errorCode: '004',
    message: `Upgrade not allowed`
  },
  mutationDowngrade: {
    errorCode: '005',
    message: `Couldn't perform downgrade`
  },
  downgradeNotAllowed: {
    errorCode: '006',
    message: `Downgrade not allowed`
  },
  iframePaymentAttemptFailed: {
    errorCode: '007',
    message: `Payment failed`
  }
}

export const warnings = {
  onlyDowngradeAllowed: 'You can only change to an Annual Plan after your next billing date.'
}

export const paymentLabel = 'Payment'

export const firstPaymentLabel = 'First payment'

export const firstPaymentDueLabel = 'First payment due'

export const direction = {
  upgrade: 'UPGRADE',
  downgrade: 'DOWNGRADE'
}

const annualForAvod = {
  ...annuallyPlanDetailsForAvod,
  copy:
    'Please provide your credit card details below to complete your plan set up.',
  details: [
    annuallyPlanDetailsForAvod.staticDetail,
    {
      name: paymentLabel,
      value: `$${annuallyPlanDetailsForAvod.price}`
    }
  ]
}

const standardForAvod = {
  ...standardPlanDetailsForAvod,
  copy:
    'Please provide your credit card details below to complete your plan set up. You can cancel any time.',
  details: [
    standardPlanDetailsForAvod.staticDetail,
    {
      name: paymentLabel,
      value: `$${standardPlanDetailsForAvod.price}`
    }
  ]
}

const basicForAvod = {
  ...basicPlanDetailsForAvod,
  copy:
    'Please provide your credit card details below to complete your plan set up. You can cancel any time.',
  details: [
    basicPlanDetailsForAvod.staticDetail,
    {
      name: paymentLabel,
      value: `$${basicPlanDetailsForAvod.price}`
    }
  ]
}

export const productsForAvod = {
  annualForAvod,
  basicForAvod,
  standardForAvod
}

export const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
