import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'

import SERVICE_MESSAGE_FRAGMENT from '../../../graphql/fragments/service-message.gql'

import styles from './service-message.css'

import closeImg from '../../../images/banner-close.svg'

function ServiceMessages({
  serviceMessage,
  handleDismiss,
  registerHeightWatcher,
  elementHeight
}) {
  return (
    <div>
      <div
        className={styles.wrapper}
        style={{
          height: elementHeight + 10 /* IE11 breaks without it, +10 padding */
        }}
      >
        <div className={styles.message} ref={registerHeightWatcher}>
          {serviceMessage.message}
        </div>
        <div
          className={styles.close}
          onClick={() => handleDismiss(serviceMessage)}
          style={{
            height: elementHeight + 10 /* IE11 breaks without it, +10 padding */
          }}
        >
          <div className={styles.closeMessage}>Close this message</div>
          <img className={styles.closeIcon} src={closeImg} />
        </div>
      </div>
    </div>
  )
}

ServiceMessages.propTypes = {
  serviceMessage: propType(SERVICE_MESSAGE_FRAGMENT).isRequired,
  handleDismiss: PropTypes.func.isRequired,
  registerHeightWatcher: PropTypes.func.isRequired,
  elementHeight: PropTypes.number.isRequired
}

export default ServiceMessages
