import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import NavLinkWithQuery from '../../components/shared/nav-link-with-query'
import styles from './signup-nav-button.css'

const SIGN_UP_LINK = '/signup/account'

function SignUpNavButton({ isAuthenticated, searchIsClicked }) {
  return (
    !isAuthenticated
      ? (
        <NavLinkWithQuery
          exact
          analyticsLabel="sign up"
          to={{ pathname: SIGN_UP_LINK }}
          className={classNames(styles.navLink, styles.headerButton, searchIsClicked && styles.navLinkWhileSearching)}
          data-e2e="nav-sign-up"
        >
          sign up
        </NavLinkWithQuery>
      )
      : null
  )
}

SignUpNavButton.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  searchIsClicked: PropTypes.bool.isRequired
}

export default SignUpNavButton
