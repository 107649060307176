import {
  propEq,
  pathOr,
  converge,
  pipe,
  nthArg,
  propOr,
  find
} from 'ramda'

import { isRentable } from '../../lib/content-item'
import { getDurationTextFromMinutes } from '../../lib/date-time'
import {
  PLAYBACK_INFO,
  THEME_OPTIONS,
  DURATION_FORMAT,
  TIME_FORMAT,
  TIME_FORMAT_SHORT
} from '../../constants'
import {
  PLAY_BUTTON_RENT_TEXT,
  PLAY_BUTTON_WATCH_TEXT,
  PLAY_BUTTON_RESUME_TEXT,
  PLAY_BUTTON_FINISHED_TEXT,
  PLAY_BUTTON_FINISHED_TITLE_TEXT,
  PLAY_BUTTON_KIDS_PLAY_ICON,
  PLAY_BUTTON_PLAY_ICON,
  PLAY_BUTTON_REPLAY_ICON
} from './constants'

export const findPlaybackInfoById = converge(
  find,
  [pipe(nthArg(0), propEq('contentItemId')), nthArg(1)]
)

export const getPlaybackInfo = propOr(null, 'playbackInfo')

export const calcProgressPercentage = (duration, minutesWatched, status) => {
  if (status === PLAYBACK_INFO.status.finished) {
    return 100
  }

  return !minutesWatched ? 0 : ((minutesWatched * 100) / duration)
}

export const getIcon = (status, theme, forKids) => {
  let icon
  switch (status) {
    case PLAYBACK_INFO.status.resume:
      icon = (theme === THEME_OPTIONS.light && forKids) ? PLAY_BUTTON_KIDS_PLAY_ICON : PLAY_BUTTON_PLAY_ICON
      break
    case PLAYBACK_INFO.status.finished:
      icon = (theme === THEME_OPTIONS.light && forKids) ? PLAY_BUTTON_KIDS_PLAY_ICON : PLAY_BUTTON_REPLAY_ICON
      break
    case PLAYBACK_INFO.status.start:
    default:
      icon = (theme === THEME_OPTIONS.light && forKids) ? PLAY_BUTTON_KIDS_PLAY_ICON : PLAY_BUTTON_PLAY_ICON
      break
  }
  return icon
}

export const getCTA = (status, isAuthenticated, rentalStatus, watchable) => {
  if (!isAuthenticated) {
    return rentalStatus ? PLAY_BUTTON_RENT_TEXT : PLAY_BUTTON_WATCH_TEXT
  }

  if (!watchable && isRentable(rentalStatus)) {
    return PLAY_BUTTON_RENT_TEXT
  }

  switch (status) {
    case PLAYBACK_INFO.status.resume:
      return PLAY_BUTTON_RESUME_TEXT
    case PLAYBACK_INFO.status.finished:
      return rentalStatus ? PLAY_BUTTON_FINISHED_TITLE_TEXT : PLAY_BUTTON_FINISHED_TEXT
    case PLAYBACK_INFO.status.start:
    default:
      return PLAY_BUTTON_WATCH_TEXT
  }
}

export const getDurationMinutesRemaining = (playbackInfo, timeFormat = TIME_FORMAT_SHORT) => (
  getDurationTextFromMinutes(
    pathOr(0, ['numberMinutesRemaining'], playbackInfo),
    {
      format: timeFormat
    }
  )
)

export const getNumberMinutesWatched = pathOr(0, ['numberMinutesWatched'])

export const getNumberMinutesRemaining = pathOr(0, ['numberMinutesRemaining'])

const getFormattedMinutesRemaining = (
  playbackInfo,
  timeFormat = TIME_FORMAT
) => {
  const minutesRemain = getNumberMinutesRemaining(playbackInfo)

  if (minutesRemain === 0) {
    return ''
  }

  return getDurationInMinutes(minutesRemain, {
    format: timeFormat
  })
}

const getDurationInMinutes = (minutes = 0, options) => {
  let durationText = ''
  const format = options && options.format

  durationText += ` ${minutes} ${(format && format.minutes) || DURATION_FORMAT.minutes}`

  if (!options.suffix) {
    return durationText
  }

  return `${durationText} ${options.suffix}`
}

export const getTimeString = (inProgress, numberMinutesRemaining) => (
  inProgress ? `${numberMinutesRemaining} left` : `${numberMinutesRemaining}`
)

export const getTimeLeftString = (playbackInfo, onlyMinutes) => {
  // Get number of minutes remaining
  const formattedNumberMinutesRemaining = onlyMinutes
    ? getFormattedMinutesRemaining(playbackInfo)
    : getDurationMinutesRemaining(playbackInfo, TIME_FORMAT)

  // Get playback status
  const status = pathOr(null, ['status'], playbackInfo)

  if (status === PLAYBACK_INFO.status.finished) {
    return 'watched'
  }

  const inProgress = status === PLAYBACK_INFO.status.resume
  const time = getTimeString(inProgress, formattedNumberMinutesRemaining)
  return time
}
