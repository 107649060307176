import ActionTypes from '../actions/types'

const currentSubscriptionName = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_SUBSCRIPTION_NAME:
      return action.currentSubscriptionName
    default:
      return state
  }
}

export default currentSubscriptionName
