import {
  compose
} from 'recompose'
import { graphql } from 'react-apollo'

import getDeviceInfo from '../lib/device-info'
import withDeviceFingerprint from './with-device-fingerprint'

import CONFIG_QUERY from '../../graphql/queries/config.gql'

const withConfigCacheOnly = compose(
  withDeviceFingerprint,
  graphql(CONFIG_QUERY, {
    name: 'configQuery',
    options: ({ deviceFingerprint }) => {
      const deviceInfo = {
        ...getDeviceInfo(),
        uuid: deviceFingerprint,
        platform: 'Web'
      }
      return {
        fetchPolicy: 'cache-only',
        variables: {
          input: {
            deviceInfo
          }
        },
        notifyOnNetworkStatusChange: true
      }
    },
    props: ({ ownProps, configQuery: { loading, error, config } }) => {
      return ({
        ...ownProps,
        configLoading: loading,
        configError: error,
        appConfig: (config || {})
      })
    }
  })
)

export default withConfigCacheOnly
