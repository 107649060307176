import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import { withHandlers } from 'recompose'
import {
  groupBy,
  path,
  pipe,
  splitAt,
  tail
} from 'ramda'
import classNames from 'classnames'

import NavigationItem from '../../containers/navigation/navigation-item'
import NavigationItemHeader from './navigation-item-header'
import styles from './navigation-dropdown.css'
import NAV_ITEM_FRAGMENT from '../../../graphql/fragments/nav-item.gql'

function NavigationDropdown({
  navItems,
  inSideNav,
  setParentIsExpanded,
  isDiscoverNav,
  propertiesCount
}) {
  // the sidebar shouldn't repeat the properties inside the discover navigation
  // filteredItems contains all the items on normal resolutions and only the collections on
  // small ones
  const getDiscoverSideNavItems = pipe(
    splitAt(propertiesCount),
    tail,
    path(['0'])
  )

  const filteredItems = (isDiscoverNav && inSideNav) ? getDiscoverSideNavItems(navItems) : navItems

  const Wrapper = ({ children }) => (
    <div
      className={classNames({
        [styles.navDropdownWrapper]: true,
        [styles.navDropdownSide]: inSideNav
      })}
    >
      {children}
    </div>
  )

  const mapNavItems = items => items.map((navItem, idx) => (
    <NavigationItem
      key={idx} // eslint-disable-line react/no-array-index-key
      navItem={navItem}
      inSideNav={inSideNav}
      className={styles.navDropdownLink}
      activeClassName={styles.navDropdownLinkActive}
      setParentIsExpanded={setParentIsExpanded}
      inDropdown // prop to identify items on secondary dropdowns
      expandOnHover={!inSideNav && isDiscoverNav} // whether subnav should expand on hover
    />
  ))

  if (!isDiscoverNav || inSideNav) {
    return (
      <Wrapper>
        { filteredItems && mapNavItems(filteredItems) }
      </Wrapper>
    )
  }

  // For discovery dropdown in the header, split the nav items by typename so we can insert a header

  const {
    NavItem: discoverItems,
    NavSection: collectionItems
  } = groupBy(i => i.__typename, filteredItems || [])

  return (
    <Wrapper>
      { discoverItems && mapNavItems(discoverItems) }
      <NavigationItemHeader title="Collections" />
      { collectionItems && mapNavItems(collectionItems) }
    </Wrapper>
  )
}

NavigationDropdown.propTypes = {
  navItems: PropTypes.arrayOf(propType(NAV_ITEM_FRAGMENT)).isRequired,
  inSideNav: PropTypes.bool,
  isDiscoverNav: PropTypes.bool,
  setParentIsExpanded: PropTypes.func.isRequired,
  propertiesCount: PropTypes.number
}

NavigationDropdown.defaultProps = {
  inSideNav: false,
  isDiscoverNav: false,
  propertiesCount: 0
}

export default withHandlers({
  setParentIsExpanded: ({ setIsExpanded, setParentIsExpanded }) => (bool) => {
    setIsExpanded(bool)
    if (setParentIsExpanded) setParentIsExpanded(bool)
  }
})(NavigationDropdown)
