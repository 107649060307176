/**
 * Spacer component is a place holder to act like margin/padding in css
 * to make the logic easier to understand
 * */

import React from 'react'
import PropTypes from 'prop-types'

const Spacer = ({ width, height }) => (
  <div style={{ width, height }} />
)

Spacer.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}
Spacer.defaultProps = {
  width: 0,
  height: 0
}

export default Spacer
