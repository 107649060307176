import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import truncate from 'lodash.truncate'
import {
  compose,
  take,
  join,
  defaultTo
} from 'ramda'

import MyListRibbon from '../../my-list-ribbon'
import { getDurationTextFromMinutes } from '../../../../lib/date-time'
import { getLowestPrice } from '../../../../lib/content-item'
import styles from './title-kids-long.css'

import TILE_FRAGMENT from '../../../../../graphql/fragments/tile.gql'
import { COMPONENT_NAME_CAROUSEL_KIDS } from '../../../../lib/analytics/custom-dimensions'
import Clamped from '../../../shared/clamped'

function GridOverlayTitle({
  tile,
  isAuthenticated,
  isOnMyList,
  addToMyList,
  removeFromMyList
}) {
  const contentItem = tile.contentItem
  const title = truncate(contentItem.title, { length: 40, omission: '…' })
  const year = contentItem.year
  const rating = contentItem.rating && contentItem.rating.rating
  const genres = compose(
    join(', '),
    take(2),
    defaultTo([])
  )(contentItem.genres)
  const duration = getDurationTextFromMinutes(contentItem.duration)
  const price = getLowestPrice(contentItem)
  const isRental = contentItem.isRental

  return (
    <div className={styles.wrapper}>
      {
        isAuthenticated && (
          <MyListRibbon
            contentItem={contentItem}
            className={styles.ribbon}
            isOnMyList={isOnMyList}
            addAction={addToMyList(COMPONENT_NAME_CAROUSEL_KIDS)}
            removeAction={removeFromMyList(COMPONENT_NAME_CAROUSEL_KIDS)}
          />
        )
      }
      <Clamped className={styles.title} lines={3}>{title}</Clamped>

      <div className={styles.upperParts}>
        <div className={styles.year}>
          {year}
          {' '}
          |
          {' '}
          {rating}
        </div>
        <Clamped className={styles.genres} lines={1}>{genres}</Clamped>
        <div className={styles.duration}>{duration}</div>
      </div>

      {
        isRental && price !== -Infinity && (
          <div className={styles.price}>
            Rent $
            {price}
          </div>
        )
      }

      <div className={styles.buttonWrapper}>
        <div className={styles.button}>
          More Info
        </div>
      </div>
    </div>
  )
}

GridOverlayTitle.propTypes = {
  tile: propType(TILE_FRAGMENT).isRequired,
  removeFromMyList: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  isOnMyList: PropTypes.bool,
  addToMyList: PropTypes.func.isRequired
}

GridOverlayTitle.defaultProps = {
  isOnMyList: false,
  isAuthenticated: false
}

export default GridOverlayTitle
