import { find, propEq } from 'ramda'

import { displayErrorDialog, updateAuthTokenAndDefaultTheme } from '../../actions'
import { MW_ERRORS, MW_ERROR_MESSAGES } from '../../constants'

const findError = find(propEq('code', MW_ERRORS.NOT_AUTHENTICATED))

/**
 * Handles unauthorized (401) errors through the apollo network error link
 * @param {Object} store - Redux store
 */
export function handleUnauthorizedError(errors, store) {
  const notAuthError = errors ? findError(errors) : null

  // Clear the invalid JWT from the store and local storage
  store.dispatch(updateAuthTokenAndDefaultTheme(null))

  if (notAuthError) {
    store.dispatch(displayErrorDialog(notAuthError))
  } else {
    store.dispatch(displayErrorDialog({
      message: MW_ERROR_MESSAGES.NOT_AUTHENTICATED
    }))
  }
}
