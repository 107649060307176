import React from 'react'

export const SeriesIcon = props => {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.8177 0H2.18139C1.60338 0.00194365 1.04955 0.260082 0.640831 0.71804C0.232116 1.176 0.00173465 1.79656 0 2.44421V17.1126C0.00173465 17.7603 0.232116 18.3808 0.640831 18.8388C1.04955 19.2968 1.60338 19.5549 2.18139 19.5568H7.63581V22H16.3633V19.5568H21.8177C22.3946 19.5546 22.9473 19.296 23.3543 18.8378C23.7613 18.3796 23.9895 17.7591 23.9887 17.1126L24 2.44421C23.9983 1.79638 23.7678 1.17566 23.3588 0.71767C22.9499 0.259682 22.3958 0.00166603 21.8177 0ZM21.8177 17.1126H2.18139V2.44421H21.8158L21.8177 17.1126Z"
        fill="white"
      />
    </svg>
  )
}
