import qs from 'query-string'
import { compose, branch } from 'recompose'
import { connect } from 'react-redux'

import { MODALS } from '../constants'
import { getModalLocation } from '../lib/modal'
import { checkIsAuthenticated } from '../lib/auth'

export const redirectKidsToProfilesSwitchIfAuthenticated = compose(
  connect(state => ({ isAuthenticated: checkIsAuthenticated(state) })),
  branch(
    ({ data, location, isAuthenticated }) => {
      if (!isAuthenticated) return false
      if (location.pathname !== '/kids') return false
      const parsedSearch = qs.parse(location.search)
      if (parsedSearch.modal === MODALS.qsParams.profilesSwitch) return false
      return true
    },
    () => ({ history, location }) => {
      history.push(getModalLocation({
        pathname: '/',
        search: location.search
      }, MODALS.qsParams.profilesSwitch))
      return null
    }
  )
)
