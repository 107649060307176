import { takeLast, pathOr } from 'ramda'

export const getLocation = (pathname) => {
  if (pathname === '/') {
    return 'home'
  }

  return takeLast(pathname.length - 1)(pathname)
}

// TODO, the data structure for guest hotspot should be the same as login hotspot,
// The adapter should be removed in future.
export const contentAdapter = ({
  slides,
  ...others
}) => ({
  slides: slides.map(({
    header,
    description,
    subHeader,
    image,
    primaryCta,
    contentItem,
    copyright,
    headerImageUri
  }) => ({
    line1: header,
    line2: `${subHeader}<br />${description}`,
    line3: '',
    image,
    cta: {
      text: primaryCta,
      url: contentItem.path
    },
    isRental: pathOr(false, ['isRental'], contentItem),
    copyright,
    headerImageUri
  })),
  ...others
})
