import { path, pathSatisfies } from 'ramda'
import { differenceInSeconds } from 'date-fns'

import { PIN } from '../constants'

export const isPinValid = state => (
  pathSatisfies((ts) => {
    return (
      differenceInSeconds(new Date(), ts) <= PIN.expirationTime
    )
  },
  ['pin', 'timestamp'])(state)
)

export const getOnValidPin = (state) => {
  return path(['pin', 'onValidPin'])(state)
}

export const getPinVisibility = (state) => {
  return path(['pin', 'visibility'])(state)
}
