import React, { createContext } from 'react'
import PropTypes from 'prop-types'

/**
 * Hooks for getting selected category tab
 */

export const CategoryContext = createContext(null)
const CategoryContextProvider = ({ selectedCategory, linkedNavItemName, children }) => {
  return (
    <CategoryContext.Provider value={{ selectedCategory, linkedNavItemName }}>
      {children}
    </CategoryContext.Provider>
  )
}

CategoryContextProvider.propTypes = {
  selectedCategory: PropTypes.oneOfType([PropTypes.object]),
  linkedNavItemName: PropTypes.string
}

CategoryContextProvider.defaultProps = {
  selectedCategory: null,
  linkedNavItemName: ''
}

export default CategoryContextProvider
