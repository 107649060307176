import {
  branch,
  compose,
  mapProps,
  renderComponent,
  renderNothing,
  withHandlers,
  setDisplayName
} from 'recompose'
import { graphql } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  checkQueryStatus,
  getMainNavHeaderSectionLinks,
  getPropertiesCount
} from '../../lib/utils'
import {
  getProfileNameAndAvatarUri,
  getSelectedProfileIdFromSession,
  isKidsProfile
} from '../../lib/account'
import { getModalLocation } from '../../lib/modal'
import withIsAuthenticated from '../../hoc/is-authenticated'
import { MODALS } from '../../constants'

import KidsSideNavigation from '../../layouts/shared-navigation/navigation-side/kids-side-navigation'
import AdultsSideNavigation from '../../layouts/shared-navigation/navigation-side/adults-side-navigation'

import NAV_HEADER_QUERY from '../../../graphql/queries/navigation-header.gql'
import ACCOUNT_QUERY from '../../../graphql/queries/account.gql'

import { setNavigationVisibility } from '../../actions'

const mapStateToProps = ({ navigation: { sideNav }, session }) => ({
  display: sideNav,
  profileId: getSelectedProfileIdFromSession(session)
})

const enhance = compose(
  setDisplayName('SideNavigationContainer'),
  withRouter,
  connect(mapStateToProps),
  withIsAuthenticated,
  graphql(ACCOUNT_QUERY, {
    name: 'accountQuery',
    skip: ({ isAuthenticated }) => !isAuthenticated
  }),
  branch(({ accountQuery }) => checkQueryStatus(accountQuery), renderNothing),
  graphql(NAV_HEADER_QUERY, {
    name: 'navHeaderQuery',
    options: {
      fetchPolicy: 'cache-only'
    }
  }),
  branch(
    ({ navHeaderQuery }) => checkQueryStatus(navHeaderQuery),
    renderNothing
  ),
  withHandlers({
    handleProfileChange: ({ history }) => () => {
      history.push(getModalLocation(location, MODALS.qsParams.profilesSwitch))
    },
    onClickOutside: ({ display, dispatch }) => () => {
      if (display) {
        dispatch(setNavigationVisibility(false))
      }
    },
    onSideNavLinkClick: ({ dispatch }) => () => {
      dispatch(setNavigationVisibility(false))
    }
  }),
  mapProps(
    ({
      accountQuery,
      navHeaderQuery,
      display,
      isAuthenticated,
      profileId,
      handleProfileChange,
      onClickOutside,
      onSideNavLinkClick
    }) => {
      const { name, avatarUri } = getProfileNameAndAvatarUri(accountQuery)
      const isKid = isKidsProfile(accountQuery)
      const mainSectionLinks = getMainNavHeaderSectionLinks(navHeaderQuery)
      // Calculate the number of properties before the discover navigation
      // to know how many items should skip on this dropdown
      const propertiesCount = getPropertiesCount(mainSectionLinks)
      return {
        display,
        isAuthenticated,
        isKid,
        profileId,
        name,
        avatarUri,
        mainSectionLinks,
        propertiesCount,
        handleProfileChange,
        onClickOutside,
        onSideNavLinkClick
      }
    }
  ),
  branch(
    ({ isKid }) => isKid,
    renderComponent(KidsSideNavigation),
    renderComponent(AdultsSideNavigation)
  )
)

export default enhance()
