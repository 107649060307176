import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import ReactSVG from 'react-svg'
import classNames from 'classnames'

import styles from './my-list-button.css'
import CONTENT_ITEM_LIGHT_FRAGMENT from '../../../graphql/fragments/content-item-light.gql'

import AddMyListAdd from '../../../images/add-my-list-add.svg'
import AddMyListDone from '../../../images/add-my-list-done.svg'
import { dataLayerAddToMyList } from '../../lib/analytics/datalayer'
import { segmentTrackAddMyList } from '../../segment/segment-track'

const MyListButton = ({
  contentItem, isOnMyList, addAction, removeAction, className, isRental, deleteFromMyList
}) => {
  const [image, setImage] = useState(AddMyListAdd)

  useEffect(() => {
    setImage(isOnMyList ? AddMyListDone : AddMyListAdd)
  }, [isOnMyList])

  const onClick = (event) => {
    event.preventDefault()
    event.stopPropagation()

    if (!isOnMyList) {
      // Add segment data analytics for adding content to my list
      segmentTrackAddMyList(contentItem)
    }

    return isOnMyList
      ? removeAction(contentItem)
      : (addAction(contentItem), dataLayerAddToMyList(contentItem))
  }

  return (
    <div
      data-lbx-e2e={`my-list-button-${contentItem.path}`}
      className={classNames(className, {
        [styles.iconOnHoverStyle]: !isRental,
        [styles.iconOnHoverRentalStyle]: isRental
      })}
      onClick={deleteFromMyList ? null : onClick}
    >
      <ReactSVG src={image} />
    </div>
  )
}

MyListButton.propTypes = {
  contentItem: propType(CONTENT_ITEM_LIGHT_FRAGMENT).isRequired,
  addAction: PropTypes.func,
  removeAction: PropTypes.func,
  isOnMyList: PropTypes.bool.isRequired,
  className: PropTypes.string,
  isRental: PropTypes.bool,
  deleteFromMyList: PropTypes.bool
}

MyListButton.defaultProps = {
  className: '',
  isRental: false
}

export default MyListButton
