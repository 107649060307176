import PropTypes from 'prop-types'

export const playbackQualityOptionsPropType = PropTypes.shape({
  wifi: PropTypes.arrayOf(
    PropTypes.shape({
      bitrate: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired
    })
  ).isRequired
})

export const appConfigPropType = PropTypes.shape({
  playbackQualityOptions: playbackQualityOptionsPropType.isRequired
})

const accountPropType = PropTypes.shape({
  cpCustomerID: PropTypes.string.isRequired,
  selectedProfile: PropTypes.string.isRequired
})

const currentQualityPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  bitrate: PropTypes.number.isRequired,
  isDefault: PropTypes.bool.isRequired
})

const deviceInfoPropType = PropTypes.shape({
  uuid: PropTypes.string.isRequired
})

// episodeNumber and seasonNumber is optional for movies
const contentItemPropType = PropTypes.shape({
  episodeNumber: PropTypes.number,
  seasonNumber: PropTypes.number
})

export const youboraDataPropType = PropTypes.oneOfType([
  PropTypes.shape({
    account: accountPropType.isRequired,
    currentQuality: currentQualityPropType.isRequired,
    deviceInfo: deviceInfoPropType.isRequired,
    contentItem: contentItemPropType.isRequired,
    title: PropTypes.string.isRequired,
    houseId: PropTypes.string.isRequired,
    contentType: PropTypes.string.isRequired
  }),
  PropTypes.shape({
    loading: PropTypes.bool.isRequired
  }),
  PropTypes.shape({
    error: PropTypes.bool.isRequired
  })
])
