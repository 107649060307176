import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import ClickOutside from 'react-click-outside'
import classNames from 'classnames'

import { getModalLocation } from '../../lib/modal'
import { MODALS, STATIC_SCREENS } from '../../constants'
import NavLinkWithQuery from '../../components/shared/nav-link-with-query'
import styles from './navigation-account-kids.css'
import { E2E_SELECTORS } from '../../e2e-selectors'

function KidsNavigation({
  onAccountNavClick,
  display,
  handleClickOutside,
  location,
  myListTotalItems,
  myRentalsTotalItems
}) {
  const outsideClick = display ? handleClickOutside : () => {}

  return (
    <ClickOutside onClickOutside={outsideClick}>
      <nav
        className={classNames(styles.kidsAccountNavWrapper, {
          [styles.hidden]: !display
        })}
      >
        <div className={styles.buttonPlaceholder} />
        <ul className={styles.accountLinks}>
          <li>
            <NavLink
              exact
              to={STATIC_SCREENS.MY_LIST.screenId}
              onClick={onAccountNavClick}
              className={styles.accountLink}
              data-lbx-e2e={E2E_SELECTORS.MY_LIST_KIDS_ACCOUNT}
            >
              My List
              {myListTotalItems > 0 && <span>{`(${myListTotalItems})`}</span>}
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              to={STATIC_SCREENS.MY_RENTALS.screenId}
              onClick={onAccountNavClick}
              className={styles.accountLink}
              data-lbx-e2e={E2E_SELECTORS.MY_RENTALS_KIDS_ACCOUNT}
            >
              My Rentals
              {myRentalsTotalItems > 0 && <span>{`(${myRentalsTotalItems})`}</span>}
            </NavLink>
          </li>
          <li>
            <NavLinkWithQuery
              exact
              to={getModalLocation(location, MODALS.qsParams.profilesSwitch)}
              onClick={onAccountNavClick}
              className={styles.accountLink}
              data-lbx-e2e={E2E_SELECTORS.EXIT_KIDS_ACCOUNT}
            >
              Exit Kids
            </NavLinkWithQuery>
          </li>
        </ul>
        <div className={styles.menuBackground} />
      </nav>
    </ClickOutside>
  )
}

KidsNavigation.propTypes = {
  display: PropTypes.bool.isRequired,
  onAccountNavClick: PropTypes.func.isRequired,
  handleClickOutside: PropTypes.func.isRequired,
  myListTotalItems: PropTypes.number.isRequired,
  myRentalsTotalItems: PropTypes.number.isRequired
}

export default KidsNavigation
