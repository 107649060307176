import { createUseStyles } from 'react-jss'
import { commonStyles } from '../../../shared/common.styles'

export const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),
  divider: {
    height: 1,
    margin: [10, 0, 10],
    backgroundColor: theme.field,
    width: '100%'
  },

  availableVoucher: {
    margin: [-50, 0, 50, 0],
    textAlign: 'center',
    fontStyle: 'normal',
    fontSize: 20,
    fontWeight: 325,
    lineHeight: '28px',

    [theme.media.smallMobile]: {
      fontSize: 18
    }
  },

  notAvailableVoucher: {
    margin: [-50, 0, 50, 0],
    color: theme.error,
    textAlign: 'center',
    fontStyle: 'normal',
    fontSize: 20,
    fontWeight: 325,
    lineHeight: '28px',

    [theme.media.smallMobile]: {
      fontSize: 18
    }
  }
}))
