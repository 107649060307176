import React, { useEffect, useContext, useState } from 'react'
import {
  useHistory,
  Link
} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import ReactImgix from 'react-imgix'

import styles from './index.css'

import { UseNotFoundContext, UPDATE } from './hooks/use-not-found-context'
import { SCREEN_SIZE_BREAKPOINTS } from '../../constants'

/**
 * A component to display 404 error
 */

const images = {
  landscape: 'https://lightbox-prod.imgix.net/images/not-found-landscape.jpg',
  portrait: 'https://lightbox-prod.imgix.net/images/not-found-portrait.jpg'
}

const NotFound = () => {
  const [, dispatch] = useContext(UseNotFoundContext)
  const history = useHistory()
  const [counter, setCounter] = useState(5)

  useEffect(() => {
    dispatch({ type: UPDATE, payload: true })
  }, [])

  useEffect(() => {
    if (!counter) {
      history.push('/')
    }

    const setIntervalFunction = setInterval(() => {
      setCounter(counter - 1)
    }, 1000)

    return () => clearInterval(setIntervalFunction)
  }, [counter])

  const screenWidth = window.innerWidth
  const imageSrc = screenWidth < SCREEN_SIZE_BREAKPOINTS.MIN_480 ? images.portrait : images.landscape

  return (
    <>
      <Helmet>
        <meta name="prerender-status-code" content="404" />
      </Helmet>
      <div className={styles.imageContainer}>
        <ReactImgix
          className={styles.image}
          src={imageSrc}
          imgixParams={{ fit: 'crop', crop: 'bottom' }}
          sizes="100vw"
        />
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.mainText}>Oops there&apos;s nothing here</div>
        <div className={styles.subText}>
          We&apos;ll send you home in&nbsp;
          <span className={styles.counter}>{counter}</span>
          &nbsp;
          {counter <= 1 ? 'second' : 'seconds'}
        </div>
        <Link to="/">go back home</Link>
      </div>
    </>
  )
}

export default NotFound
