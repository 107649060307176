import React, { Suspense } from 'react'
import { IdleQueue } from '../../../lib/idlize/IdleQueue.mjs'

const ModalLoader = React.lazy(() => import('./modal'))
const LazyModalLoader = props => (
  <Suspense fallback={null}>
    <ModalLoader {...props} />
  </Suspense>
)

const modalLoadingQueue = new IdleQueue({
  defaultMinTaskTime: 5
})

class AsyncModal extends React.Component {
  constructor(props) {
    super(props)

    modalLoadingQueue.pushTask(() => this.setLoadScripts(true))

    this.state = {
      loadScripts: false
    }
  }

  setLoadScripts(loadScripts) {
    if (!loadScripts) return
    if (this.state.loadScripts === loadScripts) return

    this.setState({
      loadScripts: true
    })
  }

  render() {
    if (!this.state.loadScripts) {
      return null
    }

    return <LazyModalLoader {...this.props} />
  }
}

export default AsyncModal
