import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import { withRemoveFromMyList } from '../../hoc/with-remove-from-my-list'
import MyListButton from '../carousel/my-list-button'

import styles from './remove-from-list-overlay.css'

function RemoveFromListOverlay({ removeFromMyList, contentItem }) {
  return (
    <div onClick={removeFromMyList} className={styles.removeIcon}>
      <MyListButton
        contentItem={contentItem}
        className={styles.addMyListButton}
        isOnMyList
        addAction={() => {}}
        removeAction={() => {}}
        isRental={contentItem.isRental}
        alt="Remove from My List"
        deleteFromMyList
      />
    </div>
  )
}

RemoveFromListOverlay.propTypes = {
  removeFromMyList: PropTypes.func.isRequired,
  contentItem: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    isRental: PropTypes.bool
  }).isRequired
}

const enhance = compose(withRemoveFromMyList)

export default enhance(RemoveFromListOverlay)
