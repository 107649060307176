import React from 'react'
import { propType } from 'graphql-anywhere'
import {
  branch,
  renderComponent
} from 'recompose'
import classNames from 'classnames'
import ReactImgix from 'react-imgix'

import imgixStyles from '../imgix/imgix.css'

import PROMOTIONAL_TILES_FRAGMENT from '../../../graphql/fragments/promotional-tile-component.gql'

import styles from './index.css'
import GuestPromotionalTiles from './guest-promotional-tiles'

function PromotionalTiles({ promotionalTiles: { promotionalTiles } }) {
  const tiles = promotionalTiles.map(({ description, header, image }) => (
    <div className={styles.promotionalTile} key={header}>
      <ReactImgix
        className={classNames(imgixStyles.imgix, styles.promotionalTileThumb)}
        src={image.uri}
        imgixParams={{ fit: 'facearea' }}
        customParams={{ facepad: 4.5, mask: 'ellipse' }}
        width={110}
        height={110}
      />
      <div className={styles.promotionalTileHeader}>
        {header}
      </div>
      <div className={styles.promotionalTileCaption}>
        {description}
      </div>
    </div>
  ))

  return (
    <div className={styles.promotionalTilesComponent}>
      {tiles}
    </div>
  )
}

PromotionalTiles.propTypes = {
  promotionalTiles: propType(PROMOTIONAL_TILES_FRAGMENT).isRequired
}

export default branch(
  ({ isAuthenticated }) => {
    return (isAuthenticated)
  },
  renderComponent(PromotionalTiles),
  renderComponent(GuestPromotionalTiles)
)()
