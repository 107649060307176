import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { compose, withHandlers } from 'recompose'

import {
  BROWSE_NEON,
  BROWSE_RENTALS,
  THEME_OPTIONS
} from '../../constants'
import Button from '../forms/button'
import styles from './carousel-empty-my-rentals.css'

function CarouselEmptyMyRentals({
  onButtonClick,
  theme
}) {
  const CTA = theme === THEME_OPTIONS.light
    ? BROWSE_NEON
    : BROWSE_RENTALS

  return (
    <div className={styles.carouselEmpty}>
      <p>There&apos;s nothing here yet!</p>
      <p>When you rent a movie it will appear here, ready to watch on any device.</p>

      <div className={styles.buttonsWrapper}>
        <Button
          text={CTA.label}
          onClick={() => onButtonClick(CTA.url)}
        />
      </div>
    </div>
  )
}

CarouselEmptyMyRentals.propTypes = {
  onButtonClick: PropTypes.func,
  theme: PropTypes.string.isRequired
}

CarouselEmptyMyRentals.defaultProps = {
  onButtonClick: null
}

const enhance = compose(
  withHandlers({
    onButtonClick: ({ history }) => (url) => {
      history.push(url)
    }
  })
)

export default withRouter(enhance(CarouselEmptyMyRentals))
