import React from 'react'
import PropTypes from 'prop-types'

import './chromecast-nav-button.css'

const createCastButton = () => ({
  __html: '<button class="chromecastButton" is="google-cast-button" />'
})

function ChromecastNavButton({ className }) {
  /**
   * window.isChromecastCompatible lives in index.html.
   * It checks for the same stuff the Chromecast library does,
   * and unless it returns true, the Chromecast libraries aren't loaded.
   */
  if (!window.isChromecastCompatible()) {
    return (
      <div className={className} />
    )
  }

  return (
    <div
      className={className}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={createCastButton()}
    />
  )
}

ChromecastNavButton.propTypes = {
  className: PropTypes.string
}

ChromecastNavButton.defaultProps = {
  className: ''
}

export default ChromecastNavButton
