
/**
 * Hook to check basic profile is disabled
 */
export const useProfileIsDisabled = (isProfileAllowBasicUpsell, profileLimit, index) => {
  // profile should be disabled if the index is greater than profileLimit
  const isProfileDisabled = isProfileAllowBasicUpsell && index > (profileLimit - 1)

  return isProfileDisabled
}
