import { useState, useContext, useEffect } from 'react'
import { useMutation } from 'react-apollo'
import REMOVE_CONTINUITY from './gql/remove-continuity.gql'
import ADD_CONTINUITY from './gql/add-continuity.gql'
import {
  isEpisode,
  isTitle
} from '../../../lib/content'
import { ContinuityContext } from './continuity-context'
import {
  REMOVE_ITEM,
  UNDO_REMOVE_ITEM,
  REFRESH_UI
} from './continuity-reducer'
import { UNDO_COUNTER } from '../constants'
/**
 * A custom hook to handle add&remove continuity item(s)
 */

export const useContinuity = (contentItem) => {
  const { id } = contentItem
  const [removedItems, setRemovedItems] = useState([])
  const [{ removedItemsList }, dispatch] = useContext(ContinuityContext)

  const [removeContinuitiesHandler, { data: removeContinuitiesData }] = useMutation(REMOVE_CONTINUITY)

  useEffect(() => {
    if (removeContinuitiesData) {
      const filteredContinuities = removeContinuitiesData.removeContinuity.map(item => {
        const { __typename, ...others } = item
        return others
      })
      setRemovedItems(filteredContinuities)
    }
  }, [removeContinuitiesData])

  const [addContinuitiesHandler] = useMutation(ADD_CONTINUITY)

  /**
   * A timer to update selected content item's timer id
   * will calling action to refresh UI when time is out
   */
  const fireCounterToRefreshUI = () => {
    const timer = setTimeout(() => {
      refreshUI()
    }, UNDO_COUNTER)
    dispatch({ type: REMOVE_ITEM, selectedItem: { contentItem, isInUndoState: true, timerId: timer } })
  }

  /**
   * Remove selected items from continuity list
   * Function to exposure for UI to call
   */
  const removeContinuity = () => {
    if (isTitle(contentItem)) {
      removeContinuitiesHandler({ variables: { contentItem: id } })
    }
    if (isEpisode(contentItem)) {
      removeContinuitiesHandler({ variables: { contentItem: contentItem.series.id } })
    }
    fireCounterToRefreshUI()
  }

  /**
   * Add content items back to continuity list
   * Function to exposure for UI to call
   */
  const addContinuity = () => {
    addContinuitiesHandler({ variables: { continuities: removedItems } })

    // clear item timer before item removed
    cancelSelectedItemTimer()
    dispatch({ type: UNDO_REMOVE_ITEM, selectedItem: { contentItem, isInUndoState: false } })
  }

  /**
   * Get timer id from reducer, then cancel timer
   */
  const cancelSelectedItemTimer = () => {
    const currentItem = removedItemsList.find(item => item.contentItem.id === contentItem.id)
    if (currentItem && currentItem.timerId) {
      clearTimeout(currentItem.timerId)
    }
  }

  /**
   * Calling action to refresh carousel UI
   */
  const refreshUI = () => {
    cancelSelectedItemTimer()
    dispatch({ type: REFRESH_UI, selectedItem: contentItem })
  }

  return {
    removeContinuity,
    addContinuity
  }
}

export default useContinuity
