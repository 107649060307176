import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'
import classNames from 'classnames'
import styles from './ripple-icon.css'

/**
 * Icon component with ripple effect when click
 */
const RippleIcon = ({
  icon,
  onClick,
  className,
  svgClassName,
  ...props
}) => {
  const [size, setSize] = useState()
  const [addRipple, setAddRipple] = useState(false)

  const classes = classNames(className, styles.iconContainer)

  const clickToGetEffect = (event) => {
    onClick()
    const container = event.currentTarget.getBoundingClientRect()
    const diameter = Math.max(container.width, container.height)
    const radius = diameter / 2
    setSize(radius)
    setAddRipple(true)

    // Clear ripple after animation
    setTimeout(() => {
      setAddRipple(false)
    }, 601)
  }

  return (
    <div
      className={classes}
      onClick={(event) => clickToGetEffect(event)}
      {...props}
    >
      <div className={styles.iconEffect}>
        <ReactSVG src={icon} className={svgClassName} />
        {addRipple ? <span className={styles.ripple} style={{ width: `${size}px`, height: `${size}px` }} /> : null}
      </div>
    </div>
  )
}

RippleIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  svgClassName: PropTypes.string,
  onClick: PropTypes.func
}

export default RippleIcon
