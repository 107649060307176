import qs from 'query-string'
import {
  __, compose, curryN, lensPath, merge, over
} from 'ramda'

import { lensIso } from './fp'

export const getLocationBase = () => {
  return window.location.port
    ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}`
    : `${window.location.protocol}//${window.location.hostname}`
}

export const getLocationBaseWithHref = () => {
  return `${getLocationBase()}${window.location.pathname}`
}

// This pattern came from here: https://stackoverflow.com/a/19709846
const urlTest = new RegExp('^(?:[a-z]+:)?//', 'i')

/**
 * Looks for fully qualified URLs including some scheme.
 * Given a string with any scheme, returns true.
 * Anything else returns false.
 * @example
 * // returns true, even for empty scheme
 * isFullyQualifiedUrl("//example.com")
 * @example
 * // returns false
 * isFullyQualifiedUrl("google.com")
 */
export const isFullyQualifiedUrl = (href) => {
  if (typeof href !== 'string') {
    return false
  }
  return urlTest.test(href)
}

export const lensSearch = compose(
  lensPath(['search']),
  lensIso(qs.parse, qs.stringify)
)

export const mergeQueryParams = curryN(
  2,
  (location, params) => over(lensSearch, merge(__, params), location)
)

export const pushQueryParams = curryN(
  3,
  (history, location, params) => history.push(mergeQueryParams(location, params))
)
