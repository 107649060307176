import { find, propEq } from 'ramda'
import { MAINTENANCE_MODE } from '../../constants'

import {
  enableMaintenanceMode
} from '../../actions'

export function getMaintenanceError(errors = []) {
  return find(propEq('code', MAINTENANCE_MODE.ENABLED))(errors)
}

/**
 * Handles the app maintenance mode through the network interface afterware
 * @param {Array} errors - List of GraphQL JSON error objects
 * @param {Object} store - Redux store
 */
export function handleMaintenanceMode(errors, store) {
  const maintenanceError = getMaintenanceError(errors)
  const maintenance = store.getState().maintenance
  const modeEnabled = maintenance.mode

  if (!maintenanceError || modeEnabled) {
    return false
  }

  return store.dispatch(enableMaintenanceMode(maintenanceError.message))
}
