import {
  find, path, pathEq, pathOr, pipe
} from 'ramda'
import jwtDecode from 'jwt-decode'
/**
 * reselect allows for memoizing parts of a `mapStateToProps` selector.
 * This means that values that are expensive to derive from the redux store
 * can be calculated once, and held onto until the base value changes.
 * See https://github.com/reactjs/reselect
 */
import { createSelector } from 'reselect'

import {
  CONFIG,
  HELP_SITE
} from '../constants'

const getValues = pathOr('', ['values'])

/**
 * Not _quite_ the same as the one in the config lib
 * Hopefully this whole selector business can replace
 * the need for that.
 */
const findConfigValue = configKey => pipe(
  find(pathEq(['key'], configKey)),
  pathOr('', ['value'])
)

export const facebookSelector = createSelector(
  [getValues],
  findConfigValue(CONFIG.facebookUsername)
)

export const instagramSelector = createSelector(
  [getValues],
  findConfigValue(CONFIG.instagramUsername)
)

export const twitterSelector = createSelector(
  [getValues],
  findConfigValue(CONFIG.twitterUsername)
)

export const getPlaybackQuality = path(['playbackQualityOptions', 'wifi'])

export const getDefaultPlaybackQualityOption = createSelector(
  getPlaybackQuality,
  playbackQualityOptions => playbackQualityOptions.find(item => item.isDefault === true)
)

export const helpsiteUrlSelector = createSelector(
  [getValues],
  findConfigValue(HELP_SITE.URL_HELP_SITE)
)

export const getTokenFromConfig = path(['session', 'token'])
export const getDecodedJwtFromConfig = createSelector(
  [getTokenFromConfig],
  (token) => {
    if (!token) return null
    try {
      return jwtDecode(token)
    } catch (err) {
      console.error(err)
      return null
    }
  }
)

export const getAccountIdFromConfig = createSelector(
  [getDecodedJwtFromConfig],
  path(['accountId'])
)

export const deepLinkWatchUrlSelector = createSelector(
  [getValues],
  findConfigValue(CONFIG.deepLinkWatchUrl)
)
