import { createUseStyles } from 'react-jss'
import { commonStyles } from '../../../shared/common.styles'

export const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),

  priceSectionFrame: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    width: '100%',
    [theme.media.mobile]: {
      gap: '15px'
    },
    [theme.media.smallMobile]: {
      gap: '10px'
    }
  },

  priceSectionLeftFrame: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },

  priceSectionRightFrame: {
    display: 'flex',
    flexDirection: 'column',
    flex: 2
  },

  outlineButtonPink: {
    display: 'block',
    fontSize: 16,
    fontWeight: 420,
    lineHeight: '20px',
    fontFamily: 'GothamRounded',
    padding: [8, 0],
    textAlign: 'center',
    color: 'black',
    background: theme.neonPink,
    borderRadius: `5px 5px 0 0`,
    outline: 'none',
    margin: [10, 0],
    textTransform: 'uppercase',
    [theme.media.mobile]: {
      fontSize: 14
    },
    [theme.media.smallMobile]: {
      fontSize: 13
    }
  },

  divider: {
    height: 1,
    margin: [10, 0, 10],
    backgroundColor: theme.field,
    width: '100%'
  },

  discountSectionForMobileView: {
    [theme.media.desktop]: {
      display: 'none'
    }
  },

  discountSectionForDescription: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    [theme.media.mobile]: {
      gap: '15px'
    },
    [theme.media.smallMobile]: {
      gap: '10px'
    }
  },

  discountDescription: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'flex-start',
    fontSize: 18,
    [theme.media.mobile]: {
      fontSize: 16
    },
    [theme.media.smallMobile]: {
      fontSize: 14
    }
  },

  normalDescription: {
    paddingTop: 10
  },

  priceDescriptionDisabled: {
    color: '#a0a0a0',
    width: '100%',
    height: '100%',
    backgroundColor: theme.greyHighlight,
    padding: 10
  }
}))
