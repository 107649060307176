import { withRouter } from 'react-router-dom'
import {
  compose,
  withHandlers,
  withProps,
  withState,
  branch,
  renderNothing
} from 'recompose'
import { graphql } from 'react-apollo'
import { connect } from 'react-redux'
import {
  always,
  complement,
  cond,
  equals,
  find,
  filter,
  join,
  path,
  pipe,
  prepend,
  prop,
  propEq,
  T,
  test,
  toLower,
  when
} from 'ramda'

import {
  displayErrorDialog,
  setNavigationVisibility,
  setSearchIsClicked
} from '../../actions'
import { navigationBarGa } from '../../lib/analytics/ga'
import {
  UUID_SLUG, MODALS, NAVIGATION_BAR_GA_KIDS_INSTANT_SWITCH, DEFAULT_KIDS_PROFILE_DETAIL
} from '../../constants'
import { handleSwitchProfile } from '../../actions/profile'

import withMutateAndLoading from '../../hoc/with-mutate-and-loading'

import NavigationItem from '../../layouts/shared-navigation/navigation-item'
import { customDimensions } from '../../lib/analytics/custom-dimensions'
import { getSelectedProfileIdFromSession, getCurrentProfile, requirePinForSwitchProfile } from '../../lib/account'
import { getGQLErrorMsg } from '../../lib/apollo'

import ACCOUNT_QUERY from '../../../graphql/queries/account.gql'
import PROFILE_MUTATION from '../../../graphql/mutations/profile.gql'
import { checkQueryStatus } from '../../lib/utils'

const notAbsolute = complement(
  test(/^\//)
)
const FAIL_ADDING_KIDS = 'failAddingKids'

/**
 * Extract the `path` property from a navItem.
 * If the path doesn't start with a `/`, prepend one
 * so links don't break if you're not on the home screen.
 */
const getPath = pipe(
  prop('path'),
  when(
    notAbsolute,
    pipe(
      prepend('/'),
      join('')
    )
  )
)

const isHome = pipe(
  prop('name'),
  toLower,
  equals('home')
)

const toUUIDPath = pipe(
  prop('name'),
  name => `/${UUID_SLUG}/${name}`
)

const toUUIDTarget = pipe(
  prop('target'),
  target => `/${UUID_SLUG}/${target}`
)

/**
 * Extracts the path name from a navItem.
 * It looks for a not null `path`, `pathname` or `target` property,
 * then falls back on the `name` property, unless
 * the nav item points to home, in which case
 * no path is used.
 */
const findPathName = cond([
  [prop('path'), getPath],
  [prop('target'), toUUIDTarget],
  [isHome, always('')],
  [T, toUUIDPath]
])

const mapStateToProps = ({ navigation: { searchIsClicked, sideNav }, session }) => ({
  isClicked: searchIsClicked,
  sideNavIsOpen: sideNav,
  profileId: getSelectedProfileIdFromSession(session)
})

const mapDispatchToProps = dispatch => ({
  setIsClicked: (bool) => {
    dispatch(setSearchIsClicked(bool))
  },
  hideSideNavigation: () => {
    dispatch(setNavigationVisibility(false))
  },
  dispatch
})

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  graphql(ACCOUNT_QUERY, {
    name: 'accountQuery',
    skip: ({ navItem }) => {
      return !(navItem.isAuthenticated && navItem.isKids)
    }
  }),
  graphql(PROFILE_MUTATION),
  withMutateAndLoading,
  withState('isExpanded', 'setIsExpanded', false),
  branch(({ accountQuery }) => checkQueryStatus(accountQuery), renderNothing),
  withProps(({ navItem, accountQuery }) => ({
    pathname: findPathName(navItem),
    profiles: path(['account', 'profiles'], accountQuery),
    entitlement: path(['account', 'subscription', 'entitlement'], accountQuery),
    profile: getCurrentProfile(accountQuery),
    requirePin: accountQuery && requirePinForSwitchProfile(accountQuery.account)
  })),
  withState('isExpanded', 'setIsExpanded', false),
  withProps(({ navItem }) => {
    return {
      pathname: findPathName(navItem)
    }
  }),
  withHandlers({
    onToggleMenu: ({ isExpanded, setIsExpanded }) => () => {
      setIsExpanded(!isExpanded)
    },
    onClick: ({
      history,
      pathname,
      navItem,
      inSideNav,
      isInsideNavigationBar,
      hideSideNavigation,
      setIsClicked,
      setParentIsExpanded,
      profileId,
      profiles,
      mutateAndLoading,
      dispatch,
      profile: currentProfile,
      requirePin,
      entitlement
    }) => (event) => {
      if (isInsideNavigationBar) {
        navigationBarGa({
          action: navItem.name,
          [customDimensions.ProfileId]: profileId
        })
      }

      if (navItem.name === 'Search') {
        setIsClicked(true)
      }

      if (navItem.isKids === true && navItem.isAuthenticated) {
        const allKids = filter(profile => propEq('isKid', true)(profile), profiles)
        if (allKids.length === 1) {
          handleOneKidsProfile({
            dispatch, kidsProfile: allKids[0], currentProfile, requirePin
          })
        } else if (allKids.length === 0) {
          const result = handleNonKidsProfile({
            profiles,
            dispatch,
            mutateAndLoading,
            currentProfile,
            requirePin,
            entitlement
          })
          if (result === FAIL_ADDING_KIDS) {
            pathname = handleFullProfiles()
          }
        } else {
          pathname = handleMultipleKidsProfile()
        }
      }

      // For all the navigation items, except Discover, we have to prevent the
      // default behavior to avoid expand the dropdowns when the site is
      // redirecting to a page
      if (!navItem.isDiscoverNav) {
        event.stopPropagation()
        event.preventDefault()
        // Except navItem is kids and path is the default,
        // because of proceeding to redirect in the switch profile
        if (!(navItem.isKids && pathname === '/#')) {
          history.push(pathname)
        }
      }

      // If is Discover nav, keep the side navigation opened
      if (inSideNav && !navItem.isDiscoverNav) {
        hideSideNavigation()
      }

      if (setParentIsExpanded) setParentIsExpanded(false)
    },
    onClickOutside: ({
      setIsExpanded,
      isExpanded,
      inSideNav,
      sideNavIsOpen,
      hideSideNavigation
    }) => () => {
      if (!isExpanded) {
        return
      }
      if (inSideNav && sideNavIsOpen) {
        hideSideNavigation()
      }
      setIsExpanded(false)
    }
  })
)

export default enhance(NavigationItem)

export const handleOneKidsProfile = ({
  dispatch, kidsProfile, currentProfile, requirePin
}) => {
  handleSwitchProfile({
    dispatch, currentProfile, targetProfile: kidsProfile, requirePin, navigationBarKidsGAOption: NAVIGATION_BAR_GA_KIDS_INSTANT_SWITCH
  })
}

export const handleNonKidsProfile = ({
  profiles,
  dispatch,
  mutateAndLoading,
  currentProfile,
  requirePin,
  entitlement
}) => {
  // show manage profile modal if the current plan hit the profile limitation
  if (profiles?.length === entitlement?.profileLimit) {
    return FAIL_ADDING_KIDS
  }

  if (profiles && profiles.length < entitlement?.profileLimit) {
    const existingDefaultKidsProfiles = filter(profile => propEq('name', DEFAULT_KIDS_PROFILE_DETAIL.name)(profile), profiles)
    if (existingDefaultKidsProfiles && existingDefaultKidsProfiles.length === 1) {
      // show manage profile modal if there is a naming conflict
      return FAIL_ADDING_KIDS
    }
    // System try creat a kids profile automatically
    mutateAndLoading('profileAdd', {
      variables: {
        input: DEFAULT_KIDS_PROFILE_DETAIL
      }
    })
      .then(({ data: { profile: { profiles: latestProfiles } } }) => {
        const newDefaultKidsProfile = find(propEq('isKid', true), latestProfiles)
        handleSwitchProfile({
          dispatch, currentProfile, targetProfile: newDefaultKidsProfile, requirePin, navigationBarKidsGAOption: NAVIGATION_BAR_GA_KIDS_INSTANT_SWITCH
        })
      })
      .catch((error) => {
        dispatch(displayErrorDialog({
          message: getGQLErrorMsg(error)
        }))
      })
  } else {
    return FAIL_ADDING_KIDS
  }
  return null
}

export const handleMultipleKidsProfile = () => `?modal=${MODALS.qsParams.profilesSwitch}&nav=true`

export const handleFullProfiles = () => `?modal=${MODALS.qsParams.profilesSwitch}&nav=true&kids_full=true`
