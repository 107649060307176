import React from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'
import classNames from 'classnames'

import neonLoadingImg from '../../../images/neon/loading-white.svg'

import styles from './index.css'

const FullScreenLoadingComponent = ({
  error,
  timedOut,
  pastDelay,
  withoutLayer
}) => {
  const classes = classNames(styles.fullscreenWrapper, {
    [styles.withoutLayer]: withoutLayer
  })

  if (error) {
    return (
      <div>Error loading component!</div>
    )
  }

  if (timedOut) {
    return (
      <div className={classes}>
        <ReactSVG
          src={neonLoadingImg}
          beforeInjection={(svg) => {
            svg.setAttribute('class', styles.centeredSpinner)
          }}
        />
      </div>
    )
  }

  if (pastDelay) {
    return (
      <div className={classes}>
        <ReactSVG
          src={neonLoadingImg}
          beforeInjection={(svg) => {
            svg.setAttribute('class', styles.centeredSpinner)
          }}
        />
      </div>
    )
  }

  return null
}

FullScreenLoadingComponent.propTypes = {
  error: PropTypes.bool,
  timedOut: PropTypes.bool,
  pastDelay: PropTypes.bool,
  withoutLayer: PropTypes.bool
}

FullScreenLoadingComponent.defaultProps = {
  error: false,
  timedOut: false,
  pastDelay: false,
  withoutLayer: false
}

export default FullScreenLoadingComponent
