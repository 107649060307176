import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import styles from './navigation-account-side.css'

import MyListIcon from './my-list-icon'
import MyRentalsIcon from './my-rentals-icon'
import NavAvatarSection from '../nav-avatar-section'
import { STATIC_SCREENS } from '../../../../constants'

function NavigationAccountSide({
  userName,
  avatarUri,
  onSwitchProfileClick,
  isExpanded,
  onToggleAccountNav,
  onSideNavLinkClick,
  myListTotalItems,
  myRentalsTotalItems,
  isKid
}) {
  return (
    <div className={styles.navAccountSideWrapper} id="navigation-account-side">
      <div className={styles.navAccountSide}>
        <NavAvatarSection
          userName={userName}
          avatarUri={avatarUri}
          isKid={isKid}
          isExpanded={isExpanded}
          onToggleAccountNav={onToggleAccountNav}
          onSwitchProfileClick={onSwitchProfileClick}
        />
        {
          isExpanded && (
            <div className={styles.navAccountSection}>
              <ul className={styles.accountLinks}>
                <li>
                  <NavLink
                    exact
                    to={STATIC_SCREENS.MY_LIST.screenId}
                    className={styles.accountLink}
                    onClick={onSideNavLinkClick}
                  >
                    <MyListIcon />
                    <span className={styles.accountLinkText}>
                      My list
                    </span>
                    { myListTotalItems > 0 && <span>{`(${myListTotalItems})`}</span> }
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to={STATIC_SCREENS.MY_RENTALS.screenId}
                    className={styles.accountLink}
                    onClick={onSideNavLinkClick}
                  >
                    <MyRentalsIcon />
                    <span className={styles.accountLinkText}>
                      My rentals
                    </span>
                    { myRentalsTotalItems > 0 && <span>{`(${myRentalsTotalItems})`}</span> }
                  </NavLink>
                </li>
              </ul>
            </div>
          )
        }
      </div>
    </div>
  )
}

NavigationAccountSide.propTypes = {
  isKid: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onToggleAccountNav: PropTypes.func.isRequired,
  onSideNavLinkClick: PropTypes.func.isRequired,
  userName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]).isRequired,
  avatarUri: PropTypes.string.isRequired,
  onSwitchProfileClick: PropTypes.func.isRequired,
  myListTotalItems: PropTypes.number.isRequired,
  myRentalsTotalItems: PropTypes.number.isRequired
}

export default NavigationAccountSide
