import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { useStyles } from './rent-movie-section.styles'
import { tvodDetails, tvodDetailsForNewPrice } from '../../../shared/subscription-constants'

const RentMovieSection = (props) => {
  const styles = useStyles()
  const { selectToNext } = props
  return (
    <div className={classNames(styles.tableRowContainer)}>
      <div className={styles.rentMovieTextFrame}>
        <div className={styles.rentMovieText}>{tvodDetailsForNewPrice.title}</div>
        <div className={styles.rentMovieTextTitle}>{tvodDetailsForNewPrice.subTitle}</div>
        <div className={styles.rentMovieTextSubTitle}>{tvodDetailsForNewPrice.content}</div>
      </div>

      <div className={styles.rentMovieButtonFrame}>
        <div onClick={() => selectToNext(tvodDetails.type)} className={classNames(styles.outlineButtonPink, styles.button)}>{tvodDetailsForNewPrice.buttonText}</div>
        <NavLink className={styles.rentMovieLinkText} target="_blank" to={tvodDetailsForNewPrice.rentalLink.link}>{tvodDetailsForNewPrice.rentalLink.text}</NavLink>
      </div>
    </div>
  )
}

RentMovieSection.propTypes = {
  selectToNext: PropTypes.func.isRequired
}

RentMovieSection.defaultProps = {
  selectToNext: null
}

export default RentMovieSection
