import React from 'react'
import PropTypes from 'prop-types'
import styles from './video.css'

class Video extends React.PureComponent {
  video = React.createRef()

  player = {
    play: () => {
      const player = this.video.current
      return player.play()
    },
    pause: () => {
      const player = this.video.current
      return player.pause()
    },
    mute: () => {
      const player = this.video.current
      player.muted = true
    },
    unmute: () => {
      const player = this.video.current
      player.muted = false
    }
  }

  render() {
    const {
      onEnded, onPlay, onPause, video, onLoadedData
    } = this.props

    return (
      <video
        className={styles.video}
        muted
        autoPlay={false}
        playsInline
        ref={this.video}
        onEnded={onEnded}
        onPause={onPause}
        onPlay={onPlay}
        onLoadedData={onLoadedData}
      >
        <source src={video} type="video/mp4" />
      </video>
    )
  }
}

Video.propTypes = {
  onEnded: PropTypes.func.isRequired,
  onPlay: PropTypes.func.isRequired,
  onPause: PropTypes.func.isRequired,
  onLoadedData: PropTypes.func.isRequired,
  video: PropTypes.string.isRequired
}

export default Video
