
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PurchaseFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Purchase"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"profile"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"contentItem"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ContentItemLightFields"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"product"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"renewable"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"total"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"startAvailable"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"endAvailable"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"endViewable"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":274}};
    doc.loc.source = {"body":"#import \"../fragments/content-item-light.gql\"\n\nfragment PurchaseFields on Purchase {\n  id\n  profile {\n    id\n    name\n  }\n  contentItem {\n    ...ContentItemLightFields\n  }\n  product {\n    id\n    name\n    renewable\n  }\n  total\n  startAvailable\n  endAvailable\n  endViewable\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../fragments/content-item-light.gql").definitions));


      module.exports = doc;
    
