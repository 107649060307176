import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import TileBadge from '../../../../components/shared/badge'
import TileOverlay from '../../../../containers/carousel/tile/overlay/index'
import CarouselImgix from '../../../../components/imgix/carousel-imgix'
import { dataLayerProductClick } from '../../../../lib/analytics/datalayer'
import { getTileRecipe } from '../../../../components/imgix/recipes'
import styles from './search-tile.css'
import rentalBadgeKidsSmall from '../../../../../images/neon/rental-badge-kids-small.svg'
import rentalBadgeSmall from '../../../../../images/neon/rental-badge-small.svg'
import { THEME_OPTIONS } from '../../../../constants'

/**
 * A tile component for displaying search result items
 */
const SearchTile = ({
  tile,
  index,
  carouselName,
  isAuthenticated,
  isOnMyList,
  addToMyList,
  removeFromMyList,
  theme
}) => {
  const {
    image, badge, contentItem
  } = tile
  const { isRental, path, __typename: type } = contentItem

  const tileRecipe = getTileRecipe(type === 'Title' ? 'PORTRAIT' : 'LANDSCAPE')

  const [isMouseHover, setIsMouseHover] = useState(false)

  const rentalBadge =
    theme === THEME_OPTIONS.light ? rentalBadgeKidsSmall : rentalBadgeSmall

  const isKids = theme === THEME_OPTIONS.light

  return (
    <div>
      <div
        className={styles.tile}
        onMouseEnter={() => {
          setIsMouseHover(true)
        }}
        onMouseLeave={() => {
          setIsMouseHover(false)
        }}
      >
        {badge ? (
          <TileBadge title={badge} isRental={isRental} />
        ) : null}

        <Link
          to={path}
          onClick={() => dataLayerProductClick(contentItem, index, carouselName, window.location.pathname)}
          className={styles.imgWrapper}
        >
          <CarouselImgix src={image.uri} recipe={tileRecipe} isKids={isKids} />

          {isRental && (
            <img
              className={styles.rentalBadge}
              src={rentalBadge}
              alt="Rental Content"
            />
          )}

          <TileOverlay
            tileType={type}
            tile={tile}
            isAuthenticated={isAuthenticated}
            isOnMyList={isOnMyList}
            addToMyList={addToMyList}
            removeFromMyList={removeFromMyList}
            display={isMouseHover}
            isRental={isRental}
          />
        </Link>
      </div>
    </div>
  )
}

SearchTile.propTypes = {
  tile: PropTypes.oneOfType([PropTypes.object]).isRequired,
  index: PropTypes.number.isRequired,
  carouselName: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isOnMyList: PropTypes.bool.isRequired,
  addToMyList: PropTypes.func,
  removeFromMyList: PropTypes.func,
  theme: PropTypes.string.isRequired
}

export default SearchTile
