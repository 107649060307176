import React from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'

import styles from './continuity.css'
import UndoIcon from '../../../../../images/undo-icon.svg'
import RemoveWatchingDefault from '../../../../../images/delete-default.svg'
import { useContinuity } from '../../hooks/use-continuity'
import { UNDO_INFO } from '../../constants'

/**
 * Component for display continuity tile remove/undo overlay
 */
const ContinuityOverlay = React.memo(({
  contentItem,
  mouseHoverState,
  isInUndoState,
  updateMouseHoverRemoveIconState
}) => {
  const { isRental } = contentItem
  const {
    removeContinuity,
    addContinuity
  } = useContinuity(contentItem)

  const clickToRemove = () => {
    removeContinuity()
  }

  const clickToUndoRemove = () => {
    addContinuity()
  }

  if (isRental) {
    return null
  }

  if (mouseHoverState && !isInUndoState) {
    return (
      <div
        className={styles.removeIconContainer}
        onClick={clickToRemove}
        onMouseEnter={() => updateMouseHoverRemoveIconState(true)}
        onMouseLeave={() => updateMouseHoverRemoveIconState(false)}
      >
        <ReactSVG
          className={styles.removeIcon}
          src={RemoveWatchingDefault}
        />
      </div>
    )
  }

  if (isInUndoState) {
    return (
      <div className={styles.undoOverlay}>
        <span>{UNDO_INFO.title}</span>
        <div className={styles.undoIconContainer} onClick={clickToUndoRemove}>
          <div className={styles.revertIconContainer}>
            <ReactSVG
              className={styles.revertIcon}
              src={UndoIcon}
            />
          </div>
          {UNDO_INFO.button}
        </div>
      </div>
    )
  }

  return null
})

ContinuityOverlay.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  contentItem: PropTypes.oneOfType([PropTypes.object]).isRequired,
  mouseHoverState: PropTypes.bool.isRequired,
  isInUndoState: PropTypes.bool.isRequired,
  updateMouseHoverRemoveIconState: PropTypes.func
}

export default ContinuityOverlay
