import ActionTypes from '../actions/types'

const initialState = {
  mode: false,
  message: ''
}

const maintenanceModeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ENABLE_MAINTENANCE_MODE:
      return {
        mode: true,
        message: action.message
      }
    case ActionTypes.DISABLE_MAINTENANCE_MODE:
      return {
        mode: false,
        message: ''
      }
    case ActionTypes.UPDATE_MAINTENANCE_MESSAGE:
      return {
        ...state,
        message: action.message
      }
    default:
      return state
  }
}

export default maintenanceModeReducer
