import {
  complement,
  find,
  head,
  isNil,
  last,
  path,
  pathEq,
  pathOr,
  pipe,
  prop,
  propEq,
  test
} from 'ramda'
import { matchPath } from 'react-router-dom'

import { store } from '../store'
import { findConfigValue } from './config'
import { isVideoExtra } from './content'
import {
  UUID_SLUG,
  LOADING_OVERLAY
} from '../constants'
import { endLoadingOverlay } from '../actions'
import { IdleQueue } from './idlize/IdleQueue.mjs'

const hasContentPath = complement(
  pipe(
    prop('path'),
    isNil
  )
)

export function getClosestHLSPlaylist(sources, bitrate) {
  if (!sources || !sources.length || !bitrate) {
    return null
  }
  let curr = sources[0]
  let diff = Math.abs(bitrate - curr.bitrate)
  for (let i = 0; i < sources.length; i += 1) {
    const newdiff = Math.abs(bitrate - sources[i].bitrate)
    if (newdiff < diff) {
      diff = newdiff
      curr = sources[i]
    } else if (newdiff === diff && curr.bitrate >= sources[i].bitrate) {
      // if the difference is the same
      // opt by the lower bitrate
      diff = newdiff
      curr = sources[i]
    }
  }
  return curr
}

function changeSelectedIndex(levels, newIndex) {
  for (let i = 0; i < levels.length; i += 1) {
    if (i === newIndex) {
      levels[i].enabled = true
    } else {
      levels[i].enabled = false
    }
  }
}

// If a preferred bitrate is defined,
// select closest rendition / playlist (hls)
export function selectSource(player, dataSaverOption) {
  const qualityLevels = player.qualityLevels()
  const bitrate = dataSaverOption.bitrate
  // eslint-disable-next-line no-underscore-dangle
  const sources = player.qualityLevels().levels_

  if (sources && sources.length && bitrate) {
    // TODO: investigate if we need to implement the select
    // rendition for other kinds of browser / player / plugin / sources combination
    const selectedSource = getClosestHLSPlaylist(sources, bitrate)

    // Rely on player to automatically choose the best rendition
    // if none was retrieved
    if (!selectedSource) {
      return
    }

    // implemented according to
    // https://github.com/videojs/videojs-contrib-quality-levels
    // eslint-disable-next-line no-underscore-dangle
    // player.qualityLevels().selectedIndex_ = sources.indexOf(selectedSource);
    changeSelectedIndex(qualityLevels, sources.indexOf(selectedSource))
  }
}

/**
 * Check if the content item is available to be watched
 * @param {Object} contentItem - GraphQL contentItem JSON object
 */
function isContentItemAvailable(contentItem) {
  return contentItem && contentItem.available
}

/**
 * Find the next episode to play for a certain content item
 * @param {Object} contentItem - GraphQL contentItem JSON object
 */
export function getNextEpisodeId(contentItem) {
  let nextEpisodeID = null
  if (contentItem.__typename !== 'Episode') { // icon is not displayed on movies
    return null
  }

  // icon is displayed only on series
  const episodeNumber = contentItem.episodeNumber
  const seasonNumber = contentItem.seasonNumber
  const currentSeason = contentItem.series.seasons.find(
    season => season.seasonNumber === seasonNumber
  )
  let episodes = currentSeason.episodes
  if (episodeNumber < episodes.length) { // check for next episode in the season
    const nextEpisode = episodes.find(
      episode => episode.episodeNumber === (episodeNumber + 1)
    )
    if (isContentItemAvailable(nextEpisode)) {
      nextEpisodeID = nextEpisode.id
    }
  } else {
    // the current episode is the last in the season,
    // so get the first episode in the next season if it exist.
    const seasons = contentItem.series.seasons
    if (seasonNumber < seasons.length) {
      const nextSeason = contentItem.series.seasons.find(
        season => season.seasonNumber === (seasonNumber + 1)
      )
      episodes = nextSeason.episodes
      const nextEpisode = episodes[0]
      if (isContentItemAvailable(nextEpisode)) {
        nextEpisodeID = nextEpisode.id
      }
    }
  }
  return nextEpisodeID
}

/**
 * Get the path for an extra's associated content item
 * @param {Object} contentItem - GraphQL contentItem JSON object
 */
export function getShowPagePath(contentItem) {
  switch (contentItem.__typename) {
    case 'Episode': return contentItem.series.path
      ? contentItem.series.path
      : `/${UUID_SLUG}/${contentItem.series.id}`
    case 'Series':
    case 'Title': return hasContentPath(contentItem)
      ? contentItem.path
      : `/${UUID_SLUG}/${contentItem.id}`
    case 'VideoExtra': {
      // We don't know what content item to route to so use the first associated content item
      const showPath = path(['contentItems', '0', 'path'], contentItem)
      if (showPath) return showPath
      const showId = path(['contentItems', '0', 'id'], contentItem)
      // if no associated content items, return null and let the caller deal with it
      return showId ? `/${UUID_SLUG}/${showId}` : null
    }
    default: return null
  }
}

/**
 * Get the title for an extra's associated content item
 * @param {Object} trailer - GraphQL contentItem JSON object
 * @param {Object} contentItemId - ID of associated series or title
 */
export function getTrailerShowTitle(trailer, contentItemId) {
  if (!trailer.contentItems) return ''
  const item = trailer.contentItems.find(i => i.id === contentItemId) || trailer.contentItems[0]
  return item ? item.title : ''
}

/**
 * Check if youbora plugin exist before fire the given event
 * @param {String} event - Youbora event to fire
 */
export function emitYouboraEvent(event) {
  // if the plugin was not created by the method initYouboraPlugin
  // the events can't be fired
  if (!window.youbora) { return }

  if (event === 'init') {
    // checks if youbora has fired the /init call before
    // to avoid call it twice
    if (!window.youbora.isInitiated) {
      window.youbora.fireInit()
    }
  } else if (event === 'stop') {
    // Finishes the current youbora session
    window.youbora.fireStop()
  } else if (event === 'error') {
    // Sends a playback error
    window.youbora.fireError()
  }
}

/**
 * Determine whether the player should navigate back one page
 * @param {Object} contentItem - GraphQL contentItem JSON object
 * @param {Object} history - react router history object
 */
export function navigateBack(contentItem, history) {
  emitYouboraEvent('stop')
  const showPagePath = getShowPagePath(contentItem)
  return (isVideoExtra(contentItem) || !showPagePath)
    ? history.goBack()
    : history.push(showPagePath)
}

/**
 * Get last cuepoint with type = CODE
 */
export function getLastCuePoint(textTracks) {
  const cues = pathOr([], ['0', 'cues', 'cues_'], textTracks)
  const filteredCues = cues.filter(tt => tt.originalCuePoint.type === 'CODE')
  return last(filteredCues)
}

/**
 * Get first cuepoint with type = CODE
 */
export function getFirstCuePoint(textTracks) {
  const cues = pathOr([], ['0', 'cues', 'cues_'], textTracks)
  const filteredCues = cues.filter(tt => tt.originalCuePoint.type === 'CODE')
  return head(filteredCues)
}

/**
 * Initialize Youbora plugin
 */
export function initYouboraPlugin(appConfig) {
  const idleQueue = new IdleQueue({
    defaultMinTaskTime: 10
  })
  idleQueue.pushTask(() => {
    import('youboralib').then(youbora => {
      const youboraAccount = findConfigValue('YOUBORA_ACCOUNT')(appConfig)
      window.youbora = new youbora.Plugin({ accountCode: youboraAccount })
    })
  })

  setTimeout(() => {
    idleQueue.runTasksImmediately()
  }, 5000)
}

export const iOS = test(/(iPad|iPhone|iPod)/g)(navigator.userAgent)
export const Android = test(/(Android)/g)(navigator.userAgent)

/**
 * Remove the current Youbora adapter and automatically fires stop if needed.
 */
export function removeYouboraAdapter() {
  if (window.youbora) {
    window.youbora.removeAdapter()
  }
}

const matchUrl = (url, pathString) => matchPath(url, { path: pathString })
export const isPlayerUrl = (pathname) => {
  return pathEq(['isExact'], true, matchUrl(pathname, '/trailer/:contentId')) ||
  pathEq(['isExact'], true, matchUrl(pathname, '/trailer/:videoExtraId/:contentId')) ||
  pathEq(['isExact'], true, matchUrl(pathname, '/watch/:contentId'))
}

/**
 * When the app triggers the timeout handler and the user
 * is trying to play a video, fires /error and /stop events
 * to stop /ping calls
 */
export const stopYouboraEventsOnTimeout = (history) => {
  if (isPlayerUrl(path(['location', 'pathname'], history))) {
    emitYouboraEvent('error')
    emitYouboraEvent('stop')
  }
}

/**
 * Disable player loading overlay
 */
export function disablePlayerLoadingOverlay() {
  const reduxStore = store.getState()
  const playerIsStillLoading = find(propEq('key', LOADING_OVERLAY.KEYS.PLAYER_LOADING))(reduxStore.loading)
  if (playerIsStillLoading) {
    store.dispatch(endLoadingOverlay(LOADING_OVERLAY.KEYS.PLAYER_LOADING, 'playerLoading'))
  }
}
