import * as Yup from 'yup'

// Create custom method to compare value using a Yup.ref
Yup.addMethod(Yup.mixed, 'sameAs', function addSameAs(ref, message) {
  return this.test('sameAs', message, function sameAs(value) {
    const other = this.resolve(ref)

    return !other || !value || value === other
  })
})

export default Yup
