import {
  map, identity, ifElse, addIndex, compose, concat, prop
} from 'ramda'

import { getDetailsUrl } from '../utils'

const mapIndexed = addIndex(map)
const currentBaseUrl = `${window.location.protocol}//${window.location.hostname}`
const getContentItem = ifElse(prop('id'), identity, prop('contentItem'))

export const createItemListLDSchema = (items) => {
  return {
    itemListElement: mapIndexed((child, position) => {
      const url = ifElse(
        getContentItem,
        compose(concat(currentBaseUrl), getDetailsUrl, getContentItem),
        identity
      )(child)

      return {
        '@type': 'ListItem',
        position,
        url
      }
    })(items)
  }
}
