import React, { Suspense } from 'react'
import { LoadingComponent } from '../../components/loading/index'

const ReactSelect = React.lazy(() => import('react-select'))

const AsyncReactSelect = props => (
  <Suspense fallback={<LoadingComponent />}>
    <ReactSelect {...props} />
  </Suspense>
)

export default AsyncReactSelect
