import {
  dissocPath,
  lensProp,
  map,
  path,
  pathEq,
  pathOr,
  pipe,
  set,
  not,
  equals,
  merge,
  isNil
} from 'ramda'
import jwtDecode from 'jwt-decode'

import { SUBSCRIPTION_STATUS } from '../constants'
import { createReducer } from '../lib/reducer'
import { mergeProp } from '../lib/fp'
import ActionTypes from '../actions/types'

const initialState = {}

const passwordTimestampLens = lensProp('passwordTimestamp')

const accounts = createReducer(initialState, {
  [ActionTypes.UPDATE_AUTH_TOKEN_AND_THEME]: (state, action) => {
    try {
      const decodedJwt = jwtDecode(action.token)
      const { accountId, selectedProfile } = decodedJwt

      if (isNil(accountId)) {
        return state
      }

      const selectedProfileStored = path([accountId, 'selectedProfile'], state)
      const passwordTimestamp = pathOr(null, [accountId, 'passwordTimestamp'], state)

      const profilesChanged = not(equals(selectedProfileStored, selectedProfile))

      let propsToMerge = { selectedProfile, passwordTimestamp }

      if (profilesChanged) {
        propsToMerge = merge(propsToMerge, { passwordTimestamp: null })
      }

      return mergeProp(accountId, propsToMerge, state)
    } catch (error) {
      console.error(error)
      return state
    }
  },
  [ActionTypes.SAVE_HAS_SEEN_RENEWAL_PROMPT]: (state, { accountId, hasSeenRenewalPrompt }) => {
    return mergeProp(accountId, { hasSeenRenewalPrompt }, state)
  },
  [ActionTypes.SAVE_HAS_SEEN_REPAIR_PROMPT]: (state, { accountId, hasSeenRepairPrompt }) => {
    return mergeProp(accountId, { hasSeenRepairPrompt }, state)
  },
  [ActionTypes.SAVE_INTRODUCTION_VERSION]: (state, action) => {
    const { accountId, version: lastSeenPremiumIntroductionVersion } = action
    return mergeProp(accountId, { lastSeenPremiumIntroductionVersion }, state)
  },
  [ActionTypes.USER_LOGIN]: (state, { login }) => {
    try {
      const accountId = pipe(
        path(['session', 'token']),
        jwtDecode,
        path(['accountId'])
      )(login)

      // If the user's subscription isn't cancelled,
      // make sure there's no `hasSeenRenewalPrompt` field in the store.
      // This prevents the Resume Subscription prompt from reappearing if the user
      // actually *does* resubscribe.
      if (!pathEq(['subscription', 'status'], SUBSCRIPTION_STATUS.CANCELLED, login)) {
        return dissocPath([accountId, 'hasSeenRenewalPrompt'], state)
      }

      return state
    } catch (err) {
      console.error(err)
      return state
    }
  },
  [ActionTypes.USER_LOGOUT]: (state) => {
    // Make sure no token is persisted in localStorage.
    localStorage.removeItem('token')

    // Unset the `passwordTimestamp` for all accounts in localStorage.
    // In practice, only one account should have a set `passwordTimestamp`, but
    // without requiring knowledge of state external to this reducer.
    return map(set(passwordTimestampLens, null), state)
  },
  [ActionTypes.SET_ACCOUNT_PASSWORD_TIMESTAMP]: (state, { passwordTimestamp, accountId }) => {
    return mergeProp(accountId, { passwordTimestamp }, state)
  },
  [ActionTypes.SELECT_DATA_SAVER_OPTION]: (state, { dataSaverSelection, accountId }) => {
    return mergeProp(accountId, { dataSaverSelection }, state)
  }
})

export default accounts
