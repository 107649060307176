import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './navigation-wrapper.css'

const NavigationWrapper = ({ children, overlay }) => {
  return (
    <div className={classnames(styles.navWrapper, {
      [styles.overlay]: overlay
    })}
    >
      {children}
    </div>
  )
}

NavigationWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]).isRequired,
  overlay: PropTypes.bool.isRequired
}

export default NavigationWrapper
