import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'

import GridOverlaySeries from './grid-overlay-series'
import GridOverlaySeriesKids from './grid-overlay-series-kids'
import GridOverlayTitle from './grid-overlay-title'
import GridOverlayTitleKids from './grid-overlay-title-kids'
import { THEME_OPTIONS } from '../../constants'

import TILE_FRAGMENT from '../../../graphql/fragments/tile.gql'

import styles from './grid-tile-overlay.css'
import {
  COMPONENT_NAME_GRID,
  COMPONENT_NAME_GRID_KIDS
} from '../../lib/analytics/custom-dimensions'

// TODO: https://bcconsulting.atlassian.net/browse/LBXW-1330
// - TileOverlay could be turned into a container
// responsible for the branching of the different overlays
// - Reduce code duplication in Grid / Carousel overlays
const GridTileOverlay = React.memo(({
  tile,
  display,
  hasRemoveIcon,
  setRemoved,
  onRemoveClick,
  theme,
  isOnMyList,
  isAuthenticated,
  addToMyList,
  removeFromMyList
}) => {
  let overlay = null
  const isKids = theme === THEME_OPTIONS.light
  if (tile.contentItem.__typename === 'Series') {
    overlay = isKids
      ? (
        <GridOverlaySeriesKids
          tile={tile}
          display={display}
          hasRemoveIcon={hasRemoveIcon}
          setRemoved={setRemoved}
          onRemoveClick={onRemoveClick(COMPONENT_NAME_GRID_KIDS)}
          isAuthenticated={isAuthenticated}
          isOnMyList={isOnMyList}
          addToMyList={addToMyList(COMPONENT_NAME_GRID_KIDS)}
          removeFromMyList={removeFromMyList(COMPONENT_NAME_GRID_KIDS)}
        />
      )
      : (
        <GridOverlaySeries
          tile={tile}
          display={display}
          hasRemoveIcon={hasRemoveIcon}
          setRemoved={setRemoved}
          onRemoveClick={onRemoveClick(COMPONENT_NAME_GRID)}
          isAuthenticated={isAuthenticated}
          isOnMyList={isOnMyList}
          addToMyList={addToMyList(COMPONENT_NAME_GRID)}
          removeFromMyList={removeFromMyList(COMPONENT_NAME_GRID)}
        />
      )
  } else if (tile.contentItem.__typename === 'Title') {
    overlay = isKids
      ? (
        <GridOverlayTitleKids
          tile={tile}
          display={display}
          hasRemoveIcon={hasRemoveIcon}
          setRemoved={setRemoved}
          onRemoveClick={onRemoveClick(COMPONENT_NAME_GRID_KIDS)}
          isAuthenticated={isAuthenticated}
          isOnMyList={isOnMyList}
          addToMyList={addToMyList(COMPONENT_NAME_GRID_KIDS)}
          removeFromMyList={removeFromMyList(COMPONENT_NAME_GRID_KIDS)}
        />
      )
      : (
        <GridOverlayTitle
          tile={tile}
          display={display}
          hasRemoveIcon={hasRemoveIcon}
          setRemoved={setRemoved}
          onRemoveClick={onRemoveClick(COMPONENT_NAME_GRID)}
          isAuthenticated={isAuthenticated}
          isOnMyList={isOnMyList}
          addToMyList={addToMyList(COMPONENT_NAME_GRID)}
          removeFromMyList={removeFromMyList(COMPONENT_NAME_GRID)}
        />
      )
  }

  const onOffClass = display ? styles.tileOverlayOn : styles.tileOverlayOff

  return (
    <div className={`${styles.tileOverlay} ${onOffClass}`}>
      { overlay }
    </div>
  )
})

GridTileOverlay.propTypes = {
  tile: propType(TILE_FRAGMENT).isRequired,
  display: PropTypes.bool.isRequired,
  hasRemoveIcon: PropTypes.bool.isRequired,
  setRemoved: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  isOnMyList: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  addToMyList: PropTypes.func.isRequired,
  removeFromMyList: PropTypes.func.isRequired
}

GridTileOverlay.defaultProps = {
  isOnMyList: false,
  isAuthenticated: false
}

export default GridTileOverlay
