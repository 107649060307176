import { pathOr } from 'ramda'

import ActionTypes from '../actions/types'
import { LOADING_OVERLAY } from '../constants'

export function getLoadingMesage(operation, msg) {
  return pathOr(LOADING_OVERLAY[msg], ['operations', operation, msg])(LOADING_OVERLAY)
}

const loading = (state = [], action) => {
  switch (action.type) {
    // Add loading item to loading overlay
    case ActionTypes.LOADING_OVERLAY_ENABLE:
      return [
        ...state,
        {
          key: action.key,
          loading: true,
          message: getLoadingMesage(action.operation, 'loadingMsg')
        }
      ]
    // Add loading success message to loading item in loading overlay
    case ActionTypes.LOADING_OVERLAY_SUCCESS:
      return [
        ...state.filter(loadingItem => loadingItem.key !== action.key),
        {
          key: action.key,
          message: getLoadingMesage(action.operation, 'successMsg')
        }
      ]
    // Add loading error message to loading item in loading overlay
    case ActionTypes.LOADING_OVERLAY_ERROR:
      return [
        ...state.filter(loadingItem => loadingItem.key !== action.key),
        {
          key: action.key,
          error: true,
          message: getLoadingMesage(action.operation, 'errorMsg')
        }
      ]
      // Add loading timeout error message to loading item in loading overlay
    case ActionTypes.LOADING_OVERLAY_TIMEOUT:
      return [
        ...state.filter(loadingItem => loadingItem.key !== action.key),
        {
          key: action.key,
          error: true,
          message: getLoadingMesage(action.operation, 'timeoutErrorMsg')
        }
      ]
    // Remove loading item from loading overlay
    case ActionTypes.LOADING_OVERLAY_DISABLE:
      return [
        ...state.filter(loadingItem => loadingItem.key !== action.key)
      ]
    default:
      return state
  }
}

export default loading
