import { connect } from 'react-redux'
import {
  compose, lifecycle, branch, renderNothing
} from 'recompose'

import { readyToReset as readyToResetAction } from './actions'
import { getIsResetting } from '../selectors/auth'

export default compose(
  connect(
    state => ({
      isResetting: getIsResetting(state)
    }),
    { readyToReset: readyToResetAction }
  ),
  lifecycle({
    componentDidUpdate({ isResetting: prevIsResetting }) {
      const { readyToReset, isResetting: newIsResetting } = this.props
      if (!prevIsResetting && newIsResetting) {
        readyToReset()
      }
    }
  }),
  branch(
    ({ isResetting }) => isResetting,
    renderNothing
  )
)
