import { getColor } from '../player/theme/shared/color'
import appTheme from '../../theme'

const signupDropdownHeight = '45px'

const customReactSelectStylesColors = (isKid, darkTheme, autoHeight) => ({
  color: getColor(isKid).white,
  backgroundColor: darkTheme ? appTheme.field : getColor(isKid).black,
  height: (darkTheme && !autoHeight) ? signupDropdownHeight : 'auto'
})

const customReactSelectStylesColorsSelected = (isKid, isRentalThemed, darkTheme, autoHeight) => {
  const color = getColor(isKid)

  let primaryColor = color.neonGreen
  if (isRentalThemed) primaryColor = color.rental

  return {
    backgroundColor: darkTheme ? appTheme.field : color.black,
    color: primaryColor,
    height: (darkTheme && !autoHeight) ? signupDropdownHeight : 'auto'
  }
}

const customReactSelectStylesStandard = isKid => ({
  ...customReactSelectStylesColors(isKid),
  userSelect: 'none'
})

export const customReactSelectStyles = ({
  isKid, plainTheme = false, width, isRentalThemed = false, darkTheme, warningStyle = false
}) => ({
  control: (provided, state) => {
    const borderStyle = {
      borderColor: warningStyle ? appTheme.error : appTheme.white
    }
    const controlStyle = {
      ...provided,
      cursor: 'pointer'
    }
    const colors = state.isFocused
      ? customReactSelectStylesColorsSelected(isKid, isRentalThemed, darkTheme)
      : customReactSelectStylesColors(isKid, darkTheme)
    return plainTheme
      ? controlStyle
      : { ...controlStyle, ...colors, ...borderStyle }
  },
  container: provided => {
    const containerStyle = {
      ...provided,
      width: width || 180,
      borderRadius: 5
    }
    return plainTheme
      ? { ...containerStyle, paddingTop: 5 }
      : { ...containerStyle, ...customReactSelectStylesStandard(isKid) }
  },
  menu: provided => {
    const menuStyle = {
      ...provided,
      zIndex: 1550
    }
    return plainTheme
      ? menuStyle
      : { ...menuStyle, ...customReactSelectStylesStandard(isKid) }
  },
  singleValue: (provided, state) => {
    const colors = state.isFocused
      ? customReactSelectStylesColorsSelected(isKid, isRentalThemed, darkTheme, true)
      : customReactSelectStylesColors(isKid, darkTheme, true)
    return plainTheme
      ? provided
      : { ...provided, ...customReactSelectStylesStandard(isKid), ...colors }
  },
  indicatorSeparator: () => ({
    display: 'none'
  }),
  indicatorContainer: provided => ({
    ...provided,
    color: getColor(isKid).white
  }),
  option: (provided, state) => {
    const optionStyle = {
      ...provided,
      cursor: 'pointer',
      userSelect: 'none',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
    const colors =
      state.isSelected || state.isFocused
        ? customReactSelectStylesColorsSelected(isKid, isRentalThemed, darkTheme)
        : customReactSelectStylesColors(isKid, darkTheme)
    return plainTheme
      ? optionStyle
      : { ...optionStyle, ...colors }
  }
})

export const customReactSelectTheme = ({
  isKid, plainTheme = false, isRentalThemed = false
}) => theme => {
  const color = getColor(isKid)

  if (plainTheme) {
    return theme
  }

  let primaryColor = color.neonGreen
  if (isRentalThemed) primaryColor = color.rental

  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary: color.black,
      primary25: color.black,
      primary50: color.black,
      primary75: color.black,
      danger: color.black,
      dangerLight: color.black,
      neutral0: color.black,
      neutral10: color.white,
      neutral20: color.white,
      neutral30: primaryColor,
      neutral40: color.white,
      neutral50: color.white,
      neutral60: primaryColor,
      neutral70: primaryColor,
      neutral80: primaryColor,
      neutral90: primaryColor
    }
  }
}
