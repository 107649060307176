import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useLazyQuery } from 'react-apollo'
import qs from 'query-string'

import { MODALS } from '../../../constants'
import { STORAGE_KEY_EMAIL_ON_GUEST_HOME_PAGE } from '../../../storage-keys'
import Input from '../../forms/input'
import Button from '../../forms/neon/button'
import CEHCK_EMAIL_QUERY from '../../../../graphql/queries/check-email.gql'
import { useStyles } from './email-box.styles'

const EmailBox = () => {
  const styles = useStyles()
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [validateEmail, { loading, error, data }] = useLazyQuery(CEHCK_EMAIL_QUERY, {
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (error) {
      setEmailError('An unexpected error occurred. Please try again later.')
    }
  }, [error])

  useEffect(() => {
    // Save email in local storage
    if (email) {
      sessionStorage.setItem(STORAGE_KEY_EMAIL_ON_GUEST_HOME_PAGE, email)
    }

    if (data) {
      const checkEmailResult = data.checkEmail
      if (checkEmailResult) {
        // Nav to login modal
        history.replace({
          ...location,
          search: qs.stringify({
            modal: MODALS.qsParams.login
          })
        })
      } else {
        // Nav to sign up form
        history.replace({
          pathname: '/signup/account',
          search: null
        })
      }
    }

    // Clear input email
    setEmail('')
    setEmailError('')
  }, [data])

  const handleChange = (e) => {
    setEmail(e.target.value)
  }

  const handleSubmit = () => {
    const REGES_EMAIL = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)
    if (!email) {
      setEmailError('Email is required')
    } else if (!REGES_EMAIL.test(email)) {
      setEmailError('Invalid email address')
    } else {
      validateEmail({ variables: { email } })
    }
  }

  return (
    <div className={styles.emailBoxFrame}>
      <Input
        type="email"
        name="email"
        placeholder="Email Address"
        inputClass={styles.emailInput}
        value={email}
        onChange={handleChange}
        onBlur={() => {}}
        warning={emailError}
        warningClass={styles.errorMessage}
        exclamationClass={styles.exclamation}
        data-e2e="validate-email"
      />
      <Button
        wrapperClassName={styles.buttonWrapper}
        text="Get Started"
        className={styles.checkEmailButton}
        onClick={handleSubmit}
        disabled={loading}
      />
    </div>
  )
}

export default EmailBox
