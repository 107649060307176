const theme = {
  neonGreen: '#cafd34',
  neonPink: '#FF2EA1',
  neonGreenLight: '#e6ff9d',
  black: '#333',
  white: '#fff',
  field: '#303030',
  yellow: '#ffd500',
  fieldHighlight: '#3c3c3c',
  greyHighlight: '#252525',
  error: '#f95c4f',
  media: {
    smallMobile: '@media only screen and (max-width: 479px)',
    mobile: '@media only screen and (min-width:480px) and (max-width: 767px)',
    tablet: '@media only screen and (min-width:768px) and (max-width: 1023px)',
    desktop: '@media only screen and (min-width:1024px)',
    largeDesktop: '@media only screen and (min-width:1280px)'
  },
  // subscription card styles
  zIndexCardBorder: 1,
  zIndexCardBadge: 2,
  cardBorderRadius: 4,
  cardBoxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
  cardBackground: 'linear-gradient(to bottom, #363636, #252525)'
}

export default theme
