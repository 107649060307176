import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import { hasNativeApp } from '../../utils/os-device'
import AsyncPlayer from '../../components/player/async-player'
import { isPathTrailer } from '../../components/player/utils/is-path-trailer'

export const PlayerLayout = props => {
  const { pathname } = useLocation()
  const isTrailer = isPathTrailer(pathname)

  if (hasNativeApp && !isTrailer) return <Redirect to="/app-download" />
  return <AsyncPlayer {...props} />
}

export default PlayerLayout
