
import { usePlanChangeFlag } from './usePlanChange'
/**
 * Hook to get basic profile limitation
 */
export const useProfilesEntitlement = (accountQuery) => {
  const getPlanDetails = usePlanChangeFlag()
  const profiles = accountQuery?.account?.profiles
  const profileLimit = accountQuery?.account?.subscription?.entitlement?.profileLimit
  const currentSubscription = accountQuery?.account?.subscription?.currentSubscription
  // User who don't have subscription or on basic plan will have the profile limitation
  const isProfileAllowBasicUpsell = profiles?.length >= profileLimit && (!currentSubscription || currentSubscription?.sku === getPlanDetails?.basic.sku)

  return { profiles, profileLimit, isProfileAllowBasicUpsell }
}
