import React from 'react'
import PropTypes from 'prop-types'

const CloseIcon = props => {
  const { color } = props
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={color}
        opacity="0.998"
        d="M19.4501 0.565078C18.8651 -0.0199215 17.9201 -0.0199215 17.3351 0.565078L10.0001 7.88508L2.66508 0.550078C2.08008 -0.0349218 1.13508 -0.0349218 0.550078 0.550078C-0.0349218 1.13508 -0.0349218 2.08008 0.550078 2.66508L7.88508 10.0001L0.550078 17.3351C-0.0349218 17.9201 -0.0349218 18.8651 0.550078 19.4501C1.13508 20.0351 2.08008 20.0351 2.66508 19.4501L10.0001 12.1151L17.3351 19.4501C17.9201 20.0351 18.8651 20.0351 19.4501 19.4501C20.0351 18.8651 20.0351 17.9201 19.4501 17.3351L12.1151 10.0001L19.4501 2.66508C20.0201 2.09508 20.0201 1.13508 19.4501 0.565078Z"
      />
    </svg>
  )
}

export default CloseIcon

CloseIcon.propTypes = {
  color: PropTypes.string.isRequired
}

CloseIcon.defaultProps = {
  color: 'white'
}
