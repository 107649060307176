import { connect } from 'react-redux'
import { compose } from 'recompose'
import { prop } from 'ramda'

const getCastSender = prop('castSender')

// Higher order component to manage loading screen on mutations
export default compose(
  connect(
    state => ({
      castSender: getCastSender(state) // used to skip account query
    })
  )
)
