import React from 'react'
import PropTypes from 'prop-types'

import Imgix from '../imgix'
import neonLogo from '../../../images/neon/neon-logo-hoz-wht-rgb.svg'
import styles from './maintenance.css'

function Maintenance({ background, message }) {
  const logoSrc = neonLogo

  return (
    <div className={styles.maintenanceWrapper}>
      <Imgix className={styles.imgixBackground} src={background} background />

      <div className={styles.maintenance}>
        <img className={styles.logo} src={logoSrc} />

        <h1 className={styles.title}>
          Under Maintenance
        </h1>

        <p className={styles.message}>{message}</p>
      </div>
    </div>
  )
}

Maintenance.propTypes = {
  background: PropTypes.string,
  message: PropTypes.string
}

Maintenance.defaultProps = {
  background: '',
  message: ''
}

export default Maintenance
