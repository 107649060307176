/* eslint no-return-assign: 0 */
import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import { compose, withStateHandlers, mapProps } from 'recompose'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import CATEGORY_COMPONENT_ITEM_FRAGMENT from '../../../graphql/fragments/category.gql'
import { useRentalPageDetector } from '../../hooks/use-rental-page-detector'

import styles from './category-navigation-tabs.css'

const SELECT_VALUE_KEY = 'name'

const CategoryNavTabs = ({
  tabs,
  selectedTab,
  prefetchScreen,
  onTotalTabWidths,
  onSelectionChange
}) => {
  const tabReferences = useRef({})
  const isOnARentalThemedPage = useRentalPageDetector()

  const selectedIndex = tabs.indexOf(selectedTab)

  const getTotalTabWidths = () => {
    let total = 0

    tabs.forEach(tab => {
      const tabRef =
        tabReferences.current && tabReferences.current[`tab-${tab.id}`]
      if (!tabRef) return

      const tabWidth = tabRef.getBoundingClientRect().width
      total += Math.floor(tabWidth)
    })

    return total
  }

  useEffect(() => {
    const totalTabWidths = getTotalTabWidths()
    onTotalTabWidths(totalTabWidths)
  }, [])

  return (
    <div className={styles.wrapper}>
      {tabs.map((tab, i) => {
        return (
          <span
            key={tab.id}
            className={styles.category}
            onClick={onSelectionChange}
            ref={ref => (tabReferences.current[`tab-${tab.id}`] = ref)}
          >
            <Link
              className={classNames(styles.tab, {
                [styles.selected]: selectedIndex === i,
                [styles.isRental]: isOnARentalThemedPage
              })}
              to={tab.path || '/404'}
              onMouseOver={() => prefetchScreen(tab)}
            >
              {tab[SELECT_VALUE_KEY]}
            </Link>
          </span>
        )
      })}
    </div>
  )
}

CategoryNavTabs.propTypes = {
  tabs: PropTypes.arrayOf(propType(CATEGORY_COMPONENT_ITEM_FRAGMENT))
    .isRequired,
  selectedTab: propType(CATEGORY_COMPONENT_ITEM_FRAGMENT).isRequired,
  prefetchScreen: PropTypes.func.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  onTotalTabWidths: PropTypes.func.isRequired
}

function initialTabsState({ selectedOption }) {
  return {
    selectedOption
  }
}

const enhanced = compose(
  mapProps(
    ({
      categoryItems,
      selectedCategoryIdx,
      prefetchScreen,
      onTotalTabWidths
    }) => {
      const selectedTab = categoryItems[selectedCategoryIdx]
      return {
        tabs: categoryItems,
        selectedTab,
        prefetchScreen,
        onTotalTabWidths
      }
    }
  ),
  withStateHandlers(initialTabsState, {
    onSelectionChange: () => selectedTab => {
      return {
        selectedTab
      }
    }
  })
)(CategoryNavTabs)

export default enhanced
