import { init } from '@elastic/apm-rum'

/**
 * https://www.elastic.co/guide/en/apm/agent/rum-js/current/agent-api.html#apm-init
 */
const apmActive = Math.random() < parseFloat(process.env.APP_ELASTIC_APM_SAMPLE_RATE) / 100
const apm = init({
  serviceName: 'Neon-Desktop',
  environment: process.env.APP_ELASTIC_APM_SERVICE_ENV,
  serverUrl: process.env.APP_ELASTIC_APM_SERVER_URL,
  active: apmActive, // we only sample a port of the sessions to avoid overloading the server
  transactionSampleRate: 0.2, // this is transaction and span level rate: https://www.elastic.co/guide/en/apm/agent/rum-js/current/performance-tuning.html#performance-sampling
  ignoreTransactions: [
    /\/static\/.*/,
    /lbdash[.]akamaized[.]net\/.*/ // not instrumenting the streaming segment req for now
  ]
})

export default apm
