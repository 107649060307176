import React, { createContext, useReducer } from 'react'

/**
 * A hooks to detect not found page been rendered
 * @returns {boolean} isNotFoundRendered
 * @returns {action} dispatch
 */
export const UPDATE = 'UPDATE'

const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE:
      return {
        ...state,
        isNotFoundRendered: action.payload
      }
    default:
      return state
  }
}

const initialState = { isNotFoundRendered: false }

export const UseNotFoundContext = createContext(initialState)

const UseNotFoundProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <UseNotFoundContext.Provider value={[state, dispatch]}>
      {children}
    </UseNotFoundContext.Provider>
  )
}

export default UseNotFoundProvider
