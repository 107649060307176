import { curry, over, lensPath } from 'ramda'

import mergeRight from './mergeRight'

/*
 * Stolen from https://char0n.github.io/ramda-adjunct/2.1.0/mergePath.js.html
 * Trying so hard to avoid bringing in the whole library :)
 *
 * Creates a new object with the own properties of the object under the `path`,
 * merged with the own properties of the provided `source`.
 * If a key exists in both objects, the value from `source` is used.
 *
 * mergePath(
 *   ['outer, 'inner'],
 *   { foo: 3, bar: 4},
 *   { outer: { inner: { foo: 2 } } }
 * ); //=> { outer: inner: { foo: 3, bar: 4 } }
 */

const mergePath = curry((path, source, obj) => over(lensPath(path), mergeRight(source), obj))

export default mergePath
