import ActionTypes from '../actions/types'

const dismissed = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.SERVICE_MESSAGES_DISMISS:
      return {
        ...state,
        [action.serviceMessage.id]: action.serviceMessage
      }
    default:
      return state
  }
}

const serviceMessages = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.SERVICE_MESSAGES_DISMISS:
      return {
        ...state,
        dismissed: dismissed(state.dismissed, action)
      }
    default:
      return state
  }
}

export default serviceMessages
