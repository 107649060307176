export const isRelativeUrl = (urlString = '') => {
  // return true if it is a relative path e.g. /pages/terms
  return urlString.startsWith('/')
}

export const isValidUrl = (urlString = '') => {
  try {
    return Boolean(new URL(urlString))
  } catch {
    return false
  }
}
