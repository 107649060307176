import { createUseStyles } from 'react-jss'
import { commonStyles } from '../../../shared/common.styles'

export const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),
  tableRowColumn: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center'
  },

  activeSubscription: {
    color: theme.neonGreen
  },

  button: {
    zIndex: theme.zIndexCardBadge,
    position: 'relative',
    padding: '10px',
    height: 60,
    width: '100%',
    marginTop: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 22,
    [theme.media.smallMobile]: {
      fontSize: 16
    }
  },

  hoverButton: {
    color: theme.black,
    backgroundColor: theme.neonGreen,
    '&::after': {
      content: "''",
      position: 'absolute',
      top: '99%',
      left: 0,
      right: 0,
      width: 0,
      height: 0,
      margin: '0 auto',
      borderTop: ['solid', 12, theme.neonGreen],
      borderLeft: 'solid 18px transparent',
      borderRight: 'solid 18px transparent'
    }
  },

  priceContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 10
  },

  priceContentDisabled: {
    color: '#a0a0a0',
    width: '100%',
    backgroundColor: theme.greyHighlight,
    height: 200,
    marginTop: -10,
    [theme.media.tablet]: {
      height: 120
    },
    [theme.media.mobile]: {
      height: 110
    },
    [theme.media.smallMobile]: {
      height: 100
    }
  },

  priceContentWithSubscription: {
    marginTop: -10
  },

  priceAmountDisabled: {
    paddingTop: 5
  },

  priceContentDescription: {
    color: 'rgba(255, 255, 255, 0.9)',
    fontSize: 16,
    textAlign: 'center',
    paddingTop: 15,
    [theme.media.smallMobile]: {
      fontSize: 12
    }
  },

  priceAmount: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'GothamRounded'
  },

  priceAmountRightFrame: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.media.smallMobile]: {
      justifyContent: 'flex-start'
    }
  },

  priceAmountUnit: {
    fontSize: 12,
    fontWeight: 325,
    lineHeight: '15px',
    [theme.media.smallMobile]: {
      fontSize: 10
    }
  },

  priceAmountBeforeDot: {
    fontSize: 56,
    fontWeight: 500,
    lineHeight: '46px',
    paddingRight: 2,
    [theme.media.mobile]: {
      fontSize: 46
    },
    [theme.media.smallMobile]: {
      fontSize: 38
    }
  },

  priceAmountAfterDot: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '24px',
    [theme.media.mobile]: {
      fontSize: 18,
      lineHeight: '26px'
    },
    [theme.media.smallMobile]: {
      fontSize: 16,
      lineHeight: '28px'
    }
  },

  currency: {
    fontSize: 20,
    lineHeight: '24px',
    fontWeight: 400,
    [theme.media.mobile]: {
      fontSize: 18,
      lineHeight: '30px'
    },
    [theme.media.smallMobile]: {
      fontSize: 16,
      lineHeight: '36px'
    }
  },

  planTitle: {
    marginTop: 10,
    fontSize: 16,
    color: theme.white,
    height: 25,
    [theme.media.mobile]: {
      fontSize: 14,
      height: 20
    },
    [theme.media.smallMobile]: {
      fontSize: 14,
      height: 18
    }
  },

  planFooterTitle: {
    fontSize: 16,
    [theme.media.mobile]: {
      fontSize: 12,
      height: 40
    },
    [theme.media.smallMobile]: {
      fontSize: 8,
      height: 30
    }
  },

  originalPrice: {
    color: theme.neonGreen,
    fontSize: 14,
    textDecoration: 'line-through 2px', // for Chrome and Edge
    '-webkit-text-decoration-line': 'line-through', // for Safari
    whiteSpace: 'break-spaces',
    marginTop: 10,
    [theme.media.mobile]: {
      marginTop: 8
    },
    [theme.media.smallMobile]: {
      marginTop: 5
    }
  },

  displayForWebView: {
    display: 'none',
    [theme.media.desktop]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center'
    }
  }
}))
