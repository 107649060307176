import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import ClickOutside from 'react-click-outside'

import crossIcon from '../../../images/cross.svg'
import RippleIcon from '../ripple-icon/ripple-icon'
import styles from './bottom-sheet.css'
import Portal from '../portal'

/**
 * A component has slide up effect
 */
const BottomSheet = ({
  isOpen,
  onClickOutside,
  children,
  onClose
}) => {
  const classes = classNames(styles.content, {
    [styles.close]: !isOpen
  })

  const clickOutsideHandler = () => {
    if (isOpen) {
      onClickOutside()
    }
  }

  const containerClasses = classNames(styles.container, {
    [styles.hide]: !isOpen
  })

  return (
    <Portal>
      <div className={containerClasses}>
        <ClickOutside onClickOutside={clickOutsideHandler} className={classes}>
          <RippleIcon
            icon={crossIcon}
            className={styles.closeIcon}
            svgClassName={styles.svgStyles}
            onClick={onClose}
          />
          {children}
        </ClickOutside>
      </div>
    </Portal>
  )
}

BottomSheet.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onClickOutside: PropTypes.func
}

BottomSheet.defaultProps = {
  isOpen: false
}

export default BottomSheet
