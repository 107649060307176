import throttle from 'lodash.throttle'
import {
  compose,
  lifecycle,
  withHandlers,
  withState
} from 'recompose'

export const withScreenWidthWatching = compose(
  withState('screenWidth', 'setScreenWidth', window.innerWidth),
  withHandlers({
    updateScreenWidth: ({ setScreenWidth }) => throttle(() => {
      setScreenWidth(window.innerWidth)
    })
  }, 100),
  lifecycle({
    UNSAFE_componentWillMount() {
      this.props.updateScreenWidth()
    },
    componentDidMount() {
      window.addEventListener('resize', this.props.updateScreenWidth)
    },
    componentWillUnmount() {
      window.removeEventListener('resize', this.props.updateScreenWidth)
    }
  })
)
