import qs from 'query-string'
import { pathOr } from 'ramda'
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import CONTENT_KEYART_QUERY from '../../graphql/queries/content-keyart.gql'

export const useContentKeyartReplacement = (client, defaultImage) => {
  const [background, setBackground] = useState('')
  const { pathname, search } = useLocation()
  const loadingDockId = qs.parse(search).utm_ldid

  useEffect(() => {
    if (pathname !== '/' || !loadingDockId) {
      setBackground(defaultImage)
      return
    }

    const loadFromUtm = async () => {
      try {
        const response = await client.query({
          query: CONTENT_KEYART_QUERY,
          variables: {
            ldid: loadingDockId
          }
        })

        const contentKeyart = pathOr(defaultImage, [
          'data',
          'contentItem',
          'keyart',
          'uri'
        ])(response)

        setBackground(contentKeyart)
      } catch (err) {
        // Swallow
        setBackground(defaultImage)
      }
    }

    loadFromUtm()
  }, [])

  return background
}
