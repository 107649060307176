import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Modal from '../../modal'

import styles from './confirmation-modal.css'

function ConfirmationModal({
  header,
  message,
  confirmText,
  cancelText,
  onConfirmClick,
  onCancelClick,
  customWrapperClass
}) {
  const hasCancelButton = (cancelText !== '')
  const isNotificationModal = true

  return (
    <Modal
      isNotificationModal={isNotificationModal}
    >
      <div className={classNames(styles.confirmationWrapper, customWrapperClass)}>
        <div className={styles.header}>
          <h1 className={styles.title}>{header}</h1>
          {
            message && (
              <span className={styles.subHeader}>
                {message}
              </span>
            )
          }
        </div>
        <div className={styles.buttonsWrapper}>
          <button
            onClick={onConfirmClick}
            className={
              classNames(
                styles.confirmButton,
                {
                  [styles.large]: !hasCancelButton
                }
              )
            }
          >
            {confirmText}
          </button>
          {
            hasCancelButton && (
              <button
                onClick={onCancelClick}
                className={styles.cancelButton}
              >
                {cancelText}
              </button>
            )
          }
        </div>
      </div>
    </Modal>
  )
}

ConfirmationModal.propTypes = {
  header: PropTypes.string.isRequired,
  message: PropTypes.string,
  confirmText: PropTypes.string.isRequired,
  cancelText: PropTypes.string,
  onConfirmClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func,
  customWrapperClass: PropTypes.string
}

ConfirmationModal.defaultProps = {
  message: '',
  cancelText: '',
  onCancelClick: null,
  customWrapperClass: ''
}

export default ConfirmationModal
