import JwtDecode from 'jwt-decode'

const setFrameVisible = iframe => {
  if (iframe.style.visibility !== 'visible') iframe.style.visibility = 'visible'
}

const setFrameInvisible = iframe => {
  if (iframe.style.visibility !== 'hidden') iframe.style.visibility = 'hidden'
}

const handleFreshchatVisibility = (
  location
) => {
  const freshChatIframe = document.getElementById('fc_frame')
  const token = localStorage.getItem('token')
  const session = token && JwtDecode(token)

  toggleFreshchatVisibility(freshChatIframe, location, session)
}

export const toggleFreshchatVisibility = (
  freshChatIframe,
  location,
  session
) => {
  if (!freshChatIframe) return

  if (
    location.pathname.startsWith('/my-account') ||
    location.pathname.startsWith('/signup') ||
    (session && !session.isKid && location.pathname === '/') ||
    (!session && !location.pathname.startsWith('/trailer'))
  ) {
    setFrameVisible(freshChatIframe)
    return
  }

  setFrameInvisible(freshChatIframe)
}

const configFreshChat = history => {
  // history.listen will not be triggered after refresh, so check toggleFreshchatVisibility again
  setTimeout(() => {
    handleFreshchatVisibility(history.location)
  }, 2000)

  const unlistenFreshchat = history.listen(location => {
    // User cannot get token immediately after first time login in
    setTimeout(() => {
      handleFreshchatVisibility(location)
    }, 1000)
  })

  return () => {
    unlistenFreshchat()
  }
}

export default configFreshChat
