import {
  applySpec,
  evolve,
  find,
  pathEq,
  pipe,
  prop,
  propOr,
  whereEq,
  curryN
} from 'ramda'

import { CONFIG } from '../constants'
import { parseDecimalInt } from './utils'

/**
 * findValue walks through the CONFIG provided by middleware
 * until it finds the key requested. It returns the
 * associated value.
 * @param {string} key
 * @param {string} type
 */
export const findValue = (key, type) => pipe(
  find(
    whereEq({
      key,
      type
    })
  ),
  prop('value')
)

/**
 * Returns the latest Premium Introduction Version
 * This enables bumping the version through the admin console
 * and causing the modal to appear for those who'd seen it before.
 *
 * @param  {Config} config
 * @return {Number}
 */
const findVersion = findValue(
  CONFIG.premiumIntroductionVersion,
  'INTEGER'
)

const findEnabled = pipe(
  findValue(
    CONFIG.premiumIntroductionEnabled,
    'BOOLEAN'
  ),
  // These booleans are serialized as strings
  // from the middleware; convert them to actual
  // booleans with the following
  value => value === 'true'
)

const transformations = {
  enabled: findEnabled,
  version: findVersion
}

export const findPremiumIntroductionVersion = pipe(
  prop('values'),
  applySpec(transformations),
  evolve({
    version: parseDecimalInt
  })
)

// Used in conjunction with the appConfig data payload
export const findConfigValue = curryN(2, (configKey, appConfig) => pipe(
  propOr([], 'values'),
  find(pathEq(['key'], configKey)),
  propOr('', 'value')
)(appConfig))

export const findAndroidAppStoreLink = findConfigValue(CONFIG.androidAppStoreLink)
export const findAndroidAppStoreIcon = findConfigValue(CONFIG.androidAppStoreIcon)
export const findiOSAppStoreLink = findConfigValue(CONFIG.iOSAppStoreLink)
export const findiOSAppStoreIcon = findConfigValue(CONFIG.iOSAppStoreIcon)
