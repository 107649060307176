import React from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import classNames from 'classnames'
import { withRouter } from 'react-router-dom'
import { compose, withState, withHandlers } from 'recompose'

import styles from './modal.css'

// This modal should only be used to render
// a modal without changing location

// TODO: LBXW-457
// Refactor so this modal uses the same
// styling and dom structure as the layouts/shared/modal
function Modal({
  isOpen, children, onRequestClose, isNotificationModal, ...props
}) {
  return (
    <ReactModal
      isOpen={isOpen}
      className={{
        base: classNames({
          [styles.modal]: true,
          [styles.notificationModal]: isNotificationModal
        }),
        afterOpen: styles.modalAfterOpen,
        beforeClose: styles.modalBeforeClose
      }}
      overlayClassName={{
        base: styles.overlay,
        afterOpen: styles.overlayAfterOpen,
        beforeClose: styles.overlayBeforeClose
      }}
      shouldCloseOnOverlayClick={false}
      onRequestClose={onRequestClose}
      contentLabel="Modal"
      {...props}
    >
      {children}
    </ReactModal>
  )
}

Modal.propTypes = {
  children: PropTypes.element.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  modalClass: PropTypes.string,
  noRouting: PropTypes.bool,
  isNotificationModal: PropTypes.bool
}

Modal.defaultProps = {
  modalClass: '',
  noRouting: false,
  isNotificationModal: false
}

const enhance = compose(
  withState('isOpen', 'setIsOpen', true),
  withHandlers({
    onRequestClose: ({
      noRouting,
      onDismiss,
      setIsOpen,
      history,
      previousLocation,
      onCustomClose
    }) => () => {
      setIsOpen(false)
      if (onCustomClose) {
        onCustomClose()
        return
      }
      if (onDismiss) {
        onDismiss()
      }
      if (noRouting) {
        return
      }

      if (previousLocation) {
        history.goBack()
      } else {
        history.push('/')
      }
    }
  })
)

export default withRouter(enhance(Modal))
