import * as Fingerprint2 from 'fingerprintjs2'
import {
  compose, branch, renderNothing, withState, lifecycle
} from 'recompose'

import { isIE } from '../lib/utils'

const FP_OPTIONS = {
  excludeJsFonts: true,
  excludeAdBlock: true,
  excludeDoNotTrack: true,
  excludeIndexedDB: true
}

// IE only
if (isIE()) {
  FP_OPTIONS.excludeCanvas = true
  FP_OPTIONS.excludeWebGL = true
}

/**
 * A higher-order component that's used to async
 * hash a configurable device fingerprint on the client.
 * This is primarily for generating our device uuid to be sent
 * with the config query.
 *
 * Future Reference:
 * To debug any uuid discrepancies on certain platforms,
 * just compare the json diff of fp.get's second arg (components)
 * to figure out which config option will need to be excluded for
 * the device in question.
 */
const withDeviceFingerprint = compose(
  withState('deviceFingerprint', 'setDeviceFingerPrint', localStorage.getItem('deviceUUID')),
  lifecycle({
    componentDidMount() {
      const { setDeviceFingerPrint, deviceFingerprint } = this.props
      if (deviceFingerprint) {
        return
      }
      Fingerprint2.get(FP_OPTIONS, (results) => {
        const values = results.map(result => result.value)
        const hashedResult = Fingerprint2.x64hash128(values.join(''), 31)
        localStorage.setItem('deviceUUID', hashedResult)
        setDeviceFingerPrint(hashedResult)
      })
    }
  }),
  branch(
    ({ deviceFingerprint }) => !deviceFingerprint,
    renderNothing
  )
)

export default withDeviceFingerprint
