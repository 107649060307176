import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import { NavLink } from 'react-router-dom'
import ClickOutside from 'react-click-outside'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'

import { getModalLocation } from '../../lib/modal'
import { ACCOUNT_SCREENS, MODALS, STATIC_SCREENS } from '../../constants'
import { TESTERS } from '../../lib/tester-classnames'
import NavLinkWithQuery from '../../components/shared/nav-link-with-query'
import styles from './navigation-account.css'
import { showPlanUpgradePopup } from '../../actions'

import PROFILE_FRAGMENT from '../../../graphql/fragments/profile.gql'
import { useProfileIsDisabled } from '../../hooks/useProfileIsDisabled'
import { E2E_SELECTORS } from '../../e2e-selectors'

function NavigationAccount({
  onAccountNavClick,
  onLogoutClick,
  profiles,
  display,
  onSelectProfile,
  handleClickOutside,
  location,
  myListTotalItems,
  myRentalsTotalItems,
  helpURLConfig,
  isProfileAllowBasicUpsell,
  profileLimit,
  email,
  cpId
}) {
  const outsideClick = display ? handleClickOutside : () => {}
  const dispatch = useDispatch()
  const showUpgradePopup = () => {
    dispatch(showPlanUpgradePopup())
  }

  const onProfileClick = (isProfileDisabled, profile) => {
    if (isProfileDisabled) {
      showUpgradePopup()
    } else {
      onAccountNavClick()
      onSelectProfile(profile)
    }
  }

  return (
    <ClickOutside onClickOutside={outsideClick}>
      <nav
        className={classNames(styles.accountNavWrapper, {
          [styles.hidden]: !display
        })}
      >
        <div className={styles.buttonPlaceholder} />
        <ul className={styles.accountLinks}>
          <li>
            <NavLink
              exact
              to={STATIC_SCREENS.MY_LIST.screenId}
              className={styles.accountLink}
              onClick={onAccountNavClick}
              data-e2e={E2E_SELECTORS.ADULT_AVATAR_MENU_MY_LIST}
            >
              My List
              {myListTotalItems > 0 && <span className={styles.total}>{`(${myListTotalItems})`}</span>}
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              to={STATIC_SCREENS.MY_RENTALS.screenId}
              className={styles.accountLink}
              onClick={onAccountNavClick}
              data-e2e={E2E_SELECTORS.ADULT_AVATAR_MENU_MY_RENTALS}
            >
              My Rentals
              {myRentalsTotalItems > 0 && <span className={styles.total}>{`(${myRentalsTotalItems})`}</span>}
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              to={ACCOUNT_SCREENS.MY_ACCOUNT}
              className={styles.accountLink}
              onClick={onAccountNavClick}
              data-e2e={E2E_SELECTORS.MY_ACCOUNT_IN_ACCOUNT_MENU}
            >
              My Account
            </NavLink>
          </li>
          <li>
            <NavLinkWithQuery
              exact
              to={getModalLocation(location, MODALS.qsParams.profilesManagement)}
              onClick={onAccountNavClick}
              className={styles.accountLink}
              data-e2e={E2E_SELECTORS.MANAGE_PROFILE_IN_ACCOUNT_MENU}
            >
              Manage profiles
            </NavLinkWithQuery>
          </li>
          <li>
            <a
              href={helpURLConfig}
              onClick={onAccountNavClick}
              className={styles.accountLink}
              data-e2e={E2E_SELECTORS.HELP_IN_ACCOUNT_MENU}
            >
              Help
            </a>
          </li>
          <li>
            <div // eslint-disable-line jsx-a11y/no-static-element-interactions
              className={styles.accountLink}
              onClick={() => {
                onAccountNavClick()
                onLogoutClick(email)
              }}
              data-e2e={E2E_SELECTORS.LOGOUT_IN_ACCOUNT_MENU}
            >
              Log out
            </div>
          </li>
          <li>
            <NavLinkWithQuery
              exact
              to={getModalLocation(location, MODALS.qsParams.profilesSwitch)}
              onClick={onAccountNavClick}
              className={classNames(styles.accountLink, styles.switchProfile)}
            >
              Switch profile
            </NavLinkWithQuery>
          </li>
        </ul>
        {
          profiles && profiles.map((profile, index) => {
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            // The active profile is not been accounted, so profileLimit - 1
            const isProfileDisabled = useProfileIsDisabled(isProfileAllowBasicUpsell, profileLimit - 1, index)
            return (
              <div
                className={classNames(TESTERS.NAVIGATION.PROFILE, styles.profile)}
                key={profile.id}
                onClick={() => onProfileClick(isProfileDisabled, profile)}
              >
                <img alt="Avatar" src={isProfileDisabled ? profile.avatar.uriForDisabledProfile : profile.avatar.uri} className={styles.avatar} />
                <span className={styles.userName} data-lbx-e2e={profile.name}>{profile.name}</span>
              </div>
            )
          })
        }
        <div className={styles.menuBackground} />
      </nav>
    </ClickOutside>
  )
}

NavigationAccount.propTypes = {
  display: PropTypes.bool.isRequired,
  onAccountNavClick: PropTypes.func.isRequired,
  handleClickOutside: PropTypes.func.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
  onSelectProfile: PropTypes.func.isRequired,
  profiles: PropTypes.arrayOf(propType(PROFILE_FRAGMENT)),
  myListTotalItems: PropTypes.number.isRequired,
  myRentalsTotalItems: PropTypes.number.isRequired,
  helpURLConfig: PropTypes.string.isRequired,
  isProfileAllowBasicUpsell: PropTypes.bool,
  profileLimit: PropTypes.number,
  email: PropTypes.string,
  cpId: PropTypes.string

}

NavigationAccount.defaultProps = {
  userName: '',
  profiles: [],
  currentProfile: null,
  isProfileAllowBasicUpsell: false
}

export default NavigationAccount
