import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import NavigationBurgerIcon from '../../layouts/shared-navigation/navigation-burger-icon'
import { toggleNavigationVisibility } from '../../actions'

function NavigationBurgerIconContainer(props) {
  return (
    <NavigationBurgerIcon
      {...props}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme
  }
}

const enhance = compose(
  connect(
    mapStateToProps,
    dispatch => ({
      onClick: () => {
        dispatch(toggleNavigationVisibility())
      }
    })
  )
)

export default enhance(NavigationBurgerIconContainer)
