import { useEffect, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import MY_LIST_QUERY from './gql/my-list.gql'
import ADD_TO_MY_LIST_MUTATION from '../../../../graphql/mutations/add-to-my-list.gql'
import REMOVE_FROM_MY_LIST_MUTATION from '../../../../graphql/mutations/remove-from-my-list.gql'
import {
  episodeHouseId,
  myListGa,
  seasonHouseId,
  seriesTitleHouseId
} from '../../../lib/analytics/ga'
import {
  customDimensions,
  getCategoryNameFromContentItem
} from '../../../lib/analytics/custom-dimensions'
import {
  getAdditionalGenreFromContentItem
} from '../../../lib/analytics/datalayer'
import { useProfile } from '../../../hooks/use-profile'
import { store } from '../../../store'
import { checkIsAuthenticated } from '../../../lib/auth'

/**
 * A hook to add/remove selected content item
 */
export const useMyList = () => {
  const [fetchMyList, { data, loading, error }] = useLazyQuery(MY_LIST_QUERY)

  const [
    addToList,
    { data: addingListData, loading: addingListLoading, error: addingListError }
  ] = useMutation(ADD_TO_MY_LIST_MUTATION, {
    refetchQueries: [
      {
        query: MY_LIST_QUERY
      }
    ]
  })

  const [
    removeFromList,
    {
      data: removingListData,
      loading: removingListLoading,
      error: removingListError
    }
  ] = useMutation(REMOVE_FROM_MY_LIST_MUTATION, {
    refetchQueries: [
      {
        query: MY_LIST_QUERY
      }
    ]
  })

  const state = store.getState()
  const isAuthenticated = checkIsAuthenticated(state)

  const [allItems, setAllItems] = useState([])

  const { currentProfile } = useProfile('cache-only')

  // Getting all items in my list
  useEffect(() => {
    if (isAuthenticated && !loading && data) {
      setAllItems(data.mylist.items)
    }
  }, [data, error, loading])

  // Update all items when remove & add items action perform
  useEffect(() => {
    if (isAuthenticated && (!addingListLoading || !removingListLoading)) {
      fetchMyList()
    }
  }, [
    addingListData,
    addingListLoading,
    addingListError,
    removingListData,
    removingListLoading,
    removingListError
  ])

  const addToMyList = (renderedInComponent) => {
    return (contentItem) => {
      addToList({
        variables: {
          contentItemId: contentItem.id
        }
      })
      myListGa({
        label: contentItem.title,
        action: 'Add',
        [customDimensions.CategoryName]: getCategoryNameFromContentItem(contentItem),
        [customDimensions.ProfileId]: currentProfile.id,
        [customDimensions.ProfileName]: currentProfile.name,
        [customDimensions.ShowTitle]: contentItem.title,
        [customDimensions.AdditionalGenre]: getAdditionalGenreFromContentItem(
          contentItem
        ),
        [customDimensions.SeriesTitleHouseId]: seriesTitleHouseId(contentItem),
        [customDimensions.SeasonHouseId]: seasonHouseId(contentItem),
        [customDimensions.EpisodeHouseId]: episodeHouseId(contentItem),
        [customDimensions.ComponentName]: renderedInComponent
      })
    }
  }

  const removeFromMyList = (renderedInComponent) => {
    return (contentItem) => {
      removeFromList({
        variables: {
          contentItemId: contentItem.id
        }
      })
      myListGa({
        label: contentItem.title,
        action: 'Remove',
        [customDimensions.CategoryName]: getCategoryNameFromContentItem(contentItem),
        [customDimensions.ProfileId]: currentProfile.id,
        [customDimensions.ProfileName]: currentProfile.name,
        [customDimensions.ShowTitle]: contentItem.title,
        [customDimensions.AdditionalGenre]: getAdditionalGenreFromContentItem(
          contentItem
        ),
        [customDimensions.SeriesTitleHouseId]: seriesTitleHouseId(contentItem),
        [customDimensions.SeasonHouseId]: seasonHouseId(contentItem),
        [customDimensions.EpisodeHouseId]: episodeHouseId(contentItem),
        [customDimensions.ComponentName]: renderedInComponent
      })
    }
  }

  return {
    allItems,
    addToMyList,
    removeFromMyList
  }
}
