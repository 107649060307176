import React, { Suspense, useEffect } from 'react'
import { ThemeProvider } from 'react-jss'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import NavigationHeader from '../../containers/navigation/navigation-header'
import PinCheck from '../../containers/pin/pin-check'
import LoadingOverlay from '../../containers/loading-overlay'
import NavigationSide from '../../containers/navigation/navigation-side'
import NavigationWrapper from '../../containers/navigation/navigation-wrapper'
import AsyncNavigationFooter from '../../containers/navigation/async-navigation-footer'
import darkTheme from './main-layout-dark.css'
import { setTheme } from '../../actions/index'
import { THEME_OPTIONS } from '../../constants'
import useSearchParams from '../hooks/useSearchParams'
import UseNotFoundProvider from '../../components/not-found/hooks/use-not-found-context'
import ContinuityProvider from '../../modules/continuity/hooks/continuity-context'
import mainTheme from '../../theme'
import { PagedSearchResult } from '../../modules/search/components/paged-search-result/paged-search-result'
import { segmentTracKHomepageLand } from '../../segment/segment-track'

const AbandonedSignupBanner = React.lazy(() => import('../../modules/signup/components/abandoned-signup-banner'))

const MainLayout = ({ children, history, isAuthenticated }) => {
  const { hasSearchParams } = useSearchParams()
  const { theme } = useSelector(state => state.theme)
  const dispatch = useDispatch()
  const styles = darkTheme

  useEffect(() => {
    dispatch(setTheme(THEME_OPTIONS.dark))
  }, [])

  useEffect(() => {
    if (history.location?.pathname === '/' && isAuthenticated) {
      // Add segment data analytics for landing home page
      segmentTracKHomepageLand()
    }
  }, [history.location?.pathname])

  return (
    <ThemeProvider theme={mainTheme}>
      <UseNotFoundProvider>
        <div className={`${theme} ${styles.layout}`}>
          <Suspense fallback="">
            <AbandonedSignupBanner />
          </Suspense>
          <NavigationHeader />
          <NavigationSide />
          <PinCheck />
          <LoadingOverlay />
          <NavigationWrapper>
            <ContinuityProvider>
              <main className={styles.content}>
                {hasSearchParams ? (
                  <PagedSearchResult />
                ) : (
                  <>{children}</>
                )}
              </main>
            </ContinuityProvider>
          </NavigationWrapper>
          <AsyncNavigationFooter
            className={styles.footer}
            location={history.location}
          />
        </div>
      </UseNotFoundProvider>
    </ThemeProvider>
  )
}

MainLayout.propTypes = {
  children: PropTypes.element,
  isAuthenticated: PropTypes.bool.isRequired
}

MainLayout.defaultProps = {
  children: null
}

export default MainLayout
