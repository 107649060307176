import {
  assoc,
  complement,
  curry,
  curryN,
  defaultTo,
  identity,
  isNil,
  lens,
  over,
  view
} from 'ramda'

import mergePath from './mergePath'
import mergeProp from './mergeProp'
import mergeRight from './mergeRight'
import lensIso from './lensIso'

/**
 * Works like `propOr` for the Ramda `view` function.
 * Accepts a defaultValue, a lens, and the data to be
 * evaluated. Returns the the focused value if available,
 * otherwise the default.
 */
export const viewOr = curryN(
  3,
  (defaultValue, targetLens, data) => defaultTo(defaultValue, view(targetLens, data))
)

export const assocFn = curry((prop, fn) => over(
  lens(
    fn,
    assoc(prop)
  ),
  identity
))

export const isNotNil = complement(isNil)

export {
  lensIso,
  mergePath,
  mergeProp,
  mergeRight
}
