import React from 'react'
import ReactSVG from 'react-svg'
import plusIcon from '../../../../../images/plus.svg'
import styles from '../navigation-side.css'

const MyListIcon = () => {
  return (
    <div className={styles.myListIcon}>
      <ReactSVG
        src={plusIcon}
        className={styles.svgWrapper}
      />
    </div>
  )
}

export default MyListIcon
