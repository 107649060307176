import React from 'react'

import classnames from 'classnames'
import { getContext } from 'recompose'
import PT from 'prop-types'

import styles from './grid.css'

function Grid(props) {
  const { children, slidesToShow, margin } = props
  const containerWidth = `calc(${100 / slidesToShow}% - ${margin /
    slidesToShow}px)`

  function wrapChildren(innerChildren, key) {
    const updated = React.Children.map(innerChildren, child => {
      return React.cloneElement(child, {
        className: classnames(styles.itemStyle, child.props.className)
      })
    })
    return (
      <div
        key={key}
        className={styles.itemWrapper}
        style={{ flexBasis: containerWidth }}
      >
        {updated}
      </div>
    )
  }

  return (
    <div
      className={styles.wrapper}
      style={{
        paddingLeft: margin
      }}
    >
      {children.map(wrapChildren)}
    </div>
  )
}

Grid.propTypes = {
  slidesToShow: PT.number.isRequired,
  margin: PT.number.isRequired
}

const enhance = getContext({
  slidesToShow: PT.number.isRequired,
  duration: PT.number.isRequired,
  offset: PT.number.isRequired
})

export default enhance(Grid)
