import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

export const useIsKid = () => {
  const mapState = useSelector(state => state)
  const { session } = mapState
  let isKid = session?.decodedJwt?.isKid || false

  const location = useLocation()
  if (location?.pathname === '/kids') {
    isKid = true
  }

  return isKid
}
