import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  compose,
  renderComponent
} from 'recompose'

import PromotionalGrid from '../../components/promotional-grid'
import { checkIsAuthenticated } from '../../lib/auth'

const enhance = compose(
  withRouter,
  connect(state => ({
    isAuthenticated: checkIsAuthenticated(state)
  })),
  renderComponent(PromotionalGrid)
)

export default enhance()
