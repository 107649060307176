import {
  path,
  pathOr
} from 'ramda'

import { MODALS } from '../../constants'
import { getModalLocation } from '../../lib/modal'
import {
  calcProgressPercentage,
  getIcon,
  getCTA,
  getNumberMinutesWatched,
  getTimeLeftString
} from './utils'
import { getWatchUrl } from '../../lib/utils'
import { playerGaPlayButtonEvent } from '../../lib/analytics/ga'
import { dataLayerPlay } from '../../lib/analytics/datalayer'
import { modalContent } from '../../modules/shared/subscription-constants'

export const getEpisodePlaybackInfo = ({
  playbackInfo,
  playbackInfoArray
}) => {
  // Get appropriate playback info
  return {
    playbackInfo: path(['playbackInfo'], playbackInfo) || path([0, 'playbackInfo'], playbackInfoArray)
  }
}
const episodeWatchUrlOrModal = ({
  castSender,
  contentItemId,
  isAuthenticated,
  location,
  watchable
}) => {
  if (watchable && !castSender.isConnected) {
    return `/watch/${contentItemId}`
  }

  if (!isAuthenticated) {
    return getModalLocation(
      location,
      MODALS.qsParams.login
    )
  }

  return getModalLocation(
    location,
    MODALS.qsParams.addSubscription,
    { content: modalContent.selection }
  )
}

export const getEpisodeProps = ({
  location,
  isAuthenticated,
  maybeInitializeCastSender,
  contentItem,
  watchable,
  displayedInLocation,
  playbackInfo,
  theme,
  castSender,
  currentProfile
}) => {
  const contentItemId = contentItem.id
  // Get number of minutes watched s
  const numberMinutesWatched = getNumberMinutesWatched(playbackInfo)
  const numberMinutesRemaining = path(['numberMinutesRemaining'], playbackInfo)
  const duration = contentItem.duration || (numberMinutesRemaining + numberMinutesWatched)

  // Get playback status
  const status = pathOr(null, ['status'], playbackInfo)

  // Get play button icon
  const icon = getIcon(status, theme, false)

  // Calculate progress indicator
  const progressPercentage = calcProgressPercentage(duration, numberMinutesWatched, status)

  const watchUrl = episodeWatchUrlOrModal({
    castSender,
    contentItemId,
    isAuthenticated,
    location,
    watchable
  })

  // Get play button main call to action text
  const ctaPrefix = getCTA(status, isAuthenticated)
  const price = ''
  const time = getTimeLeftString(playbackInfo)
  const displayCta = false
  const displayPrice = false
  const displayTime = false
  const onClick = (event) => {
    playerGaPlayButtonEvent(contentItem, displayedInLocation, numberMinutesWatched, currentProfile)
    dataLayerPlay(contentItem)

    // Let Link handle the click and location change
    // if cast sender is disconnected
    if (!castSender.isConnected) { return }

    if (watchable) {
      // PreventLink from handling the click
      event.preventDefault()

      maybeInitializeCastSender(getWatchUrl(contentItem), contentItem)
    }
  }
  const hasLinearGradient = true

  return {
    icon,
    progressPercentage,
    watchUrl,
    ctaPrefix,
    price,
    time,
    displayCta,
    displayPrice,
    displayTime,
    onClick,
    hasLinearGradient,
    watchable
  }
}

export default getEpisodeProps
