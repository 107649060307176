import PropTypes from 'prop-types'
import React from 'react'
import { merge } from 'ramda'

export const extendJSONLDSchema = (schema, type) => {
  return JSON.stringify(merge(schema, {
    '@context': 'http://schema.org',
    '@type': type
  }), null, 2)
}

export function JSONLD({ schema, type }) {
  return (
    <script type="application/ld+json">{extendJSONLDSchema(schema, type)}</script>
  )
}

JSONLD.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.oneOfType([PropTypes.object]).isRequired,
  type: PropTypes.string.isRequired
}
