import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'

import AccountNavButton from '../../containers/navigation/account-nav-button'
import LoginNavButton from './login-nav-button'
import SignupNavButton from './signup-nav-button'
import SearchNavButton from '../../containers/search/search-input'
import ChromecastNavButton from './chromecast-nav-button'
import styles from './navigation-right.css'
import NavigationAccount from '../../containers/navigation/navigation-account-menu'

function NavigationRight({
  onAccountNavClick, isAuthenticated, searchIsClicked, ...props
}) {
  const location = useLocation()
  const isHomePage = location.pathname === '/'

  return (
    <div
      className={classNames({
        [styles.rightNav]: true,
        [styles.searchIsClicked]: searchIsClicked
      })}
    >
      <SearchNavButton isAuthenticated={isAuthenticated} {...props} />
      <ChromecastNavButton
        className={classNames({
          [styles.chromecastNavButton]: true,
          [styles.searchIsOpen]: searchIsClicked
        })}
      />
      <AccountNavButton {...props} isAuthenticated={isAuthenticated} onClick={onAccountNavClick} />
      <LoginNavButton {...props} isAuthenticated={isAuthenticated} searchIsClicked={searchIsClicked} isHomePage={isHomePage} />
      {/* Hide the sign up button in guest home page as user can get started by the email box */}
      {!isHomePage && <SignupNavButton {...props} isAuthenticated={isAuthenticated} searchIsClicked={searchIsClicked} />}
      <NavigationAccount />
    </div>
  )
}

NavigationRight.propTypes = {
  onAccountNavClick: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  searchIsClicked: PropTypes.bool.isRequired
}

export default NavigationRight
