const storeKey = 'lbxw:hotspotVideoCounter'
class HotspotVideoCounter {
  initial = JSON.parse(localStorage.getItem(storeKey) || '{}')

  current = { ...this.initial }

  getByVideo = video => {
    return this.initial[video] || 0
  }

  incrementByVideo = video => {
    this.current[video] = (this.initial[video] || 0) + 1
    localStorage.setItem(storeKey, JSON.stringify(this.current))
  }
}

export default HotspotVideoCounter
