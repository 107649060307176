import React from 'react'
import FullScreenLoadingComponent from '../loading/fullscreen-loader'

const Loader = () => {
  return (
    <FullScreenLoadingComponent pastDelay withoutLayer />
  )
}

export default Loader
