import React from 'react'
import styles from './top-gradient.css'

const TopGradient = () => {
  return (
    <div className={styles.gradient} />
  )
}

export default TopGradient
