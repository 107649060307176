import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import {
  branch,
  compose,
  renderComponent,
  renderNothing,
  setDisplayName,
  shouldUpdate,
  withProps,
  withContext
} from 'recompose'
import {
  anyPass, F, isEmpty, isNil, partialRight, pathOr
} from 'ramda'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import Screen from '../components/screen'
import { checkIsAuthenticated } from '../lib/auth'
import {
  dropMyListTile,
  getOverrides,
  getPlabackInfoIds,
  getScreenId,
  hasMyListComponentsP,
  mapPlaybackInfoResponse
} from '../lib/screen'
import Loader from '../components/loader/index'

import SCREEN_QUERY from '../../graphql/queries/screen.gql'
import MY_LIST_QUERY from '../../graphql/queries/my-list.gql'
import MY_RENTALS_QUERY from '../../graphql/queries/my-rentals.gql'
import ADD_TO_MY_LIST_MUTATION from '../../graphql/mutations/add-to-my-list.gql'
import REMOVE_FROM_MY_LIST_MUTATION from '../../graphql/mutations/remove-from-my-list.gql'
import PLAYBACK_INFO_MANY_QUERY from '../../graphql/queries/playback-info-many.gql'

import {
  isContentItemOnMyList,
  isLocationChange,
  nextPropsDataHasScreenComponents
} from '../lib/utils'
import {
  episodeHouseId,
  myListGa,
  seasonHouseId,
  seriesTitleHouseId
} from '../lib/analytics/ga'
import {
  customDimensions,
  getCategoryNameFromContentItem
} from '../lib/analytics/custom-dimensions'
import { getSelectedProfileIdFromSession } from '../lib/account'
import { withCurrentProfile } from '../hoc/with-current-profile'
import { getAdditionalGenreFromContentItem } from '../lib/analytics/datalayer'
import { redirectKidsToProfilesSwitchIfAuthenticated } from '../hoc/redirect-kids-to-profilesswitch-if-authenticated'
import { withScreenWidthWatching } from '../hoc/with-screen-width-watching'

import NotFound from '../components/not-found/index'

export default compose(
  setDisplayName('ScreenContainer'),
  withRouter,
  connect(
    state => ({
      isAuthenticated: checkIsAuthenticated(state), // used to skip list query
      profileId: getSelectedProfileIdFromSession(state.session)
    }),
    dispatch => ({
      dispatch
    })
  ),
  withCurrentProfile,
  graphql(SCREEN_QUERY, {
    options: ({ fetchPolicy, ...rest }) => {
      const screenId = getScreenId(rest)
      const overrides = getOverrides(rest)

      return {
        variables: {
          screenId,
          overrides
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
      }
    },
    props: props => {
      const {
        data,
        data: { loading, error }
      } = props
      return {
        data,
        loading,
        error
      }
    }
  }),
  redirectKidsToProfilesSwitchIfAuthenticated,
  // Render not found (404) page if there is no error, there is no screen and it's not loading
  branch(
    ({ data, loading }) => isNil(data.screen) && !loading,
    renderComponent(NotFound)
  ),
  // Render loader if it is loading
  branch(
    ({ data, loading }) => isNil(data.screen) || loading,
    renderComponent(Loader)
  ),
  // Render nothing if it is an error
  branch(({ data, error }) => isNil(data.screen) || error, renderNothing),
  graphql(MY_LIST_QUERY, {
    name: 'myListQuery',
    skip: ownProps => !ownProps.isAuthenticated,
    options: {
      notifyOnNetworkStatusChange: true
    },
    props: ({ ownProps, myListQuery: { mylist } }) => ({
      ...ownProps,
      myList: mylist
    })
  }),
  graphql(MY_RENTALS_QUERY, {
    name: 'myRentalsQuery',
    skip: ownProps => !ownProps.isAuthenticated
  }),
  shouldUpdate(anyPass([nextPropsDataHasScreenComponents, isLocationChange])),
  withProps(({ data, myList }) => {
    if (!myList) {
      return {
        isContentItemOnMyList: F,
        playbackInfoIds: getPlabackInfoIds(data)
      }
    }

    return {
      isContentItemOnMyList: partialRight(isContentItemOnMyList, [
        myList.items
      ]),
      hasMyListComponents: hasMyListComponentsP(data),
      playbackInfoIds: getPlabackInfoIds(data)
    }
  }),
  // Add mutation handlers here
  branch(
    ({ myList }) => !isNil(myList),
    compose(
      graphql(ADD_TO_MY_LIST_MUTATION, {
        props: ({
          ownProps: { hasMyListComponents, currentProfile, ...rest },
          mutate
        }) => {
          const screenId = getScreenId(rest)
          const overrides = getOverrides(rest)
          const refetchQueries = [
            { query: MY_LIST_QUERY },
            {
              query: SCREEN_QUERY,
              variables: {
                screenId,
                overrides
              }
            }
          ]
          return {
            addToMyList: renderedInComponent => contentItem => mutate({
              variables: { contentItemId: contentItem.id },
              refetchQueries
            }).then(() => {
              myListGa({
                label: contentItem.title,
                action: 'Add',
                [customDimensions.CategoryName]: getCategoryNameFromContentItem(
                  contentItem
                ),
                [customDimensions.ProfileId]: currentProfile.id,
                [customDimensions.ProfileName]: currentProfile.name,
                [customDimensions.ShowTitle]: contentItem.title,
                [customDimensions.AdditionalGenre]: getAdditionalGenreFromContentItem(
                  contentItem
                ),
                [customDimensions.SeriesTitleHouseId]: seriesTitleHouseId(
                  contentItem
                ),
                [customDimensions.SeasonHouseId]: seasonHouseId(contentItem),
                [customDimensions.EpisodeHouseId]: episodeHouseId(
                  contentItem
                ),
                [customDimensions.ComponentName]: renderedInComponent
              })
            })
          }
        }
      }),
      graphql(REMOVE_FROM_MY_LIST_MUTATION, {
        props: ({
          ownProps: {
            screenId, match, location, currentProfile
          },
          mutate
        }) => ({
          removeFromMyList: renderedInComponent => contentItem => mutate({
            variables: { contentItemId: contentItem.id },
            refetchQueries: [
              {
                query: MY_LIST_QUERY
              }
            ],
            update: (store, { data: { removeFromMyList } }) => {
              if (!removeFromMyList) return

              const queryScreenId = getScreenId({ screenId, match, location })

              const data = store.readQuery({
                query: SCREEN_QUERY,
                variables: {
                  screenId: queryScreenId
                }
              })
              store.writeQuery({
                query: SCREEN_QUERY,
                variables: {
                  screenId: queryScreenId
                },
                data: dropMyListTile(contentItem.id, data)
              })
            }
          }).then(() => {
            myListGa({
              label: contentItem.title,
              action: 'Remove',
              [customDimensions.CategoryName]: getCategoryNameFromContentItem(
                contentItem
              ),
              [customDimensions.ProfileId]: currentProfile.id,
              [customDimensions.ProfileName]: currentProfile.name,
              [customDimensions.ShowTitle]: contentItem.title,
              [customDimensions.AdditionalGenre]: getAdditionalGenreFromContentItem(
                contentItem
              ),
              [customDimensions.SeriesTitleHouseId]: seriesTitleHouseId(
                contentItem
              ),
              [customDimensions.SeasonHouseId]: seasonHouseId(contentItem),
              [customDimensions.EpisodeHouseId]: episodeHouseId(contentItem),
              [customDimensions.ComponentName]: renderedInComponent
            })
          })
        })
      })
    )
  ),
  graphql(PLAYBACK_INFO_MANY_QUERY, {
    name: 'playbackInfoManyQuery',
    skip: ({ isAuthenticated, playbackInfoIds }) => {
      return !isAuthenticated || isEmpty(playbackInfoIds)
    },
    options: ({ playbackInfoIds }) => {
      return {
        variables: { contentItemIds: playbackInfoIds },
        fetchPolicy: 'network-only'
      }
    },
    props: ({ ownProps, playbackInfoManyQuery: { playbackInfoMany } }) => {
      return {
        ...ownProps,
        playbackInfoMany: mapPlaybackInfoResponse(playbackInfoMany)
      }
    }
  }),
  withProps(({ data }) => {
    const templateId = pathOr(null, ['screen', 'templateId'])(data)
    return {
      isMarketing: !isNil(templateId) && templateId === 'MARKETING',
      screenTitle: pathOr(null, ['screen', 'title'], data)
    }
  }),
  withContext(
    {
      isWhiteTheme: PropTypes.bool.isRequired,
      isMarketing: PropTypes.bool.isRequired
    },
    ({ isMarketing }) => {
      return {
        isWhiteTheme: false,
        isMarketing
      }
    }
  ),
  withScreenWidthWatching
)(Screen)
