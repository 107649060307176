import React from 'react'
import PropTypes from 'prop-types'

import SubscriptionTable from '../subscription-table'

/**
 * Permanently deprecate the usage of the 5 grid component
 * use this component as a subscription price table placeholder without actually rendering the 5 grid,
 * so whenever we see GRID / PROMO GRID component in admin console, it means a subscription price table.
 *
 * @param {boolean} isAuthenticated
 */

function PromotionalGrid({
  isAuthenticated
}) {
  return (
    !isAuthenticated && <SubscriptionTable />
  )
}

PromotionalGrid.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired
}

export default PromotionalGrid
