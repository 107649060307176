import {
  always,
  compose,
  concat,
  either,
  identity,
  ifElse,
  lensProp,
  lt,
  merge,
  omit,
  path,
  prop,
  reduce,
  set,
  sort,
  toPairs,
  toString
} from 'ramda'

import { getLocationBaseWithHref } from '../location'
import {
  PLAY_BUTTON_IN_PLAYER_EPISODE_SELECTOR,
  PLAY_BUTTON_IN_SERIES_EPISODE_SELECTOR,
  PLAY_BUTTON_IN_SERIES_HERO,
  PLAY_BUTTON_IN_TITLE_HERO
} from '../../containers/play-button/constants'
import {
  PLAYBACK_EVENT_CONTINUE,
  PLAYBACK_EVENT_RESUME,
  PLAYBACK_EVENT_START
} from '../../components/video-player/lib/constants'
import {
  isEpisode,
  isSeason,
  isSeries,
  isTitle,
  isVideoExtra
} from '../content'
import {
  customDimensions,
  getCategoryNameFromContentItem
} from './custom-dimensions'
import { getAdditionalGenreFromContentItem } from './datalayer'
import { IdleQueue } from '../idlize/IdleQueue.mjs'

export const MY_LIST_LOCATIONS_TITLE_DETAIL = 'Title Detail'

const prependZeroToSingleDigit = ifElse(
  lt(10),
  identity,
  compose(
    concat('0'),
    toString
  )
)

export const episodeTitleFromContentItem = contentItem => {
  const title = path(['series', 'title'], contentItem)
  return `${title} S${contentItem.seasonNumber}E${prependZeroToSingleDigit(
    contentItem.episodeNumber
  )}`
}

export const seriesTitleHouseId = contentItem => {
  if (isTitle(contentItem) || isSeries(contentItem)) {
    return contentItem.ldId
  }
  return contentItem.series.ldId
}

export const seasonHouseId = contentItem => {
  if (isSeason(contentItem)) {
    return contentItem.ldId
  }

  if (isEpisode(contentItem)) {
    return path(
      ['series', 'seasons', contentItem.seasonNumber - 1, 'ldId'],
      contentItem
    )
  }

  return undefined
}

export const episodeHouseId = contentItem => {
  return isEpisode(contentItem) ? contentItem.ldId : undefined
}

const idleQueue = new IdleQueue({
  ensureTasksRun: true,
  defaultMinTaskTime: 5
})

export const initialiseGa = history => {
  window.ga =
    window.ga ||
    // eslint-disable-next-line func-names
    function() {
      // eslint-disable-next-line prefer-rest-params
      (window.ga.q = window.ga.q || []).push(arguments)
    }

  idleQueue.pushTask(() => {
    window.ga('create', process.env.GA_ID, 'auto')
  })
  idleQueue.pushTask(() => window.ga('set', 'transport', 'beacon'))
  idleQueue.pushTask(() => window.ga('send', 'pageview'))

  history.listen(location => {
    idleQueue.pushTask(() => window.ga('send', 'pageview', location.pathname))
  })
}

const sendEvent = event => {
  idleQueue.pushTask(() => window.ga(
    'send',
    compose(
      omit(['category', 'action', 'label']),
      merge({
        label: getLocationBaseWithHref(),
        hitType: 'event',
        transport: 'beacon',
        eventCategory: event.category,
        eventAction: event.action,
        eventLabel: event.label
      })
    )(event)
  ))
}

// Simple event wrappers
export const navigationGa = compose(
  sendEvent,
  merge({
    category: 'navigation'
  })
)

export const videoInteraction = compose(
  sendEvent,
  merge({
    category: 'videoInteraction'
  })
)

export const navigationBarGa = compose(
  sendEvent,
  merge({
    category: 'navigationBar'
  })
)

export const searchGa = compose(
  sendEvent,
  always({
    category: 'search',
    action: 'Search opened'
  })
)

export const playerGa = compose(
  sendEvent,
  merge({
    category: 'playerInteraction'
  })
)

export const loginGa = compose(
  sendEvent,
  merge({
    category: 'login'
  })
)

export const myListGa = compose(
  sendEvent,
  merge({
    category: 'myList'
  })
)

export const bitrateGa = compose(
  sendEvent,
  merge({
    category: 'playerInteraction'
  })
)

export const subscriptionGa = compose(
  sendEvent,
  merge({
    category: 'subscription'
  })
)

export const getSeriesTitleFromEpisode = path(['series', 'title'])

export const getTitleOfContent = ifElse(isTitle, prop('title'), episode => episodeTitleFromContentItem(episode))

export const getShowTitleFromContentItem = ifElse(
  either(isTitle, isVideoExtra),
  prop('title'),
  getSeriesTitleFromEpisode
)

// Complex event emitters
export const playerGaPlayButtonEvent = (
  contentItem,
  displayedInLocation,
  numberMinutesWatched,
  currentProfile = {},
  navigationGaFunction = navigationGa,
  playerGaFunction = playerGa
) => {
  const sharedProperties = {
    label: getShowTitleFromContentItem(contentItem),
    [customDimensions.ShowTitle]: getShowTitleFromContentItem(contentItem),
    [customDimensions.ProfileId]: currentProfile.id,
    [customDimensions.ProfileName]: currentProfile.name,
    [customDimensions.SeasonNumber]: contentItem.seasonNumber,
    [customDimensions.EpisodeNumber]: contentItem.episodeNumber,
    [customDimensions.Timestamp]: Date.now(),
    [customDimensions.AdditionalGenre]: getAdditionalGenreFromContentItem(
      contentItem
    ),
    [customDimensions.SeriesTitleHouseId]: seriesTitleHouseId(contentItem),
    [customDimensions.SeasonHouseId]: seasonHouseId(contentItem),
    [customDimensions.EpisodeHouseId]: episodeHouseId(contentItem)
  }

  if (numberMinutesWatched) {
    return navigationGaFunction(
      merge({ action: `Resume from ${numberMinutesWatched}` }, sharedProperties)
    )
  }

  switch (displayedInLocation) {
    case PLAY_BUTTON_IN_SERIES_HERO:
    case PLAY_BUTTON_IN_TITLE_HERO: {
      return navigationGaFunction(
        merge({ action: 'Play - from hero image' }, sharedProperties)
      )
    }
    case PLAY_BUTTON_IN_PLAYER_EPISODE_SELECTOR: {
      if (isTitle(contentItem)) {
        return null
      }
      return navigationGaFunction(
        merge({ action: 'Play from episode selector' }, sharedProperties)
      )
    }
    case PLAY_BUTTON_IN_SERIES_EPISODE_SELECTOR: {
      if (isTitle(contentItem)) {
        return null
      }
      return playerGaFunction(
        merge({ action: 'Play from episode selector' }, sharedProperties)
      )
    }
    default:
      return null
  }
}

export const playerGaVideoPlayerEvent = (
  contentItem,
  type,
  profileId,
  playerGaFunction = playerGa
) => {
  switch (type) {
    case PLAYBACK_EVENT_START: {
      playerGaFunction({
        category: 'navigation',
        action: 'Play from beginning',
        label: getTitleOfContent(contentItem),
        [customDimensions.ShowTitle]: getShowTitleFromContentItem(contentItem),
        [customDimensions.CategoryName]: getCategoryNameFromContentItem(
          contentItem
        ),
        [customDimensions.ProfileId]: profileId,
        [customDimensions.SeasonNumber]: contentItem.seasonNumber,
        [customDimensions.EpisodeNumber]: contentItem.episodeNumber,
        [customDimensions.Timestamp]: Date.now(),
        [customDimensions.SeriesTitleHouseId]: seriesTitleHouseId(contentItem),
        [customDimensions.SeasonHouseId]: seasonHouseId(contentItem),
        [customDimensions.EpisodeHouseId]: episodeHouseId(contentItem)
      })
      break
    }
    case PLAYBACK_EVENT_CONTINUE:
    case PLAYBACK_EVENT_RESUME: {
      playerGaFunction({
        category: 'navigation',
        action: 'Play',
        label: getTitleOfContent(contentItem),
        [customDimensions.ShowTitle]: getShowTitleFromContentItem(contentItem),
        [customDimensions.CategoryName]: getCategoryNameFromContentItem(
          contentItem
        ),
        [customDimensions.ProfileId]: profileId,
        [customDimensions.SeasonNumber]: contentItem.seasonNumber,
        [customDimensions.EpisodeNumber]: contentItem.episodeNumber,
        [customDimensions.Timestamp]: Date.now(),
        [customDimensions.SeriesTitleHouseId]: seriesTitleHouseId(contentItem),
        [customDimensions.SeasonHouseId]: seasonHouseId(contentItem),
        [customDimensions.EpisodeHouseId]: episodeHouseId(contentItem)
      })
      break
    }
    default:
  }
}

/**
 * Sets up the actual event emitter for use within a single video play through.
 *
 * @param doneTime Number The total time in millilseconds this video runs for
 * @param milestones Object Contains thresholds labeled by percentages for times beyond which events
 *                          should be sent for. e.g: { 25: 1000, 50: 2000, 75: 3000 }
 *                                                   { key (percentage): timestamp (milliseconds)
 * @param sendMilestoneEvent Function function to be called when emitting a ga event
 * @param contentItem Object the content item being played
 * @param profileId String the profile ID for the viewing user
 * @returns {function(currentTime)} The function called when a timeupdate event occurs, sends the
 *                                  actual event if appropriate
 */
export const prepareFireMilestoneGaEvent = (
  doneTime,
  milestones,
  sendMilestoneEvent,
  contentItem,
  profileId
) => {
  /**
   * Returned function, when called - sends any event according to the current state
   * of ${milestones}, then updates ${milestones} with the reduced set of outstanding milestones
   *
   * @param currentTime Number the current timestamp being viewed, in milliseconds
   */
  return currentTime => {
    let sentEventForMilestone = false
    milestones = reduce(
      (accumulator, [milestone, milestoneTime]) => {
        if (currentTime < milestoneTime) {
          return set(lensProp(milestone), milestoneTime, accumulator)
        } if (!sentEventForMilestone) {
          sendMilestoneEvent({
            category: 'videoInteraction',
            action: milestone,
            label: getTitleOfContent(contentItem),
            [customDimensions.CategoryName]: getCategoryNameFromContentItem(
              contentItem
            ),
            [customDimensions.ProfileId]: profileId
          })
          sentEventForMilestone = milestone
        }

        return accumulator
        // Milestones must be in reverse order so that the larger values are processed first
      },
      {},
      compose(
        sort(([a], [b]) => b - a),
        toPairs
      )(milestones)
    )
  }
}
