import React from 'react'
import ReactSVG from 'react-svg'
import plusIcon from '../../../../../images/plus.svg'
import styles from './navigation-account-side.css'
import { E2E_SELECTORS } from '../../../../e2e-selectors'

const MyListIcon = () => {
  return (
    <div
      className={styles.myListIcon}
      data-lbx-e2e={E2E_SELECTORS.MY_LIST_SIDE_NAVIGATION}
    >
      <ReactSVG
        src={plusIcon}
        className={styles.svgWrapper}
      />
    </div>
  )
}

export default MyListIcon
