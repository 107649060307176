import { graphql } from 'react-apollo'
import {
  compose,
  withHandlers,
  withState
} from 'recompose'

import GridTile from '../../components/grid/grid-tile'
import withTheme from '../../hoc/with-theme'
import withIsAuthenticated from '../../hoc/is-authenticated'

import { STATIC_SCREENS } from '../../constants'

import MY_LIST_QUERY from '../../../graphql/queries/my-list.gql'
import SCREEN_QUERY from '../../../graphql/queries/screen.gql'
import REMOVE_FROM_MY_LIST_MUTATION from '../../../graphql/mutations/remove-from-my-list.gql'
import {
  getAdditionalGenreFromContentItem
} from '../../lib/analytics/datalayer'
import {
  customDimensions,
  getCategoryNameFromContentItem
} from '../../lib/analytics/custom-dimensions'
import {
  episodeHouseId,
  myListGa,
  seasonHouseId,
  seriesTitleHouseId
} from '../../lib/analytics/ga'
import { withCurrentProfile } from '../../hoc/with-current-profile'

const enhance = compose(
  withTheme,
  withIsAuthenticated,
  withCurrentProfile,
  graphql(REMOVE_FROM_MY_LIST_MUTATION),
  withState('isVisible', 'setIsVisible', false),
  withHandlers({
    onRemoveClick: ({ currentProfile, mutate }) => {
      return renderedInComponent => (contentItem) => {
        mutate({
          variables: {
            contentItemId: contentItem.id
          },
          refetchQueries: [
            {
              query: MY_LIST_QUERY,
              options: {
                notifyOnNetworkStatusChange: true
              }
            },
            {
              query: SCREEN_QUERY,
              variables: {
                screenId: STATIC_SCREENS.MY_LIST.screenId
              },
              options: {
                notifyOnNetworkStatusChange: true
              }
            }
          ]
        })
          .then(() => {
            myListGa({
              label: contentItem.title,
              action: 'Remove',
              [customDimensions.CategoryName]: getCategoryNameFromContentItem(contentItem),
              [customDimensions.ProfileId]: currentProfile.id,
              [customDimensions.ProfileName]: currentProfile.name,
              [customDimensions.ShowTitle]: contentItem.title,
              [customDimensions.AdditionalGenre]: getAdditionalGenreFromContentItem(
                contentItem
              ),
              [customDimensions.SeriesTitleHouseId]: seriesTitleHouseId(contentItem),
              [customDimensions.SeasonHouseId]: seasonHouseId(contentItem),
              [customDimensions.EpisodeHouseId]: episodeHouseId(contentItem),
              [customDimensions.ComponentName]: renderedInComponent
            })
          })
      }
    },
    onVisibilityChange: ({
      isVisible,
      setIsVisible
    }) => () => {
      if (isVisible) {
        return
      }
      setIsVisible(true)
    }
  })
)

export default enhance(GridTile)
