import {
  createSelector
} from 'reselect'
import {
  prop,
  path
} from 'ramda'

export const getSession = prop('session')

export const getTokenFromState = createSelector(getSession, path(['token']))
// Memoize `accountId` based on `session`. Since we immutably update the redux store, this is safe.
export const getAccountIdFromState = createSelector(getSession, path(['decodedJwt', 'accountId']))

export const getSelectedProfileFromState = createSelector(getSession, path(['decodedJwt', 'selectedProfile']))

export const getUserSubjectFromState = createSelector(getSession, path(['decodedJwt', 'sub']))
