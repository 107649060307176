import {
  compose,
  withProps,
  branch,
  renderComponent
} from 'recompose'
import { connect } from 'react-redux'
import {
  pathOr,
  equals
} from 'ramda'
import {
  THEME_OPTIONS
} from '../../../constants'

import ContentVideoDisplay from '../../../components/shared/content-video-display'

// For adult profiles
import PlayButton from '../../../components/shared/details-play-button'

// Mapping theme reducer state to props
const mapStateToProps = state => ({
  theme: state.theme
})

const getEpisodicImageForKidsTitle = (contentItem, tile) => (
  pathOr(
    pathOr(
      '',
      ['image'],
      tile
    ),
    ['tile', 'episodicImage', 'uri'],
    contentItem
  )
)

// 1. Get Tile image from
//    a) tile.image if exists
//    b) contentItem.tile.image
const getTileImage = (contentItem, tile, isTitleKids = false) => {
  if (isTitleKids) {
    return getEpisodicImageForKidsTitle(contentItem, tile) || pathOr(
      '',
      ['tile', 'image'],
      contentItem
    )
  }

  return pathOr(
    pathOr(
      '',
      ['tile', 'image'],
      contentItem
    ),
    ['image'],
    tile
  )
}

const isAdult = equals(THEME_OPTIONS.dark)

const enhance = compose(
  connect(mapStateToProps),
  // If we're in an adult theme, go ahead and render the default play button.
  // Otherwise, handle the UI video display treatment for kids.
  branch(
    ({ theme }) => isAdult(theme),
    renderComponent(PlayButton)
  ),
  withProps(({
    contentItem,
    tile,
    isTitleKids
  }) => {
    const imageUri = getTileImage(contentItem, tile, isTitleKids)
    return {
      imageUri
    }
  })
)

export {
  getEpisodicImageForKidsTitle,
  getTileImage
}

export default enhance(ContentVideoDisplay)
