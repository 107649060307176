import React from 'react'
import classNames from 'classnames'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import qs from 'query-string'
import ReactImgix from 'react-imgix'

import Modal from '../../modal'
import { appConfigPropType } from '../../player/utils/prop-types'
import { MODALS, MANAGE_PLAN_OPTIONS } from '../../../constants'
import { modalContent } from '../../../modules/shared/subscription-constants'
import { dismissPlanUpgradePopup, confirmPlanUpgradePopup } from '../../../actions'
import {
  getUpgradePopupProfileImage,
  getUpgradePopupProfileTitle,
  getUpgradePopupProfileMessage,
  getUpgradePopupCTAOK,
  getUpgradePopupCTAUpgrade,
  getUpgradePopupPlayerImage,
  getUpgradePopupPlayerTitle,
  getUpgradePopupPlayerMessage,
  getUpgradePopupConcurrentImage,
  getUpgradePopupConcurrentMessage,
  getUpgradePopupConcurrentTitle
} from '../../player/state/utils'
import { usePlanChangeFlag } from '../../../hooks/usePlanChange'
import styles from './plan-upgrade-popup.css'

function PlanUpgradePopup({
  appConfig, isQualityUpgradePopupShown, onPopupDismiss, onPopupConfirm, isStreamingUpgradePopupShown
}) {
  const history = useHistory()
  const location = useLocation()
  const mapState = useSelector(state => state)
  const getPlanDetails = usePlanChangeFlag()
  const { planUpgradePopup, manageSubsOptions } = mapState
  const dispatch = useDispatch()
  const userHasNoPlan = manageSubsOptions.includes(MANAGE_PLAN_OPTIONS.ADD)

  const onDismiss = () => {
    if (isStreamingUpgradePopupShown) {
      if (history.location.key) {
        // Back to previous page
        history.goBack()
      } else {
        // In a new tab, back to home page
        history.push('/')
      }
    } else if (isQualityUpgradePopupShown) {
      onPopupDismiss()
    } else {
      dispatch(dismissPlanUpgradePopup())
    }
  }

  const upgradeProfileInfo = {
    title: getUpgradePopupProfileTitle(appConfig.values),
    img: getUpgradePopupProfileImage(appConfig.values),
    msg: getUpgradePopupProfileMessage(appConfig.values)
  }

  const upgradeQualityInfo = {
    title: getUpgradePopupPlayerTitle(appConfig.values),
    img: getUpgradePopupPlayerImage(appConfig.values),
    msg: getUpgradePopupPlayerMessage(appConfig.values)
  }

  const upgradeStreamingInfo = {
    title: getUpgradePopupConcurrentTitle(appConfig.values),
    img: getUpgradePopupConcurrentImage(appConfig.values),
    msg: getUpgradePopupConcurrentMessage(appConfig.values)
  }

  const ctaInfo = {
    ctaOk: getUpgradePopupCTAOK(appConfig.values),
    ctaUpgrade: getUpgradePopupCTAUpgrade(appConfig.values)
  }

  let upgradeInfo = null
  if (isQualityUpgradePopupShown) {
    upgradeInfo = { ...ctaInfo, ...upgradeQualityInfo }
  } else if (isStreamingUpgradePopupShown) {
    upgradeInfo = { ...ctaInfo, ...upgradeStreamingInfo }
  } else {
    upgradeInfo = { ...ctaInfo, ...upgradeProfileInfo }
  }

  const onConfirm = () => {
    if (isQualityUpgradePopupShown || isStreamingUpgradePopupShown) {
      // on quality or streaming upgrade confirm
      onPopupConfirm()
    } else {
      // on profile upgrade confirm
      dispatch(confirmPlanUpgradePopup())
    }

    // Nav to select plan modal
    history.replace({
      ...location,
      search: qs.stringify({
        content: modalContent.selection,
        currentSubscription: userHasNoPlan ? null : getPlanDetails?.basic.title,
        modal: MODALS.qsParams.addSubscription
      })
    })
  }

  return (
    planUpgradePopup.show || isQualityUpgradePopupShown || isStreamingUpgradePopupShown ? (
      <Modal
        noRouting
      >
        <div className={styles.contentBox}>
          <ReactImgix
            className={styles.contentImage}
            src={upgradeInfo.img}
            width={420}
            height={420}
          />

          <div className={styles.contentFrame}>
            <div className={styles.header}>{upgradeInfo.title}</div>
            <div className={styles.text}>{upgradeInfo.msg}</div>
            <div className={styles.buttonGroup}>
              {manageSubsOptions && manageSubsOptions.length && !manageSubsOptions.includes(MANAGE_PLAN_OPTIONS.RESUME) && (
                <button
                  onClick={onConfirm}
                  className={styles.button}
                  data-e2e="plan-upgrade-popup-confirm-button"
                >
                  {upgradeInfo.ctaUpgrade}
                </button>
              )}
              <button
                onClick={onDismiss}
                className={classNames(styles.button, styles.closeButton)}
                data-e2e="plan-upgrade-popup-cancel-button"
              >
                {upgradeInfo.ctaOk}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    )
      : null
  )
}

PlanUpgradePopup.propTypes = {
  appConfig: appConfigPropType.isRequired,
  isQualityUpgradePopupShown: PropTypes.bool,
  isStreamingUpgradePopupShown: PropTypes.bool,
  onPopupDismiss: PropTypes.func,
  onPopupConfirm: PropTypes.func
}

export default PlanUpgradePopup
