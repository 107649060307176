import thunk from 'redux-thunk'
import { createBrowserHistory, createMemoryHistory } from 'history'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore, autoRehydrate } from 'redux-persist'
import { reduxBatch } from '@manaflair/redux-batch'

import authMiddleware from '../auth/middleware'
import { LOCAL_STORAGE } from '../constants'
import reducers from '../reducers'

// Browser history
export const history = process.env.NODE_ENV === 'test' ? createMemoryHistory() : createBrowserHistory()

// Debug history event, use when wanting to troubleshoot navigation
// history.listen((location, action) => {
//   console.info(
//     `1neon Location is ${location.pathname}${location.search}${location.hash}`
//   )
//   console.info(`1neon Navigation action was ${action}`)
// })

// Create Redux store connected to React Router through middleware and history
export const store = createStore(
  reducers,
  composeWithDevTools(
    reduxBatch,
    applyMiddleware(
      thunk,
      authMiddleware.reduxMiddleware
    ),
    reduxBatch,
    autoRehydrate()
  )
)

// begin periodically persisting the store
persistStore(store, {
  blacklist: ['router', 'apollo', 'auth', 'loading', 'theme', 'maintenance', 'user', 'castSender', 'error'],
  keyPrefix: LOCAL_STORAGE.reduxPersistPrefix
})
