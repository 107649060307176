import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'

import { withScreenWidthWatching } from '../../hoc/with-screen-width-watching'
import { SCREEN_SIZE_BREAKPOINTS, APP_PADDING } from '../../constants'

import CATEGORY_COMPONENT_ITEM_FRAGMENT from '../../../graphql/fragments/category.gql'

import CategoryNavTabs from './category-navigation-tabs'
import CategoryNavSelector from './category-navigation-selector'

class CategoryNavigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      totalTabWidths: 0
    }
  }

  // eslint-disable-next-line
  setTotalTabWidths = (totalTabWidths) => {
    this.setState({ totalTabWidths })
  }

  // eslint-disable-next-line
  getNavMarginOffset = (screenWidth) => {
    /**
     * Ideally, these APP_PADDING values need to correspond
     * to the CSS app min values in `src/_variables.css` in
     * order to accurately determine which component to render.
     */
    if (screenWidth >= SCREEN_SIZE_BREAKPOINTS.MIN_480) {
      return APP_PADDING.MIN_480 * 2
    } if (screenWidth >= SCREEN_SIZE_BREAKPOINTS.MIN_768) {
      return APP_PADDING.MIN_768 * 2
    }
    return APP_PADDING.DEFAULT * 2
  }

  render() {
    const {
      categoryItems,
      selectedCategoryIdx,
      prefetchScreen,
      screenWidth
    } = this.props
    const { totalTabWidths } = this.state
    const navMarginOffset = this.getNavMarginOffset(screenWidth)
    const shouldRenderSelector = (screenWidth - navMarginOffset) < totalTabWidths
    return (
      <>
        {
          shouldRenderSelector ? (
            <CategoryNavSelector
              categoryItems={categoryItems}
              selectedCategoryIdx={selectedCategoryIdx}
              prefetchScreen={prefetchScreen}
            />
          ) : (
            <CategoryNavTabs
              categoryItems={categoryItems}
              selectedCategoryIdx={selectedCategoryIdx}
              prefetchScreen={prefetchScreen}
              onTotalTabWidths={this.setTotalTabWidths}
            />
          )
        }
      </>
    )
  }
}

CategoryNavigation.propTypes = {
  categoryItems: PropTypes.arrayOf(propType(CATEGORY_COMPONENT_ITEM_FRAGMENT)).isRequired,
  selectedCategoryIdx: PropTypes.number.isRequired,
  prefetchScreen: PropTypes.func.isRequired,
  screenWidth: PropTypes.number.isRequired
}

export default withScreenWidthWatching(CategoryNavigation)
