import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { sanitizeDom } from '../../utils'

import Imgix from '../imgix'
import PlayButton from './play-button'

import styles from './video-feature.css'

const VideoFeature = ({
  copy,
  imageUri,
  onTrailerClick,
  onWatchCTAClick,
  onDetailsCTAClick,
  isSeriesContentType,
  showWatch,
  isWhiteTheme,
  isMarketing
}) => {
  return (
    <div
      className={classnames(styles.videoFeature, {
        [styles.whiteTheme]: isWhiteTheme || isMarketing
      })}
    >
      <div className={styles.left}>
        <div className={styles.videoImage}>
          <Imgix
            className={styles.image}
            src={imageUri}
            recipe="videoFeature"
          />
          <PlayButton onTrailerClick={onTrailerClick} />
        </div>
      </div>
      <div className={styles.copyWrapper}>
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: sanitizeDom(copy) }}
        />
        <button className={styles.readMoreRectangle} onClick={onDetailsCTAClick}>Read More</button>
        {showWatch && (
          <button className={styles.watchRectangle} onClick={onWatchCTAClick}>
            Watch
            {' '}
            {isSeriesContentType && 'S1 E1'}
          </button>
        )}
      </div>
    </div>
  )
}

VideoFeature.propTypes = {
  copy: PropTypes.string.isRequired,
  imageUri: PropTypes.string.isRequired,
  onTrailerClick: PropTypes.func.isRequired,
  onWatchCTAClick: PropTypes.func.isRequired,
  onDetailsCTAClick: PropTypes.func.isRequired,
  isSeriesContentType: PropTypes.bool.isRequired,
  showWatch: PropTypes.bool.isRequired,
  isWhiteTheme: PropTypes.bool.isRequired,
  isMarketing: PropTypes.bool.isRequired
}

export default VideoFeature
