import React, { Suspense } from 'react'
import { LoadingComponent } from '../../../components/loading/index'

const Table = React.lazy(() => import('../../../components/settings/shared/table'))

const AsyncTable = props => (
  <Suspense fallback={<LoadingComponent />}>
    <Table {...props} />
  </Suspense>
)

export default AsyncTable
