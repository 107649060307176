/* eslint-disable global-require */

/**
 * File-level imports are disallowed in this file - we do not want code that may require
 * polyfilling to be executed until the requirement for a polyfill is checked, and any required
 * polyfill is loaded.
 *
 * Please do not modify the call-order without testing in
 *
 * iOS:
 *   - 10.2  (Safari,  Chrome)
 *   - 9.2 (Safari)
 * IE11
 */
require('./app/lib/elastic-apm')

const clientNeedsPolyfill = () => {
  return !window.Promise || !Object.values || !window.fetch
}

const render = () => {
  const ReactDOM = require('react-dom')
  const React = require('react')
  const { Provider } = require('react-redux')
  const { ApolloProvider } = require('react-apollo')
  const ReactModal = require('react-modal')

  const { store, history } = require('./app/store')
  const apolloClient = require('./app/lib/apollo').default

  require('./fonts/Gotham-Rounded/index.css')
  require('./fonts/GT-Walsheim/index.css')
  require('./index.css')
  const App = require('./app/gatekeeper').default

  ReactModal.setAppElement('#root')

  ReactDOM.render(
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <App history={history} />
      </ApolloProvider>
    </Provider>,
    document.getElementById('root')
  )
}

const renderWithHotLoader = () => {
  // Do not simplify this block
  //  - The outer NODE_ENV block will cause the inner block to be excluded from production builds
  if (process.env.NODE_ENV === 'development') {
    // Hot Module Replacement API
    if (module.hot) {
      module.hot.accept('./app', () => render())
    }
  }

  render()
};

(() => {
  if (clientNeedsPolyfill()) {
    return window.loadScript(window.LBX_POLYFILL, renderWithHotLoader)
  }

  return renderWithHotLoader()
})()
