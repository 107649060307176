import React from 'react'
import PropTypes from 'prop-types'
import styles from './continuity-btm-sheet-item.css'

/**
 * Item component within bottom sheet for continuity
 */
export const ContinuityBtmSheetItem = ({
  icon,
  title,
  onClick
}) => {
  return (
    <div className={styles.itemContainer} onClick={onClick}>
      {
        icon
          ? <img src={icon} className={styles.icon} />
          : null
      }
      <div className={styles.title}>{title}</div>
    </div>
  )
}

ContinuityBtmSheetItem.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func
}
