import React, { Suspense, useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import {
  converge, identity, path, pipe, split, head
} from 'ramda'

import MainLayoutRoute from './layouts/main/route'
import MyAccountRoute from './layouts/my-account/route'
import PlayerLayoutComponent from './layouts/player'

import Screen from './containers/screen'
import Payment from './containers/settings/my-account/subscription-and-payment'
import Devices from './containers/settings/my-account/devices-and-downloads'
import MyActivity from './components/settings/my-account/my-activity'
import { appConfigPropType } from './components/player/utils/prop-types'
import NotFound from './components/not-found'
import AppDownload from './components/app-download'
import Settings from './containers/settings'
import { UUID_SLUG } from './constants'
import { segmentPage } from './segment/segment-page'

const SignupModule = React.lazy(() => import('./modules/signup'))
const DirectCarrierBillingModule = React.lazy(() => import('./modules/direct-carrier-billing'))
const OnboardingModule = React.lazy(() => import('./modules/content-onboarding'))

const fromGenreSlug = pipe(
  path(['match', 'params', 'genreSlug']),
  slug => `/genre/${slug}`
)

const fromUUIDSlug = pipe(
  path(['match', 'params', 'screenParams']),
  split('/'),
  head
)

const fromMovieSlug = pipe(
  path(['match', 'params', 'movieSlug']),
  slug => `/movie/${slug}`
)

const fromSeriesSlug = pipe(
  path(['match', 'params', 'seriesSlug']),
  slug => `/series/${slug}`
)

const fromPathSlug = pipe(path(['location', 'pathname']), slug => slug)

const renderScreen = getScreenId => converge((props, screenId) => <Screen screenId={screenId} {...props} />, [
  identity,
  getScreenId
])

const PlayerComponent = props => (
  <PlayerLayoutComponent featureNames={['new_player']} {...props} />
)

const Routes = props => {
  /**
   * Returns a Switch/Route react component with all the routes
   * using the Main layout (/src/app/layouts/main)
   */

  const { appConfig } = props

  const getMainLayoutRoutes = () => {
    useEffect(() => {
      segmentPage({
        path: window.location.pathname,
        url: window.location.href
      })
    }, [window.location.pathname])

    const DefaultLayoutRoutes = (
      <Switch>
        <Route
          exact
          path="/my-list"
          render={() => {
            return <Screen screenId="/my-list" {...props} />
          }}
        />
        <Route
          exact
          path="/my-rentals"
          render={() => {
            return <Screen screenId="/my-rentals" {...props} />
          }}
        />

        <Route
          exact
          path="/movie/:movieSlug/:rent?"
          render={renderScreen(fromMovieSlug)}
        />
        <Route
          path="/series/:seriesSlug"
          render={renderScreen(fromSeriesSlug)}
        />
        <Route path="/genre/:genreSlug" render={renderScreen(fromGenreSlug)} />
        <Route
          path={`/${UUID_SLUG}/:screenParams+`}
          component={renderScreen(fromUUIDSlug)}
        />
        <Route exact path="/" component={Screen} />
        <Route exact path="/app-download" render={() => <AppDownload />} />
        <Route component={renderScreen(fromPathSlug)} />
      </Switch>
    )

    return <MainLayoutRoute path="/" component={DefaultLayoutRoutes} appConfig={appConfig} />
  }

  return (
    <>
      <Switch>
        {/* Not found 404 */}
        <Route exact path="/404" component={NotFound} />

        {/* Routes that use player layout */}
        <Route exact path="/trailer/:contentId" component={PlayerComponent} />
        <Route
          path="/trailer/:videoExtraId/:contentId"
          component={PlayerComponent}
        />
        <Route exact path="/watch/:contentId" component={PlayerComponent} />

        {/* Routes that use my account layout */}
        <Redirect exact from="/my-account" to="/my-account/details" />

        <MyAccountRoute path="/my-account/details" component={Settings} />
        <MyAccountRoute path="/my-account/subscription" component={Payment} />
        <MyAccountRoute path="/my-account/devices" component={Devices} />
        <MyAccountRoute path="/my-account/my-activity" component={MyActivity} />

        {/* Other modules */}
        <Route path="/link-account">
          <Suspense fallback="">
            <DirectCarrierBillingModule />
          </Suspense>
        </Route>

        <Route path="/signup">
          <Suspense fallback="">
            <SignupModule />
          </Suspense>
        </Route>

        <Route path="/content-onboarding">
          <Suspense fallback="">
            <OnboardingModule />
          </Suspense>
        </Route>

        {/* Routes that use main layout */}
        {getMainLayoutRoutes()}
      </Switch>
    </>
  )
}

Routes.propTypes = {
  appConfig: appConfigPropType.isRequired
}

export default Routes
