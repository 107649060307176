import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './select.css'

import requiredIcon from '../../../images/required-star.svg'
import arrowDown from '../../../images/select-arrow-down.svg'
import arrowUp from '../../../images/select-arrow-up.svg'
import AsyncReactSelect from '../../containers/async-react-select/index'
import { useRentalPageDetector } from '../../hooks/use-rental-page-detector'
import moreInfoIcon from '../../../images/neon/more-info-alt.svg'
import { TOOLTIPS } from '../../constants'

import {
  customReactSelectStyles,
  customReactSelectTheme
} from './select-custom-syles'

function Select({
  name,
  wrapperClass,
  labelClass,
  inputClass,
  warningClass,
  label,
  value,
  options,
  warning,
  exclamationClass,
  onChange,
  onBlur,
  readonly,
  isRequired,
  isValid,
  inline,
  plainTheme,
  useKidsTheme,
  darkTheme,
  hasTooltip
}) {
  const labelClassName = labelClass || styles.label
  const invalidClass = warning ? styles.invalid : ''
  const isValidClass = isValid ? styles.valid : ''
  const inputClassName = inputClass || styles.input
  const inputClasses = [inputClassName, invalidClass, isValidClass].join(' ')
  const warningClassName = warningClass || styles.warning
  const exclamationClassName = exclamationClass || styles.exclamation

  const wrapperClassNames = classNames(
    styles.wrapper,
    { [styles.inline]: inline },
    wrapperClass
  )

  const isOnARentalPage = useRentalPageDetector()

  const getTooltipContent = (tooltipName) => {
    return TOOLTIPS.find(item => item.name === tooltipName)?.content || ''
  }

  return (
    <div className={wrapperClassNames}>
      {
        label && (
          <label
            className={classNames(labelClassName, hasTooltip && styles.titleWithTooltipFrame)}
            htmlFor={name}
          >
            {label}
            {isRequired && <img className={styles.required} alt="Required" src={requiredIcon} />}
            { hasTooltip && (
              <div className={styles.tooltip}>
                <img src={moreInfoIcon} className={styles.tooltipIcon} />
                <span className={styles.tooltiptext}>
                  {getTooltipContent(name)}
                </span>
              </div>
            )}
          </label>
        )
      }
      {
        readonly
          ? <div className={styles.readonlyValue}>{value}</div>
          : (
            <div className={inputClasses}>
              <AsyncReactSelect
                styles={customReactSelectStyles({
                  plainTheme,
                  isKid: useKidsTheme,
                  width: '100%',
                  isRentalThemed: isOnARentalPage,
                  warningStyle: Boolean(warning),
                  darkTheme
                })}
                theme={customReactSelectTheme({
                  plainTheme,
                  isKid: useKidsTheme,
                  isRentalThemed: isOnARentalPage
                })}
                value={value}
                options={options}
                onChange={onChange}
                onBlur={onBlur}
                isSearchable={false}
                isClearable={false}
                deleteRemoves={false}
                backspaceRemoves={false}
                arrowRenderer={({ isOpen }) => {
                  if (isOpen) {
                    return (
                      <img alt="Arrow up" src={arrowUp} />
                    )
                  }
                  return (
                    <img alt="Arrow down" src={arrowDown} />
                  )
                }}
              />
            </div>
          )
      }
      {
        warning
          ? <span className={exclamationClassName} />
          : null
      }
      {
        !readonly && (
          <span className={warningClassName}>
            {warning}
          </span>
        )
      }
    </div>
  )
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  wrapperClass: PropTypes.string,
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
  warningClass: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object]),
  options: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  warning: PropTypes.string,
  exclamationClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  readonly: PropTypes.bool,
  isRequired: PropTypes.bool,
  isValid: PropTypes.bool,
  inline: PropTypes.bool,
  plainTheme: PropTypes.bool,
  useKidsTheme: PropTypes.bool,
  darkTheme: PropTypes.bool,
  hasTooltip: PropTypes.bool
}

Select.defaultProps = {
  wrapperClass: '',
  labelClass: '',
  inputClass: '',
  warningClass: '',
  label: '',
  value: null,
  options: [],
  warning: '',
  exclamationClass: '',
  readonly: false,
  isRequired: false,
  isValid: false,
  inline: false,
  plainTheme: false,
  useKidsTheme: false,
  darkTheme: false,
  hasTooltip: false,
  onBlur: () => {}
}

export default Select
