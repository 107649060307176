import ActionTypes from '../actions/types'

const onValidPin = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.PIN_REQUIRE:
      return action.onValidPin
    case ActionTypes.PIN_VALIDATE:
    case ActionTypes.PIN_DISMISS:
      return null
    default:
      return state
  }
}

const visibility = (state = false, action) => {
  switch (action.type) {
    case ActionTypes.PIN_REQUIRE:
      return true
    case ActionTypes.PIN_VALIDATE:
    case ActionTypes.PIN_DISMISS:
      return false
    default:
      return state
  }
}

const timestamp = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.PIN_VALIDATE:
      return new Date()
    default:
      return state
  }
}

const pin = (state = {}, action) => {
  return {
    onValidPin: onValidPin(state.onValidPin, action),
    timestamp: timestamp(state.timestamp, action),
    visibility: visibility(state.visibility, action)
  }
}

export default pin
