import React, { Suspense } from 'react'

const Player = React.lazy(() => import('./player'))

const AsyncPlayer = props => (
  <Suspense fallback={null}>
    <Player {...props} />
  </Suspense>
)

export default AsyncPlayer
