import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

import { appConfigPropType } from '../../components/player/utils/prop-types'
import MainLayout from './enhanced-main-layout'

const MainLayoutRoute = ({ component, appConfig, ...rest }) => (
  <Route
    {...rest}
    render={() => (
      <MainLayout appConfig={appConfig}>
        {component}
      </MainLayout>
    )}
  />
)

MainLayoutRoute.propTypes = {
  component: PropTypes.element,
  appConfig: appConfigPropType.isRequired
}

MainLayoutRoute.defaultProps = {
  component: null
}

export default MainLayoutRoute
