import React, { Suspense } from 'react'
import { LoadingComponent } from '../../../loading/index'

const Devices = React.lazy(() => import('./devices-and-downloads'))

const AsyncDevices = props => (
  <Suspense fallback={<LoadingComponent />}>
    <Devices {...props} />
  </Suspense>
)

export default AsyncDevices
