import ActionTypes from '../actions/types'

const error = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.GENERIC_ERROR_MSG_SHOW:
    case 'APOLLO_QUERY_ERROR':
      return {
        code: action.error.code,
        message: action.error.message
      }
    case ActionTypes.GENERIC_ERROR_MSG_DISMISS:
      return {}
    default:
      return state
  }
}

export default error
