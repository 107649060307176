import PropTypes from 'prop-types'

export const navItemPropType = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string,
  isDiscoverNav: PropTypes.bool
}

navItemPropType.children = PropTypes.arrayOf(PropTypes.shape(navItemPropType))

export const navSectionPropType = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.shape(navItemPropType))
  })
)
