import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { planDetailsForAvod } from '../../../shared/subscription-constants'
import { MANAGE_PLAN_OPTIONS } from '../../../../constants'
import { STORAGE_KEY_VOUCHER_APPLICABLE_PLANS } from '../../../../storage-keys'
import { useStyles } from './subscription-contents-view.styles'
import SubscriptionContentSection from '../subscription-content-section/subscription-content-section'
import SubscriptionSelectButtons from '../subscription-select-button/subscription-select-button'
import { usePlanChangeFlag } from '../../../../hooks/usePlanChange'

const SubscriptionContentsView = props => {
  const styles = useStyles()
  const [voucherAvailable, setVoucherAvailable] = useState(false)
  const getPlanDetails = usePlanChangeFlag()
  const {
    activeSubscription, updateActiveSubscription, selectToNext, showRentMovies, currentSubscription,
    oneWayChangeDirection, hasRecurringVouchers, addSubscriptionFromSignup
  } = props

  useEffect(() => {
    // run a function when the component unmounts
    return () => {
      // Clear the voucher info in session storage, otherwise voucher message will be in the normal select plan modal
      if (!addSubscriptionFromSignup) {
        sessionStorage.removeItem(STORAGE_KEY_VOUCHER_APPLICABLE_PLANS)
      }
    }
  }, [])

  const isCurrentSubscriptionSelected = currentSubscription === activeSubscription
  // Display warning info if only downgrade allowed and user select annual plan
  const upgradeIsNotAvailable =
  (oneWayChangeDirection === MANAGE_PLAN_OPTIONS.DOWNGRADE) &&
  (activeSubscription === getPlanDetails?.annual.title) &&
  !isCurrentSubscriptionSelected

  const plans = planDetailsForAvod.features.map(item => {
    return (
      <div key={item.title} style={{ width: '100%' }}>
        <div className={styles.divider} />

        {item.extraInfoWithTitle ? (
          <SubscriptionContentSection
            title={item.title}
            data={item.extraInfoWithTitle}
            iconName={item.iconName}
            subTitle={item.subTitle}
            subTitleLink={item.subTitleLink}
            activeSubscription={activeSubscription}
            updateActiveSubscription={updateActiveSubscription}
            currentSubscription={currentSubscription}
            titleWithExtraInfoStyle
          />
        ) : (
          <SubscriptionContentSection
            title={item.title}
            data={item.content}
            iconName={item.iconName}
            subTitle={item.subTitle}
            subTitleLink={item.subTitleLink}
            activeSubscription={activeSubscription}
            updateActiveSubscription={updateActiveSubscription}
            currentSubscription={currentSubscription}
          />
        )}
      </div>
    )
  })

  const subscriptions = sessionStorage.getItem(STORAGE_KEY_VOUCHER_APPLICABLE_PLANS)?.split(',')

  const voucherRelatedPlans = subscriptions ? [getPlanDetails.annual, getPlanDetails.basic, getPlanDetails.standard].filter(
    item => subscriptions.includes(item.sku)
  )
    : []

  const VoucherMessage = () => {
    if ((addSubscriptionFromSignup && voucherRelatedPlans.length > 0) || voucherRelatedPlans.length > 1) {
      if (voucherRelatedPlans.find(item => item.type.toLowerCase() === activeSubscription.toLowerCase())) {
        setVoucherAvailable(true)
        return <p className={styles.availableVoucher}>Your voucher applies to this plan</p>
      }
      setVoucherAvailable(false)
      return <p className={styles.notAvailableVoucher}>Sorry, your voucher isn’t valid for this plan</p>
    }

    return null
  }

  return (
    <>
      {plans}

      <SubscriptionSelectButtons
        activeSubscription={activeSubscription}
        selectToNext={selectToNext}
        showRentMovies={showRentMovies}
        isCurrentSubscriptionSelected={isCurrentSubscriptionSelected}
        currentSubscription={currentSubscription}
        upgradeIsNotAvailable={upgradeIsNotAvailable}
        hasRecurringVouchers={hasRecurringVouchers}
        voucherAvailable={voucherAvailable}
        addSubscriptionFromSignup={addSubscriptionFromSignup}
      />

      <VoucherMessage />
    </>
  )
}

SubscriptionContentsView.propTypes = {
  activeSubscription: PropTypes.string.isRequired,
  updateActiveSubscription: PropTypes.func.isRequired,
  selectToNext: PropTypes.func.isRequired,
  showRentMovies: PropTypes.bool.isRequired,
  currentSubscription: PropTypes.string,
  oneWayChangeDirection: PropTypes.string,
  hasRecurringVouchers: PropTypes.string,
  addSubscriptionFromSignup: PropTypes.bool.isRequired
}

SubscriptionContentsView.defaultProps = {
  activeSubscription: '',
  updateActiveSubscription: null,
  selectToNext: null,
  showRentMovies: true,
  currentSubscription: '',
  hasRecurringVouchers: null,
  addSubscriptionFromSignup: false
}

export default SubscriptionContentsView
