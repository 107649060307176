import React from 'react'

import { planDetailsForAvod } from '../../../shared/subscription-constants'
import theme from '../../../../theme'
import CheckIcon from '../icons/check-icon'
import { useStyles } from './subscription-card-content.styles'

const SubscriptionCardContent = () => {
  const styles = useStyles()

  return (
    <div className={styles.paymentMethodCardContent}>
      <div className={styles.paymentMethodCardContentRow}>
        <span className={styles.paymentMethodCardCheckIcon}>
          <CheckIcon color={theme.neonGreen} />
        </span>
        {planDetailsForAvod.unlimitedAccess}
      </div>
    </div>
  )
}

export default SubscriptionCardContent
