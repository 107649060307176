export const loadScript = (src, onLoad, options = {}) => {
  const script = document.createElement('script')
  script.src = src

  if (options.id) {
    script.id = options.id
  }

  // default async to true if not set with custom attributes
  if (!script.hasAttribute('async')) {
    script.async = true
  }

  script.onload = onLoad

  // TODO proper error handling
  script.onerror = error => {
    throw error
  }

  document.body.appendChild(script)

  return script
}

export const loadLink = (src, onLoad) => {
  const link = document.createElement('link')
  link.href = src
  link.rel = 'stylesheet'
  link.onload = onLoad
  document.getElementsByTagName('head')[0].appendChild(link)

  return link
}
