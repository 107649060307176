import {
  graphql,
  withApollo
} from 'react-apollo'
import {
  compose,
  branch,
  renderNothing,
  withHandlers,
  withState,
  withProps,
  setDisplayName
} from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { pathOr } from 'ramda'

import { checkIsAuthenticated } from '../../lib/auth'
import {
  toggleAccountNavVisibility,
  setAccountNavVisibility,
  logoutAndRedirectTo,
  switchProfileWithPin,
  switchProfile,
  toggleNavigationVisibility
} from '../../actions'
import { requirePinForSwitchProfile } from '../../lib/account'
import { findConfigValue } from '../../lib/config'
import { hasHigherParentalRating, checkQueryStatus } from '../../lib/utils'
import withConfigCacheOnly from '../../hoc/with-config'

import { HELP_SITE } from '../../constants'

import ACCOUNT_QUERY from '../../../graphql/queries/account.gql'
import MY_LIST_QUERY from '../../../graphql/queries/my-list.gql'
import MY_RENTALS_QUERY from '../../../graphql/queries/my-rentals.gql'
import { segmentTrackSignout } from '../../segment/segment-track'

const mapStateToProps = (state) => {
  return {
    display: state.navigation.accountNav,
    isAuthenticated: checkIsAuthenticated(state),
    theme: state.theme,
    navComponentWidth: state.navigation.navComponentWidth
  }
}

const mapDispatchToProps = dispatch => ({
  onAccountNavClick: () => {
    dispatch(toggleAccountNavVisibility())
  },
  onLogoutClick: (email) => {
    // Add segment data analytics for signing out
    segmentTrackSignout({
      email
    })
    dispatch(logoutAndRedirectTo(''))
  },
  onLogoutSettingClick: (pathname) => {
    dispatch(logoutAndRedirectTo(pathname))
  },
  onSideNavLinkClick: () => {
    dispatch(toggleNavigationVisibility())
  },
  handleClickOutside: (event) => {
    if (event.target.closest('#navigation-account-side') ||
      event.target.closest('#navigation-account-nav-button')) {
      return
    }

    dispatch(setAccountNavVisibility(false))
  },
  dispatch
})

const NavigationAccountHoC = compose(
  setDisplayName('NavigationAccountHoC'),
  withRouter,
  withApollo,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  graphql(ACCOUNT_QUERY, {
    name: 'accountQuery',
    skip: ownProps => !ownProps.isAuthenticated,
    options: {
      notifyOnNetworkStatusChange: true
    }
  }),
  graphql(MY_LIST_QUERY, {
    name: 'myListQuery',
    skip: ownProps => !ownProps.isAuthenticated,
    options: {
      notifyOnNetworkStatusChange: true
    },
    props: ({ ownProps, myListQuery }) => ({
      ...ownProps,
      myListQuery,
      myList: myListQuery.myList
    })
  }),
  graphql(MY_RENTALS_QUERY, {
    name: 'myRentalsQuery',
    skip: ownProps => !ownProps.isAuthenticated,
    options: {
      notifyOnNetworkStatusChange: true
    },
    props: ({ ownProps, myRentalsQuery }) => ({
      ...ownProps,
      myRentalsQuery,
      myRentals: myRentalsQuery.myRentalHistory
    })
  }),
  branch(
    ({ accountQuery, myRentalsQuery, myListQuery }) => (
      checkQueryStatus(accountQuery) ||
      checkQueryStatus(myRentalsQuery) ||
      checkQueryStatus(myListQuery)
    ),
    renderNothing
  ),
  withConfigCacheOnly,
  withState('selectedProfile', 'setSelectedProfile', null),
  withProps(({
    accountQuery, myListQuery, myRentalsQuery, appConfig
  }) => ({
    requirePin: accountQuery && requirePinForSwitchProfile(accountQuery.account),
    currentProfile: accountQuery && accountQuery.account.profiles.find(
      profile => profile.id === accountQuery.account.selectedProfile
    ),
    myListTotalItems: pathOr(0, ['mylist', 'totalItems'], myListQuery),
    myRentalsTotalItems: pathOr(0, ['myRentalHistory', 'totalCurrentRentals'], myRentalsQuery),
    helpURLConfig: findConfigValue(HELP_SITE.URL_HELP_SITE, appConfig),
    feedbackURLConfig: findConfigValue(HELP_SITE.URL_FEEDBACK, appConfig),
    email: accountQuery?.account?.email,
    cpId: accountQuery?.account?.cpCustomerID
  })),
  withHandlers({
    onSelectProfile: ({
      dispatch,
      requirePin,
      currentProfile
    }) => (selectedProfile) => {
      if (requirePin &&
          hasHigherParentalRating(currentProfile.maxRating, selectedProfile.maxRating)) {
        dispatch(switchProfileWithPin(selectedProfile))
        return
      }
      dispatch(switchProfile(selectedProfile))
    }
  })
)

export default NavigationAccountHoC
