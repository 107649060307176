import {
  either,
  isEmpty,
  isNil
} from 'ramda'

const APP_GRAPHQL_API_URI = `${window.location.protocol}//${window.location.hostname.replace('www', 'api')}/api/client/gql?`

export const getGraphQLURI = () => {
  if (either(isNil, isEmpty)(process.env.APP_GRAPHQL_API_URI)) {
    return APP_GRAPHQL_API_URI
  }
  return process.env.APP_GRAPHQL_API_URI
}
