import React, { useState, useRef, useEffect } from 'react'
import { useMutation } from 'react-apollo'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'

import Video from './video'
import Image from './image'
import Control from './control'
import Synopsis, { SynopsisType } from './synopsis'
import HotspotVideoCounter from './hotspot-video-counter'
import RATINGFIELDS_FRAGMENT from '../../../../graphql/fragments/rating.gql'
import INCREMENT_VIDEO_HOTSPOT_PLAYBACK_COUNT from '../../../../graphql/mutations/increment-video-hotspot-playback-count.gql'

import styles from './hotspot-video.css'
import rentalBadge from '../../../../images/neon/rental-badge.png'

const counter = new HotspotVideoCounter()

const HotspotVideo = ({ content, fallback }) => {
  const {
    video, maxShown, isRental, image, synopsis
  } = content
  const selectedVideo = window.innerWidth < 1280 ? video.low : video.high
  const shownCount = counter.getByVideo(selectedVideo)

  const [{ isPlaying, shouldHideImage }, setState] = useState({
    isPlaying: false,
    shouldHideImage: false
  })

  const [incrementVideoHotspotPlaybackCount] = useMutation(
    INCREMENT_VIDEO_HOTSPOT_PLAYBACK_COUNT,
    {
      variables: {
        videoPath: selectedVideo
      }
    }
  )

  const videoRef = useRef()
  let onLoadedTimer

  const play = () => {
    if (!videoRef.current) return

    const { player } = videoRef.current
    player.play()
  }

  const onPlaybackStarted = () => {
    setState({ isPlaying: true, shouldHideImage: true })
  }

  const onLoaded = () => {
    let { delaySeconds } = content

    if (!delaySeconds) {
      delaySeconds = 0
    }

    onLoadedTimer = setTimeout(() => {
      play()
      onPlaybackStarted()
    }, delaySeconds * 1000)
  }

  useEffect(() => {
    return () => {
      if (onLoadedTimer) {
        clearTimeout(onLoadedTimer)
      }
    }
  }, [])

  return shownCount > maxShown ? (
    fallback
  ) : (
    <div className={styles.wrapper}>
      { isRental && <img className={styles.rentalBadge} src={rentalBadge} alt="Rental Content" /> }

      <div className={styles.videoWrapper}>
        <Control
          player={videoRef.current && videoRef.current.player}
          isPlaying={isPlaying}
        />

        <Image isHidden={shouldHideImage} image={image} />

        <Video
          ref={videoRef}
          video={selectedVideo}
          onEnded={() => {
            setState({ isPlaying: false, shouldHideImage: false })
            counter.incrementByVideo(selectedVideo)
            incrementVideoHotspotPlaybackCount()
          }}
          onLoadedData={onLoaded}
          onPlay={onPlaybackStarted}
          onPause={() => {
            setState({ isPlaying: false, shouldHideImage })
          }}
        />

        <div className={styles.videoLeftGradient} />
        <div className={styles.videoBottomGradient} />
      </div>
      <Synopsis synopsis={synopsis} isRental={isRental} rating={content.rating} />
    </div>
  )
}

export const HotspotVideoContentType = PropTypes.shape({
  image: PropTypes.string.isRequired,
  video: PropTypes.shape({
    high: PropTypes.string,
    low: PropTypes.string
  }).isRequired,
  synopsis: SynopsisType.isRequired,
  maxShown: PropTypes.number.isRequired,
  delaySeconds: PropTypes.number,
  isRental: PropTypes.bool.isRequired,
  rating: propType(RATINGFIELDS_FRAGMENT).isRequired
})

HotspotVideo.propTypes = {
  content: HotspotVideoContentType.isRequired,
  fallback: PropTypes.element.isRequired
}

export default HotspotVideo
