import React, { Suspense } from 'react'

const BackToTop = React.lazy(() => import('./back-to-top'))

const AsyncBackToTop = props => (
  <Suspense fallback={null}>
    <BackToTop {...props} />
  </Suspense>
)

export default AsyncBackToTop
