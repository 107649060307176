import { isEmpty } from 'ramda'

const withHandleResume = ({
  mutateAndLoading,
  sku,
  isUpcomingCancelled,
  upcomingFinalBillSku,
  handleUpdateSubscription
}) => () => {
  if (isUpcomingCancelled && !isEmpty(upcomingFinalBillSku)) {
    return mutateAndLoading('reactivateSubscription', {
      variables: { sku: upcomingFinalBillSku }
    }).then(handleUpdateSubscription)
  }

  return mutateAndLoading('reactivateSubscription', {
    variables: { sku }
  }).then(handleUpdateSubscription)
}

export default withHandleResume
