const placeholders = {
  landscape:
    'https://lightbox-prod.imgix.net/images/uploads/1592955999037-neon-placeholder-16-9.png?h=100&fit=crop',
  portrait:
    'https://lightbox-prod.imgix.net/images/uploads/1592955969441-neon-placeholder-2-3.png?h=100&fit=crop'
}

const appendAspectRatio = (placeholderImage, aspectRatio) => {
  return `${placeholderImage}&ar=${1 / aspectRatio}`
}

/**
 * Provides a suitable placeholder for the lazy-loaded image based on the recipe config
 *
 * @param {recipe} recipeConfig The recipe config that should be used to get the dimensions and aspect ratio
 */
export const getPlaceholder = (recipeConfig) => {
  return recipeConfig.ratio > 1
    ? appendAspectRatio(placeholders.portrait, recipeConfig.ratio)
    : appendAspectRatio(placeholders.landscape, recipeConfig.ratio)
}
