import React from 'react'
import { withRouter } from 'react-router-dom'

import NavigationSettings from '../../layouts/shared-navigation/navigation-settings'
import NavigationAccountHoC from './navigation-account'

export default withRouter(NavigationAccountHoC(({ ...props }) => {
  return (
    <NavigationSettings
      {...props}
    />
  )
}))
