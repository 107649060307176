import ActionTypes from '../actions/types'

const manageSubsOptions = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.SET_MANAGE_SUBS_OPTIONS:
      return action.manageSubsOptions
    default:
      return state
  }
}

export default manageSubsOptions
