import React from 'react'
import classnames from 'classnames'
import PT from 'prop-types'
import { compose, getContext } from 'recompose'
import { addIndex, map } from 'ramda'

import styles from './slide.css'
import { LEFT_SLIDE, RIGHT_SLIDE, STILL } from './constants'
import { buildIndexArray, buildIndexSequence } from './utils'
import withTouchHandler from './with-touch-handler'

function Slide({
  duration,
  slidesToShow,
  offset,
  children,
  sliderStatus,
  onTransitionFinish,
  margin,
  isHideLeft,
  isHideRight,
  doNotLoopSlides,
  hideSlideRightButton,
  hideSlideLeftButton
}) {
  const containerWidth = `${100 / slidesToShow}%`
  const slideTransition = `${duration}ms`

  function wrapChildren(childrenToWrap, key) {
    return (
      <div
        key={key}
        className={styles.itemWrapper}
        style={{ width: containerWidth }}
      >
        {React.cloneElement(childrenToWrap, {
          className: classnames(
            styles.itemStyle,
            childrenToWrap.props.className
          )
        })}
      </div>
    )
  }

  const createSequence = buildIndexSequence(children.length)

  const mapWithIndex = addIndex(map)

  const generateElements = compose(
    mapWithIndex(wrapChildren),
    map(idx => children[idx])
  )

  const startingPoint = slidesToShow * offset
  // dislay the rest of slides if the number of slides in center < slidesToShow
  const slidesToShowInCenter = (doNotLoopSlides && children.length - startingPoint < slidesToShow)
    ? children.length - startingPoint : slidesToShow

  const sequenceBuilderLeft = compose(
    generateElements,
    buildIndexArray(children.length, slidesToShow + 1),
    start => createSequence(start - 1)
  )

  const sequenceBuilderRight = compose(
    generateElements,
    buildIndexArray(children.length, slidesToShow + 1),
    createSequence
  )

  const sequenceBuilderCenter = compose(
    generateElements,
    buildIndexArray(children.length, slidesToShowInCenter),
    createSequence
  )

  let left = []
  let right = []
  let center = []

  if (doNotLoopSlides) {
    // Create left, right and center slides for carousel which is not required for looping slides
    center = sequenceBuilderCenter(startingPoint)

    if (slidesToShow < children.length - startingPoint) {
      // Set right slides except the last page
      right = sequenceBuilderRight(startingPoint + slidesToShow)
      if (children.length - startingPoint <= 2 * slidesToShow) {
        // Reset right slides in the second last page when slide to right
        right = right.slice(0, children.length - startingPoint - slidesToShow)
      }
    }

    if (children.length - startingPoint <= slidesToShow && !isHideRight && sliderStatus === RIGHT_SLIDE) {
      // Remove all right slides if the number of the rest of slides in center <= slidesToShow
      right = []
      left = sequenceBuilderLeft(startingPoint - slidesToShow)
      // Stop user slide to further right
      hideSlideRightButton()
    }

    if (startingPoint === 0 && !isHideLeft && sliderStatus === LEFT_SLIDE) {
      // Remove all left slides if user slide to the first page
      left = []
      right = sequenceBuilderRight(startingPoint + slidesToShow)
      // Stop user slide to further left
      hideSlideLeftButton()
    } else if (!isHideLeft && offset > 0) {
      // Set left slides except the first page
      left = sequenceBuilderLeft(startingPoint - slidesToShow)
      if (offset === 1) {
        // Reset left slides in the second page when slide to left
        left = left.slice(1)
      }
    }
  } else {
    // Create left, right and center slides for carousel which is required for looping slides
    left = sequenceBuilderLeft(startingPoint - slidesToShow)
    right = sequenceBuilderRight(startingPoint + slidesToShow)
    center = sequenceBuilderCenter(startingPoint)
  }

  return (
    <div
      onTransitionEnd={evt => {
        if (evt.currentTarget === evt.target) {
          onTransitionFinish()
        }
      }}
      style={{
        transitionDuration:
          sliderStatus !== STILL ? slideTransition : undefined,
        marginLeft: margin,
        marginRight: margin
      }}
      className={classnames(styles.still, styles.sliderWrapper, {
        [styles.slideLeft]: sliderStatus === LEFT_SLIDE,
        [styles.slideRight]: sliderStatus === RIGHT_SLIDE,
        [styles.disabled]: sliderStatus !== STILL
      })}
    >
      <div
        className={classnames(styles.bucket, {
          [styles.hide]: isHideLeft
        })}
      >
        <div
          className={classnames(styles.bucketEdge, styles.leftBucket)}
          style={{
            msTransform: `translateX(-${containerWidth})` // for the love of ie
          }}
        >
          {left}
        </div>
      </div>

      <div className={classnames(styles.bucket)}>
        <div className={styles.bucketEdge} data-e2e="slides-center">{center}</div>
      </div>

      <div
        className={classnames(styles.bucket, {
          [styles.hide]: isHideRight
        })}
      >
        <div className={styles.bucketEdge}>{right}</div>
      </div>
    </div>
  )
}

Slide.propTypes = {
  sliderStatus: PT.string.isRequired,
  onTransitionFinish: PT.func.isRequired,
  slidesToShow: PT.number.isRequired,
  duration: PT.number.isRequired,
  offset: PT.number.isRequired,
  isHideLeft: PT.bool.isRequired,
  isHideRight: PT.bool.isRequired,
  margin: PT.number,
  doNotLoopSlides: PT.bool,
  hideSlideRightButton: PT.func,
  hideSlideLeftButton: PT.func
}

Slide.defaultProps = {
  margin: 0,
  doNotLoopSlides: false
}

const enhance = compose(
  withTouchHandler(),
  getContext({
    slidesToShow: PT.number.isRequired,
    duration: PT.number.isRequired,
    offset: PT.number.isRequired,
    isHideLeft: PT.bool.isRequired,
    isHideRight: PT.bool.isRequired
  })
)

export default enhance(Slide)
