import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ControlCenter from './control-center'
import ControlVolume from './control-volume'
import ControlPlay from './control-play'
import styles from './control.css'

export const CONTROL_FADE_TIMEOUT = 2000

class ControlVhsStream extends React.PureComponent {
  state = {
    isUserInteracted: false
  }

  componentDidMount() {
    window.addEventListener('touchstart', this.toggleControl)
    window.addEventListener('mousemove', this.toggleControl)
    window.addEventListener('click', this.toggleControl)
  }

  componentWillUnmount() {
    window.removeEventListener('touchstart', this.toggleControl)
    window.removeEventListener('mousemove', this.toggleControl)
    window.removeEventListener('click', this.toggleControl)

    clearTimeout(this.touchTimer)
  }

  toggleControl = () => {
    clearTimeout(this.touchTimer)
    this.setState({ isUserInteracted: true }, () => {
      this.touchTimer = setTimeout(() => {
        this.setState({ isUserInteracted: false })
      }, CONTROL_FADE_TIMEOUT)
    })
  }

  render() {
    if (!this.props.player) return null

    const { isPlaying, player } = this.props
    const { isUserInteracted } = this.state

    return (
      <div className={styles.wrapper}>
        <ControlCenter
          wrapperClassName={styles.controlPlay}
          play={() => player.play()}
          pause={() => player.pause()}
          isPlaying={isPlaying}
        />
        <div
          className={classnames(styles.lowerControlWrapper, {
            [styles.show]: !isPlaying || isUserInteracted
          })}
        >
          <ControlPlay
            wrapperClassName={styles.controlElement}
            iconWrapperClassName={styles.lowerControlIconWrapper}
            iconClassName={styles.lowerControlIcon}
            play={() => player.play()}
            pause={() => player.pause()}
            isPlaying={isPlaying}
          />
          <ControlVolume
            wrapperClassName={styles.controlElement}
            iconClassName={classnames(
              styles.lowerControlIcon,
              styles.lowerControlIconWrapper
            )}
            mute={() => player.muted(true)}
            unmute={() => player.muted(false)}
          />
        </div>
      </div>
    )
  }
}

ControlVhsStream.propTypes = {
  player: PropTypes.shape({
    play: PropTypes.func.isRequired,
    pause: PropTypes.func.isRequired,
    muted: PropTypes.func.isRequired
  }),
  isPlaying: PropTypes.bool.isRequired
}

ControlVhsStream.defaultProps = {
  player: null
}

export default ControlVhsStream
