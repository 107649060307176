import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles(theme => ({
  wrapper: {
    width: '100vw',
    paddingBottom: '3vh',
    boxSizing: 'border-box'
  },

  '@media only screen and (max-width: 767px)': {
    wrapper: {
      marginTop: '50px'
    }
  },

  searchTitle: {
    marginTop: 20,
    marginLeft: 50,
    fontSize: 24
  },

  searchQuery: {
    color: [theme.neonGreen]
  }
}))
