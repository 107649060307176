import { pathOr } from 'ramda'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import {
  compose,
  branch,
  renderNothing,
  withProps,
  withHandlers,
  lifecycle
} from 'recompose'

import ServiceMessages from '../../components/service-messages'
import { dismissServiceMessage } from '../../actions'
import { withElementHeightWatching } from '../../hoc/with-element-height-watching'

import SERVICE_MESSAGES_QUERY from '../../../graphql/queries/service-messages.gql'

const enhance = compose(
  withElementHeightWatching,
  withRouter,
  connect(
    (state) => {
      return {
        maintenanceMode: state.maintenance.mode,
        dismissed: pathOr([], ['serviceMessages', 'dismissed'], state)
      }
    },
    dispatch => ({
      dispatch
    })
  ),
  graphql(SERVICE_MESSAGES_QUERY, {
    name: 'serviceMessagesQuery',
    options: () => {
      return {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
      }
    },
    skip: ({ maintenanceMode }) => maintenanceMode
  }),
  lifecycle({
    UNSAFE_componentWillReceiveProps({ location }) {
      const {
        location: currLocation,
        serviceMessagesQuery
      } = this.props

      if (
        currLocation.pathname === location.pathname ||
        !serviceMessagesQuery
      ) {
        return
      }

      serviceMessagesQuery.refetch()
    }
  }),
  branch(
    ({ serviceMessagesQuery }) => {
      const isLoading = pathOr(false, 'loading', serviceMessagesQuery)
      const hasError = pathOr(false, 'error', serviceMessagesQuery)
      const hasNoServiceMessages = !pathOr([], ['serviceMessages'], serviceMessagesQuery).length
      return (isLoading || hasError || hasNoServiceMessages)
    },
    renderNothing
  ),
  withProps(({ dismissed, serviceMessagesQuery = {} }) => {
    const serviceMessages = serviceMessagesQuery.serviceMessages
    const serviceMessage = serviceMessages.find((sm) => {
      const dismissedSM = dismissed[sm.id]
      return !dismissedSM || (dismissedSM && dismissedSM.updatedAt < sm.updatedAt)
    })
    return {
      serviceMessage
    }
  }),
  branch(
    ({ serviceMessage }) => !serviceMessage,
    renderNothing
  ),
  withHandlers({
    handleDismiss: ({ dispatch }) => (serviceMessage) => {
      dispatch(dismissServiceMessage(serviceMessage))
    }
  })
)

export default enhance(ServiceMessages)
