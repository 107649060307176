import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './section.css'

function Section({
  title,
  children,
  className,
  headerClassName,
  testerClassName
}) {
  return (
    <div className={classNames(testerClassName, styles.sectionWrapper, className)}>
      <h3
        className={classNames(styles.header, headerClassName)}
        data-lbx-e2e={title}
      >
        {title}

      </h3>
      <div>
        {children}
      </div>
    </div>
  )
}

Section.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  testerClassName: PropTypes.string
}

Section.defaultProps = {
  className: '',
  headerClassName: '',
  testerClassName: ''
}

export default Section
