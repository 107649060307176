import React from 'react'
import { Helmet } from 'react-helmet'

import { getLocationBase } from './lib/location'
import { extendJSONLDSchema } from './components/jsonld/index'

import neonLogo from '../images/neon/neon-logo-hoz-wht-rgb.svg'

const metadata = {
  neon: {
    logo: neonLogo,
    title: 'NEON - Watch On Demand Movies & TV Shows Online',
    description: 'Watch hit TV shows on-demand and rent blockbuster movies with NEON.',
    socials: [
      'https://www.facebook.com/NeonNewZealand/',
      'https://twitter.com/neonnz',
      'https://www.instagram.com/NEON_NZ/'
    ]
  }
}

const Head = () => {
  const brand = 'Neon'
  const meta = metadata.neon

  const openGraphImage = `${getLocationBase()}${meta.logo}`
  const googleLogoSchema = {
    url: getLocationBase(),
    logo: `${getLocationBase()}${meta.logo}`,
    name: brand,
    sameAs: meta.socials
  }

  return (
    <Helmet>
      <link rel="shortcut icon" href="/favicon-neon.ico" />

      <script type="application/ld+json">
        {extendJSONLDSchema(googleLogoSchema, 'Organization')}
      </script>

      <meta property="fb:pages" content="238639659662821" />

      <meta name="title" property="og:title" content={meta.title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={getLocationBase()} />
      <meta property="og:image" content={openGraphImage} />
      <meta
        name="description"
        property="og:description"
        content={meta.description}
      />

      <title>{meta.title}</title>
    </Helmet>
  )
}

export default Head
