/* eslint-disable react/jsx-closing-tag-location */
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import ReactSvg from 'react-svg'
import classNames from 'classnames'

import rightArrow from '../../../images/arrow-right.svg'

import styles from './grid-header-breadcrumb.css'
import { CategoryContext } from '../category/category-context'
import { useRentalPageDetector } from '../../hooks/use-rental-page-detector'

const GridHeaderBreadcrumb = ({
  isEmptyGrid, isKidTheme, header
}) => {
  if (isEmptyGrid) {
    return null
  }
  const categoryInfo = useContext(CategoryContext)
  const selectedCategory = categoryInfo?.selectedCategory
  const linkedNavItemName = categoryInfo?.linkedNavItemName
  const isOnARentalThemedPage = useRentalPageDetector()

  return (
    <div className={classNames(styles.wrapper, {
      [styles.kidsThemeWrapper]: isKidTheme
    })}
    >
      <div className={classNames(styles.mainTitle, {
        [styles.kidsThemeTitle]: isKidTheme
      })}
      >
        {isKidTheme ? header : (linkedNavItemName || '')}
      </div>
      {selectedCategory && linkedNavItemName
        // eslint-disable-next-line react/jsx-wrap-multilines
        ? <>
          <ReactSvg
            src={rightArrow}
            className={styles.rightArrow}
          />
          <div className={classNames(styles.header, {
            [styles.rentalHeader]: isOnARentalThemedPage
          })}
          >
            {selectedCategory.name}
          </div>
        </>
        : null}
    </div>
  )
}

GridHeaderBreadcrumb.propTypes = {
  isEmptyGrid: PropTypes.bool,
  header: PropTypes.string,
  isKidTheme: PropTypes.bool.isRequired
}

GridHeaderBreadcrumb.defaultProps = {
  isEmptyGrid: false,
  header: ''
}

export default GridHeaderBreadcrumb
