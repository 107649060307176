import React from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'
import classnames from 'classnames'

import styles from './navigation-burger-icon.css'
import { THEME_OPTIONS } from '../../constants'
import burgerIcon from '../../../images/burger-new.svg'
import crossIcon from '../../../images/cross.svg'
import { E2E_SELECTORS } from '../../e2e-selectors'

function NavigationBurgerIcon({
  onClick,
  className,
  display,
  theme
}) {
  const iconStyle = theme === THEME_OPTIONS.light
    ? styles.iconSVGDark
    : styles.iconSVG
  return (
    <div // eslint-disable-line jsx-a11y/no-static-element-interactions
      className={`${styles.burgerIcon} ${className}`.trim()}
      onClick={() => onClick()}

    >
      <ReactSVG
        src={display ? crossIcon : burgerIcon}
        beforeInjection={(svg) => {
          svg.setAttribute('class', classnames({
            [iconStyle]: true,
            [styles.cross]: display
          }))
        }}
        className={styles.iconSVGWrapper}
        data-lbx-e2e={E2E_SELECTORS.HAMBURGER_ICON}
      />
    </div>
  )
}

NavigationBurgerIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  display: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired
}

NavigationBurgerIcon.defaultProps = {
  className: ''
}

export default NavigationBurgerIcon
