import React, { Suspense } from 'react'
import { LoadingComponent } from '../../components/loading/index'

const MyAccountLayout = React.lazy(() => import('./my-account-layout'))

const AsyncMyAccountLayout = props => (
  <Suspense fallback={<LoadingComponent />}>
    <MyAccountLayout {...props} />
  </Suspense>
)

export default AsyncMyAccountLayout
