import React from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'
import { Link } from 'react-router-dom'

import kidsLogo from '../../../../images/neon/kids-logo.svg'

import styles from './kids-logo.css'

import { E2E_SELECTORS } from '../../../e2e-selectors'

const KidsLogo = ({
  onClick
}) => {
  return (
    <Link
      to="/"
      className={styles.logoLink}
      onClick={onClick}
    >
      <ReactSVG
        src={kidsLogo}
        data-lbx-e2e={E2E_SELECTORS.KIDS_LOGO}
        className={styles.logo}
      />
    </Link>
  )
}

KidsLogo.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default KidsLogo
