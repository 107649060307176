import { curry, map } from 'ramda'
/**
 * I stole this from `ramda-adjunct` and plopped it in here
 * to save from bringing in the whole library.
 * https://github.com/char0n/ramda-adjunct/blob/7708ddf14dc33975ec317254ba6a7e6195097fa0/src/lensIso.js
 */

const isomorphic = (to, from) => {
  const isomorphism = x => to(x)
  isomorphism.from = from
  return isomorphism
}

const isomorphisms = (to, from) => isomorphic(
  curry((toFunctorFn, target) => map(from, toFunctorFn(to(target)))),
  curry((toFunctorFn, target) => map(to, toFunctorFn(from(target))))
)

const from = curry((isomorphism, x) => isomorphism.from(x))

/*
 * Defines an isomorphism that works like a lens, only instead
 * of providing a setter and getter,
 * it provides a transformer and an un-transformer.
 * It can be composed with lenses.
 */
const lensIso = curry(isomorphisms)
lensIso.from = from

export default lensIso
