import React from 'react'
import { withRouter } from 'react-router-dom'
import {
  compose,
  withState,
  withHandlers
} from 'recompose'

import {
  pathOr
} from 'ramda'

import NavigationAccountSide from '../../layouts/shared-navigation/navigation-side/adults-side-navigation/navigation-account-side'

import withTheme from '../../hoc/with-theme'
import NavigationAccountHoC from './navigation-account'

const enhance = compose(
  withRouter,
  withTheme,
  withState('isExpanded', 'setIsExpanded', false),
  withHandlers({
    onToggleAccountNav: ({ isExpanded, setIsExpanded }) => () => {
      setIsExpanded(!isExpanded)
    }
  })
)

export default enhance(
  NavigationAccountHoC(({ currentProfile, ...props }) => {
    const isKid = pathOr(false, ['isKid'], currentProfile)
    const userName = pathOr(false, ['name'], currentProfile)
    return (
      currentProfile && (
        <NavigationAccountSide
          {...props}
          userName={userName}
          isKid={isKid}
        />
      ))
  })
)
