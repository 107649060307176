// import { REHYDRATE } from 'redux-persist/constants';
import { combineReducers } from 'redux'

import ActionTypes from '../actions/types'

const playingTimestampReducer = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.SET_START_PLAYING_TIMESTAMP:
      return action.startPlayingTimestamp
    default:
      return state
  }
}

const dataSaverReducer = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.SELECT_DATA_SAVER_OPTION:
      return action.dataSaverSelection
    default:
      return state
  }
}

const accountReducer = combineReducers({
  dataSaver: dataSaverReducer
})

const user = combineReducers({
  account: accountReducer,
  startPlayingTimestamp: playingTimestampReducer
})

export default user
