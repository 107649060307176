import React, { Suspense } from 'react'
import { LoadingComponent } from '../loading/index'

const Settings = React.lazy(() => import('./settings'))
const AsyncSettings = props => (
  <Suspense fallback={<LoadingComponent />}>
    <Settings {...props} />
  </Suspense>
)

export default AsyncSettings
