import { REHYDRATE } from 'redux-persist/constants'
import ActionTypes from '../actions/types'

const initialState = {
  isLoading: true,
  playerIsInView: false
}

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case REHYDRATE:
      return {
        ...state,
        isLoading: false
      }
    case ActionTypes.SET_PLAYER_IS_IN_VIEW:
      return {
        ...state,
        playerIsInView: action.playerIsInView
      }
    default:
      return state
  }
}

export default appReducer
