export const SEGMENT_WRITE_KEY = process.env.SEGMENT_WRITE_KEY

export const TRACK_SIGN_UP = 'Signed Up'

export const TRACK_ADD_PROFILE = 'Profile Created'

export const TRACK_EDIT_PROFILE = 'Profile Updated'

export const TRACK_DELETE_PROFILE = 'Profile Deleted'

export const TRACK_SIGN_IN = 'Signed In'

export const TRACK_SIGN_OUT = 'Signed Out'

export const TRACK_PLAY_VIDEO_CONTENT = 'Video Content Started'

export const TRACK_ADD_SUBSCRIPTION = 'Subscription Added'

export const TRACK_CHANGE_SUBSCRIPTION = 'Subscription Changed'

export const TRACK_SEARCH_RESULT = 'Search Result Returned'

export const TRACK_SEARCH_NO_RESULT = 'Search No Result Returned'

export const TRACK_MY_LIST = 'Content Added To My List'

export const TRACK_SUBTITLE_CHANGED = 'Subtitle Settings Changed'

export const TRACK_STOP_VIDEO_CONTENT = 'Video Content Stopped'

export const TRACK_CLICK_CONTENT_CARD = 'Content Card Clicked'

export const TRACK_DELETE_DEVICE = 'Device Removed From Account'

export const TRACK_DEVICE_LIMIT = 'Permitted Device Limit Reached'

export const TRACK_PURCHASE_INITIATE = 'Content Purchase Initiated'

export const TRACK_PURCHASE_COMPLETE = 'Content Purchase Completed'

export const TRACK_ADD_VOUCHER = 'Voucher Added For Existing User'

export const TRACK_PAUSE_VIDEO_CONTENT = 'Video Content Paused'

export const TRACK_WINBACK_MODAL_CLICKED = 'Winback Pop Up Clicked'

export const TRACK_HOMEPAGE_LAND = 'Homepage Land'
