import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { propType } from 'graphql-anywhere'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { pathOr } from 'ramda'

import CarouselImgix from '../../imgix/carousel-imgix'
import { getTileRecipe } from '../../imgix/recipes'
import { getDetailsUrl } from '../../../lib/utils'

import { THEME_OPTIONS } from '../../../constants'

import TILE_FRAGMENT from '../../../../graphql/fragments/tile.gql'

import styles from './carousel-tile.css'

import neonCarouselRightArrowImg from '../../../../images/neon/carousel-right-arrow.svg'
import neonRentalcarouselRightArrowImg from '../../../../images/neon/carousel-right-arrow-rental.svg'
import carouselRightArrowDarkImg from '../../../../images/carousel-right-arrow-dark.svg'
import rentalBadgeSmall from '../../../../images/neon/rental-badge-small.svg'

import PlayProgressButton from '../../../containers/play-button/details-play-button'
import {
  isEpisode,
  isTitle
} from '../../../lib/content'

const getCarouselRightArrow = (theme, isRental) => {
  if (theme === THEME_OPTIONS.light) return carouselRightArrowDarkImg

  if (isRental) {
    return neonRentalcarouselRightArrowImg
  }

  return neonCarouselRightArrowImg
}

const MyRentalsTile = React.memo(({
  tile,
  theme,
  playbackInfoMany
}) => {
  const mapState = useSelector(state => state)
  const { castSender } = mapState
  const history = useHistory()

  const tileRecipe = getTileRecipe('EPISODE')
  const rentalInfo = tile.rentalInfo || {
    canWatchUntilText: ''
  }

  const { contentItem, header, image } = tile
  const title = isTitle(contentItem) ? header : contentItem.series.title
  const episodeTileSubheader = isEpisode(contentItem) ? `S${contentItem.seasonNumber} | E${tile.contentItem.episodeNumber} "${tile.header}"` : ''
  const playbackInfo = playbackInfoMany && playbackInfoMany[contentItem.id]
  const isRental = contentItem && contentItem.isRental
  const isKids = theme === THEME_OPTIONS.light

  const [isMouseHover, setIsMouseHover] = useState(false)
  const [isImageMouseHover, setImageIsMouseHover] = useState(false)

  const onHeaderClick = (event) => {
    event.stopPropagation()
    const url = getDetailsUrl(tile.contentItem)
    if (!castSender.isConnected) {
      history.push(url)
    }
  }

  let badgeTitle = tile.badge
  if (isEpisode(contentItem)) {
    badgeTitle = pathOr('', ['contentItem', 'series', 'tile', 'badge'], tile)
  }

  const carouselRightArrow = getCarouselRightArrow(theme, isRental)

  return (
    <div
      className={classNames(styles.tile, theme, styles.myRentals, styles.myRentalsInRentalPage)}
    >
      {isRental && (
        <img
          className={styles.rentalBadge}
          src={rentalBadgeSmall}
          alt="Rental Content"
        />
      )}
      <div
        className={styles.imgWrapper}
        onMouseEnter={() => setImageIsMouseHover(true)}
        onMouseLeave={() => setImageIsMouseHover(false)}
      >
        <CarouselImgix
          src={image}
          recipe={tileRecipe}
          shouldCrop
          isKids={isKids}
        />
        <div className={classNames(styles.ctaWrapper, {
          [styles.isRental]: isRental,
          [styles.isHover]: isImageMouseHover
        })}
        >
          <div className={classNames(styles.ctaContainer, styles.smallPlay)}>
            {
              playbackInfoMany && (
                <PlayProgressButton
                  size="small"
                  contentItem={contentItem}
                  playbackInfo={playbackInfo}
                  fromMyRentals
                  isMyRentalsBadge={!!badgeTitle}
                  shouldUseRentalStyle={false}
                  fromContinuityTile
                />
              )
            }
            <div className={classNames(styles.wrapper, {
              [styles.isRental]: isRental
            })}
            />
          </div>
        </div>
      </div>
      <div
        className={styles.tileInfoWrapper}
        onMouseEnter={() => setIsMouseHover(true)}
        onMouseLeave={() => setIsMouseHover(false)}
        onClick={onHeaderClick}
      >
        <div className={styles.header}>
          <div
            className={styles.title}
          >
            {title}
          </div>
          {
            isMouseHover && (
              <img
                className={styles.arrowButton}
                src={carouselRightArrow}
              />
            )
          }
        </div>
        {isRental && isTitle(contentItem) && (
          <div className={classNames(styles.timeLeftToWatch, {
            [styles.isRental]: isRental
          })}
          >
            {rentalInfo.canWatchUntilText}
          </div>
        )}
        {isEpisode(contentItem) && (
          <div className={styles.episodeSubheader}>
            {episodeTileSubheader}
          </div>
        )}
      </div>
    </div>
  )
})

MyRentalsTile.propTypes = {
  tile: propType(TILE_FRAGMENT).isRequired,
  theme: PropTypes.string.isRequired,
  playbackInfoMany: PropTypes.oneOfType([PropTypes.object])
}

MyRentalsTile.defaultProps = {
  playbackInfoMany: null
}

export default MyRentalsTile
