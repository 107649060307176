import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
// import { isModalDisplayed } from '../../lib/modal'

export const HelmetIfNoModal = ({ locationObject, children }) => {
  // LBX-7501
  // if (isModalDisplayed(locationObject)) {
  //   return null
  // }
  return (
    <Helmet>
      {children}
    </Helmet>
  )
}

HelmetIfNoModal.propTypes = {
  locationObject: PropTypes.oneOfType([PropTypes.object])
}

HelmetIfNoModal.defaultProps = {
  locationObject: location
}
