import {
  compose, withStateHandlers, lifecycle, withHandlers
} from 'recompose'

let timer = null

const enhance = compose(
  withStateHandlers(
    { showItem: 0 },
    {
      switchFade: ({ showItem }, { children }) => item => {
        if (typeof item !== 'undefined') {
          return {
            showItem: item
          }
        }

        if (children.length <= showItem + 1) {
          return {
            showItem: 0
          }
        }

        return {
          showItem: showItem + 1
        }
      }
    }
  ),
  withHandlers({
    clickToSwitch({ switchFade, interval }) {
      return item => {
        switchFade(item)
        clearInterval(timer)
        timer = setInterval(() => {
          switchFade()
        }, interval || 5000)
      }
    }
  }),
  lifecycle({
    componentDidMount() {
      // start the counter
      timer = setInterval(() => {
        this.props.switchFade()
      }, this.props.interval || 5000)
    },
    componentWillUnmount() {
      if (timer) {
        clearInterval(timer)
      }
    }
  })
)

export default enhance
