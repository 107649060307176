import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { SeriesIcon } from '../icons/series-icon'
import { TitleIcon } from '../icons/title-icon'
import { RentalIcon } from '../icons/rental-icon'

import { useStyles } from './search-carousel-with-type.styles'
import { SearchCarousel } from './search-carousel'

const getCarouselIcon = (type, className) => {
  switch (type) {
    case 'TITLE':
      return <TitleIcon className={className} />
    case 'RENTAL':
      return <RentalIcon className={className} />
    case 'SERIES':
      return <SeriesIcon className={className} />
    default:
      return null
  }
}

/**
 * Component display carousels and title
 */
export const SearchCarouselWithType = ({
  tiles,
  carouselName,
  tilesCount,
  onClickFetchMore,
  type,
  theme,
  myListItems,
  addToMyList,
  removeFromMyList
}) => {
  const styles = useStyles()
  const iconStyles = classNames(styles.icon, {
    [styles.kidsIcon]: theme === 'LIGHTBOX_KIDS'
  })

  return (
    <>
      {tiles.length > 0 && (
        <>
          <div className={styles.carouseTitle}>
            {getCarouselIcon(type, iconStyles)}
            {carouselName}
            (
            {tilesCount}
            )
          </div>
          <SearchCarousel
            tiles={tiles}
            onClickFetchMore={onClickFetchMore}
            myListItems={myListItems}
            addToMyList={addToMyList}
            removeFromMyList={removeFromMyList}
          />
        </>
      )}
    </>
  )
}

SearchCarouselWithType.propTypes = {
  tiles: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  carouselName: PropTypes.string.isRequired,
  tilesCount: PropTypes.number,
  theme: PropTypes.string,
  type: PropTypes.string.isRequired,
  onClickFetchMore: PropTypes.func,
  myListItems: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  addToMyList: PropTypes.func,
  removeFromMyList: PropTypes.func
}
