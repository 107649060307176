import { combineReducers } from 'redux'

import ActionTypes from '../actions/types'
import { createReducer } from '../lib/reducer'

/**
 * Tracks if cast sender plugin is connected or disconnected
 */
const isConnectedReducer = createReducer(false, {
  [ActionTypes.CAST_SENDER_CONNECT]: () => {
    return true
  },
  [ActionTypes.CAST_SENDER_DISCONNECT]: () => {
    return false
  }
})

const castSenderReducer = combineReducers({
  isConnected: isConnectedReducer
})

/**
 * The cast sender reducer
 */
export default castSenderReducer
