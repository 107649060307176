import React from 'react'
import classnames from 'classnames'
import PT from 'prop-types'

import Arrow from './arrow'
import styles from './button.css'

function Button({
  left, width, className, ...props
}) {
  return (
    <button
      style={{ width }}
      className={classnames(className, styles.button)}
      {...props}
    >
      <Arrow left={left} className={classnames(styles.arrow, 'arrow')} />
    </button>
  )
}

Button.propTypes = {
  left: PT.bool,
  width: PT.number.isRequired,
  className: PT.string.isRequired
}

Button.defaultProps = {
  left: false
}

export default Button
