import throttle from 'lodash.throttle'
import {
  compose,
  lifecycle,
  withHandlers,
  withState
} from 'recompose'

export const withElementHeightWatching = compose(
  withState('elementHeight', 'setElementHeight'),
  withState('elementRef', 'setElementRef'),
  withHandlers({
    updateElementHeight: ({ setElementHeight, elementRef }) => throttle(() => {
      if (elementRef) {
        setElementHeight(elementRef.clientHeight)
      }
    })
  }),
  withHandlers({
    registerHeightWatcher: ({
      updateElementHeight,
      setElementRef
    }) => (element) => {
      setElementRef(element)
      updateElementHeight()
    }
  }),
  lifecycle({
    componentDidMount() {
      window.addEventListener('resize', this.props.updateElementHeight)
    },
    componentWillUnmount() {
      window.removeEventListener('resize', this.props.updateElementHeight)
    }
  })
)
