import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import { isNil } from 'ramda'
import classnames from 'classnames'

import { OverlayTitle } from './title'
import OverlayTitleKids from './title-kids'
import OverlayTitleKidsLong from './title-kids-long'
import OverlaySeries from './series'
import OverlaySeriesKids from './series-kids'
import OverlayEpisode from './episode'
import { DefaultOverlay } from './default-overlay'
import Movie4x3 from './movie-4x3'
import styles from './overlay.css'
import { THEME_OPTIONS } from '../../../../constants'

import TILE_FRAGMENT from '../../../../../graphql/fragments/tile.gql'

const getOverlayComponent = (tile, isKids, tileType) => {
  switch (tile.contentItem.__typename) {
    case 'Title':
      if (isKids) {
        return tileType === 'TITLE' ? OverlayTitleKidsLong : OverlayTitleKids
      }

      if (tileType === 'Movie4x3') {
        return Movie4x3
      }
      return OverlayTitle
    case 'Series':
      if (isKids) {
        return OverlaySeriesKids
      }
      return OverlaySeries
    case 'Episode':
      return OverlayEpisode
    default:
      return DefaultOverlay
  }
}

// TODO: https://bcconsulting.atlassian.net/browse/LBXW-1330
// - TileOverlay could be turned into a container
// responsible for the branching of the different overlays
// - Reduce code duplication in Grid / Carousel overlays
const TileOverlay = React.memo(({
  tile,
  display,
  theme,
  tileType,
  isOnMyList,
  isAuthenticated,
  addToMyList,
  removeFromMyList,
  isRental
}) => {
  const isKids = theme === THEME_OPTIONS.light
  const OverlayComponent = getOverlayComponent(tile, isKids, tileType)
  const isDefaultOverlay = OverlayComponent === DefaultOverlay
  let overlay = null
  if (!isNil(OverlayComponent)) {
    overlay = (
      <OverlayComponent
        tile={tile}
        display={display}
        isAuthenticated={isAuthenticated}
        isOnMyList={isOnMyList}
        addToMyList={addToMyList}
        removeFromMyList={removeFromMyList}
        tileType={tileType}
      />
    )
  }

  const onOffClass = display ? styles.tileOverlayOn : styles.tileOverlayOff

  // TODO: the add or remove button currently have no functionality.
  return (
    <div className={
      classnames(
        styles.tileOverlay,
        onOffClass,
        {
          [isRental ? styles.defaultRentalTileOverlay : styles.defaultTileOverlay]: isDefaultOverlay
        }
      )
    }
    >
      { overlay }
    </div>
  )
})

TileOverlay.propTypes = {
  tile: propType(TILE_FRAGMENT).isRequired,
  tileType: PropTypes.string,
  display: PropTypes.bool.isRequired,
  theme: PropTypes.string,
  isOnMyList: PropTypes.bool,
  isAuthenticated: PropTypes.bool.isRequired,
  addToMyList: PropTypes.func.isRequired,
  removeFromMyList: PropTypes.func.isRequired,
  isRental: PropTypes.bool
}

TileOverlay.defaultProps = {
  tileType: null,
  theme: THEME_OPTIONS.dark,
  isOnMyList: false
}

export default TileOverlay
