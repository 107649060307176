import React from 'react'
import Shiitake from 'shiitake'
import PropTypes from 'prop-types'

const Clamped = ({ children, ...config }) => {
  return (
    <Shiitake lines={2} tagName="div" {...config}>
      {children.replace(/\\n/g, '\n')}
    </Shiitake>
  )
}

Clamped.propTypes = {
  children: PropTypes.string.isRequired
}

export default Clamped
