import {
  compose,
  curry,
  defaultTo,
  either,
  head,
  merge,
  nth,
  reject,
  isNil,
  isEmpty
} from 'ramda'

import {
  customDimensions,
  getCategoryNameFromContentItem
} from './custom-dimensions'
import { seriesTitleHouseId, getTitleOfContent } from './ga'
import { isVideoExtra, isScreen } from '../content'
import { IdleQueue } from '../idlize/IdleQueue.mjs'
import { segmentTrackClickContentCard } from '../../segment/segment-track'

const datalayerIdleQueue = new IdleQueue({
  ensureTasksRun: true,
  defaultMinTaskTime: 5
})
const GTM_SCRIPT = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.GTM_ID}');`

export const rejectEmptyNilValue = object => reject(either(isNil, isEmpty), object)

export const initialiseGtm = () => {
  datalayerIdleQueue.pushTask(() => {
    const gtmScript = document.createElement('script')
    gtmScript.innerHTML = GTM_SCRIPT
    gtmScript.async = false

    const headElement = head(document.getElementsByTagName('head'))
    headElement.insertBefore(gtmScript, headElement.firstChild)

    const noscript = document.createElement('noscript')
    const iframe = document.createElement('iframe')
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${
      process.env.GTM_ID
    }`
    iframe.height = 0
    iframe.width = 0
    iframe.style.display = 'none'
    iframe.style.visibility = 'hidden'
    noscript.appendChild(iframe)

    const bodyElement = head(document.getElementsByTagName('body'))
    bodyElement.insertBefore(noscript, bodyElement.firstChild)
  })
}

export const getGenresFromContentItem = contentItem => {
  return defaultTo([], contentItem.genres)
}

export const getAdditionalGenreFromContentItem = compose(
  nth(1),
  getGenresFromContentItem
)

export const emitDataLayerEventEcommerce = curry((event, ecommerce) => {
  datalayerIdleQueue.pushTask(() => {
    window.dataLayer.push({
      event,
      transport: 'beacon',
      ecommerce: merge(
        {
          currencyCode: 'NZD'
        },
        ecommerce
      )
    })
  })
})

export const emitDataLayerEvent = curry(payload => {
  datalayerIdleQueue.pushTask(() => {
    window.dataLayer.push({
      transport: 'beacon',
      ...payload
    })
  })
})

export const dataLayerProductDetail = (
  contentItem,
  emitDataLayerEventFunction = emitDataLayerEventEcommerce
) => {
  emitDataLayerEventFunction('productDetail', {
    detail: {
      products: [
        {
          name: contentItem.title,
          id: contentItem.ldId,
          brand: head(getGenresFromContentItem(contentItem)),
          category: getCategoryNameFromContentItem(contentItem),
          [customDimensions.AdditionalGenre]: nth(
            1,
            getGenresFromContentItem(contentItem)
          ),
          [customDimensions.ShowTitle]: contentItem.title
        }
      ]
    }
  })
}

/**
 * @param contentItem
 * @param position Number index of item within carousel
 * @param list String name of carousel
 */
export const dataLayerProductClick = (contentItem, position, list, path = false) => {
  if (path) {
    // Add segment data analytics for content card
    segmentTrackClickContentCard(contentItem, position, list, path)
  }

  const houseId =
    !isScreen(contentItem) && !isVideoExtra(contentItem)
      ? seriesTitleHouseId(contentItem)
      : undefined
  const name = !isVideoExtra(contentItem)
    ? contentItem.title
    : `${contentItem.contentItems[0].title} trailer`
  emitDataLayerEventEcommerce('productClick', {
    click: {
      actionField: {
        list
      },
      products: [
        {
          name,
          id: contentItem.id,
          brand: head(getGenresFromContentItem(contentItem)),
          category: getCategoryNameFromContentItem(contentItem),
          list,
          position,
          [customDimensions.SeriesTitleHouseId]: houseId
        }
      ]
    }
  })
}

/**
 * @param contentItem
 * @param price displayed price of item - normal or promotional price
 * @param variant
 */
export const dataLayerAddToCart = (
  contentItem,
  price,
  variant,
  emitDataLayerEventFunction = emitDataLayerEventEcommerce
) => {
  emitDataLayerEventFunction('addToCart', {
    add: {
      products: [
        {
          name: contentItem.title,
          id: contentItem.ldId,
          price,
          brand: head(getGenresFromContentItem(contentItem)),
          category: getCategoryNameFromContentItem(contentItem),
          variant,
          [customDimensions.AdditionalGenre]: nth(
            1,
            getGenresFromContentItem(contentItem)
          ),
          [customDimensions.ShowTitle]: contentItem.title
        }
      ]
    }
  })
}

/**
 * @param contentItem
 * @param price the $ amount charged to the customer
 * @param variant
 * @param transactionId the ID for the financial transaction
 * @param coupon the coupon that was used, if any
 */
export const dataLayerPurchase = (
  contentItem,
  price,
  variant,
  transactionId,
  coupon,
  emitDataLayerEventFunction = emitDataLayerEventEcommerce
) => {
  emitDataLayerEventFunction('purchase', {
    purchase: {
      actionField: {
        id: transactionId,
        affiliation: 'Desktop',
        revenue: price,
        coupon
      },
      products: [
        {
          name: contentItem.title,
          id: contentItem.ldId,
          price,
          brand: head(getGenresFromContentItem(contentItem)),
          category: getCategoryNameFromContentItem(contentItem),
          variant,
          [customDimensions.AdditionalGenre]: nth(
            1,
            getGenresFromContentItem(contentItem)
          ),
          [customDimensions.ShowTitle]: contentItem.title
        }
      ]
    }
  })
}

/**
 * Datalayer wrapper for non-ecommerce events
 *
 * @param contentItem
 */
const dataLayerEvent = dataLayer => {
  return event => {
    datalayerIdleQueue.pushTask(() => {
      if (dataLayer) dataLayer.push(event)
    })
  }
}

/**
 * @param contentItem
 */
export const dataLayerAddToMyList = (
  contentItem,
  emitDataLayerEventFunction = dataLayerEvent
) => {
  emitDataLayerEventFunction({
    event: 'addToWatchList',
    category: 'myList',
    action: 'Add',
    label: contentItem.title
  })
}

export const dataLayerPlay = (
  contentItem,
  emitDataLayerEventFunction = dataLayerEvent(window.dataLayer)
) => {
  emitDataLayerEventFunction({
    event: 'videoStart',
    category: 'navigation',
    action: 'Play',
    label: getTitleOfContent(contentItem)
  })
}

/**
 * @param orderId - orderId if signed up to a plan, otherwise null
 */
export const dataLayerSignUp = (
  orderId,
  emitDataLayerEventFunction = dataLayerEvent(window.dataLayer)
) => {
  const subscription = orderId ? 'Movie and Plan' : 'Movie Only'
  emitDataLayerEventFunction({
    event: 'SignUp',
    category: 'SignUp',
    action: 'Complete SignUp',
    label: subscription
  })
}

export const dataLayerSubscriptionConfirmation = (
  {
    event = 'trackEvent',
    vpv = 'vpv_successful_subscription',
    category = 'subscription',
    action = 'subscribe',
    label = 'successful',
    value,
    userType = 'svod and tvod',
    subscriptionStartDate,
    subscriptionEndDate,
    ...rest
  },
  emitDataLayerEventFunction = dataLayerEvent(window.dataLayer)
) => {
  try {
    emitDataLayerEventFunction(
      rejectEmptyNilValue({
        event,
        vpv,
        'eventDetails.category': category,
        'eventDetails.action': action,
        'eventDetails.label': label,
        'eventDetails.value': value,
        subscriptionStartDate: formatDate(subscriptionStartDate),
        subscriptionEndDate: formatDate(subscriptionEndDate),
        userType,
        ...rest
      })
    )
  } catch (error) {
    console.error(error)
  }
}

export const formatDate = date => {
  if (!date) return null
  // using regex here instead of date-fns because the lib
  // cannot handle the `dd/mm/yyyy` correctly for version: 1.29.0
  const dateRegex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/.exec(date)
  return `${dateRegex[3]}-${dateRegex[2]}-${dateRegex[1]}`
}

export const dataLayerNoSubscriptionConfirmation = (
  {
    event = 'trackEvent',
    vpv = 'vpv_no_subscription',
    category = 'subscription',
    action = 'no subscribe',
    label = 'successful',
    lifeStageCohort = 'account created',
    userType = 'tvod'
  },
  emitDataLayerEventFunction = dataLayerEvent(window.dataLayer)
) => {
  try {
    emitDataLayerEventFunction(
      rejectEmptyNilValue({
        event,
        vpv,
        'eventDetails.category': category,
        'eventDetails.action': action,
        'eventDetails.label': label,
        lifeStageCohort,
        userType
      })
    )
  } catch (error) {
    console.error(error)
  }
}
