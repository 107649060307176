import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'

import { enableLoadingOverlay, endLoadingOverlay } from '../actions'

// Higher order component to manage loading screen on mutations
export default compose(
  connect(
    () => ({}),
    dispatch => ({
      dispatch
    })
  ),
  withProps(({ mutate, dispatch }) => ({
    /**
     * @param  {string} operation - Used in loading reducer
     *            to set loading, success and error messages
     * @param  {Object} ...args - (apollo) mutate arguments
     */

    mutateAndLoading: (operation, ...args) => {
      const key = new Date().getTime()
      // Display loading screen
      dispatch(enableLoadingOverlay(key, operation))
      return mutate(...args)
        .then(res => {
          // Display success message and hide loading screen
          dispatch(endLoadingOverlay(key, operation))
          return res
        })
        .catch(error => {
          // Display error message and hide loading screen
          dispatch(endLoadingOverlay(key, operation, { error: true }))
          throw error
        })
    }
  }))
)
