import React from 'react'
import PropTypes from 'prop-types'
import { setDisplayName, wrapDisplayName } from 'recompose'

function touchX(event) {
  return event.touches[0].clientX
}

function touchY(event) {
  return event.touches[0].clientY
}

function withTouchDirection(tapTolerance = 20, swipeTolerance = 60) {
  return Target => {
    class TouchHandler extends React.Component {
      state = { direction: null }

      handleTouchStart = event => {
        if (this.touchStarted) {
          return
        }

        this.touchStarted = true

        this.touchMoved = false
        this.swipeOutBounded = false

        this.startX = touchX(event)
        this.startY = touchY(event)

        this.currentX = 0
        this.currentY = 0
      }

      handleTouchMove = event => {
        this.currentX = touchX(event)
        this.currentY = touchY(event)

        if (!this.touchMoved) {
          this.touchMoved =
            Math.abs(this.startX - this.currentX) > tapTolerance ||
            Math.abs(this.startY - this.currentY) > tapTolerance
        } else if (!this.swipeOutBounded) {
          this.swipeOutBounded =
            Math.abs(this.startX - this.currentX) > swipeTolerance &&
            Math.abs(this.startY - this.currentY) > swipeTolerance
        }
      }

      handleTouchCancel = () => {
        this.touchStarted = false
        this.touchMoved = false
        this.startX = 0
        this.startY = 0
      }

      handleTouchEnd = () => {
        this.touchStarted = false

        if (!this.swipeOutBounded && this.touchMoved) {
          if (this.props.onSwipe) {
            if (Math.abs(this.startX - this.currentX) > swipeTolerance) {
              this.props.onSwipe(this.startX > this.currentX ? 'left' : 'right')
            }
          }
        }
      }

      render() {
        return (
          <div
            onTouchStart={this.handleTouchStart}
            onTouchMove={this.handleTouchMove}
            onTouchCancel={this.handleTouchCancel}
            onTouchEnd={this.handleTouchEnd}
          >
            <Target {...this.props} />
          </div>
        )
      }
    }

    TouchHandler.propTypes = {
      children: PropTypes.node,
      onSwipe: PropTypes.func.isRequired
    }

    if (process.env.NODE_ENV !== 'production') {
      return setDisplayName(wrapDisplayName(Target, 'withTouchHandler'))(
        TouchHandler
      )
    }

    return TouchHandler
  }
}

export default withTouchDirection
