import React from 'react'
import { graphql } from 'react-apollo'
import {
  compose, branch, renderNothing, setDisplayName
} from 'recompose'
import { connect } from 'react-redux'

import AccountNavButton from '../../layouts/shared-navigation/account-nav-button'
import { checkIsAuthenticated } from '../../lib/auth'

import ACCOUNT_QUERY from '../../../graphql/queries/account.gql'

const mapStateToProps = (state) => {
  return {
    isAuthenticated: checkIsAuthenticated(state),
    theme: state.theme,
    menuActive: state.navigation.accountNav
  }
}

const enhance = compose(
  setDisplayName('AccountNavButtonContainer'),
  connect(
    mapStateToProps
  ),
  graphql(ACCOUNT_QUERY, {
    name: 'accountQuery',
    skip: ownProps => !ownProps.isAuthenticated
  }),
  branch(
    ({ accountQuery }) => accountQuery && (accountQuery.loading || accountQuery.error),
    renderNothing
  )
)

export default enhance(({ accountQuery, ...props }) => {
  const selectedProfile = accountQuery &&
  accountQuery.account &&
  accountQuery.account.selectedProfile

  const currentProfile = accountQuery &&
  selectedProfile &&
  accountQuery.account &&
  accountQuery.account.profiles.find(profile => profile.id === selectedProfile)

  const userName = currentProfile ? currentProfile.name : ''
  const avatar = currentProfile && currentProfile.avatar ? currentProfile.avatar.uri : ''
  return (
    <AccountNavButton
      {...props}
      userName={userName}
      avatar={avatar}
    />
  )
})
