import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'

import styles from '../hotspot.css'
import { HotspotSlide } from '../../../containers/hotspot/hotspot-slide'
import Fader from '../fader'

import HOTSPOT_COMPONENT_FRAGMENT from '../../../../graphql/fragments/hotspot-component.gql'

function HotspotMobile({
  hotspot,
  hotspotPosition,
  playbackInfoMany,
  theme
}) {
  const slides = hotspot.slides
  if (!slides || !slides.length) {
    return null
  }

  const rentalInfo = slides.map(slide => slide.contentItem.isRental)

  const settings = {
    slideWidth: 1,
    autoplay: true,
    autoplayInterval: hotspot.uiConfig.interval * 1000,
    isSquare: true,
    rentalInfo
  }

  return (
    <div className={`hotspot-slider ${styles.hotspotComponentWrapper} ${theme}`}>
      <Fader {...settings}>
        {
          slides.map((slide, index) => (
            <HotspotSlide
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              index={index}
              slide={slide}
              hotspotPosition={hotspotPosition}
              playbackInfoMany={playbackInfoMany}
            />
          ))
        }
      </Fader>
    </div>
  )
}

HotspotMobile.propTypes = {
  hotspot: propType(HOTSPOT_COMPONENT_FRAGMENT).isRequired,
  theme: PropTypes.string.isRequired,
  hotspotPosition: PropTypes.number.isRequired,
  playbackInfoMany: PropTypes.oneOfType([PropTypes.object])
}

HotspotMobile.defaultProps = {
  playbackInfoMany: null
}

export default HotspotMobile
