import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import styles from './nav-dots.css'

function NavDots({
  count, onClick, current, rentalInfo
}) {
  const isCurrentItemRental = rentalInfo[current]

  return (
    <div
      className={classnames(styles.navDotWrapper, {
        [styles.isRental]: isCurrentItemRental
      })}
      data-e2e="nav-dots"
    >
      {Array.from(Array(count)).map((_, idx) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
          onClick={() => onClick(idx)}
          data-e2e="nav-dot"
        >
          <div
            className={classnames(styles.navDot, {
              [styles.navDotActive]: idx === current
            })}
          />
        </div>
      ))}
    </div>
  )
}

NavDots.propTypes = {
  count: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  current: PropTypes.number.isRequired,
  rentalInfo: PropTypes.arrayOf(PropTypes.bool).isRequired
}

export default NavDots
