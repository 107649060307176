import { mergeDeepWith } from 'ramda'

const TESTERS_CLASSES = {
  ACCOUNT: {
    COMMUNICATION_SETTINGS: 'accountCommunicationSettings',
    DETAILS: 'accountDetails',
    DEVICES_DOWNLOADS: 'accountDevicesDownloads',
    PROFILE_SECURITY: 'accountProfileSecurity',
    PURCHASING_SECURITY: 'accountPurchasingSecurity',
    VIDEO_SETTINGS: 'accountVideoSettings',
    RENTALS: 'accountRentals',
    PAYMENT_DETAILS: 'accountPaymentDetails',
    TV_PLAN: 'accountTvPlan'
  },
  NAVIGATION: {
    PROFILE: 'navigationProfile'
  },
  SIGNUP: {
    SELECTION: 'signupSubscriptionSelection',
    VOUCHER: 'signupVoucher',
    SIGNUP: 'signup',
    PAYMENT: 'signupPayment',
    CONFIRMATION_ORDER: 'signupConfirmationOrder',
    CONFIRMATION_PROMOTION: 'signupConfirmationPromotion'
  },
  MODALS: {
    SIGNUP: {
      WRAPPER: 'signupModalWrapper'
    }
  },
  CAROUSEL_TILE: 'carouselTile'
}

// Prepend t- to all TESTER classes to help testers ensure they use only static classes intended
// for use with automation
export const TESTERS = mergeDeepWith(
  a => `t-${a}`,
  TESTERS_CLASSES,
  TESTERS_CLASSES
)
