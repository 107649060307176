import React from 'react'
import ReactSVG from 'react-svg'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import arrowDown from '../../../images/down-arrow.svg'
import styles from './navigation-arrow.css'

export default function Arrow({ isExpanded, arrowClassName, className }) {
  return (
    <ReactSVG
      src={arrowDown}
      beforeInjection={(svg) => {
        svg.setAttribute('class', classnames(
          arrowClassName,
          styles.navArrowSvg,
          {
            [styles.reverse]: isExpanded
          }
        ))
      }}
      className={className}
    />
  )
}

Arrow.propTypes = {
  isExpanded: PropTypes.bool,
  className: PropTypes.string,
  arrowClassName: PropTypes.string
}

Arrow.defaultProps = {
  isExpanded: false,
  className: styles.navArrow,
  arrowClassName: ''
}
