import React, { Component } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import ClickOutside from 'react-click-outside'
import classNames from 'classnames'
import { isKids } from '../../lib/utils'

import styles from './search-nav-button.css'
import searchCross from '../../../images/neon/close-filled.svg'
import searchCrossBlack from '../../../images/neon/close-filled-black.svg'

class SearchInput extends Component {
  componentDidUpdate() {
    const { isClicked } = this.props

    if (document.activeElement && document.activeElement.blur) {
      document.activeElement.blur()
    }

    if (isClicked) {
      this.searchInput.focus()
    } else {
      this.searchInput.blur()
    }
  }

  render() {
    const {
      value, onChange, onClick, className
    } = this.props
    return (
      <input
        value={value}
        onChange={onChange}
        onClick={onClick}
        id="navSearch"
        type="text"
        className={classNames(styles.searchInput, className)}
        placeholder="Search by title, people or genre"
        ref={input => {
          this.searchInput = input
        }}
        data-e2e="nav-search-input-placeholder"
      />
    )
  }
}

SearchInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  isClicked: PropTypes.bool.isRequired,
  className: PropTypes.string
}

function SearchNavButton({
  isClicked,
  handleChange,
  handleInputToggle,
  handleOnClickOutside,
  handleClearInput,
  query,
  isAuthenticated
}) {
  const mapState = useSelector(state => state)
  const { theme } = mapState

  const searchCrossSrc = isKids(theme)
    ? searchCrossBlack
    : searchCross

  const searchNavButtonClass = isClicked
    ? styles.searchWrapperFocus
    : styles.searchWrapper

  return (
    <ClickOutside
      className={classNames({
        [searchNavButtonClass]: true,
        [styles.hide]: !isAuthenticated && !isClicked
      })}
      onClickOutside={handleOnClickOutside}
    >
      <div className={styles.searchInner} data-e2e="nav-search">
        <SearchInput
          value={query}
          onChange={handleChange}
          onClick={handleInputToggle}
          isClicked={isClicked}
        />
        <label htmlFor="navSearch" className={styles.searchLabel}>
          Search
        </label>
        {query && isClicked && (
          <img
            className={styles.searchCross}
            src={searchCrossSrc}
            onClick={handleClearInput}
          />
        )}
      </div>
    </ClickOutside>
  )
}

SearchNavButton.propTypes = {
  isClicked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleInputToggle: PropTypes.func.isRequired,
  handleOnClickOutside: PropTypes.func.isRequired,
  handleClearInput: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
}

SearchNavButton.defaultProps = {
  isClicked: false,
  query: ''
}

export default SearchNavButton
