import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import classNames from 'classnames'
import {
  compose, defaultTo, join, take
} from 'ramda'

import Clamped from '../shared/clamped'
import MyListRibbon from '../carousel/my-list-ribbon'
import MyListRibbonKids from '../shared/my-list/ribbon-kids'

import TILE_FRAGMENT from '../../../graphql/fragments/tile.gql'

import styles from './grid-overlay-series-kids.css'
import overlayStyles from './grid-tile-overlay.css'

function GridOverlaySeriesKids({
  display,
  tile,
  hasRemoveIcon,
  setRemoved,
  onRemoveClick,
  isOnMyList,
  isAuthenticated,
  addToMyList,
  removeFromMyList
}) {
  const onOffClass = display ? overlayStyles.tileOverlayOn : overlayStyles.tileOverlayOff
  const contentItem = tile.contentItem
  const title = contentItem.title
  const genres = compose(
    join(', '),
    take(2),
    defaultTo([])
  )(contentItem.genres)

  let ribbon = null
  if (hasRemoveIcon) {
    ribbon = (
      <MyListRibbonKids
        contentItem={contentItem}
        setRemoved={setRemoved}
        onRemoveClick={onRemoveClick}
      />
    )
  } else if (isAuthenticated) {
    ribbon = (
      <MyListRibbon
        contentItem={contentItem}
        className={styles.ribbon}
        isOnMyList={isOnMyList}
        addAction={addToMyList}
        removeAction={removeFromMyList}
      />
    )
  }

  return (
    <div className={`${overlayStyles.tileOverlay} ${onOffClass}`}>
      <div className={classNames(styles.wrapper, {
        [styles.wrapperNeon]: true
      })}
      >
        {ribbon}
        <div className={styles.title}>
          <Clamped>{title}</Clamped>
        </div>
        <div className={styles.seasons}>
          <Clamped lines={1}>{tile.subHeader}</Clamped>
        </div>
        <div className={styles.genres}>
          <Clamped lines={1}>{genres}</Clamped>
        </div>
        <div className={styles.buttonWrapper}>
          <div className={classNames(styles.button, {
            [styles.buttonNeon]: true
          })}
          >
            More Info
          </div>
        </div>
      </div>
    </div>
  )
}

GridOverlaySeriesKids.propTypes = {
  tile: propType(TILE_FRAGMENT).isRequired,
  display: PropTypes.bool.isRequired,
  hasRemoveIcon: PropTypes.bool.isRequired,
  setRemoved: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  isOnMyList: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  addToMyList: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]).isRequired,
  removeFromMyList: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]).isRequired
}

GridOverlaySeriesKids.defaultProps = {
  isOnMyList: false,
  isAuthenticated: false
}

export default GridOverlaySeriesKids
