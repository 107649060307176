import {
  graphql,
  withApollo
} from 'react-apollo'
import { connect } from 'react-redux'
import {
  branch,
  compose,
  renderNothing,
  withHandlers,
  withProps
} from 'recompose'
import { withRouter } from 'react-router-dom'
import { pathEq } from 'ramda'

import { UserSlide } from '../../components/hotspot/hotspot-slide/user-slide'
import withIsAuthenticated from '../../hoc/is-authenticated'
import { THEME_OPTIONS } from '../../constants'
import { checkIsAuthenticated } from '../../lib/auth'
import withCastSender from '../../hoc/with-cast-sender'
import withInitializeCastSender from '../../hoc/with-castPlayer-sender-initialize'
import MY_LIST_QUERY from '../../../graphql/queries/my-list.gql'
import { withUsableOnClick } from '../../hoc/with-usable-onclick'
import { setTrailerLaunchedFromComponent } from '../../actions/index'
import { withScreenWidthWatching } from '../../hoc/with-screen-width-watching'
import {
  dataLayerProductClick
} from '../../lib/analytics/datalayer'

const mapStateToProps = (state) => {
  const isAuthenticated = checkIsAuthenticated(state)
  const isKid = pathEq(['theme'], THEME_OPTIONS.light, state)
  return {
    isAuthenticated,
    isKid
  }
}

const mapDispatchToProps = dispatch => ({
  setHotspotCtaIsClicked: () => {
    dispatch(setTrailerLaunchedFromComponent(true))
  }
})

export const HotspotSlide = compose(
  withRouter,
  withApollo,
  withCastSender,
  withInitializeCastSender,
  withIsAuthenticated,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withUsableOnClick,
  graphql(MY_LIST_QUERY, {
    name: 'myListQuery',
    skip: ownProps => !ownProps.isAuthenticated,
    options: {
      notifyOnNetworkStatusChange: true
    }
  }),
  branch(
    ({ myListQuery }) => myListQuery && (myListQuery.loading || myListQuery.error),
    renderNothing
  ),
  withProps(({ myListQuery, slide }) => ({
    myList: (myListQuery && myListQuery.mylist && myListQuery.mylist.items) || [],
    contentItem: slide.contentItem
  })),
  withHandlers({
    onCtaClick: ({
      history,
      setHotspotCtaIsClicked,
      maybeInitializeCastSender,
      contentItem,
      castSender,
      index
    }) => (url) => {
      dataLayerProductClick(contentItem, index, 'Hotspot', window.location.pathname)
      // check if is redirecting to a trailer from hotspot to display the modal
      if (pathEq(['__typename'], 'VideoExtra', contentItem)) {
        maybeInitializeCastSender(url, contentItem, true)
        if (!castSender.isConnected) {
          // dispatch action to set the state value trailerModalVisibility to true
          setHotspotCtaIsClicked()
        }
        return
      }
      history.push(url)
    }
  }),
  withScreenWidthWatching
)(UserSlide)
