import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  pipe, path, find, propEq, prop, contains, T, F, toUpper
} from 'ramda'
import { propType } from 'graphql-anywhere'

import { STATIC_SCREENS, THEME_OPTIONS } from '../../constants'
import Select from '../forms/select'

import styles from './grid.css'
import selectStyles from './grid-sorting-select.css'

import TILE_FRAGMENT from '../../../graphql/fragments/tile.gql'
import { GridBody } from './grid-body'
import { JSONLD } from '../jsonld'
import { createItemListLDSchema } from '../../lib/jsonld/item-list'
import { E2E_SELECTORS } from '../../e2e-selectors'
import GridHeaderBreadcrumb from './grid-header-breadcrumb'

const TABS = {
  movies: 'Title',
  series: 'Series'
}
const TABS_LABELS = {
  Title: 'Movies',
  Series: 'TV Shows'
}
const TABS_SUBTYPES = [STATIC_SCREENS.MY_LIST.subType]

function getSortingOptions(sortValues) {
  return sortValues.map(value => ({
    label: value.label,
    value
  }))
}

function sortGrid(tiles, sortingValue) {
  if (!sortingValue) {
    return tiles
  }

  const sorting = path(['value', 'dir'], sortingValue)
  const sortingKey = path(['value', 'key'], sortingValue)

  const getCompareValue = tile => pipe(
    prop('sortValues'),
    find(propEq('key', sortingKey)),
    prop('value'),
    String,
    toUpper
  )(tile)

  return [...tiles].sort((a, b) => {
    if (sorting === 'DESC') {
      return getCompareValue(a) < getCompareValue(b) ? 1 : -1
    }
    return getCompareValue(a) < getCompareValue(b) ? -1 : 1
  })
}

const getGridHeader = (isEmptyGrid, header, tabbing, selectedTab) => {
  if (isEmptyGrid) return ''
  return (
    !header && tabbing ? TABS_LABELS[selectedTab] : header
  )
}

function Grid({
  header,
  tiles,
  sortingValue,
  sortingOptions,
  setSortingValue,
  setSelectedTab,
  theme,
  contentType,
  subType,
  hasRemoveIcon,
  tabbing,
  series,
  movies,
  selectedTab,
  isEmptyGrid,
  gridPosition,
  isContentItemOnMyList,
  addToMyList,
  removeFromMyList
}) {
  const myListPage = subType === STATIC_SCREENS.MY_LIST.subType

  const headerWrapperClass = myListPage
    ? styles.myListHeaderWrapper
    : styles.gridHeaderWrapper

  const gridHeadingWrapperStyles = classNames([
    styles.gridHeadingWrapper,
    {
      [styles.myListHeadingWrapper]: myListPage
    }
  ])

  return (
    <div className={styles.gridComponentWrapper}>
      <div className={headerWrapperClass}>
        <div className={gridHeadingWrapperStyles}>
          { header === 'My List'
            ? (
              <h1 className={styles.gridHeading}>
                {getGridHeader(isEmptyGrid, header, tabbing, selectedTab)}
              </h1>
            )
            : (
              <GridHeaderBreadcrumb
                isEmptyGrid={isEmptyGrid}
                header={header}
                isKidTheme={theme === THEME_OPTIONS.light}
              />
            )}
        </div>
        {
          (tabbing && !isEmptyGrid) && (
            <>
              <div
                className={classNames(styles.tab, {
                  [styles.active]: TABS.series === selectedTab
                })}
                onClick={() => { setSelectedTab(TABS.series) }}
              >
                {TABS_LABELS[TABS.series]}
                {' '}
                (
                {series.length}
                )
              </div>
              <div
                className={classNames(styles.tab, {
                  [styles.active]: TABS.movies === selectedTab
                })}
                onClick={() => { setSelectedTab(TABS.movies) }}
                data-lbx-e2e={E2E_SELECTORS.MY_LIST_MOVIES_TAB}
              >
                {TABS_LABELS[TABS.movies]}
                {' '}
                (
                {movies.length}
                )
              </div>
            </>
          )
        }
        {sortingValue && <div className={styles.newRow} />}
        {
          (sortingValue && !isEmptyGrid) && (
            <Select
              inline
              name="gridSort"
              label="Sort By"
              value={sortingValue}
              options={sortingOptions}
              onChange={setSortingValue}
              wrapperClass={styles.sortingWrapper}
              labelClass={selectStyles.labelClass}
              useKidsTheme={theme === THEME_OPTIONS.light}
              inputClass={selectStyles.inputClass}
            />
          )
        }
      </div>

      <GridBody
        tiles={tiles}
        contentType={contentType}
        subType={subType}
        theme={theme}
        hasRemoveIcon={hasRemoveIcon}
        isEmptyGrid={isEmptyGrid}
        gridPosition={gridPosition}
        listTitle={getGridHeader(isEmptyGrid, header, tabbing, selectedTab)}
        isContentItemOnMyList={isContentItemOnMyList}
        addToMyList={addToMyList}
        removeFromMyList={removeFromMyList}
      />
      <JSONLD schema={createItemListLDSchema(tiles)} type="ItemList" />
    </div>
  )
}

Grid.propTypes = {
  header: PropTypes.string,
  tiles: PropTypes.arrayOf(propType(TILE_FRAGMENT)).isRequired,
  series: PropTypes.arrayOf(propType(TILE_FRAGMENT)).isRequired,
  movies: PropTypes.arrayOf(propType(TILE_FRAGMENT)).isRequired,
  sortingValue: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.object])
  }),
  sortingOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.object])
  })).isRequired,
  setSortingValue: PropTypes.func.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  contentType: PropTypes.string,
  subType: PropTypes.string,
  hasRemoveIcon: PropTypes.bool.isRequired,
  tabbing: PropTypes.bool,
  selectedTab: PropTypes.string,
  isEmptyGrid: PropTypes.bool,
  gridPosition: PropTypes.number.isRequired,
  isContentItemOnMyList: PropTypes.func,
  addToMyList: PropTypes.func,
  removeFromMyList: PropTypes.func
}

Grid.defaultProps = {
  header: '',
  sortingValue: null,
  contentType: null,
  subType: null,
  tabbing: true,
  series: [],
  movies: [],
  selectedTab: '',
  isEmptyGrid: false,
  isContentItemOnMyList: F,
  addToMyList: T,
  removeFromMyList: T
}

const EnhancedGrid = React.memo(({ grid, grid: { subType, contentType }, ...props }) => {
  const sortingOptions = grid.uiConfig.sortBy && grid.uiConfig.sortBy.length
    ? getSortingOptions(grid.uiConfig.sortBy) : []
  const movies = grid.tiles.filter(tile => tile.contentItem.__typename === TABS.movies)
  const series = grid.tiles.filter(tile => tile.contentItem.__typename === TABS.series)

  const [sortingValue, setSortValue] = useState(null)
  const setSortingValue = useCallback((value) => {
    setSortValue(value)
  }, [sortingValue, setSortValue])

  const [selectedTab, setSelected] = useState(movies.length && !series.length ? TABS.movies : TABS.series)
  const [tabbing, setTabbing] = useState(grid.header === 'My List')

  const setSelectedTab = useCallback((selected) => {
    setSelected(selected)
    setTabbing(contains(subType, TABS_SUBTYPES))
  }, [selectedTab])

  const getTiles = () => {
    if (tabbing) {
      return sortGrid(
        (selectedTab === TABS.movies ? movies : series) || [], sortingValue || null
      )
    }
    return sortGrid(grid.tiles || [], sortingValue || null)
  }

  return (
    <Grid
      tabbing={tabbing}
      sortingOptions={sortingOptions}
      hasRemoveIcon={subType === STATIC_SCREENS.MY_LIST.subType}
      movies={movies}
      series={series}
      subType={subType}
      contentType={tabbing && selectedTab ? selectedTab.toUpperCase() : contentType}
      sortingValue={sortingOptions.length ? (sortingValue || sortingOptions[0]) : null}
      setSortingValue={setSortingValue}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      tiles={getTiles()}
      isEmptyGrid={tabbing ? grid.tiles.length === 0 : false}
      header={grid.header}
      {...props}
    />
  )
})

EnhancedGrid.propTypes = {
  grid: PropTypes.oneOfType([PropTypes.object]).isRequired
}

export default EnhancedGrid
