import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { path } from 'ramda'
import AccountQuery from '../../graphql/queries/account.gql'

/**
 * Hook to return profiles
 */
export const useProfile = (fetchPolicy) => {
  const [allProfiles, setAllProfiles] = useState([])
  const [currentProfile, setCurrentProfile] = useState({
    id: null,
    name: null,
    email: null
  })

  const { data, loading, error } = useQuery(AccountQuery, {
    fetchPolicy
  })

  useEffect(() => {
    if (loading) return

    if (error) {
      console.error(
        `Unable to obtain profile`
      )
      return
    }

    const profiles = path(['account', 'profiles'], data)
    const selectedProfile = path(['account', 'selectedProfile'], data)
    const profile = profiles && profiles.find(p => p.id === selectedProfile)

    setAllProfiles(profiles)
    setCurrentProfile(profile)
  }, [data, error, loading])

  return {
    allProfiles,
    currentProfile
  }
}
