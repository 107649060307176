import React from 'react'
import PropTypes from 'prop-types'

import Card from '../../../shared/card'
import Table from '../../../../../containers/settings/shared/table'
import Imgix from '../../../../imgix'

import styles from './rentals.css'

function dateSorting(a, b) {
  return a.value > b.value ? 1 : -1
}

function dateCell(field) {
  return ({ row }) => row[field].label
}

function RentalsTable({
  purchases
}) {
  const rentalData = purchases
  const columns = [{
    columns: [{
      Header: 'Title',
      accessor: 'title',
      headerClassName: 'alignLeft',
      className: 'alignLeft',
      aggregate: ['title', 'boxart'],
      Cell: row => (
        <div className={styles.rentalTitle}>
          {
            row.original.boxart
              ? (
                <div className={styles.boxArtWrapper}>
                  <Imgix src={row.original.boxart} className={styles.rentalArtwork} alt="" />
                </div>
              )
              : <div className={styles.boxArtWrapper}>&nbsp;</div>
          }
          <span className={styles.rentalName}>{row.value}</span>
        </div>
      )
    },
    {
      Header: 'Rented',
      id: 'rented',
      accessor: row => row.rented,
      Cell: dateCell('rented'),
      headerClassName: 'alignLeft',
      className: 'alignLeft',
      maxWidth: 150,
      sortMethod: dateSorting
    },
    {
      Header: 'Expires',
      id: 'expires',
      accessor: row => row.expires,
      Cell: dateCell('expires'),
      headerClassName: 'alignLeft',
      className: 'alignLeft',
      maxWidth: 150,
      sortMethod: dateSorting
    },
    {
      Header: 'Price',
      accessor: 'price',
      headerClassName: 'alignLeft',
      className: 'alignLeft',
      maxWidth: 100
    }]
  }]

  return (
    <Card>
      <Table
        columns={columns}
        data={rentalData}
        noDataText={'You haven\'t rented anything yet.'}
        defaultSorted={[
          {
            id: 'rented',
            desc: true
          }
        ]}
      />
    </Card>
  )
}

RentalsTable.propTypes = {
  purchases: PropTypes.array.isRequired // eslint-disable-line react/forbid-prop-types
}

RentalsTable.defaultProps = {
  // data from account query: accountQuery.account
  purchases: []
}

export default RentalsTable
