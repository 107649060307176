import React from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import { propType } from 'graphql-anywhere'
import { compose, withState, withHandlers } from 'recompose'
import { T, pathOr } from 'ramda'
import OnVisible from 'react-on-visible'

import GridTileOverlay from './grid-tile-overlay'
import TileBadge from '../shared/badge'
import Imgix from '../imgix'
import { getTileRecipe } from '../imgix/recipes'
import { getDetailsUrl } from '../../lib/utils'
import { THEME_OPTIONS } from '../../constants'

import styles from './grid-tile.css'

import TILE_FRAGMENT from '../../../graphql/fragments/tile.gql'
import { dataLayerProductClick } from '../../lib/analytics/datalayer'

import rentalBadgeSmall from '../../../images/neon/rental-badge-small.svg'
import rentalBadgeKidsSmall from '../../../images/neon/rental-badge-kids-small.svg'

function GridTile({
  tile,
  onMouseEnter,
  onMouseLeave,
  isMouseHover,
  isAuthenticated,
  theme,
  contentType,
  subType,
  hasRemoveIcon,
  setRemoved,
  onRemoveClick,
  toBeRemoved,
  listTitle,
  index,
  isOnMyList,
  addToMyList,
  removeFromMyList,
  isVisible,
  onVisibilityChange
}) {
  // TODO: not really using subType yet
  const hasBadge = Boolean(tile.badge)
  const tileRecipe = getTileRecipe(contentType, subType)
  const removeClass = toBeRemoved ? styles.removed : ''
  const isRental = pathOr(false, ['contentItem', 'isRental'], tile)
  const rentalBadge =
    theme === THEME_OPTIONS.light ? rentalBadgeKidsSmall : rentalBadgeSmall
  const isKids = theme === THEME_OPTIONS.light

  return (
    <div className={`${styles.tile} ${theme} ${removeClass}`}>
      <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {hasBadge && (
          <TileBadge
            title={tile.badge}
            isRental={isRental}
            gridBadge
            isKids={isKids}
          />
        )}
        <Link
          to={getDetailsUrl(tile.contentItem)}
          data-lbx-e2e={tile.contentItem.path}
          onClick={() => dataLayerProductClick(tile.contentItem, index, listTitle, window.location.pathname)}
          className={styles.imgWrapper}
        >
          <Imgix src={tile.image} recipe={tileRecipe} className={!isKids ? styles.roundedBorder : null} />

          {isRental && (
            <img
              className={styles.rentalBadge}
              src={rentalBadge}
              alt="Rental Content"
            />
          )}

          <GridTileOverlay
            tile={tile}
            display={isMouseHover}
            isAuthenticated={isAuthenticated}
            hasRemoveIcon={hasRemoveIcon}
            onRemoveClick={onRemoveClick}
            setRemoved={setRemoved}
            isOnMyList={isOnMyList}
            addToMyList={addToMyList}
            removeFromMyList={removeFromMyList}
            theme={theme}
          />
        </Link>
      </div>
      {!isVisible && <OnVisible onChange={() => onVisibilityChange()} />}
    </div>
  )
}

GridTile.propTypes = {
  tile: propType(TILE_FRAGMENT).isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  isMouseHover: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired,
  contentType: PropTypes.string,
  subType: PropTypes.string,
  hasRemoveIcon: PropTypes.bool.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  setRemoved: PropTypes.func.isRequired,
  toBeRemoved: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool,
  listTitle: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isOnMyList: PropTypes.bool,
  addToMyList: PropTypes.func,
  removeFromMyList: PropTypes.func,
  onVisibilityChange: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired
}

GridTile.defaultProps = {
  contentType: null,
  subType: null,
  isAuthenticated: false,
  isOnMyList: false,
  addToMyList: T,
  removeFromMyList: T
}

const enhance = compose(
  withState('isMouseHover', 'setIsMouseHover', false),
  withState('toBeRemoved', 'setToBeRemoved', false),
  withHandlers({
    onMouseEnter: ({ setIsMouseHover }) => () => {
      setIsMouseHover(true)
    },
    onMouseLeave: ({ setIsMouseHover }) => () => {
      setIsMouseHover(false)
    },
    setRemoved: ({ setToBeRemoved }) => () => {
      setToBeRemoved(true)
    }
  })
)

export default withRouter(enhance(GridTile))
