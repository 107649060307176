import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'

import styles from './continuity.css'

import MoreInfoIcon from '../icons/more-info-icon'
import UndoIcon from '../../../../../images/undo-icon.svg'
import infoIcon from '../../../../../images/neon/info-24px.svg'
import playOutlineIcon from '../../../../../images/neon/play-outline-24px.svg'
import removeIcon from '../../../../../images/neon/remove-trash-bin.svg'
import { useContinuity } from '../../hooks/use-continuity'
import BottomSheet from '../../../../components/bottom-sheet/bottom-sheet'
import { ContinuityBtmSheetItem } from '../continuity-btm-sheet-item/continuity-btm-sheet-item'
import { UNDO_INFO } from '../../constants'

/**
 * Mobile overlay component for continuity tile
 */

const ContinuityMobileOverlay = React.memo(({
  contentItem,
  isInUndoState,
  onClickToDetail,
  onClickToResumeWatching,
  title
}) => {
  const { isRental } = contentItem
  const {
    removeContinuity,
    addContinuity
  } = useContinuity(contentItem)
  const [btmSheetOpen, setBtmSheetOpen] = useState(false)

  const clickToPopup = () => {
    setBtmSheetOpen(true)
  }

  const clickToRemove = () => {
    removeContinuity()
    setBtmSheetOpen(false)
  }

  const clickToUndoRemove = () => {
    addContinuity()
  }

  if (isRental) {
    return null
  }

  if (isInUndoState) {
    return (
      <div className={styles.undoOverlay}>
        <span className={styles.mobileText}>{UNDO_INFO.title}</span>
        <div className={styles.undoIconContainer} onClick={clickToUndoRemove}>
          <div className={styles.revertIconContainer}>
            <ReactSVG
              className={styles.revertIcon}
              src={UndoIcon}
            />
          </div>
          {UNDO_INFO.button}
        </div>
      </div>
    )
  }

  return (
    <>
      <MoreInfoIcon
        onClick={clickToPopup}
        className={styles.moreIconContainer}
      />
      <BottomSheet
        isOpen={btmSheetOpen}
        onClickOutside={() => setBtmSheetOpen(false)}
        onClose={() => setBtmSheetOpen(false)}
      >
        <div className={styles.btmContainer}>
          <div className={styles.btmTitle}>{title}</div>
          <div className={styles.btmContent}>
            <ContinuityBtmSheetItem
              icon={infoIcon}
              title="Show Details"
              onClick={onClickToDetail}
            />
            <ContinuityBtmSheetItem
              icon={playOutlineIcon}
              title="Resume Watching"
              onClick={onClickToResumeWatching}
            />
            <ContinuityBtmSheetItem
              icon={removeIcon}
              title="Remove from continue watching"
              onClick={clickToRemove}
            />
          </div>
        </div>
      </BottomSheet>
    </>
  )
})

ContinuityMobileOverlay.propTypes = {
  contentItem: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isInUndoState: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onClickToDetail: PropTypes.func,
  onClickToResumeWatching: PropTypes.func
}

export default ContinuityMobileOverlay
