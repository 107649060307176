import React from 'react'
import {
  pipe,
  propOr,
  prop,
  sortBy,
  head
} from 'ramda'

import { RENTAL_STATUS } from '../constants'

/**
 * Takes the contentItem object
 * Returns a string of the lowest priced product
 * Defaults to an empty string
 */
export const getLowestPrice = pipe(
  propOr([], 'products'),
  sortBy(
    pipe(
      prop('currentPrice'),
      Number
    )
  ),
  head,
  propOr('', 'currentPrice')
)

/**
 * Takes contentItem and myRentalHistory objects
 * Returns the item's rental status
 */
export const getRentalStatus = (contentItem, myRentalHistory) => {
  const id = propOr('', 'id', contentItem)
  const playable = propOr(false, 'playable', contentItem)
  if (!playable) {
    return RENTAL_STATUS.NOT_RENTABLE
  }
  const items = propOr([], 'items', myRentalHistory)
  const item = items.find(i => i.contentItem.id === id)
  if (!item) {
    return RENTAL_STATUS.RENTABLE
  }
  return item.expired ? RENTAL_STATUS.RENTABLE_AGAIN : RENTAL_STATUS.RENTED
}

/**
 * Takes rental status
 * Returns whether the item can be rented
 */
export const isRentable = (rentalStatus) => {
  return rentalStatus === RENTAL_STATUS.RENTABLE || rentalStatus === RENTAL_STATUS.RENTABLE_AGAIN
}

export const formatParagraphs = (text = '') => {
  return text && text.split('\n').map((item, key) => (
    // eslint-disable-next-line react/no-array-index-key
    <span key={key}>
      {item}
      <br />
    </span>
  ))
}
