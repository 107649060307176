
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ProfileFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Profile"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"email"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isKid"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isDefault"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"needToConfigure"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"ageGroup"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"avatar"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uri"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"uriForDisabledProfile"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"closedCaption"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"maxRating"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"mobile"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"birthYear"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"gender"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":221}};
    doc.loc.source = {"body":"fragment ProfileFields on Profile {\n  id\n  name\n  email\n  isKid\n  isDefault\n  needToConfigure\n  ageGroup\n  avatar {\n    uri\n    id\n    uriForDisabledProfile\n  }\n  closedCaption\n  maxRating\n  mobile\n  birthYear\n  gender\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
