import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { propType } from 'graphql-anywhere'
import {
  compose,
  take,
  join,
  defaultTo
} from 'ramda'

import MyListRibbonKids from '../shared/my-list/ribbon-kids'
import MyListRibbon from '../carousel/my-list-ribbon'
import { getDurationTextFromMinutes } from '../../lib/date-time'
import { getLowestPrice } from '../../lib/content-item'
import styles from './grid-overlay-title-kids.css'

import TILE_FRAGMENT from '../../../graphql/fragments/tile.gql'
import Clamped from '../shared/clamped'

function GridOverlayTitleKids({
  tile,
  hasRemoveIcon,
  setRemoved,
  onRemoveClick,
  isOnMyList,
  isAuthenticated,
  addToMyList,
  removeFromMyList
}) {
  const contentItem = tile.contentItem
  const title = contentItem.title
  const year = contentItem.year
  const rating = contentItem.rating && contentItem.rating.rating
  const isRental = contentItem.isRental
  const genres = compose(
    join(', '),
    take(2),
    defaultTo([])
  )(contentItem.genres)
  const duration = getDurationTextFromMinutes(contentItem.duration)
  const price = getLowestPrice(contentItem)

  let ribbon = null
  if (hasRemoveIcon) {
    ribbon = (
      <MyListRibbonKids
        contentItem={contentItem}
        setRemoved={setRemoved}
        onRemoveClick={onRemoveClick}
      />
    )
  } else if (isAuthenticated) {
    ribbon = (
      <MyListRibbon
        contentItem={contentItem}
        className={styles.ribbon}
        isOnMyList={isOnMyList}
        addAction={addToMyList}
        removeAction={removeFromMyList}
      />
    )
  }

  return (
    <div className={classNames(styles.wrapper, {
      [styles.wrapperNeon]: true,
      [styles.wrapperNeonRental]: isRental
    })}
    >
      {ribbon}
      <Clamped className={styles.title} lines={3}>{title}</Clamped>

      <div className={styles.upperParts}>
        <div className={styles.year}>
          {year}
          {' '}
          |
          {' '}
          {rating}
        </div>
        <Clamped className={styles.genres} lines={1}>{genres}</Clamped>
        <div className={styles.duration}>{duration}</div>
      </div>

      {
        isRental && price !== -Infinity && (
          <div className={styles.price}>
            $
            {price}
          </div>
        )
      }

      <div className={styles.buttonWrapper}>
        <div className={classNames(styles.button, {
          [styles.buttonNeon]: true,
          [styles.buttonNeonRental]: isRental
        })}
        >
          More Info
        </div>
      </div>
    </div>
  )
}

GridOverlayTitleKids.propTypes = {
  tile: propType(TILE_FRAGMENT).isRequired,
  hasRemoveIcon: PropTypes.bool.isRequired,
  setRemoved: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  isOnMyList: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  addToMyList: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]).isRequired,
  removeFromMyList: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]).isRequired
}

GridOverlayTitleKids.defaultProps = {
  isOnMyList: false,
  isAuthenticated: false
}

export default GridOverlayTitleKids
