import React from 'react'
import PropTypes from 'prop-types'
import {
  compose,
  withHandlers,
  withState
} from 'recompose'
import classNames from 'classnames'
import ReactSVG from 'react-svg'

import NavigationItem from '../../containers/navigation/navigation-item'
import { getModalLocation } from '../../lib/modal'
import { ACCOUNT_SCREENS, MODALS } from '../../constants'
import NavLinkWithQuery from '../../components/shared/nav-link-with-query'

import myAccountIcon from '../../../images/my-account.svg'
// TODO: LBXW-540 Implement data saver page
// import dataSaverIcon from '../../../images/data-saver.svg';
import helpIcon from '../../../images/help.svg'
import feedbackIcon from '../../../images/feedback.svg'
import profilesIcon from '../../../images/profiles.svg'
import logoutIcon from '../../../images/logout.svg'

import arrowUp from '../../../images/arrow-up.svg'
import arrowDown from '../../../images/arrow-down.svg'

import styles from './navigation-settings.css'

import { E2E_SELECTORS } from '../../e2e-selectors'

function getOptionClassName(option, location) {
  const active = option.path === location.pathname
  return classNames(styles.subMenuItem, {
    [styles.itemActive]: active
  })
}

function NavigationSettings({
  isAuthenticated,
  isExpanded,
  onHeaderClick,
  onLogoutClick,
  location,
  inSideNav,
  helpURLConfig,
  feedbackURLConfig,
  email,
  cpId
}) {
  const settingsMenu = [
    {
      path: ACCOUNT_SCREENS.ACCOUNT_DETAILS,
      name: 'Account Details',
      target: '',
      type: '',
      children: [],
      isRental: false,
      isDiscoverNav: false
    },
    {
      path: ACCOUNT_SCREENS.SUBSCRIPTION_PAYMENT,
      name: 'Plan and Payment',
      target: '',
      type: '',
      children: [],
      isRental: false,
      isDiscoverNav: false
    },
    {
      path: ACCOUNT_SCREENS.DEVICES_DOWNLOADS,
      name: 'Devices and Downloads',
      target: '',
      type: '',
      children: [],
      isRental: false,
      isDiscoverNav: false
    },
    {
      path: ACCOUNT_SCREENS.MY_ACTIVITY,
      name: 'My Activity',
      target: '',
      type: '',
      children: [],
      isRental: false,
      isDiscoverNav: false
    }
  ]

  const isSettingSelected = settingsMenu.find(option => option.path === location.pathname)
  const menuItemHeaderClass = classNames(styles.menuItemHeader, {
    [styles.selected]: isSettingSelected
  })

  return (
    <div
      className={classNames({
        [styles.sidePanelWrapper]: true,
        [styles.inSideNav]: inSideNav
      })}
    >
      {
        isAuthenticated && (
          <div className={styles.sidePanel}>
            <h2>My Account</h2>
            <ul className={styles.menu}>
              <li className={`${styles.menuItem} ${styles.expanded}`}>
                <div
                  className={menuItemHeaderClass}
                  onClick={() => {
                    onHeaderClick()
                  }}
                >
                  <ReactSVG
                    src={myAccountIcon}
                    className={classNames(styles.accountSvg, { [styles.selected]: isSettingSelected })}
                  />
                  <span data-e2e={E2E_SELECTORS.MY_ACCOUNT_IN_SETTINGS_MENU}>
                    My Account
                  </span>
                  <ReactSVG
                    className={styles.arrowIcon}
                    src={isExpanded ? arrowUp : arrowDown}
                  />
                </div>
                {
                  isExpanded && (
                    <ul className={styles.subMenu}>
                      {
                        settingsMenu.map((option) => {
                          return (
                            <li
                              className={styles.subMenuItem}
                              key={option.name}
                            >
                              <NavigationItem
                                navItem={option}
                                className={getOptionClassName(option, location)}
                                inSideNav={inSideNav}
                              />
                            </li>
                          )
                        })
                      }
                    </ul>
                  )
                }
              </li>
              {/*
            TODO: LBXW-540 Implement data saver page
            <li className={styles.menuItem}>
              <NavLink
                className={styles.navLink}
                exact
                to=ACCOUNT_SCREENS.DATA_SAVER
              >
                <img alt="Data Saver Icon" src={dataSaverIcon} />
                <span>Data Saver</span>
              </NavLink>
            </li>
            */}
              <li className={styles.menuItem}>
                <a className={styles.navLink} href={helpURLConfig}>
                  {/* <img alt="Help Icon" src={helpIcon} /> */}
                  <ReactSVG
                    className={styles.navLinkIcon}
                    src={helpIcon}
                  />

                  <span
                    className={styles.navLinkText}
                    data-e2e={E2E_SELECTORS.HELP_IN_SETTINGS_MENU}
                  >
                    Help
                  </span>
                </a>
              </li>
              <li className={styles.menuItem}>
                <a className={styles.navLink} href={feedbackURLConfig}>
                  <ReactSVG
                    className={styles.navLinkIcon}
                    src={feedbackIcon}
                  />
                  <span
                    className={styles.navLinkText}
                    data-e2e={E2E_SELECTORS.FEED_BACK}
                  >
                    Feedback
                  </span>
                </a>
              </li>
              <li className={styles.menuItem}>
                <NavLinkWithQuery
                  className={styles.navLink}
                  exact
                  to={getModalLocation(location, MODALS.qsParams.profilesManagement)}
                >
                  <ReactSVG
                    className={styles.navLinkIcon}
                    src={profilesIcon}
                  />
                  <span
                    className={styles.navLinkText}
                    data-e2e={E2E_SELECTORS.MANAGE_PROFILE_IN_SETTINGS}
                  >
                    Manage Profiles
                  </span>
                </NavLinkWithQuery>
              </li>
              <li
                className={styles.menuItem}
              >
                <div onClick={() => onLogoutClick(email)}>
                  <ReactSVG
                    className={styles.navLinkIcon}
                    src={logoutIcon}
                  />
                  <span
                    className={styles.navLinkText}
                    data-e2e={E2E_SELECTORS.LOGOUT_IN_SETTINGS}
                  >
                    Log Out
                  </span>
                </div>
              </li>
            </ul>
          </div>
        )
      }
    </div>
  )
}

NavigationSettings.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onHeaderClick: PropTypes.func.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
  inSideNav: PropTypes.bool,
  helpURLConfig: PropTypes.string.isRequired,
  feedbackURLConfig: PropTypes.string.isRequired,
  email: PropTypes.string,
  cpId: PropTypes.string
}

NavigationSettings.defaultProps = {
  inSideNav: false
}

const enhance = compose(
  withState('isExpanded', 'setIsExpanded', true),
  withHandlers({
    onHeaderClick: ({ isExpanded, setIsExpanded }) => () => {
      setIsExpanded(!isExpanded)
    }
  })
)

export default enhance(NavigationSettings)
