import React from 'react'
import PropTypes from 'prop-types'
import ReactImgix, { Background } from 'react-imgix'
import classNames from 'classnames'
import { defaultTo } from 'ramda'
import 'lazysizes'
import 'lazysizes/plugins/attrchange/ls.attrchange'

import recipes from './recipes'
import { getPlaceholder } from './get-placeholder'

import styles from './imgix.css'

/**
 * @param recipe - recipe name or recipe object
 */
function Imgix({
  className,
  src,
  recipe,
  imgixParams,
  draggable,
  precision,
  background,
  sizes
}) {
  const recipeConfig = defaultTo({}, recipes[recipe] || recipe)
  const lowQualitySrcPlaceholder = getPlaceholder(recipeConfig)
  let width = 0
  let height = 0
  if (recipeConfig.width) {
    width = recipeConfig.width
    height = width * recipeConfig.ratio
  } else {
    height = recipeConfig.height
    width = height / recipeConfig.ratio
  }

  const ImgixComponent = background ? Background : ReactImgix

  return (
    <ImgixComponent
      htmlAttributes={{ draggable: draggable.toString(), src: lowQualitySrcPlaceholder }}
      className={classNames(styles.imgix, className, 'lazyload')}
      src={src}
      width={recipe ? width : undefined}
      height={recipe ? height : undefined}
      sizes={sizes}
      imgixParams={imgixParams}
      precision={precision}
      attributeConfig={{
        src: 'data-src',
        srcSet: 'data-srcset',
        sizes: 'data-sizes'
      }}
      data-testid="imgix-component"
    />
  )
}

Imgix.propTypes = {
  src: PropTypes.string.isRequired,
  // Imgix component accepts recipes defined in './recipes.js'
  recipe: PropTypes.oneOf([...Object.keys(recipes), ...Object.values(recipes)]),
  className: PropTypes.string,
  imgixParams: PropTypes.oneOfType([PropTypes.object]),
  draggable: PropTypes.bool,
  precision: PropTypes.number,
  background: PropTypes.bool,
  sizes: PropTypes.string
}

Imgix.defaultProps = {
  recipe: null,
  className: '',
  imgixParams: { fit: 'crop' },
  draggable: false,
  precision: 100,
  background: false,
  sizes: ''
}

export default Imgix
