import React from 'react'
import { propType } from 'graphql-anywhere'
import classnames from 'classnames'
import PropTypes from 'prop-types'
// import { sanitizeDom } from '../../utils'

import HTML_FRAGMENT from '../../../graphql/fragments/html.gql'
import styles from './html.css'
import withIsWhiteTheme from '../../hoc/with-is-white-theme'
import HTMLSurvey from './html-survey'
import HTMLCountdown from './html-countdown'

const isSurveyMonkey = text => text.includes('smcx-sdk')
const isCountdownTimer = text => text.includes('countdown-timer')

function HTML({ html, isWhiteTheme, isMarketing }) {
  // Replaces any instances of "http" with "https" to prevent Mixed Content issues
  const convertProtocol = copy => copy.replace(/http:/g, 'https:')

  if (isSurveyMonkey(html.copy)) {
    return <HTMLSurvey copy={html.copy} />
  }

  if (isCountdownTimer(html.copy)) {
    return <HTMLCountdown copy={html.copy} />
  }

  return (
    <div
      className={classnames(styles.wrapper, {
        [styles.whiteTheme]: isWhiteTheme || isMarketing
      })}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: convertProtocol(html.copy) }}
    />
  )
}

HTML.propTypes = {
  html: propType(HTML_FRAGMENT).isRequired,
  isWhiteTheme: PropTypes.bool.isRequired,
  isMarketing: PropTypes.bool.isRequired
}

export default withIsWhiteTheme(HTML)
