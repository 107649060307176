import { analytics } from './segment-setup'

export const getCurrentProfile = () => {
  const profileInfo = analytics?.instance?.user()?.traits()?.profile

  const profile = {
    id: profileInfo?.id,
    name: profileInfo?.name
  }

  return profile
}
