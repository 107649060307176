import React from 'react'
import PropTypes from 'prop-types'

import styles from './navigation-item-header.css'

const NavigationItemHeader = ({
  title
}) => (
  <div className={styles.header}>
    {title}
  </div>
)

NavigationItemHeader.propTypes = {
  title: PropTypes.string.isRequired
}

export default NavigationItemHeader
