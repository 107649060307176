import { createUseStyles } from 'react-jss'
import { commonStyles } from '../../../shared/common.styles'

export const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),
  selectPlanButton: {
    zIndex: theme.zIndexCardBadge,
    position: 'relative',
    width: '60%',
    color: theme.black,
    backgroundColor: theme.neonGreen,
    margin: [60, 0, 62, 0],
    fontSize: 20,
    [theme.media.mobile]: {
      width: '100%'
    },
    [theme.media.smallMobile]: {
      width: '100%'
    }
  },

  goBackButton: {
    backgroundColor: 'transparent',
    color: theme.neonGreen,
    margin: [-40, 0, 40, 0],

    [theme.media.smallMobile]: {
      margin: [-40, 0, 20, 0]
    }
  },

  disabledButton: {
    pointerEvents: 'none',
    opacity: 0.4
  },

  selectPlanButtonNew: {
    margin: [60, 0, -40, 0]
  },

  changeNotAllowedWarning: {
    margin: [-20, 0, 20, 0],
    color: theme.neonGreen,
    textAlign: 'center',
    fontFamily: 'GothamRounded',
    fontSize: 20,
    fontWeight: 350,
    lineHeight: '24px',

    [theme.media.smallMobile]: {
      margin: 0
    }
  }
}))
