import React from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'
import Circle from 'rc-progress/lib/Circle'

import play from '../../../images/play.svg'

import styles from './video-feature.css'

const PlayButton = ({
  onTrailerClick
}) => {
  return (
    <div className={styles.playButtonWrapper}>
      <div className={styles.playButton} onClick={onTrailerClick}>
        <Circle
          className={styles.circle}
          trailWidth="5"
          strokeWidth="5"
          trailColor="rgba(255,255,255,0.6)"
        />
        <ReactSVG
          beforeInjection={(svg) => {
            svg.setAttribute('class', styles.play)
          }}
          src={play}
        />
        <div className={styles.cta}>
          WATCH TRAILER
        </div>
      </div>
    </div>
  )
}

PlayButton.propTypes = {
  onTrailerClick: PropTypes.func.isRequired
}

export default PlayButton
