import { identity, propOr } from 'ramda'

/*
 * create a reducer to handle specific actions.
 * @param {object} initialState - This is the initial state of
 * this reducer.
 * @param {object} handlers - An object the actions the reducer
 * will react to. Each key corresponds to an action type.
 *
 * @example
 * const initialState = 0;
 * createReducer(initialState, {
 *   [ActionTypes.INCREMENT]: (state) => state + 1,
 *   [ActionTypes.MULTIPLY]: (state, action) => {
 *     return state * action.multiplier;
 *   },
 * });
 */

export function createReducer(initialState, handlers) {
  // Ramda.identity returns the first value verbatim.
  // It's used here to return the state for unknown actions.
  return (state = initialState, action) => propOr(identity, action.type, handlers)(state, action)
}
