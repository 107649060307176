import PropTypes from 'prop-types'
import {
  compose,
  withHandlers,
  setPropTypes
} from 'recompose'

export const withRemoveFromMyList = compose(
  setPropTypes({
    contentItem: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      isRental: PropTypes.bool
    }).isRequired,
    onRemoveClick: PropTypes.func.isRequired,
    setRemoved: PropTypes.func.isRequired
  }),
  withHandlers({
    removeFromMyList: ({ contentItem, setRemoved, onRemoveClick }) => {
      return (event) => {
        event.stopPropagation()
        event.preventDefault()
        setRemoved(event)
        onRemoveClick(contentItem)
      }
    }
  })
)
