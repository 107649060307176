const halloweenContent = {
  image: 'https://lightbox-prod.imgix.net/images/static/halloween-hotspot-campaign.jpg',
  subject: 'Halloween Watch & Win',
  description: 'Rent a Movie between 22nd October and 4th November and be in to win a smart TV and sound system.',
  terms: {
    link: '/halloween-terms',
    text: 'Terms and Conditions'
  }
}

export default halloweenContent
