import React from 'react'
import PropTypes from 'prop-types'

import Modal from '../modal'

import PinForm from '../../containers/pin/pin-form'
import { getModalLocation } from '../../lib/modal'
import NavLinkWithQuery from '../shared/nav-link-with-query'
import { MODALS } from '../../constants'

import styles from './check-pin.css'

function PinModalContent({ onValidPin, onCancel, location }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>Enter PIN</h1>
        <span className={styles.subheader}>
          Parental controls are set. Please enter the 4-digit account PIN to access your profile.
        </span>
        <div className={styles.formWrapper}>
          <PinForm
            onValidPin={onValidPin}
            onCancel={onCancel}
          />
        </div>

        <NavLinkWithQuery
          className={styles.button}
          to={getModalLocation(location, MODALS.qsParams.pinForgotten)}
          onClick={onCancel}
        >
          Forgot your PIN?
        </NavLinkWithQuery>
      </div>
    </div>
  )
}

PinModalContent.propTypes = {
  onValidPin: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

function CheckPin({ onCancel, onValidPin, location }) {
  return (
    <Modal
      noRouting
      onDismiss={onCancel}
    >
      <PinModalContent
        onCancel={onCancel}
        onValidPin={onValidPin}
        location={location}
      />
    </Modal>
  )
}

CheckPin.propTypes = {
  onValidPin: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default CheckPin
