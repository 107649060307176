import React from 'react'
import ReactSVG from 'react-svg'
import shareIcon from '../../../../../images/my-rentals.svg'
import styles from './navigation-account-side.css'

const MyRentalsIcon = () => {
  return (
    <div className={styles.myRentalsIcon}>
      <ReactSVG
        src={shareIcon}
        className={styles.svgWrapper}
      />
    </div>
  )
}

export default MyRentalsIcon
