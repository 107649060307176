import { propEq, pathOr, anyPass } from 'ramda'
import { MW_ERRORS, MW_ERROR_MESSAGES } from '../../constants'

import {
  displayErrorDialog
} from '../../actions'

const isInternalError = propEq('code', MW_ERRORS.INTERNAL_SERVER_ERROR)
const isEvergentError = propEq('code', MW_ERRORS.EVERGENT_API_ERROR)
const isStreamingLimitUpgradeError = propEq('code', MW_ERRORS.STREAMING_LIMIT_SVOD_UPGRADE)

const isError = anyPass([
  isInternalError,
  isEvergentError,
  isStreamingLimitUpgradeError
])

function getInternalServerError(errors = []) {
  const e = errors.filter(isError)
  if (e.length > 0) return e[0]
  return false
}

/**
 * Handles internal server errors through the apollo network error link
 * @param {Array} errors - List of GraphQL JSON error objects
 * @param {Object} store - Redux store
 */
export function handleInternalServerError(errors, store) {
  const internalServerError = getInternalServerError(errors)

  if (!internalServerError) {
    return false
  }

  return store.dispatch(displayErrorDialog({
    message: pathOr(MW_ERROR_MESSAGES.INTERNAL_SERVER_ERROR_MESSAGE, ['message'], internalServerError)
  }))
}
