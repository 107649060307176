import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './layout.css'

function FormColumn({ children, className }) {
  return (
    <div className={classNames(styles.column, className)}>
      {children}
    </div>
  )
}

FormColumn.propTypes = {
  className: PropTypes.string
}

FormColumn.defaultProps = {
  className: ''
}

export default FormColumn
