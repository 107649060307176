
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"HotspotComponentFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Hotspot"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"slides"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"HotspotSlideFields"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"uiConfig"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"HotspotConfig"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"interval"},"arguments":[],"directives":[]}]}}]}}]}}],"loc":{"start":0,"end":197}};
    doc.loc.source = {"body":"#import \"./hotspot-slide.gql\"\n\nfragment HotspotComponentFields on Hotspot {\n  __typename\n  slides {\n    ...HotspotSlideFields\n  }\n  uiConfig {\n    ... on HotspotConfig {\n      interval\n    }\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./hotspot-slide.gql").definitions));


      module.exports = doc;
    
