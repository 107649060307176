import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './layout.css'

function FormRow({ children, className }) {
  return (
    <div className={classNames(styles.row, className)}>
      {children}
    </div>
  )
}

FormRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]).isRequired,
  className: PropTypes.string
}

FormRow.defaultProps = {
  className: ''
}

export default FormRow
