import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles(theme => ({
  blackArrow: {
    background: 'rgba(0, 0, 0, 0.3)',

    '&:hover': {
      background: 'rgba(0, 0, 0, 0.5)'
    },

    '& svg': {
      fill: '#fff'
    }
  }
}))
