import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import CheckIcon from '../icons/check-icon'
import CloseIcon from '../icons/close-icon'
import theme from '../../../../theme'
import devices from '../../../../../images/devices.svg'
import liveTv from '../../../../../images/live-tv.svg'
import download from '../../../../../images/download.svg'
import monitor from '../../../../../images/monitor.svg'
import group from '../../../../../images/group.svg'
import player from '../../../../../images/player-icon.svg'
import moreInfo from '../../../../../images/neon/more-info-alt.svg'
import { planDetailsForAvod, featuresHaveTooltip } from '../../../shared/subscription-constants'
import { useStyles } from './subscription-content-section.styles'

const ICONS = [
  {
    name: 'devices',
    icon: devices
  },
  {
    name: 'liveTv',
    icon: liveTv
  },
  {
    name: 'download',
    icon: download
  },
  {
    name: 'quality',
    icon: liveTv
  },
  {
    name: 'resolution',
    icon: monitor
  },
  {
    name: 'group',
    icon: group
  },
  {
    name: 'player',
    icon: player
  }
]

const SubscriptionContentSection = props => {
  const styles = useStyles()
  const SUBCRIPTION_TYPE = planDetailsForAvod.types
  const {
    title,
    iconName,
    data,
    activeSubscription,
    updateActiveSubscription,
    currentSubscription,
    titleWithExtraInfoStyle,
    subTitle,
    subTitleLink
  } = props

  const renderIconColor = (index) => {
    if (activeSubscription === SUBCRIPTION_TYPE[index]) {
      return theme.neonGreen
    }

    if (currentSubscription === SUBCRIPTION_TYPE[index]) {
      return '#a0a0a0'
    }

    return 'white'
  }

  const renderContentItem = (item, index) => {
    if (typeof item === 'boolean') {
      if (item) {
        return <CheckIcon color={renderIconColor(index)} />
      }

      return <CloseIcon color={renderIconColor(index)} />
    }

    const itemHasTooltip = featuresHaveTooltip.find(value => value.name === item)

    if (itemHasTooltip) {
      return (
        <div className={styles.contentItemFrame}>
          {item}

          <div className={styles.tooltip}>
            <div className={styles.tooltipIconFrame}>
              <img src={moreInfo} className={styles.tooltipIcon} />
            </div>
            <span className={styles.tooltiptext}>
              {itemHasTooltip.description}
            </span>
          </div>
        </div>
      )
    }

    return item
  }

  const contentData = data.map((item, index) => {
    return (
      <div
        className={
          classNames(styles.tableRowColumn,
            activeSubscription === SUBCRIPTION_TYPE[index] && styles.activeSubscription,
            currentSubscription === SUBCRIPTION_TYPE[index] && styles.disabledColor,
            currentSubscription === SUBCRIPTION_TYPE[index] && styles[`marginFor${currentSubscription}`],
            currentSubscription === SUBCRIPTION_TYPE[index] && titleWithExtraInfoStyle && styles.tableTitleWithExtraInfoRow,
            !title && styles.tableLastRowColumn,
            titleWithExtraInfoStyle && styles.tableTitleWithExtraInfoColumn)
        }
        onClick={() => updateActiveSubscription(SUBCRIPTION_TYPE[index])}
        // eslint-disable-next-line react/no-array-index-key
        key={index}
      >
        {renderContentItem(item, index)}
      </div>
    )
  })

  return (
    <div className={styles.tableRowContainer}>
      <div className={styles.tableRowTitle}>
        {iconName && <img alt={iconName} className={styles.iconFrame} src={ICONS.find(item => item.name === iconName).icon || null} />}
        <div className={styles.tableTitleFrame}>
          <span className={styles.tableRowTitleText}>{title}</span>
          {subTitle && (
            <Link
              to={subTitleLink}
              target="_blank"
              className={styles.tableRowSubTitleText}
            >
              {subTitle}
            </Link>
          )}
        </div>
      </div>

      <div className={styles.tableRowContent}>
        {contentData}
      </div>
    </div>
  )
}

SubscriptionContentSection.propTypes = {
  title: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  currentSubscription: PropTypes.string.isRequired,
  activeSubscription: PropTypes.string.isRequired,
  updateActiveSubscription: PropTypes.func.isRequired,
  titleWithExtraInfoStyle: PropTypes.bool,
  subTitle: PropTypes.string,
  subTitleLink: PropTypes.string
}

SubscriptionContentSection.defaultProps = {
  title: '',
  iconName: '',
  data: [],
  activeSubscription: '',
  updateActiveSubscription: null,
  currentSubscription: '',
  titleWithExtraInfoStyle: false,
  subTitle: '',
  subTitleLink: ''
}

export default SubscriptionContentSection
