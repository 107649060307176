import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { propType } from 'graphql-anywhere'

import CALLOUT_FRAGMENT from '../../../graphql/fragments/callout.gql'
import CALL_TO_ACTION_FRAGMENT from '../../../graphql/fragments/call-to-action.gql'

import { TARGET_TYPES } from '../../constants'

import styles from './callout.css'

function CalloutCTA({ cta, onCtaClickLocation }) {
  if (cta.type === TARGET_TYPES.EXTERNAL_URL) {
    return (
      <a
        className={styles.cta}
        href={cta.target}
      >
        {cta.text}
      </a>
    )
  }
  return (
    <NavLink
      exact
      to={onCtaClickLocation}
      className={styles.cta}
    >
      {cta.text}
    </NavLink>
  )
}

CalloutCTA.propTypes = {
  cta: propType(CALL_TO_ACTION_FRAGMENT).isRequired,
  onCtaClickLocation: PropTypes.string.isRequired
}

function CalloutComponent({ callout, ...props }) {
  return (
    <div className={styles.wrapper}>
      <h3 className={styles.header}>
        {callout.header}
      </h3>
      <p className={styles.description}>
        {callout.description}
      </p>
      <CalloutCTA
        {...props}
        className
        cta={callout.cta}
      />
    </div>
  )
}

CalloutComponent.propTypes = {
  callout: propType(CALLOUT_FRAGMENT).isRequired
}

export default CalloutComponent
