export const PLAY_BUTTON_IN_SERIES_HERO = 'PLAY_BUTTON_IN_SERIES_HERO'
export const PLAY_BUTTON_IN_TITLE_HERO = 'PLAY_BUTTON_IN_TITLE_HERO'
export const PLAY_BUTTON_IN_PLAYER_EPISODE_SELECTOR = 'PLAY_BUTTON_IN_PLAYER_EPISODE_SELECTOR'
export const PLAY_BUTTON_IN_SERIES_EPISODE_SELECTOR = 'PLAY_BUTTON_IN_SERIES_EPISODE_SELECTOR'

export const PLAY_BUTTON_WATCH_TEXT = 'WATCH'
export const PLAY_BUTTON_WATCH_NOW_TEXT = 'WATCH NOW'
export const PLAY_BUTTON_RESUME_TEXT = 'RESUME'
export const PLAY_BUTTON_FINISHED_TEXT = 'REPLAY'
export const PLAY_BUTTON_FINISHED_TITLE_TEXT = 'WATCH AGAIN'
export const PLAY_BUTTON_RENT_TEXT = 'RENT'
export const PLAY_BUTTON_TRAILER_TEXT = 'WATCH TRAILER'
export const PLAY_BUTTON_COMING_SOON_TEXT = 'COMING SOON'

export const PLAY_BUTTON_KIDS_PLAY_ICON = 'kidsPlay'
export const PLAY_BUTTON_PLAY_ICON = 'play'
export const PLAY_BUTTON_REPLAY_ICON = 'replay'
