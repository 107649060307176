import React from 'react'

import Modal from '../../modal'
import PasswordInputFormContainer from '../../../containers/modals/enter-account-password/password-input-form'

import styles from './enter-account-password.css'

const EnterAccountPasswordModal = () => {
  return (
    <Modal
      isNotificationModal
    >
      <div className={styles.wrapper}>
        <h2
          className={styles.header}
          data-e2e="my-account-modal-password-text"
        >
          Enter your account password

        </h2>
        <div
          className={styles.message}
        >
          Please enter your account password to continue
        </div>
        <PasswordInputFormContainer />
      </div>
    </Modal>
  )
}

export default EnterAccountPasswordModal
