import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { graphql } from 'react-apollo'
import {
  branch,
  compose,
  renderNothing,
  setPropTypes,
  withHandlers,
  withProps
} from 'recompose'
import { withRouter } from 'react-router-dom'
import {
  defaultTo,
  pathOr
} from 'ramda'
import AddOrRemoveFromListButton from '../components/shared/add-or-remove-from-list-button'

import {
  MODALS,
  STATIC_SCREENS
} from '../constants'

import MY_LIST_QUERY from '../../graphql/queries/my-list.gql'
import SCREEN_QUERY from '../../graphql/queries/screen.gql'
import REMOVE_FROM_MY_LIST_MUTATION from '../../graphql/mutations/remove-from-my-list.gql'
import ADD_TO_MY_LIST_MUTATION from '../../graphql/mutations/add-to-my-list.gql'
import { getModalLocation } from '../lib/modal'
import { checkIsAuthenticated } from '../lib/auth'
import { isContentItemOnMyList } from '../lib/utils'
import {
  episodeHouseId,
  getShowTitleFromContentItem,
  myListGa,
  seasonHouseId,
  seriesTitleHouseId
} from '../lib/analytics/ga'
import {
  customDimensions,
  getCategoryNameFromContentItem
} from '../lib/analytics/custom-dimensions'
import { getSelectedProfileIdFromSession } from '../lib/account'
import {
  getAdditionalGenreFromContentItem,
  dataLayerAddToMyList
} from '../lib/analytics/datalayer'
import { segmentTrackAddMyList } from '../segment/segment-track'

const mapStateToProps = state => ({
  isAuthenticated: checkIsAuthenticated(state),
  theme: state.theme,
  profileId: getSelectedProfileIdFromSession(state.session)
})

const enhance = compose(
  setPropTypes({
    renderedInComponent: PropTypes.string.isRequired
  }),
  withRouter,
  connect(
    mapStateToProps
  ),
  graphql(MY_LIST_QUERY, {
    name: 'myList',
    skip: ownProps => !ownProps.isAuthenticated,
    options: {
      notifyOnNetworkStatusChange: true
    }
  }),
  branch(
    ({ myListQuery }) => myListQuery && (myListQuery.loading || myListQuery.error),
    renderNothing
  ),
  graphql(ADD_TO_MY_LIST_MUTATION),
  withHandlers({
    addToList: ({
      mutate,
      history,
      isAuthenticated,
      profileId,
      renderedInComponent
    }) => (contentItem) => {
      if (!isAuthenticated) {
        history.push(getModalLocation(location, MODALS.qsParams.login))
      } else {
        myListGa({
          action: 'Add',
          label: contentItem.title,
          [customDimensions.ShowTitle]: getShowTitleFromContentItem(contentItem),
          [customDimensions.CategoryName]: getCategoryNameFromContentItem(contentItem),
          [customDimensions.ProfileId]: profileId,
          [customDimensions.AdditionalGenre]: getAdditionalGenreFromContentItem(contentItem),
          [customDimensions.SeriesTitleHouseId]: seriesTitleHouseId(contentItem),
          [customDimensions.SeasonHouseId]: seasonHouseId(contentItem),
          [customDimensions.EpisodeHouseId]: episodeHouseId(contentItem),
          [customDimensions.ComponentName]: renderedInComponent
        })
        dataLayerAddToMyList(contentItem)
        // Add segment data analytics for adding content to my list
        segmentTrackAddMyList(contentItem)
        mutate({
          variables: {
            contentItemId: contentItem.id
          },
          refetchQueries: [{
            query: MY_LIST_QUERY,
            options: {
              notifyOnNetworkStatusChange: true
            }
          }]
        })
      }
    }
  }),
  graphql(REMOVE_FROM_MY_LIST_MUTATION),
  withHandlers({
    removeFromList: ({
      mutate,
      profileId,
      renderedInComponent
    }) => (contentItem) => {
      myListGa({
        action: 'Remove',
        label: contentItem.title,
        [customDimensions.ShowTitle]: contentItem.title,
        [customDimensions.CategoryName]: getCategoryNameFromContentItem(contentItem),
        [customDimensions.ProfileId]: profileId,
        [customDimensions.AdditionalGenre]: getAdditionalGenreFromContentItem(contentItem),
        [customDimensions.SeriesTitleHouseId]: seriesTitleHouseId(contentItem),
        [customDimensions.SeasonHouseId]: seasonHouseId(contentItem),
        [customDimensions.EpisodeHouseId]: episodeHouseId(contentItem),
        [customDimensions.ComponentName]: renderedInComponent
      })
      return mutate({
        variables: {
          contentItemId: contentItem.id
        },
        refetchQueries: [{
          query: MY_LIST_QUERY,
          options: {
            notifyOnNetworkStatusChange: true
          }
        }, {
          query: SCREEN_QUERY,
          variables: {
            screenId: STATIC_SCREENS.MY_LIST.screenId
          },
          options: {
            notifyOnNetworkStatusChange: true
          }
        }]
      })
    }
  }),
  withProps(({ contentItem, myList }) => {
    const contentIsOnList = isContentItemOnMyList(contentItem.id, pathOr([], ['mylist', 'items'], defaultTo({}, myList)))

    return {
      contentIsOnList,
      isRental: contentItem.isRental
    }
  })
)

export default enhance(AddOrRemoveFromListButton)
