import React from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'
import {
  compose
} from 'recompose'

import { withRemoveFromMyList } from '../../../hoc/with-remove-from-my-list'

import styles from '../remove-from-list-overlay.css'
import removeIcon from '../../../../images/my-list-ribbon-remove.svg'

function MyListRibbonKids({ removeFromMyList }) {
  return (
    <div
      onClick={removeFromMyList}
      className={styles.removeIconForKidsList}
    >
      <ReactSVG
        src={removeIcon}
        alt="Remove from My List"
      />
    </div>
  )
}

MyListRibbonKids.propTypes = {
  removeFromMyList: PropTypes.func.isRequired
}

const enhance = compose(
  withRemoveFromMyList
)

export default enhance(MyListRibbonKids)
