import { useState, useEffect } from 'react'
import qs from 'query-string'
import { useLocation } from 'react-router-dom'
import { useLazyQuery } from '@apollo/react-hooks'
import PAGED_SEARCH_QUERY from './gql/paged-search.gql'

const PAGE_SIZE = 20

/**
 * Hook to handle rentals search
 */
export const useRentalSearch = () => {
  const [
    fetchSearchResult,
    { loading, data, error }
  ] = useLazyQuery(PAGED_SEARCH_QUERY)

  const [rentalsTiles, setRentalsTiles] = useState([])
  const [rentalsCount, setRentalsCount] = useState()
  const [clickCounter, setClickCounter] = useState(0)

  // Indicator that use for disable loading when fetching more to prevent screen refresh
  const [isFetchMore, setIsFetchMore] = useState(false)

  const location = useLocation()
  const { search } = qs.parse(location.search)

  /**
   * Trigger query by search url
   */
  useEffect(() => {
    setClickCounter(0)
    setRentalsTiles([])
    fetchRentalsResult(search, 0, PAGE_SIZE)
  }, [search])

  /**
   * Set result and total number of corresponding type
   */
  useEffect(() => {
    if (!loading && !error) {
      if (data) {
        const { tiles } = data.pagedSearch
        const mappedTiles = mapTiles(tiles)
        setRentalsTiles([...rentalsTiles, ...mappedTiles])

        const typeCounter = data.pagedSearch.facets.type.rentals
        setRentalsCount(typeCounter)
      }
    }
  }, [loading, data, error])

  const fetchRentalsResult = (query, after, pageSize) => {
    fetchSearchResult({
      variables: {
        pagedSearchInput: {
          query,
          after,
          pageSize,
          typeFilters: ['rentals']
        }
      }
    })
  }

  /**
   * Counting click to trigger search query
   */
  const fetchMoreRentals = () => {
    setClickCounter(clickCounter + 1)

    const after = clickCounter * PAGE_SIZE
    // If no more result, will stop trigger query
    if (rentalsCount && after > rentalsCount) return

    fetchRentalsResult(search, after, PAGE_SIZE)

    setIsFetchMore(true)
  }

  /**
   * For handling legacy component, need to change property name item to contentItem
   */
  const mapTiles = tiles => {
    return (
      tiles &&
      tiles.map(resultTile => {
        const { item, ...others } = resultTile
        return { contentItem: item, ...others }
      })
    )
  }

  return {
    rentalsTiles,
    isLoadingRentals: isFetchMore ? !isFetchMore : loading,
    fetchMoreRentals,
    rentalsCount
  }
}
