import { compose } from 'recompose'
import { connect } from 'react-redux'

import LoadingOverlay from '../layouts/shared/loading-overlay'

const enhance = compose(
  connect(
    (state) => {
      return {
        theme: state.theme,
        loaders: state.loading
      }
    }
  )
)

export default enhance(LoadingOverlay)
