import { ApolloClient } from 'apollo-client'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { ApolloLink } from 'apollo-link'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { RetryLink } from 'apollo-link-retry'
import {
  any, either, path, pathEq, pathOr, propEq
} from 'ramda'

import { getTokenFromState } from '../auth'
import { handleMaintenanceMode } from '../error-handlers/maintenance'
import { handleGeoBlocking } from '../error-handlers/geo-blocking'
import { handleExpiredSession } from '../error-handlers/session'
import { handleInternalServerError } from '../error-handlers/internal-server-error'
import { handleUnauthorizedError } from '../error-handlers/unauthorized'

import { FORM_MESSAGES, MW_ERRORS } from '../../constants'
import apolloFragmentMatcher from './fragment-matcher.json'
import authMiddleware from '../../auth/middleware'

import { store } from '../../store'
import dataIdFromObject from './data-id-from-object'
import { displayErrorDialog } from '../../actions'
import { getGraphQLURI } from '../get-api-url'

const GQL_RETRY_MAX = parseInt(process.env.APP_GRAPHQL_RETRY_MAX, 10) || 5
const GQL_RETRY_INIT_DELAY =
  parseInt(process.env.APP_GRAPHQL_RETRY_INIT_DELAY, 10) || 250
const GQL_RETRY_MAX_DELAY =
  parseInt(process.env.APP_GRAPHQL_RETRY_MAX_DELAY, 10) || 2000
const GQL_RETRY_JITTER =
  process.env.APP_GRAPHQL_RETRY_JITTER === 'true' || false

const gqlErrorHandlers = [
  handleMaintenanceMode,
  handleGeoBlocking,
  handleExpiredSession,
  handleInternalServerError
]

const httpLink = createHttpLink({
  uri: getGraphQLURI()
})

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: apolloFragmentMatcher
  }
})

const cache = new InMemoryCache({
  fragmentMatcher,
  dataIdFromObject
})

const middlewareLink = setContext((_, previousContext) => {
  const token = previousContext.jwt || getTokenFromState(store.getState())
  if (token) {
    return {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  }
  return {}
})

const isUnauthorizedError = (graphQLErrors, networkError) => {
  const isUnauthed = either(
    pathEq(['statusCode'], 401),
    pathEq(['name'], 'InvalidTokenError')
  )(networkError)

  if (isUnauthed) {
    return true
  }

  if (!graphQLErrors) {
    return false
  }

  return any(propEq('code', MW_ERRORS.NOT_AUTHENTICATED))(graphQLErrors)
}

// onError({operation: xxx, networkError: xxx, graphQLErrors: ...})
//
const errorLink = onError(error => {
  const { graphQLErrors, networkError, operation } = error
  const { skipErrorLinkHandling } = operation.getContext()

  if (skipErrorLinkHandling) {
    return
  } if (isUnauthorizedError(graphQLErrors, networkError)) {
    handleUnauthorizedError(graphQLErrors, store)
    return
  }

  if (graphQLErrors) {
    gqlErrorHandlers.find(fn => fn(graphQLErrors, store))
  }

  // Remaining network errors are handled via retry link
})

const UNAUTHORIZED_ERROR_RETRY_OPERATIONS = ['ConfigQuery']

const retryLink = new RetryLink({
  delay: {
    initial: GQL_RETRY_INIT_DELAY,
    max: GQL_RETRY_MAX_DELAY,
    jitter: GQL_RETRY_JITTER
  },
  attempts: (count, operation, error) => {
    const { skipRetryLinkHandling } = operation.getContext()
    if (skipRetryLinkHandling) {
      return false
    }
    // Don't retry 401 errors
    if (isUnauthorizedError(null, error)) {
      // if the jwt was invalid, we need to remove it
      operation.setContext({ headers: {} })

      if (operation.getContext().jwt) {
        // if the jwt from the browser url was invalid, we need to remove it
        // from the operation header that provided the jwt context.
        operation.setContext({ jwt: undefined })
      }

      if (
        UNAUTHORIZED_ERROR_RETRY_OPERATIONS.includes(operation.operationName)
      ) {
        // retry the operations that we want after resetting auth headers
        return true
      }

      return false
    }
    if (count === GQL_RETRY_MAX) {
      store.dispatch(
        displayErrorDialog({ message: FORM_MESSAGES.networkError })
      )
      return false
    }
    console.warn('operation failed, retrying', operation, error)
    return true
  }
})

const apolloLink = ApolloLink.from([
  retryLink,
  middlewareLink,
  errorLink,
  httpLink
])

// Create Apollo Client
const apolloClient = new ApolloClient({
  link: apolloLink,
  cache
})

authMiddleware.apolloClient = apolloClient

export default apolloClient

export const getGQLErrorMsg = apolloError => {
  if (apolloError.networkError) {
    return FORM_MESSAGES.networkError
  }
  return pathOr(FORM_MESSAGES.networkError, ['graphQLErrors', '0', 'message'])(
    apolloError
  )
}

export const getGQLErrorCode = path(['graphQLErrors', '0', 'code'])

export const getGQLErrorDetails = path(['graphQLErrors', '0', 'details'])

export const getGQLError = path(['graphQLErrors', '0'])
