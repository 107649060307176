import ActionTypes from '../actions/types'

const initialState = {
  confirm: false,
  show: false
}

const planUpgradePopup = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.PLAN_UPGRADE_POPUP_CONFIRM:
      return {
        confirm: true,
        show: false
      }
    case ActionTypes.PLAN_UPGRADE_POPUP_SHOW:
      return {
        ...state,
        show: true
      }
    case ActionTypes.PLAN_UPGRADE_POPUP_DISMISS:
      return {
        ...state,
        show: false
      }
    default:
      return state
  }
}

export default planUpgradePopup
