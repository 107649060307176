import React from 'react'
import PropTypes from 'prop-types'
import { useStyles } from './subscription-discount-section.styles'

const SubscriptionDiscountSection = props => {
  const styles = useStyles()
  const {
    label, endDate, period, termsAndConditionsLink
  } = props.priceDetail

  return (
    <>
      {
        label &&
        (
          <div className={styles.discountLabel}>
            <p className={styles.discountLabelText}>
              {label}
            </p>
          </div>
        )
      }

      {endDate && <span className={styles.discountEndDate}>{endDate}</span>}

      {
        period &&
        (
          <div className={styles.discountPeriod}>
            {`${period} `}
            <a
              className={styles.discountTermsAndConditions}
              href={termsAndConditionsLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              T&C&apos;s apply.
            </a>
          </div>
        )
      }
    </>
  )
}

SubscriptionDiscountSection.propTypes = {
  priceDetail: PropTypes.shape({
    label: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    period: PropTypes.string.isRequired,
    termsAndConditionsLink: PropTypes.string.isRequired
  }).isRequired
}

SubscriptionDiscountSection.defaultProps = {
  priceDetail: {
    label: '',
    endDate: '',
    period: '',
    termsAndConditionsLink: ''
  }
}

export default SubscriptionDiscountSection
