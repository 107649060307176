import React from 'react'
import PT from 'prop-types'
import classnames from 'classnames'
import styles from './arrow.css'

function Arrow({
  color, height, width, left, className
}) {
  return (
    <div className={className}>
      <svg
        className={classnames({ [styles.left]: left })}
        fill={color}
        height={height}
        width={width}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z" />
      </svg>
    </div>
  )
}

Arrow.propTypes = {
  color: PT.string,
  height: PT.number,
  width: PT.number,
  left: PT.bool,
  className: PT.string
}

Arrow.defaultProps = {
  height: 24,
  width: 24,
  color: '#000',
  left: false,
  className: ''
}

export default Arrow
