import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

/**
 * A hooks to detect if url contains search params
 * @returns {bool} hasSearchParams
 * @returns {object} location
 */

const useSearchParams = () => {
  const location = useLocation()

  const hasSearchParams = useMemo(() => {
    const searchParams = queryString.parse(location.search).search
    return Boolean(searchParams)
  }, [location.search])

  return { hasSearchParams, location }
}

export default useSearchParams
