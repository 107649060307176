import ActionTypes from '../actions/types'

const voucherValidateType = (state = '', action) => {
  switch (action.type) {
    case ActionTypes.SET_VOUCHER_VALIDATE_TYPE:
      return action.voucherValidateType
    default:
      return state
  }
}

export default voucherValidateType
