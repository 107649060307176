import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import classNames from 'classnames'

import MyListButton from '../../my-list-button'
import styles from './movie-4x3.css'

import TILE_FRAGMENT from '../../../../../graphql/fragments/tile.gql'
import { COMPONENT_NAME_CAROUSEL } from '../../../../lib/analytics/custom-dimensions'

function Movie4x3({
  tile,
  isOnMyList,
  isAuthenticated,
  addToMyList,
  removeFromMyList
}) {
  const contentItem = tile.contentItem
  const isRental = contentItem.isRental

  return (
    <div className={classNames(styles.wrapper, {
      [styles.wrapperNeonRental]: isRental
    })}
    >
      {isAuthenticated && (
        <MyListButton
          contentItem={contentItem}
          className={styles.addMyListButton}
          isOnMyList={isOnMyList}
          addAction={addToMyList(COMPONENT_NAME_CAROUSEL)}
          removeAction={removeFromMyList(COMPONENT_NAME_CAROUSEL)}
          isRental={isRental}
        />
      )}
    </div>
  )
}

Movie4x3.propTypes = {
  tile: propType(TILE_FRAGMENT).isRequired,
  isOnMyList: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  addToMyList: PropTypes.func.isRequired,
  removeFromMyList: PropTypes.func.isRequired
}

Movie4x3.defaultProps = {
  isOnMyList: false,
  isAuthenticated: false
}

export default Movie4x3
