import { graphql } from 'react-apollo'
import {
  compose,
  shouldUpdate
} from 'recompose'
import { connect } from 'react-redux'

import Routes from '../routes'
import NAVIGATION_QUERY from '../../graphql/queries/navigation.gql'
import MaintenanceContainer from './maintenance-mode'
import { omitProps } from '../hoc'

const enhance = compose(
  connect((state) => {
    return {
      maintenance: state.maintenance,
      playerIsInView: state.app.playerIsInView
    }
  }),
  shouldUpdate((ownProps, nextProps) => {
    // Does not re-render:
    if (
      // If the player is in view and Maintenance Mode is on or being turned on
      (
        (ownProps.playerIsInView === false) &&
        (ownProps.playerIsInView !== nextProps.playerIsInView) &&
        (ownProps.maintenance.mode || nextProps.maintenance.mode)
      )
    ) {
      return false
    }
    return true
  }),
  MaintenanceContainer,
  // To avoid any potential conflicts re-rendering or overwriting
  // props, we're cutting them out after we're done with them here,
  // so they don't get passed to any subsequent view components.
  omitProps(['maintenance', 'playerIsInView']),
  // We request both the nav header and footer before rendering the app routes.
  graphql(NAVIGATION_QUERY, {
    name: 'navigationQuery'
  })
)

export default enhance(Routes)
