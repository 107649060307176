import React from 'react'
import PropTypes from 'prop-types'

import styles from './no-results.css'

function SearchNoResults({
  query
}) {
  return (
    <div className={styles.wrapper}>
      <div>
        {`Sorry, we couldn't find anything for “${query}”`}
      </div>
      {/* Temporarily commented out as don't have empty results yet */}
      {/* <div className={styles.subText}> */}
      {/* Check out some other great shows */}
      {/* </div> */}
    </div>
  )
}

SearchNoResults.propTypes = {
  query: PropTypes.string.isRequired
}

export default SearchNoResults
