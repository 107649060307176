import React from 'react'
import PropTypes from 'prop-types'
import ReactImgix from 'react-imgix'

import { IMGIX_CROP_OPTIONS } from '../../../constants'

import styles from './hotspot-halloween.css'

function HotspotHalloween({
  pageContent
}) {
  return (
    <div className={styles.hotspotWrapper}>
      <div className={styles.imageWrapper}>
        <ReactImgix
          className={styles.image}
          src={pageContent.image}
          type="img"
          imgixParams={{ crop: IMGIX_CROP_OPTIONS.HOTSPOT, fit: 'min' }}
        />
      </div>
      <div className={styles.ctaWrapper}>
        <div className={styles.cta}>
          <h1 className={styles.title}>{pageContent.subject}</h1>
          <h3 className={styles.description}>{pageContent.description}</h3>
          <a
            className={styles.link}
            href={pageContent.terms.link}
          >
            {pageContent.terms.text}
          </a>
        </div>
      </div>
    </div>
  )
}

HotspotHalloween.propTypes = {
  pageContent: PropTypes.shape({
    image: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    terms: PropTypes.shape({
      link: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  }).isRequired
}

HotspotHalloween.defaultProps = {
  pageContent: {
    image: 'https://lightbox-prod.imgix.net/images/static/halloween-hotspot-campaign.jpg',
    subject: 'Halloween Watch & Win',
    description: 'Rent a Movie between 22nd October and 4th November and be in to win a smart TV and sound system.',
    terms: {
      link: '/halloween-terms',
      text: 'Terms and Conditions'
    }
  }
}

export default HotspotHalloween
