import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import throttle from 'lodash.throttle'

import Arrow from './navigation-arrow'
import styles from './account-nav-button.css'
import { E2E_SELECTORS } from '../../e2e-selectors'

function AccountNavButton({
  isAuthenticated,
  userName,
  onClick,
  avatar,
  menuActive,
  onRegisterAccountNavigationLink
}) {
  if (!isAuthenticated) {
    return null
  }

  return (
    <div
      className={classnames(styles.accountNavButton, {
        [styles.active]: menuActive
      })}
      id="navigation-account-nav-button"
      onClick={throttle((evt) => {
        evt.stopPropagation()
        onClick(menuActive)
      }, 250)}
      ref={onRegisterAccountNavigationLink}
    >
      <img alt="Avatar" src={avatar} className={styles.avatar} />
      <span
        className={styles.userName}
        data-e2e={E2E_SELECTORS.ADULT_AVATAR_MENU}
      >
        {userName}
      </span>
      <Arrow className={styles.navArrow} isExpanded={menuActive} />
      <div className={styles.menuBackground} data-lbx-e2e={userName} />
    </div>
  )
}

AccountNavButton.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  userName: PropTypes.string,
  avatar: PropTypes.string,
  menuActive: PropTypes.bool.isRequired,
  onRegisterAccountNavigationLink: PropTypes.func.isRequired
}

AccountNavButton.defaultProps = {
  className: '',
  userName: '',
  avatar: ''
}

export default AccountNavButton
