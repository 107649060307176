import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { propType } from 'graphql-anywhere'
import ClickOutside from 'react-click-outside'
import { pathOr, gt, pipe } from 'ramda'

import NavigationDropdown from './navigation-dropdown'
import NavigationItemButton from './navigation-itembutton'
import NAV_ITEM_FRAGMENT from '../../../graphql/fragments/nav-item.gql'

import styles from './navigation-item.css'

const hasChildren = pipe(
  pathOr(0, ['children', 'length']),
  length => gt(length, 0)
)

function NavigationItem({
  navItem,
  className,
  activeClassName,
  pathname,
  location,
  inSideNav,
  onClick,
  onClickOutside,
  onToggleMenu,
  isExpanded,
  setIsExpanded,
  setParentIsExpanded,
  wrapperClassName,
  inDropdown,
  propertiesCount,
  expandOnHover,
  expandOnArrow
}) {
  return (
    <ClickOutside
      className={
        classNames({
          [styles.navItemWrapperOpen]: isExpanded,
          [styles.navItemWrapper]: !isExpanded,
          [wrapperClassName]: wrapperClassName,
          [styles.isRental]: navItem.isRental
        })
      }
      onClickOutside={!inSideNav ? onClickOutside : () => {}}
      onMouseLeave={() => (expandOnHover ? setIsExpanded(false) : undefined)}
    >
      <NavigationItemButton
        activeClassName={activeClassName}
        location={location}
        navItem={navItem}
        onToggleMenu={onToggleMenu}
        isExpanded={isExpanded}
        pathname={pathname}
        inDropdown={inDropdown}
        className={
          classNames({
            [className]: className,
            [styles.lowercase]: inDropdown && !hasChildren(navItem)
          })
        }
        onClick={onClick}
        setIsExpanded={setIsExpanded}
        expandOnHover={expandOnHover}
        expandOnArrow={expandOnArrow}
      />
      {
        hasChildren(navItem) && isExpanded
          ? (
            <NavigationDropdown
              navItems={navItem.children}
              inSideNav={inSideNav}
              setParentIsExpanded={setParentIsExpanded}
              setIsExpanded={setIsExpanded}
              isDiscoverNav={navItem.isDiscoverNav}
              propertiesCount={propertiesCount}
            />
          )
          : null
      }
    </ClickOutside>
  )
}

NavigationItem.propTypes = {
  navItem: propType(NAV_ITEM_FRAGMENT).isRequired,
  pathname: PropTypes.string,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  inSideNav: PropTypes.bool,
  isExpanded: PropTypes.bool.isRequired,
  onToggleMenu: PropTypes.func.isRequired,
  onClickOutside: PropTypes.func.isRequired,
  setIsExpanded: PropTypes.func.isRequired,
  setParentIsExpanded: PropTypes.func,
  wrapperClassName: PropTypes.string,
  inDropdown: PropTypes.bool,
  propertiesCount: PropTypes.number,
  expandOnArrow: PropTypes.bool,
  expandOnHover: PropTypes.bool,
  isInsideNavigationBar: PropTypes.bool // eslint-disable-line react/no-unused-prop-types
}

NavigationItem.defaultProps = {
  navItem: {},
  className: null,
  pathname: '',
  activeClassName: null,
  inSideNav: false,
  setParentIsExpanded: null,
  wrapperClassName: '',
  inDropdown: false,
  propertiesCount: 0,
  expandOnArrow: false,
  expandOnHover: false,
  isInsideNavigationBar: false
}

export default NavigationItem
