import { graphql } from 'react-apollo'
import { compose, withHandlers, mapProps } from 'recompose'
import { reject, pathOr } from 'ramda'
import withMutateAndLoading from './with-mutate-and-loading'

import ACCOUNT_CREDIT_CARDS_QUERY from '../../graphql/queries/account-credit-cards.gql'
import DELETE_CREDITCARD_MUTATION from '../../graphql/mutations/delete-creditcard.gql'

const withDeleteCreditCardMutation = compose(
  graphql(DELETE_CREDITCARD_MUTATION),
  withMutateAndLoading,
  withHandlers({
    deleteCreditCard: ({ mutateAndLoading }) => (id) => {
      return mutateAndLoading('creditCardDelete', {
        variables: { id },
        update: (proxy, result) => {
          const data = proxy.readQuery({
            query: ACCOUNT_CREDIT_CARDS_QUERY
          })

          const currentCreditcards = pathOr([], ['account', 'creditcards'], data)
          const creditCardDeletedSuccessfully = pathOr(false, ['data', 'deleteCreditcard'], result)

          if (creditCardDeletedSuccessfully) {
            const creditcards = reject(
              card => card.id === id,
              currentCreditcards
            )
            proxy.writeQuery({
              query: ACCOUNT_CREDIT_CARDS_QUERY,
              data: {
                account: {
                  ...data.account,
                  creditcards: [...creditcards]
                }
              }
            })
          }
        }
      })
    }
  }),
  mapProps(({ mutate, mutateAndLoading, ...rest }) => rest)
)

export default withDeleteCreditCardMutation
