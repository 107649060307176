import {
  compose,
  withState,
  withHandlers,
  setDisplayName
} from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withApollo } from 'react-apollo'

import { toggleAccountNavVisibility } from '../../actions'
import NavigationRight from '../../layouts/shared-navigation/navigation-right'
import { checkIsAuthenticated } from '../../lib/auth'

const mapStateToProps = (state) => {
  return {
    searchIsClicked: state.navigation.searchIsClicked,
    isAuthenticated: checkIsAuthenticated(state)
  }
}

const mapDispatchToProps = (dispatch, { navigationLinkRef }) => ({
  onAccountNavClick: (isCurrentOpen) => {
    dispatch(toggleAccountNavVisibility(!isCurrentOpen, navigationLinkRef.clientWidth))
  }
})

const enhance = compose(
  setDisplayName('NavigationRightContainer'),
  withRouter,
  withApollo,
  withState('navigationLinkRef', 'setNavigationLinkRef', null),
  withHandlers({
    onRegisterAccountNavigationLink: ({ setNavigationLinkRef }) => (ref) => {
      setNavigationLinkRef(ref)
    }
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default enhance(NavigationRight)
