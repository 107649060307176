import { curry, of } from 'ramda'

import mergePath from './mergePath'

/*
 * Stolen from https://char0n.github.io/ramda-adjunct/2.1.0/mergeProp.js.html
 *
 *
 * Creates a new object with the ownProperties of the object under the `prop`
 * merged with the own properties of the provided `source`.
 * If a key exists in both objects, the value from the `source` object will be used.
 *
 * mergeProp(
 *   'outer',
 *   { foo: 3, bar: 4 },
 *   { outer: { foo: 2 } }
 * ); // => { outer: { foo: 3, bar: 4 } }
 */
const mergeProp = curry((p, subj, obj) => mergePath(of(p), subj, obj))

export default mergeProp
