/* eslint-disable no-nested-ternary */
import React from 'react'
import PT from 'prop-types'
import { compose } from 'ramda'
import { defaultProps, withContext, withStateHandlers } from 'recompose'
import classnames from 'classnames'
import styles from './slip-n-slide.css'

import { LEFT_SLIDE, RIGHT_SLIDE, STILL } from './constants'
import Slide from './slide'
import Swipe from './swipe'
import Grid from './grid'
import Button from './button'
import withSlideHandlers from './with-slide-handlers'

function SlipNSlide({
  margin,
  swipeMode,
  gridMode,
  slidesToShow,
  duration,
  arrowClass,
  LeftButton,
  RightButton,
  children,
  sliderStatus,
  slideLeft,
  slideRight,
  resetSlide,
  offset,
  isHideLeft,
  isHideRight,
  showLeft,
  showRight,
  hideLeft,
  hideRight,
  onClickRight,
  doNotLoopSlides
}) {
  // to avoid some times lag animation caused jump
  const delayedReset = () => setTimeout(resetSlide, 100)
  const isSwipeMode = swipeMode || slidesToShow >= children.length

  const composedSlideLeft = compose(
    slideLeft,
    showLeft,
    showRight
  )
  const composeSlideRight = compose(
    slideRight,
    showLeft
  )

  const rightButtonClickHandler = () => {
    composeSlideRight()
    onClickRight && onClickRight()
  }

  const hideSlideRightButton = compose(
    hideRight
  )

  const hideSlideLeftButton = compose(
    hideLeft
  )

  return (
    <div className={styles.wrapper}>
      {isSwipeMode ? (
        <Swipe margin={margin}>{children}</Swipe>
      ) : gridMode ? (
        <Grid margin={margin}>{children}</Grid>
      ) : (
        <Slide
          onTransitionFinish={delayedReset}
          sliderStatus={sliderStatus}
          margin={margin}
          onSwipe={direction => {
            if (doNotLoopSlides ? direction === 'left' && !isHideRight : direction === 'left') {
              composeSlideRight()
              onClickRight()
            }
            if (doNotLoopSlides ? direction === 'right' && !isHideLeft : direction === 'right') {
              composedSlideLeft()
            }
          }}
          doNotLoopSlides={doNotLoopSlides}
          hideSlideRightButton={hideSlideRightButton}
          hideSlideLeftButton={hideSlideLeftButton}
        >
          {children}
        </Slide>
      )}
      {!isSwipeMode && !gridMode && (
        <>
          {!isHideLeft && (
            <LeftButton
              width={margin}
              onClick={composedSlideLeft}
              className={classnames(styles.leftControl, arrowClass)}
            >
              left
            </LeftButton>
          )}
          {!isHideRight && (
            <RightButton
              width={margin}
              onClick={rightButtonClickHandler}
              className={classnames(styles.rightControl, arrowClass)}
            >
              right
            </RightButton>
          )}
        </>
      )}
    </div>
  )
}

SlipNSlide.propTypes = {
  slidesToShow: PT.number.isRequired,
  duration: PT.number.isRequired,
  margin: PT.number,
  swipeMode: PT.bool,
  gridMode: PT.bool,
  arrowClass: PT.string,
  LeftButton: PT.oneOfType([PT.element, PT.func]),
  RightButton: PT.oneOfType([PT.element, PT.func]),
  onClickRight: PT.func,
  // withSlideHandlers
  sliderStatus: PT.oneOf([STILL, LEFT_SLIDE, RIGHT_SLIDE]).isRequired,
  slideLeft: PT.func.isRequired,
  slideRight: PT.func.isRequired,
  resetSlide: PT.func.isRequired,
  offset: PT.number.isRequired,
  // withHandleLeft
  isHideLeft: PT.bool.isRequired,
  isHideRight: PT.bool.isRequired,
  showLeft: PT.func.isRequired,
  showRight: PT.func.isRequired,
  hideLeft: PT.func.isRequired,
  hideRight: PT.func.isRequired,
  doNotLoopSlides: PT.bool
}

SlipNSlide.defaultProps = {
  margin: 50,
  RightButton: Button,
  LeftButton: props => <Button left {...props} />,
  swipeMode: false,
  gridMode: false,
  arrowClass: '',
  doNotLoopSlides: false
}

const withHandleLeft = withStateHandlers(
  { isHideLeft: true },
  {
    showLeft: () => () => ({ isHideLeft: false }),
    hideLeft: () => () => ({ isHideLeft: true })
  }
)

const withHandleRight = withStateHandlers(
  { isHideRight: false },
  {
    hideRight: () => () => ({ isHideRight: true }),
    showRight: () => () => ({ isHideRight: false })
  }
)

const enhance = compose(
  withHandleLeft,
  withHandleRight,
  withSlideHandlers,
  defaultProps({
    slidesToShow: 1,
    duration: 800
  }),
  withContext(
    {
      slidesToShow: PT.number.isRequired,
      duration: PT.number.isRequired,
      offset: PT.number.isRequired,
      isHideLeft: PT.bool.isRequired,
      isHideRight: PT.bool.isRequired
    },
    ({
      slidesToShow, duration, offset, isHideLeft, isHideRight
    }) => ({
      slidesToShow,
      duration,
      offset,
      isHideLeft,
      isHideRight
    })
  )
)

export default enhance(SlipNSlide)
