import React from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'
import classNames from 'classnames'

import neonLoadingImg from '../../../../../images/neon/loading-white.svg'
import styles from './searching.css'

function SearchNoResults({ theme }) {
  const isKids = theme === 'LIGHTBOX_KIDS'

  return (
    <div className={styles.wrapper}>
      <ReactSVG
        src={neonLoadingImg}
        evalScripts="never"
        beforeInjection={(svg) => {
          svg.setAttribute('class', classNames({
            [styles.searchingKids]: isKids
          }))
        }}
      />
    </div>
  )
}

SearchNoResults.propTypes = {
  theme: PropTypes.string.isRequired
}

export default SearchNoResults
