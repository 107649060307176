export function buildIndexSequence(elementCount) {
  return startingPoint => {
    if (startingPoint > elementCount) {
      startingPoint %= elementCount
    } else if (startingPoint < 0) {
      startingPoint = elementCount + (startingPoint % elementCount)
    }

    if (startingPoint === elementCount) {
      startingPoint = 0
    }

    return startingPoint
  }
}

export function buildIndexArray(totalCount, length) {
  // say we have [0,1,2,3,4,5]
  // totalCount = 6
  // length = 4
  return start => {
    const sequence = [start]
    if (start + length < totalCount) {
      // start = 1
      let counter = 1
      while (counter < length) {
        sequence.push(start + counter)
        counter += 1
      }
      // result is [1,2,3,4]
    } else {
      // start = 4
      let counter = 1
      while (sequence.length < totalCount - start) {
        sequence.push(start + counter)
        counter += 1
      }
      // [4,5]
      // reverse back
      counter = 0
      while (sequence.length < length) {
        sequence.push(counter)
        counter += 1
      }
      // [4,5,0,1]
    }
    return sequence
  }
}

export function throttle(func, delay) {
  let blocked = false
  let cancel = () => {}
  return (...params) => {
    if (blocked) {
      return cancel
    }
    blocked = true
    func(...params)
    const timer = setTimeout(() => {
      blocked = false
    }, delay)

    cancel = () => clearTimeout(timer)

    return cancel
  }
}
