import {
  compose,
  withProps,
  withHandlers
} from 'recompose'
import { withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'

import Category from '../../components/category'

import SCREEN_QUERY from '../../../graphql/queries/screen.gql'

const enhance = compose(
  withRouter,
  withApollo,
  withProps(({ location, category }) => {
    const index = category.items
      .findIndex(item => item.path === location.pathname)

    return {
      selectedCategoryIdx: index === -1 ? 0 : index,
      path: category.path
    }
  }),
  withHandlers({
    prefetchScreen: ({ client }) => (category) => {
      if (!category.path) return
      client.query({
        query: SCREEN_QUERY,
        variables: { screenId: category.path }
      })
    }
  })
)

export default enhance(Category)
