import { compose, renderComponent } from 'recompose'
import { withRouter } from 'react-router-dom'

import withIsAuthenticated from '../../hoc/is-authenticated'
import { withScreenWidthWatching } from '../../hoc/with-screen-width-watching'

import UserCarousel from '../../components/carousel/index'
import withIsWhiteTheme from '../../hoc/with-is-white-theme'

const enhance = compose(
  withScreenWidthWatching,
  withIsAuthenticated,
  withIsWhiteTheme,
  withRouter,
  renderComponent(UserCarousel)
)

export default enhance()
