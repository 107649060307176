import React from 'react'
import { propType } from 'graphql-anywhere'

import ReactImgix from 'react-imgix'

import PROMOTIONAL_TILES_FRAGMENT from '../../../../graphql/fragments/promotional-tile-component.gql'

import styles from './guest-promotional-tiles.css'

function PromotionalTiles({ promotionalTiles: { promotionalTiles } }) {
  return (
    <div className={styles.wrapper}>
      {
        promotionalTiles.map(({ description, header, image }) => (
          <div
            className={styles.tile}
            key={header}
          >
            <div className={styles.imageWrapper}>
              <ReactImgix
                className={styles.img}
                src={image.uri}
                imgixParams={{ fit: 'fillmax' }}
                width={110}
              />
            </div>
            <div className={styles.header}>
              {header}
            </div>
            <div className={styles.caption}>
              {description}
            </div>
          </div>
        ))
      }
    </div>
  )
}

PromotionalTiles.propTypes = {
  promotionalTiles: propType(PROMOTIONAL_TILES_FRAGMENT).isRequired
}

export default PromotionalTiles
