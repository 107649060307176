import {
  TRACK_SIGN_UP, TRACK_ADD_PROFILE,
  TRACK_EDIT_PROFILE, TRACK_SIGN_IN, TRACK_PLAY_VIDEO_CONTENT,
  TRACK_ADD_SUBSCRIPTION, TRACK_CHANGE_SUBSCRIPTION, TRACK_DELETE_PROFILE,
  TRACK_SIGN_OUT, TRACK_SEARCH_NO_RESULT, TRACK_SEARCH_RESULT,
  TRACK_MY_LIST, TRACK_SUBTITLE_CHANGED, TRACK_STOP_VIDEO_CONTENT,
  TRACK_CLICK_CONTENT_CARD, TRACK_DELETE_DEVICE, TRACK_DEVICE_LIMIT,
  TRACK_PURCHASE_INITIATE, TRACK_PURCHASE_COMPLETE,
  TRACK_ADD_VOUCHER, TRACK_PAUSE_VIDEO_CONTENT, TRACK_WINBACK_MODAL_CLICKED,
  TRACK_HOMEPAGE_LAND
} from './constants'
import { analytics } from './segment-setup'
import { getCurrentProfile } from './getCurrentProfile'
/**
 * Provides functions to enable segment data collection
 * operations on the segment workspace "Sky-As-An-App".
 */

export const segmentTrackAddProfile = (eventDetails) => {
  analytics.track(TRACK_ADD_PROFILE, { ...eventDetails, userProfile: getCurrentProfile() })
}

export const segmentTrackEditProfile = (eventDetails) => {
  analytics.track(TRACK_EDIT_PROFILE, { ...eventDetails, userProfile: getCurrentProfile() })
}

export const segmentTrackDeleteProfile = (eventDetails) => {
  analytics.track(TRACK_DELETE_PROFILE, { ...eventDetails, userProfile: getCurrentProfile() })
}

export const segmentTrackSignup = (eventDetails) => {
  analytics.track(TRACK_SIGN_UP, { ...eventDetails, userProfile: getCurrentProfile() })
}

export const segmentTrackSignin = (eventDetails) => {
  analytics.track(TRACK_SIGN_IN, { ...eventDetails, userProfile: getCurrentProfile() })
}

export const segmentTrackSignout = (eventDetails) => {
  analytics.track(TRACK_SIGN_OUT, { ...eventDetails, userProfile: getCurrentProfile() })
  analytics.reset()
}

export const segmentTrackPlayVideo = (contentItem, ads) => {
  const containsPreRolls = ads?.some(ad => ad.type_ === 'pre')
  const containsMidRolls = ads?.some(ad => ad.type_ === 'mid')

  const {
    id,
    title,
    ldId,
    __typename,
    rating,
    genres,
    episodeNumber,
    seasonNumber,
    series,
    name,
    isKids,
    isRental
  } = contentItem
  analytics.track(TRACK_PLAY_VIDEO_CONTENT, {
    title: title || name,
    ldId,
    type: __typename,
    rating: rating?.rating,
    contentId: id,
    genres: genres || series?.genres,
    episodeNumber,
    seasonNumber,
    seriesTitle: series?.title,
    seriesId: series?.id,
    isKids: series ? series.isKids : isKids,
    isRental,
    containsPreRolls,
    containsMidRolls,
    userProfile: getCurrentProfile()
  })
}

export const segmentTrackStopVideo = (eventDetails) => {
  analytics.track(TRACK_STOP_VIDEO_CONTENT, { ...eventDetails, userProfile: getCurrentProfile() })
}

export const segmentTrackPauseVideo = (eventDetails) => {
  analytics.track(TRACK_PAUSE_VIDEO_CONTENT, { ...eventDetails, userProfile: getCurrentProfile() })
}

export const segmentTrackSubtitleChanged = (eventDetails) => {
  analytics.track(TRACK_SUBTITLE_CHANGED, { ...eventDetails, userProfile: getCurrentProfile() })
}

export const segmentTrackAddSubscription = (eventDetails) => {
  analytics.track(TRACK_ADD_SUBSCRIPTION, { ...eventDetails, userProfile: getCurrentProfile() })
}

export const segmentTrackChangeSubscription = (eventDetails) => {
  analytics.track(TRACK_CHANGE_SUBSCRIPTION, { ...eventDetails, userProfile: getCurrentProfile() })
}

export const segmentTrackSearchResult = (eventDetails) => {
  analytics.track(TRACK_SEARCH_RESULT, { ...eventDetails, userProfile: getCurrentProfile() })
}

export const segmentTrackSearchNoResult = (eventDetails) => {
  analytics.track(TRACK_SEARCH_NO_RESULT, { ...eventDetails, userProfile: getCurrentProfile() })
}

export const segmentTrackAddMyList = (contentItem) => {
  const {
    title, id, ldId, __typename, genres
  } = contentItem
  analytics.track(TRACK_MY_LIST, {
    title,
    ldId,
    type: __typename,
    contentId: id,
    genres,
    userProfile: getCurrentProfile()
  })
}

export const segmentTrackClickContentCard = (contentItem, position, list, path) => {
  const {
    title, id, ldId, __typename, isRental
  } = contentItem
  analytics.track(TRACK_CLICK_CONTENT_CARD, {
    title,
    contentId: id,
    ldId,
    isRental,
    type: __typename,
    indexInRail: position,
    railTitle: list,
    path,
    userProfile: getCurrentProfile()
  })
}

export const segmentTrackDeleteDevice = (eventDetails) => {
  analytics.track(TRACK_DELETE_DEVICE, { ...eventDetails, userProfile: getCurrentProfile() })
}

export const segmentTrackDeviceLimit = (eventDetails) => {
  analytics.track(TRACK_DEVICE_LIMIT, { ...eventDetails, userProfile: getCurrentProfile() })
}

export const segmentTrackInitiatePurchase = (selectedPlan, detail) => {
  if (detail?.isRental) {
    const {
      title, isRental, ldId, products
    } = detail
    analytics.track(TRACK_PURCHASE_INITIATE, {
      title,
      isRental,
      ldId,
      payment: Number(products[0]?.currentPrice),
      userProfile: getCurrentProfile()
    })
  } else {
    analytics.track(TRACK_PURCHASE_INITIATE, {
      planTitle: selectedPlan?.title,
      planSku: selectedPlan?.sku,
      paymentDue: selectedPlan?.paymentDue,
      payment: Number(selectedPlan?.payment),
      voucher: selectedPlan?.voucher,
      isRental: false,
      userProfile: getCurrentProfile()
    })
  }
}

export const segmentTrackCompletePurchase = (eventDetails) => {
  analytics.track(TRACK_PURCHASE_COMPLETE, { ...eventDetails, isRental: eventDetails.isRental || false, userProfile: getCurrentProfile() })
}

export const segmentTrackAddVoucher = (eventDetails) => {
  analytics.track(TRACK_ADD_VOUCHER, { ...eventDetails, userProfile: getCurrentProfile() })
}

export const segmentTrackClickOnWelcomeBackModal = (eventDetails) => {
  analytics.track(TRACK_WINBACK_MODAL_CLICKED, { ...eventDetails, userProfile: getCurrentProfile() })
}

export const segmentTracKHomepageLand = (eventDetails) => {
  analytics.track(TRACK_HOMEPAGE_LAND, { ...eventDetails, userProfile: getCurrentProfile() })
}
