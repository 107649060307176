import qs from 'query-string'
import { withRouter } from 'react-router-dom'
import {
  compose,
  lifecycle,
  withHandlers,
  withState,
  onlyUpdateForKeys,
  branch,
  renderNothing,
  setDisplayName
} from 'recompose'
import { pathOr } from 'ramda'
import debounce from 'lodash.debounce'
import { connect } from 'react-redux'

import SearchNavButton from '../../layouts/shared-navigation/search-nav-button'

import { setSearchIsClicked } from '../../actions'
import { SEARCH } from '../../constants'
import { searchGa } from '../../lib/analytics/ga'
import { customDimensions } from '../../lib/analytics/custom-dimensions'
import { getSelectedProfileIdFromSession } from '../../lib/account'

export const isLoggedOutOnKidsPage = ({ isAuthenticated, location }) => pathOr('', ['pathname'], location) === '/kids' && !isAuthenticated

const mapStateToProps = ({ navigation: { searchIsClicked }, session }) => ({
  isClicked: searchIsClicked,
  profileId: getSelectedProfileIdFromSession(session)
})

const mapDispatchToProps = dispatch => ({
  setIsClicked: (isClicked) => {
    dispatch(setSearchIsClicked(isClicked))
  }
})

const handleQuery = (history, location, query, ignoreMin) => {
  if (query.length === 0) {
    history.push({
      pathname: location.pathname,
      search: ''
    })
  } else if (ignoreMin || query.length >= SEARCH.MIN_CHAR) {
    const search = qs.stringify({
      search: query
    })

    history.push({
      pathname: location.pathname,
      search
    })
  }
}

const throttledHandleQuery = debounce(
  handleQuery,
  SEARCH.DEBOUNCE_WAIT,
  { trailing: true }
)

const initialQueryState = ({ location }) => qs.parse(location.search).search || ''

const enhance = compose(
  setDisplayName('SearchNavButtonContainer'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withState('query', 'setQuery', initialQueryState),
  withHandlers(
    {
      handleChange: ({ setQuery }) => (event) => {
        const value = event.target.value
        setQuery(value)
      },
      handleInputToggle: ({ setIsClicked, profileId }) => (event) => {
        event.preventDefault()
        searchGa({ [customDimensions.ProfileId]: profileId })
        setIsClicked(true)
      },
      handleOnClickOutside: ({ setIsClicked, isClicked }) => () => {
        if (!isClicked) {
          return
        }
        setIsClicked(false)
      },
      handleClearInput: ({ setQuery }) => () => {
        setQuery('')
      }
    }
  ),
  branch(
    isLoggedOutOnKidsPage,
    renderNothing
  ),
  lifecycle({
    componentDidMount() {
      const { history, location, query } = this.props
      if (!query || (query && query.length < SEARCH.MIN_CHAR)) {
        return
      }
      throttledHandleQuery(history, location, query)
    },
    UNSAFE_componentWillReceiveProps(newProps) {
      const { history, location, query } = newProps
      const currQuery = this.props.query

      if (currQuery !== query) {
        throttledHandleQuery(history, location, query)
      } else if (currQuery && !qs.parse(location.search).search) {
        this.props.handleClearInput()
      }
    }
  }),
  onlyUpdateForKeys(['query', 'isClicked'])
)

export default enhance(SearchNavButton)
