import { compose, branch, mapProps } from 'recompose'
import withConfigCacheOnly from './with-config'
import { deepLinkWatchUrlSelector } from '../selectors/config'
import NavLinkWithQuery from '../components/shared/nav-link-with-query'
import { iOS, Android } from '../utils/os-device'

export function isString(arg) {
  return typeof arg === 'string'
}

export function hasQSParams(location) {
  if (isString(location)) {
    return location.includes('?')
  }
  const { search } = location
  return Boolean(search)
}

export function isNotATrailer(location) {
  if (isString(location)) {
    return !location.includes('trailer')
  }
  const { pathname } = location
  return Boolean(pathname && !pathname.includes('trailer'))
}

// We generate a deep linkable url:
// 1. If user is on Android or iOS.
// 2. If `to` is a string or an object and does NOT contain any query strings.
// 3. `to` is not a trailer url
export function shouldDeepLink(to) {
  return Boolean((iOS || Android) && !hasQSParams(to) && isNotATrailer(to))
}

export function getDeepLinkUrl(baseUrl, to) {
  if (isString(to)) {
    return baseUrl + to
  }

  return baseUrl + to.pathname
}

// Allows deep linking specific pathnames on mobile devices.
// By providing a 'target', react-router's Link will not
//    a) prevent default click behavior
//    b) use history.push or history.replace
// both of which prevents deep linking.
export const DeepLink = compose(
  branch(
    ({ to }) => shouldDeepLink(to),
    compose(
      withConfigCacheOnly,
      mapProps(({
        appConfig, configLoading, configError, to, ...rest
      }) => {
        const baseUrl = deepLinkWatchUrlSelector(appConfig)
        const destinationUrl = getDeepLinkUrl(baseUrl, to)
        return {
          target: '_self',
          to: destinationUrl,
          ...rest
        }
      })
    )
  )
)(NavLinkWithQuery)
