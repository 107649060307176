
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PlaybackQualityOptionsFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"PlaybackQualityOptions"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"wifi"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PlaybackQualityOptionFields"},"directives":[]}]}}]}}],"loc":{"start":0,"end":157}};
    doc.loc.source = {"body":"#import \"./playback-quality-option.gql\"\n\nfragment PlaybackQualityOptionsFields on PlaybackQualityOptions {\n  wifi {\n    ...PlaybackQualityOptionFields\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./playback-quality-option.gql").definitions));


      module.exports = doc;
    
