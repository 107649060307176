// REMOVEME: Remove this file after we go live with Neon content

import {
  basicPlanDetailsForAvod,
  standardPlanDetailsForAvod,
  annuallyPlanDetailsForAvod,
  legacyPlanDetailsForAvod
} from '../modules/shared/subscription-constants'

const usePlanChangeFlag = () => {
/**
 * after the avod price change, we just need to remove FF isAvodCollectDemoVisible
 * and keep the following object
 * {
 *   basic: basicPlanDetailsForAvod,
 *   standard: standardPlanDetailsForAvod,
 *   annual: annuallyPlanDetailsForAvod,
 *   legacy: legacyPlanDetailsForAvod
 * }
 *
 * and in next price change, we only need to use a new set of *PlanDetails (or new FF)
 * For example:
 * const isAvodCollectDemoVisible =  useAvodCollectDemoFlag()
 * if (isAvodCollectDemoVisible) { return new plans } else { return old plans }
 */

  return {
    basic: basicPlanDetailsForAvod,
    standard: standardPlanDetailsForAvod,
    annual: annuallyPlanDetailsForAvod,
    legacy: legacyPlanDetailsForAvod
  }
}

export { usePlanChangeFlag }
