import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

import Head from './head'
import { appConfigPropType } from './components/player/utils/prop-types'
import Routes from './containers/routes'
import LoadingOverlay from './containers/loading-overlay'
import ServiceMessages from './containers/service-messages'
import PlanUpgradePopup from './components/modals/plan-upgrade-popup/plan-upgrade-popup'

const arePropsEqual = ({ location, theme }, { newLocation, newTheme }) => {
  return location === newLocation || theme === newTheme
}

const Main = React.memo(({ location, appConfig }) => {
  return (
    <div>
      <Head />
      <LoadingOverlay />
      <ServiceMessages />
      <PlanUpgradePopup appConfig={appConfig} />
      <Route component={Routes} />
    </div>
  )
}, arePropsEqual)

Main.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  theme: PropTypes.string.isRequired,
  appConfig: appConfigPropType.isRequired
}

export default Main
