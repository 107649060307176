import { graphql } from 'react-apollo'
import {
  compose, branch, renderNothing, withProps
} from 'recompose'

import { path, pathOr } from 'ramda'
import { format } from 'date-fns'

import withIsAuthenticated from '../../../../../hoc/is-authenticated'
import ACCOUNT_QUERY from '../../../../../../graphql/queries/account.gql'
import RentalsTable from '../../../../../components/settings/my-account/my-activity/rentals/rentals-table'

const enhance = compose(
  withIsAuthenticated,
  graphql(ACCOUNT_QUERY, {
    name: 'accountQuery',
    skip: ownProps => !ownProps.isAuthenticated,
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    }
  }),
  branch(
    ({ accountQuery }) => (
      !accountQuery ||
      (accountQuery && (accountQuery.loading))
    ),
    renderNothing
  ),
  withProps(({ accountQuery }) => ({
    purchases: (
      accountQuery.account.purchases.items.filter(purchase => (
        !(path(['product', 'renewable'], purchase))
      )).map(purchase => (
        {
          boxart: pathOr('', ['contentItem', 'images', '0', 'uri'], purchase),
          title: (purchase.contentItem && purchase.contentItem.title) || null,
          rented: {
            label: format(new Date(purchase.startAvailable), 'd/MM/yyyy'),
            value: purchase.startAvailable
          },
          expires: {
            label: format(new Date(purchase.endViewable || purchase.endAvailable), 'd/MM/yyyy'),
            value: purchase.endAvailable
          },
          price: `$${purchase.total}`
        }
      ))
    ) || []
  }))
)

export default enhance(RentalsTable)
