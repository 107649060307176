import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './button.css'

// TODO: add submit prop and make submit more explicit
// Currently Button is of type submit if no onClick was defined.
function Button({
  wrapperClassName,
  className,
  secondary,
  disabled,
  onClick,
  text,
  error,
  'data-e2e': e2e
}) {
  const classes = classNames(
    styles.submitButton,
    {
      [styles.secondary]: secondary
    },
    className
  )

  return (
    <div className={wrapperClassName}>
      {
        onClick
          ? (
            <button // eslint-disable-line jsx-a11y/no-static-element-interactions
              onClick={onClick}
              className={classes}
              data-e2e={e2e}
              disabled={disabled}
            >
              {text}
            </button>
          )
          : (
            <button
              type="submit"
              data-e2e={e2e}
              className={classes}
              disabled={disabled}
            >
              {text}
            </button>
          )
      }
      <span className={styles.warning}>{error}</span>
    </div>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  secondary: PropTypes.bool,
  disabled: PropTypes.bool,
  text: PropTypes.string.isRequired,
  error: PropTypes.string,
  onClick: PropTypes.func,
  wrapperClassName: PropTypes.string,
  'data-e2e': PropTypes.string
}

Button.defaultProps = {
  className: null,
  secondary: false,
  disabled: false,
  onClick: null,
  error: null,
  wrapperClassName: ''
}

export default Button
