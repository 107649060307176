import apolloClient from '../lib/apollo'
import { isPinValid } from '../lib/pin'
import {
  enableLoadingOverlay,
  endLoadingOverlay,
  logout,
  requirePin,
  updateAuthTokenAndDefaultTheme
} from './index'
import { history } from '../store'
import { getJwtFromAccount } from '../lib/auth'
import { getAccountProfileById } from '../lib/account'

import ACCOUNT_MUTATION from '../../graphql/mutations/account.gql'
import ACCOUNT_QUERY from '../../graphql/queries/account.gql'
import { navigationGa, navigationBarGa } from '../lib/analytics/ga'
import { customDimensions } from '../lib/analytics/custom-dimensions'
import { DEFAULT_THEME, KIDS_DEFAULT_THEME } from '../constants'
import { hasHigherParentalRating } from '../lib/utils'
import { segmentIdentify } from '../segment/segment-identify'

export const switchProfile = (selectedProfile, pin, navigationBarKidsGAOption) => {
  return (dispatch) => { // eslint-disable-line
    const loadingOverlayKey = Date.now()
    dispatch(enableLoadingOverlay(loadingOverlayKey, 'profilesSwitch'))
    return apolloClient.mutate({
      mutation: ACCOUNT_MUTATION,
      variables: {
        input: {
          selectedProfile: selectedProfile.id
        },
        pin
      },
      update: (proxy, response) => {
        // Get account data from Apollo Store
        const data = proxy.readQuery({ query: ACCOUNT_QUERY })

        // Update account data after profile change
        const updatedData = {
          ...data,
          account: response.data.account
        }

        // Update session token and selected profile
        proxy.writeQuery({
          query: ACCOUNT_QUERY,
          data: updatedData
        })
      }
    })
      .then((res) => {
        if (window.castPlayer && window.castPlayer.isConnected) {
          window.castPlayer.onProfileChange()
        }
        const { data: { account } } = res
        const currentProfile = getAccountProfileById(account, account.selectedProfile)
        const defaultTheme = currentProfile.isKid ? KIDS_DEFAULT_THEME : DEFAULT_THEME
        dispatch(updateAuthTokenAndDefaultTheme(getJwtFromAccount(account), defaultTheme))
        if (navigationBarKidsGAOption) {
          navigationBarGa({
            ...navigationBarKidsGAOption,
            [customDimensions.ProfileId]: selectedProfile.id,
            [customDimensions.ProfileName]: selectedProfile.name,
            [customDimensions.ParentalControl]: selectedProfile.maxRating
          })
        } else {
          navigationGa({
            action: 'Switch profile',
            [customDimensions.ProfileId]: selectedProfile.id,
            [customDimensions.ProfileName]: selectedProfile.name,
            [customDimensions.ParentalControl]: selectedProfile.maxRating
          })
        }

        history.push('/')
        dispatch(endLoadingOverlay(loadingOverlayKey, 'profilesSwitch'))

        // Add segment data identify for switching profile
        segmentIdentify(account, selectedProfile)
      })
  }
}

export const switchProfileWithPin = (selectedProfile, navigationBarKidsGAOption) => {
  return (dispatch, getState) => {
    const state = getState()
    if (!isPinValid(state)) {
      const onSuccess = (pin) => {
        dispatch(switchProfile(selectedProfile, pin, navigationBarKidsGAOption))
      }
      return dispatch(requirePin(onSuccess))
    }
    return dispatch(switchProfile(selectedProfile, null, navigationBarKidsGAOption))
  }
}

export const logoutAndRedirectTo = (path) => {
  return (dispatch) => {
    const loadingOverlayKey = Date.now()
    dispatch(enableLoadingOverlay(loadingOverlayKey, 'logout'))
    dispatch(logout())
    const newPath = path || '/'
    history.push(newPath)
    dispatch(endLoadingOverlay(loadingOverlayKey, 'logout'))
  }
}

export const handleSwitchProfile = ({
  dispatch, currentProfile, targetProfile, requirePin: isPinRequired, navigationBarKidsGAOption
}) => {
  isPinRequired && hasHigherParentalRating(currentProfile.maxRating, targetProfile.maxRating)
    ? dispatch(switchProfileWithPin(targetProfile, navigationBarKidsGAOption))
    : dispatch(switchProfile(targetProfile, null, navigationBarKidsGAOption))
}
