import {
  both,
  converge,
  find,
  merge,
  objOf,
  pathEq,
  pathOr,
  pipe,
  prop,
  propEq,
  propOr
} from 'ramda'
import { EV_SUBSCRIPTION_STATUS, SUBSCRIPTION_STATUS } from '../constants'

export const hasPin = pathEq(['hasPin'], true)

export const getAccountProfileById = (
  account,
  profileId
) => find(propEq('id', profileId))(prop('profiles', account))

export const requirePinForManageProfile = account => (
  pathEq(['settings', 'requirePinForManageProfile'], true)(account) &&
  hasPin(account)
)

export const requirePinForSwitchProfile = account => (
  pathEq(['settings', 'requirePinForSwitchProfile'], true)(account) &&
  hasPin(account)
)

export const findDefaultSVODCreditCard = pipe(
  propOr([], 'creditcards'),
  find(both(
    propEq('useForSubscriptions', true),
    propEq('status', 'ACTIVE')
  ))
)

export const findDefaultTVODCreditCard = pipe(
  propOr([], 'creditcards'),
  find(both(
    propEq('useForPurchases', true),
    propEq('status', 'ACTIVE')
  ))
)

export const getSelectedProfileIdFromSession = pathOr('', ['decodedJwt', 'selectedProfile'])

export const hasActiveSubscription = pathEq(['subscription', 'status'], SUBSCRIPTION_STATUS.ACTIVE)

/**
 * TODO: Remove these: isAddSubscriptionAllowed, isUpdateSubscriptionAllowed, isCancelAllowed, isResumeSubscriptionAllowed
 * MW does control the possible actions [ADD, CHANGE, CANCEL, RESUME] instead of DE. Account > subscription > manageSubsOptions
 **/

/**
 * Cancel allowed if
 *  - There is a valid subscription and,
 *  - Subscription is in not Final Bill and,
 *  - There is no cancellation after downgrade/upgrade and Subscription
 *  If subscription and upcoming subscription are final bill, means there was a cancellation after upgrade/dowgrade
 */
export const isCancelAllowed = (
  evSubscriptionStatus,
  evUpcomingSubscriptionStatus
) => {
  const validSubscription = !!evSubscriptionStatus
  const isFinalBill =
    evSubscriptionStatus === EV_SUBSCRIPTION_STATUS.FINAL_BILL &&
    !evUpcomingSubscriptionStatus
  const isChangingSubscriptionCancelled =
    evSubscriptionStatus === EV_SUBSCRIPTION_STATUS.FINAL_BILL &&
    evUpcomingSubscriptionStatus === EV_SUBSCRIPTION_STATUS.FINAL_BILL

  return validSubscription && !isFinalBill && !isChangingSubscriptionCancelled
}

/**
 * Add subscription is allowed only if
 *  - There is no subscription
 */
export const isAddSubscriptionAllowed = evSubscriptionStatus => {
  return !evSubscriptionStatus
}

/**
 * Update subscription is allowed only if
 *  - There is one subscription
 */
export const isUpdateSubscriptionAllowed = evSubscriptionStatus => {
  return evSubscriptionStatus
}

/**
 * Resume is allowed if
 *  - Subscription is in Final Bill
 *  - There is no Downgrade
 *  - There is no Upgrade
 */
export const isResumeSubscriptionAllowed = (
  evSubscriptionStatus,
  evUpcomingSubscriptionStatus
) => {
  return (
    evSubscriptionStatus === EV_SUBSCRIPTION_STATUS.FINAL_BILL &&
    !evUpcomingSubscriptionStatus
  )
}

export function getCurrentProfile(accountQuery) {
  const selectedProfile = accountQuery &&
    accountQuery.account &&
    accountQuery.account.selectedProfile

  const currentProfile = accountQuery &&
    accountQuery.account &&
    accountQuery.account.profiles &&
    selectedProfile &&
    accountQuery.account.profiles.find(profile => profile.id === selectedProfile)

  return currentProfile
}

// Both take `profile` from getCurrentProfile
const pickProfileName = pipe(
  pathOr('', ['name']),
  objOf('name')
) // Returns { name: <profile name> }

const pickProfileAvatarUri = pipe(
  pathOr('', ['avatar', 'uri']),
  objOf('avatarUri')
) // Returns { avatarUri: <profile avatar uri> }

// Takes `accountQuery` data payload
// Returns profile name and avatar uri
// i.e { name: 'John', avatarUri: 'avatar-uri' }.
export const getProfileNameAndAvatarUri = pipe(
  getCurrentProfile,
  converge(
    merge, [pickProfileName, pickProfileAvatarUri]
  )
)

// Takes `accountQuery` data payload
// Returns whether selected profile is kids profile
export const isKidsProfile = pipe(
  getCurrentProfile,
  propOr(false, 'isKid')
)
