import {
  path,
  pathOr
} from 'ramda'

import { getModalLocation } from '../../lib/modal'
import { getDetailsUrl, getWatchUrl } from '../../lib/utils'
import {
  calcProgressPercentage,
  getIcon,
  findPlaybackInfoById,
  getDurationMinutesRemaining,
  getNumberMinutesWatched,
  getTimeString,
  getPlaybackInfo
} from './utils'
import { playerGaPlayButtonEvent } from '../../lib/analytics/ga'
import { dataLayerPlay } from '../../lib/analytics/datalayer'
import {
  MODALS,
  PLAYBACK_INFO
} from '../../constants'
import {
  PLAY_BUTTON_WATCH_NOW_TEXT,
  PLAY_BUTTON_RESUME_TEXT,
  PLAY_BUTTON_COMING_SOON_TEXT
} from './constants'
import { modalContent } from '../../modules/shared/subscription-constants'

const getEpisodeIdToPlay = (playbackInfoQuery, playbackInfoArray) => pathOr(
  path([0, 'contentItemId'], playbackInfoArray), // get first episode as default
  ['playbackInfo', 'episodeToPlay'], // get episodeToPlay from playbackInfoQuery
  playbackInfoQuery
)

const seriesWatchUrlOrModal = ({
  castSender,
  contentItem,
  isAuthenticated,
  location,
  recommended,
  watchable,
  isKid
}) => {
  // REMOVE ME, this is for outlander issue only, remove after season 5 is out
  if (!contentItem && /\/series\/outlander/.test(location.pathname)) return null
  if ((watchable || isKid) && !castSender.isConnected) {
    return getWatchUrl(contentItem)
  }

  if (recommended) {
    return getDetailsUrl(contentItem)
  }

  if (!isAuthenticated) {
    return getModalLocation(
      location,
      MODALS.qsParams.login
    )
  }

  return getModalLocation(
    location,
    MODALS.qsParams.addSubscription,
    { content: modalContent.selection }
  )
}

export const getEpisodeToPlay = ({
  playbackInfoQuery,
  playbackInfoArray
}) => {
  // Get episode Id to play
  return {
    episodeIdToPlay: getEpisodeIdToPlay(playbackInfoQuery, playbackInfoArray)
  }
}

export const getSeriesPlaybackInfo = ({
  playbackInfoArray,
  episodeIdToPlay
}) => {
  // Find episode playback info
  const playbackInfo = getPlaybackInfo(
    findPlaybackInfoById(episodeIdToPlay)(playbackInfoArray)
  )

  return {
    playbackInfo
  }
}

export const getSeriesProps = ({
  isAuthenticated,
  maybeInitializeCastSender,
  watchable,
  contentItem,
  theme,
  displayedInLocation,
  recommended,
  castSender,
  episodeIdToPlay,
  playbackInfo,
  location,
  currentProfile,
  isKid
}) => {
  // Get first episode data (as default)
  let episodeToPlay = path(['seasons', 0, 'episodes', 0], contentItem)

  // Get episode to play data
  if (episodeIdToPlay && playbackInfo) {
    contentItem.seasons.forEach((season) => {
      season.episodes.forEach((episode) => {
        if (episode.id === episodeIdToPlay) {
          episodeToPlay = episode
        }
      })
    })
  }

  // Get number of minutes remaining
  const numberMinutesRemaining = getDurationMinutesRemaining(playbackInfo)

  // Get number of minutes watched
  const numberMinutesWatched = getNumberMinutesWatched(playbackInfo)

  // Get playback status
  const status = path(['status'], playbackInfo)
  const inProgress = status === PLAYBACK_INFO.status.resume

  // Get play button icon
  const icon = getIcon(status, theme, true)

  // Calculate progress indicator
  const progressPercentage = episodeToPlay
    ? calcProgressPercentage(episodeToPlay.duration, numberMinutesWatched, status)
    : 0

  const watchUrl = episodeToPlay && seriesWatchUrlOrModal({
    castSender,
    contentItem: episodeToPlay,
    isAuthenticated,
    location,
    recommended,
    watchable,
    isKid
  })
  // Get play button main call to action text
  let ctaPrefix = PLAY_BUTTON_COMING_SOON_TEXT
  let ctaSuffix = ''
  let clickable = false
  if (episodeToPlay) {
    clickable = true
    if (status === PLAYBACK_INFO.status.resume) {
      ctaPrefix = PLAY_BUTTON_RESUME_TEXT
      ctaSuffix = `S${episodeToPlay.seasonNumber} E${episodeToPlay.episodeNumber}`
    } else {
      ctaPrefix = PLAY_BUTTON_WATCH_NOW_TEXT
    }
  }
  const time = getTimeString(inProgress, numberMinutesRemaining)
  const displayCta = true
  const displayTime = Boolean(inProgress)
  const displayPrice = false
  const onClick = (event) => {
    playerGaPlayButtonEvent(contentItem, displayedInLocation, numberMinutesWatched, currentProfile)
    dataLayerPlay(episodeToPlay)

    // Let Link handle the click and location change
    // if cast sender is disconnected
    if (!castSender.isConnected) {
      return
    }

    if (watchable) {
      // PreventLink from handling the click
      event.preventDefault()

      maybeInitializeCastSender(getWatchUrl(episodeToPlay), episodeToPlay)
    }
  }

  return {
    icon,
    progressPercentage,
    watchUrl,
    ctaPrefix,
    ctaSuffix,
    clickable,
    time,
    displayCta,
    displayTime,
    displayPrice,
    onClick,
    episodeToPlay
  }
}
