import React, { useEffect } from 'react'
import { Router as BrowserRouter } from 'react-router-dom'

import Main from './containers/main'
import AsyncModal from './layouts/shared/modal'
import ErrorDialog from './containers/error-dialog'
import { initialiseGa } from './lib/analytics/ga'
import { initialiseGtm } from './lib/analytics/datalayer'
import configFreshChat from './lib/freshchat'

const App = ({ history }) => {
  useEffect(() => {
    const cleanupFreshchat = configFreshChat(history)
    return () => {
      cleanupFreshchat()
    }
  })

  initialiseGa(history)
  initialiseGtm()

  return (
    <BrowserRouter history={history}>
      <>
        <Main />
        <AsyncModal />
        <ErrorDialog />
      </>
    </BrowserRouter>
  )
}

export default App
